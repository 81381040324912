import { computed } from 'vue';

import { ElLoading } from 'element-plus';
import { useField, useForm } from 'vee-validate';

import { INPUT_TEXT_MAX_LENGTH } from '@/common/constants';
import { showErrorNotificationFunction } from '@/common/helpers';
import { IGanttFilter } from '@/features/4D-planning/interfaces';
import { projectPlanningService } from '@/features/4D-planning/services/planning.service';
import { projectPlanningModule } from '@/features/4D-planning/store';
import yup from '@/plugins/yup';

import {
    ColumnType,
    GanttColumn,
    GanttFilterLogic,
    GanttFilterOperation,
    VIEW_COLUMN_TYPE_SEPARATOR,
} from '../constants';
import { extractColumnWithType } from '../helper';

const columnFilterSchema = yup.object({
    name: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required().label('name'),
    columnWithType: yup.string().required(),
    configs: yup
        .array(
            yup.object({
                logic: yup.string().oneOf(Object.values(GanttFilterLogic)).required(),
                operation: yup
                    .string()
                    .oneOf(Object.values(GanttFilterOperation))
                    .required(),
                value: yup
                    .string()
                    .min(1)
                    .transform((val) => (!val?.length ? undefined : val))
                    .required(),
            }),
        )
        .required(),
});

export const initFilterColumnFormValue = {
    name: '',
    columnWithType: ColumnType.PREDEFINED + VIEW_COLUMN_TYPE_SEPARATOR + GanttColumn.ID,
    configs: [],
};
export const setupFilterColumnForm = () => {
    const { errors, resetForm, handleSubmit, setFieldValue, setValues, values } = useForm(
        {
            validationSchema: columnFilterSchema,
            initialValues: initFilterColumnFormValue,
        },
    );
    const onSubmit = handleSubmit(async (values) => {
        const planning = computed(() => projectPlanningModule.planning);
        const selectedPlanningViewId = computed(
            () => projectPlanningModule.tempPlanningView?._id || '',
        );
        const selectedColumnFilterId = computed(
            () => projectPlanningModule.selectedColumnFilterDetail?._id,
        );

        const { columnName, columnType } = extractColumnWithType(
            values.columnWithType ?? '_',
        );

        const loading = ElLoading.service({});
        let response;
        if (selectedColumnFilterId.value?.length) {
            response = await projectPlanningService.updateColumnFilter(
                planning.value?._id || '',
                selectedPlanningViewId.value,
                selectedColumnFilterId.value,
                {
                    name: values.name as string,
                    columnName,
                    columnType,
                    configs: values.configs?.map((config) => ({
                        logic: config.logic,
                        operation: config.operation,
                        value: config.value,
                    })) as IGanttFilter[],
                },
            );
        } else {
            response = await projectPlanningService.createColumnFilter(
                planning.value?._id || '',
                selectedPlanningViewId.value,
                {
                    name: values.name as string,
                    columnName,
                    columnType,
                    configs: values.configs?.map((config) => ({
                        logic: config.logic,
                        operation: config.operation,
                        value: config.value,
                    })) as IGanttFilter[],
                },
            );
        }
        if (!response.success) {
            showErrorNotificationFunction(response.message);
        }
        loading.close();

        return response;
    });

    const { value: name } = useField('name');
    const { value: columnWithType } = useField('columnWithType');
    const { value: configs } = useField('configs');
    return {
        onSubmit,
        name,
        columnWithType,
        configs,
        errors,
        setFieldValue,
        setValues,
        resetForm,
        values,
    };
};
