import { showErrorNotificationFunction } from '@/common/helpers';
import yup from '@/plugins/yup';
import { useField, useForm } from 'vee-validate';
import { CalendarForDelayLinkOption, InProgressTaskOption } from '../constants';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';

const planningFormSchema = yup.object({
    inProgressTaskOption: yup
        .string()
        .oneOf([...Object.values(InProgressTaskOption)])
        .required(),
    calendarForDelayLink: yup
        .string()
        .oneOf([...Object.values(CalendarForDelayLinkOption)])
        .required(),
    useExpectedFinish: yup.boolean().required(),
    disableScheduleTaskWithoutLinkAndConstraints: yup.boolean().required(),
    criticalTaskWithoutSuccessor: yup.boolean().required(),
    autoScheduling: yup.boolean().required(),
});

export const useRescheduleOptionForm = () => {
    const initValues = {
        inProgressTaskOption: InProgressTaskOption.PROGRESS_OVERRIDE,
        calendarForDelayLink: CalendarForDelayLinkOption.PREDECESSOR,
        useExpectedFinish: false,
        disableScheduleTaskWithoutLinkAndConstraints: false,
        criticalTaskWithoutSuccessor: false,
        autoScheduling: false,
    };

    const { handleSubmit, setValues, setFieldValue, resetForm, errors } = useForm({
        initialValues: initValues,
        validationSchema: planningFormSchema,
    });

    const onSubmit = handleSubmit(async (values) => {
        const response = await projectPlanningService.updateRescheduleOption(
            projectPlanningModule.planning?._id as string,
            {
                ...values,
                inProgressTaskOption: values.inProgressTaskOption as InProgressTaskOption,
                calendarForDelayLink:
                    values.calendarForDelayLink as CalendarForDelayLinkOption,
            },
        );
        if (response.success) {
            return response.data;
        } else {
            showErrorNotificationFunction(response.message);
            return undefined;
        }
    });

    const { value: inProgressTaskOption } = useField('inProgressTaskOption');
    const { value: calendarForDelayLink } = useField('calendarForDelayLink');
    const { value: useExpectedFinish } = useField('useExpectedFinish');
    const { value: disableScheduleTaskWithoutLinkAndConstraints } = useField(
        'disableScheduleTaskWithoutLinkAndConstraints',
    );
    const { value: criticalTaskWithoutSuccessor } = useField(
        'criticalTaskWithoutSuccessor',
    );
    const { value: autoScheduling } = useField('autoScheduling');

    return {
        onSubmit,
        setValues,
        resetForm,
        setFieldValue,
        initValues,
        errors,
        inProgressTaskOption,
        calendarForDelayLink,
        useExpectedFinish,
        disableScheduleTaskWithoutLinkAndConstraints,
        criticalTaskWithoutSuccessor,
        autoScheduling,
    };
};
