export const projectNotification = {
    breadcrumb: {
        projectNotification: 'Project notification',
    },
    table: {
        label: {
            fullName: 'Full name',
            date: 'Date',
            target: 'Target',
            action: 'Action',
        },
        action: {
            resetBaseline: 'Reset baseline',
            applyBaseline: 'Apply baseline',
            createTaskField: 'Create task field',
            updateTaskField: 'Update task field',
            deleteTaskField: 'Delete task field',
            updatePlanning: 'Update planning',
            deletePlanning: 'Delete planning',
            createResource: 'Create resource',
            createResourceGroup: 'Create resource group',
            assignResource: 'Assign resource',
            deleteAppearanceProfile: 'Delete appearance profile',
            updateAppearanceProfile: 'Update appearance profile',
            createAppearanceProfile: 'Create appearance profile',
            deleteResourceGroup: 'Delte resource group',
            updateResourceGroup: 'Update resource group',
            updateResource: 'Update resource',
            deleteResource: 'Delete resource',
            createBaseline: 'Create baseline',
            updateBaseline: 'Update baseline',
            deleteBaseline: 'Delete baseline',
            updateBaselineConfiguration: 'Update baseline configuration',
            createActivityCode: 'Create activity code',
            updateActivityCode: 'Update activity code',
            deleteActivityCode: 'Delete activity code',
            assignActivityCode: 'Assign activity code',

            delegate: 'Delegate',
            synthesis: 'Systhesis',
            createTask: 'Create task',
            createLink: 'Create link',
            deleteLink: 'Delete link',
            updateLink: 'Update link',
            updateTask: 'Update task',
            deleteTask: 'Delete task',
            topDown: 'Top down',
            bottomUp: 'Bottom up',
            absEditFolder: 'Edit Folder',
            absCreateFolder: 'Create Folder',
            absDeleteFolder: 'Delete Folder',
            absUploadFile: 'Upload File',
            absRenameFile: 'Rename File',
            absDeleteFile: 'Delete File',
            absAssign4D: 'Assign 4D',
            absAssignPBS: 'Assign PBS',
            absAssign4DBox: 'Assign 4DBox',
            absAssignPlanning: 'Assign Planning',
            absUnassign4D: 'Unassign 4D',
            absUnassignPBS: 'Unassign PBS',
            absUnassign4DBox: 'Unassign 4DBox',
            absUnassignPlanning: 'Unassign Planning',
        },
    },
    filter: {
        keyword: {
            label: 'Keyword',
            placeholder: 'Keyword',
        },
        type: {
            label: 'Type',
            placeholder: 'Type',
        },
        option: {
            resetBaseline: 'Reset baseline',
            applyBaseline: 'Apply baseline',
            createTaskField: 'Create task field',
            updateTaskField: 'Update task field',
            deleteTaskField: 'Delete task field',
            updatePlanning: 'Update planning',
            deletePlanning: 'Delete planning',
            createResource: 'Create resource',
            createResourceGroup: 'Create resource group',
            assignResource: 'Assign resource',
            deleteAppearanceProfile: 'Delete appearance profile',
            updateAppearanceProfile: 'Update appearance profile',
            createAppearanceProfile: 'Create appearance profile',
            deleteResourceGroup: 'Delete resource group',
            updateResourceGroup: 'Update resource group',
            updateResource: 'Update resource',
            deleteResource: 'Delete resource',
            createBaseline: 'Create baseline',
            updateBaseline: 'Update baseline',
            deleteBaseline: 'Delete baseline',
            updateBaselineConfiguration: 'Update baseline configuration',
            createActivityCode: 'Create activity code',
            updateActivityCode: 'Update activity code',
            deleteActivityCode: 'Delete activity code',
            assignActivityCode: 'Assign activity code',

            delegate: 'Delegate',
            synthesis: 'Synthesis',
            createTask: 'Create task',
            createLink: 'Create link',
            deleteLink: 'Delete link',
            updateLink: 'Update link',
            updateTask: 'Update task',
            deleteTask: 'Delete task',
            topDown: 'Top down',
            bottomUp: 'Bottom up',
        },
    },
};
