import localStorageAuthService from '@/common/authStorage';
import { PageName } from '@/common/constants';
import ganttChartStorage from '@/common/ganttChartStorage';
import {
    ProfilePermissionPrefix,
    ProjectSecurityPermissions,
} from '@/features/3D-viewer-profile/constants';
import { accessLogModule } from '@/features/access-log/store';
import { projectModule } from '@/features/project/store';
import router from '@/plugins/vue-router';
import { computed } from 'vue';

//Admin
export const sidebarProject = [
    // Dashboard
    {
        iconLink: require('@/assets/icons/chart-pie.svg'),
        name: 'app.sidebar.constellation.project.dashboard',
        active: false,
        to: '#',
        pageName: PageName.PROJECT_DETAIL_PAGE,
        onClick: () => {
            router.push({
                name: PageName.PROJECT_DETAIL_PAGE,
                params: {
                    projectId: computed(() => projectModule.selectedProjectId).value,
                },
            });
        },
    },

    // ABS
    {
        iconLink: require('@/assets/icons/folder.svg'),
        name: 'app.sidebar.constellation.project.abs',
        active: false,
        pageName: PageName.ABS_PAGE,
        to: '/abs',
        requiredPermissions: [],
    },

    // Admin
    {
        iconLink: require('@/assets/icons/user.svg'),
        name: 'app.sidebar.constellation.project.admin.title',
        active: false,
        requiredPermissions: [ProjectSecurityPermissions.GENERAL_ACCESS_TO_ADMIN],
        requireProjectProfile: true,
        children: [
            {
                name: 'app.sidebar.constellation.project.admin.user',
                active: false,
                to: '/user',
                pageName: PageName.USER_LIST_PAGE,
                requiredPermissions: [ProjectSecurityPermissions.GENERAL_ACCESS_TO_ADMIN],
            },
            {
                name: 'app.sidebar.constellation.project.admin.group',
                active: false,
                to: '/project-group',
                pageName: PageName.PROJECT_GROUP_LIST_PAGE,
                requiredPermissions: [ProjectSecurityPermissions.GENERAL_ACCESS_TO_ADMIN],
            },
            {
                name: 'app.sidebar.constellation.project.admin.projectProfile',
                active: false,
                to: '/project-profile',
                pageName: PageName.PROJECT_PROFILE_LIST_PAGE,
                requiredPermissions: [ProjectSecurityPermissions.GENERAL_ACCESS_TO_ADMIN],
            },
            {
                name: 'app.sidebar.constellation.project.admin.pbs',
                active: false,
                to: '/pbs',
                pageName: PageName.PBS_PAGE,
                requiredPermissions: [ProjectSecurityPermissions.GENERAL_ACCESS_TO_ADMIN],
            },
        ],
    },

    // Notifications
    {
        iconLink: require('@/assets/icons/bell.svg'),
        name: 'app.sidebar.constellation.project.notifications',
        active: false,
        to: '/project-notification',
        pageName: PageName.PROJECT_NOTIFICATION_PAGE,
    },

    // Project
    {
        iconLink: require('@/assets/icons/building.svg'),
        name: 'app.sidebar.constellation.project.project.title',
        active: false,
        requireProjectProfile: true,
        requiredPermissions: [ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT],
        children: [
            {
                name: 'app.sidebar.constellation.project.project.3D',
                active: false,
                to: '/project/3d-viewer',
                pageName: PageName['3D_PAGE'],
                require3DViewerRole: true,
                requiredPermissions: [
                    ...Object.values(ProjectSecurityPermissions).filter((permission) =>
                        permission.startsWith(ProfilePermissionPrefix.WEBVIEWER3D),
                    ),
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
            {
                name: 'app.sidebar.constellation.project.project.4DAnalyzer',
                active: false,
                to: '/project/4D-analyzer',
                pageName: PageName['4D_ANALYZER_PAGE'],
                requiredPermissions: [
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
            {
                name: 'app.sidebar.constellation.project.project.calendar',
                active: false,
                to: '/project/calendar',
                pageName: PageName.CALENDAR_PAGE,
                requiredPermissions: [
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
            {
                name: 'app.sidebar.constellation.project.project.activityCode',
                active: false,
                to: '/project/activity-code',
                pageName: PageName.ACTIVITY_CODE_LIST_PAGE,
                requiredPermissions: [
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
            {
                name: 'app.sidebar.constellation.project.project.userDefinedField',
                active: false,
                to: '/project/user-defined-field',
                pageName: PageName.USER_DEFINED_FIELD_LIST_PAGE,
                requiredPermissions: [
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
            {
                name: 'app.sidebar.constellation.project.project.appearanceProfile',
                active: false,
                to: '/project/appearance-profile',
                pageName: PageName.APPEARANCE_PROFILE_PAGE,
                requiredPermissions: [
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
            {
                name: 'app.sidebar.constellation.project.project.resource',
                active: false,
                to: '/project/resource',
                pageName: PageName.RESOURCE_PAGE,
                requiredPermissions: [
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
            {
                name: 'app.sidebar.constellation.project.project.taskRule',
                active: false,
                to: '/project/task-rule',
                pageName: PageName.TASK_RULE_PAGE,
                requiredPermissions: [
                    ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                ],
            },
        ],
    },

    // Exit project
    {
        iconLink: require('@/assets/icons/signout.svg'),
        name: 'app.sidebar.constellation.project.exitProject',
        active: false,
        to: '#',
        onClick: () => {
            projectModule.setSelectedProjectId(null);
            localStorageAuthService.setSelectedProjectId('');
            localStorageAuthService.resetProjectSecurityPermissions();
            localStorageAuthService.resetProjectAdminId();
            ganttChartStorage.resetCalendar();
            accessLogModule.updateAccessLogModule(
                localStorageAuthService.getAccessLogId(),
            );
            router.push({
                name: PageName.PROJECT_MAP_PAGE,
            });
        },
    },
];
