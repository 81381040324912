import { UtilMixins } from '@/mixins/utilMixins';
import { Chart } from 'chart.js';
import { mixins } from 'vue-class-component';
import {
    COORDINATES,
    PLACE,
    projectAttributes,
    projectProfileAttributes,
    ProjectLogActions,
    projectGroupAttributes,
    userAttributes,
    groupAttributes,
    ProjectLogHistoryActionOptions,
    ProjectLogTransactionActionOptions,
    ProjectLogType,
    DateRangeTypes,
} from './constant';
import { IProjectLog, PinnedData } from './interfaces';
import isEqual from 'lodash/isEqual';

export class ProjectLogMixins extends mixins(UtilMixins) {
    dateRangeTypes = DateRangeTypes;
    projectLogHistoryActionOptions = ProjectLogHistoryActionOptions;
    projectLogTransactionActionOptions = ProjectLogTransactionActionOptions;
    projectLogType = ProjectLogType;
    chart: Chart | null = null;
    pinnedData: PinnedData[] = [];

    parseProjectLogValue(projectLog: IProjectLog) {
        let oldData = '';
        let newData = '';
        switch (projectLog.action) {
            case ProjectLogActions.CREATE_PROJECT:
                projectAttributes.forEach((key) => {
                    if (key === PLACE) {
                        newData += `<b>${key}</b>: ${
                            this.getDisplayAddress(
                                projectLog.newData[COORDINATES] as string,
                            ) || ''
                        }<br/>`;
                    } else {
                        newData =
                            newData +
                            `<b>${key}</b>: ${projectLog.newData[key] || ''}<br/>`;
                    }
                });
                break;
            case ProjectLogActions.UPDATE_PROJECT:
                projectAttributes.forEach((key) => {
                    if (key === PLACE) {
                        newData += `<b>${key}</b>: ${
                            this.getDisplayAddress(
                                projectLog.newData[COORDINATES] as string,
                            ) || ''
                        }<br/>`;
                        oldData += `<b>${key}</b>: ${
                            this.getDisplayAddress(
                                projectLog.oldData[COORDINATES] as string,
                            ) || ''
                        }<br/>`;
                    } else {
                        newData += `<b>${key}</b>: ${projectLog.newData[key] || ''}<br/>`;
                        oldData += `<b>${key}</b>: ${projectLog.oldData[key] || ''}<br/>`;
                    }
                });
                break;
            case ProjectLogActions.DELETE_PROJECT:
                projectAttributes.forEach((key) => {
                    if (key === PLACE) {
                        oldData += `<b>${key}</b>: ${
                            this.getDisplayAddress(
                                projectLog.oldData[COORDINATES] as string,
                            ) || ''
                        }<br/>`;
                    } else {
                        oldData += `<b>${key}</b>: ${projectLog.oldData[key] || ''}<br/>`;
                    }
                });
                break;
            case ProjectLogActions.CREATE_PROJECT_PROFILE:
                projectProfileAttributes.forEach((key) => {
                    newData =
                        newData +
                        `<b>${key}</b>: ${
                            (projectLog.newData[key] as string)?.toString() || ''
                        }<br/>`;
                });
                break;
            case ProjectLogActions.UPDATE_PROJECT_PROFILE:
                projectProfileAttributes.forEach((key) => {
                    newData =
                        newData +
                        `<b>${key}</b>: ${
                            (projectLog.newData[key] as string)?.toString() || ''
                        }<br/>`;
                    oldData =
                        oldData +
                        `<b>${key}</b>: ${
                            (projectLog.oldData[key] as string)?.toString() || ''
                        }<br/>`;
                });
                break;
            case ProjectLogActions.DELETE_PROJECT_PROFILE:
                projectProfileAttributes.forEach((key) => {
                    oldData =
                        oldData +
                        `<b>${key}</b>: ${
                            (projectLog.oldData[key] as string)?.toString() || ''
                        }<br/>`;
                });
                break;
            case ProjectLogActions.CREATE_PROJECT_GROUP:
                projectGroupAttributes.forEach((key) => {
                    newData =
                        newData + `<b>${key}</b>: ${projectLog.newData[key] || ''}<br/>`;
                });
                break;
            case ProjectLogActions.UPDATE_PROJECT_GROUP:
                projectGroupAttributes.forEach((key) => {
                    newData =
                        newData + `<b>${key}</b>: ${projectLog.newData[key] || ''}<br/>`;
                    oldData =
                        oldData + `<b>${key}</b>: ${projectLog.oldData[key] || ''}<br/>`;
                });
                break;
            case ProjectLogActions.DELETE_PROJECT_GROUP:
                projectGroupAttributes.forEach((key) => {
                    oldData =
                        oldData + `<b>${key}</b>: ${projectLog.oldData[key] || ''}<br/>`;
                });
                break;
            case ProjectLogActions.CREATE_USER:
            case ProjectLogActions.ASSIGN_TO_PROJECT_USER:
                userAttributes.forEach((key) => {
                    newData =
                        newData + `<b>${key}</b>: ${projectLog.newData[key] || ''}<br/>`;
                });
                break;
            case ProjectLogActions.UPDATE_USER:
                userAttributes.forEach((key) => {
                    newData =
                        newData + `<b>${key}</b>: ${projectLog.newData[key] || ''}<br/>`;
                    oldData =
                        oldData + `<b>${key}</b>: ${projectLog.oldData[key] || ''}<br/>`;
                });
                break;
            case ProjectLogActions.REMOVE_FROM_PROJECT_USER:
                userAttributes.forEach((key) => {
                    oldData =
                        oldData + `<b>${key}</b>: ${projectLog.oldData[key] || ''}<br/>`;
                });
                break;
            case ProjectLogActions.ASSIGN_TO_PROJECT_GROUP:
                groupAttributes.forEach((key) => {
                    newData =
                        newData + `<b>${key}</b>: ${projectLog.newData[key] || ''}<br/>`;
                });
                break;

            case ProjectLogActions.REMOVE_FROM_PROJECT_GROUP:
                groupAttributes.forEach((key) => {
                    oldData =
                        oldData + `<b>${key}</b>: ${projectLog.oldData[key] || ''}<br/>`;
                });
                break;
            default:
                break;
        }
        return { ...projectLog, oldData, newData };
    }
    onClickChart(evt: any) {
        const click = this.chart?.getElementsAtEventForMode(
            evt,
            'nearest',
            { intersect: false },
            true,
        );
        if (click?.length) {
            let y = click[0]?.element?.y ?? NaN;
            let x = click[0]?.element?.x ?? NaN;
            const index = click[0].index;
            const datasetIndexes = click.map((cl) => cl.datasetIndex);
            if ((click[0].element as any).outerRadius) {
                // Doughnut and Pie Charts
                const element = click?.[0].element as any;
                const angle = (element.endAngle + element.startAngle) / 2;
                const originX = element.outerRadius / 2;
                const originY = 0;
                x += originX * Math.cos(angle) - originY * Math.sin(angle);
                y += originX * Math.sin(angle) + originY * Math.cos(angle);
            }
            const tooltipIndex = this.pinnedData.findIndex(
                (tooltip) =>
                    tooltip.index === index &&
                    isEqual(tooltip.datasetIndexes, datasetIndexes),
            );
            if (tooltipIndex === -1) {
                this.pinnedData.push({
                    x,
                    y,
                    index,
                    datasetIndexes,
                });
            } else {
                this.pinnedData.splice(tooltipIndex, 1);
            }
        }
    }
}
