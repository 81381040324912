import yup from '@/plugins/yup';
import { useField, useForm } from 'vee-validate';
import { SpeedRunSimulationOption } from '../constants';
import moment from 'moment';

const planningFormSchema = yup
    .object({
        speed: yup
            .string()
            .oneOf([...Object.values(SpeedRunSimulationOption)])
            .nullable()
            .required(),
        delay: yup.number().nullable().required(),
    })
    .shape(
        {
            startDate: yup
                .date()
                .nullable()
                .required()
                .test({
                    name: 'startDate',
                    message: 'Start date must be less than end date',
                    exclusive: false,
                    params: {},
                    test: function (value) {
                        if (value && this.parent.endDate)
                            return (
                                moment(value)
                                    .startOfDay()
                                    .diff(
                                        moment(this.parent.endDate).startOfDay(),
                                        'millisecond',
                                    ) <= 0
                            );
                        return true;
                    },
                }),
            endDate: yup
                .date()
                .nullable()
                .required()
                .test({
                    name: 'endDate',
                    message: 'End date must be greater than start date',
                    exclusive: false,
                    params: {},
                    test: function (value) {
                        if (value && this.parent.startDate)
                            return (
                                moment(value)
                                    .startOfDay()
                                    .diff(
                                        moment(this.parent.startDate).startOfDay(),
                                        'millisecond',
                                    ) >= 0
                            );
                        return true;
                    },
                }),
        },
        [['startDate', 'endDate']],
    );

export const useSimulationOptionForm = () => {
    const initValues = {
        speed: null,
        startDate: null,
        endDate: null,
        delay: null,
    };

    const { setValues, setFieldValue, resetForm, errors, validate } = useForm({
        initialValues: initValues,
        validationSchema: planningFormSchema,
    });

    const { value: speed } = useField('speed');
    const { value: startDate } = useField('startDate');
    const { value: endDate } = useField('endDate');
    const { value: delay } = useField('delay');

    return {
        setValues,
        resetForm,
        setFieldValue,
        validate,
        initValues,
        errors,
        speed,
        startDate,
        endDate,
        delay,
    };
};
