import yup from '@/plugins/yup';
import { useField, useForm } from 'vee-validate';
import { IUpdateResourceAssignment } from '../interfaces';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';
import { showErrorNotificationFunction } from '@/common/helpers';

const assignResourceDetailSchema = yup.object({
    id: yup.string().required(),
    resourceId: yup.string().required(),
    resourceName: yup.string().required(),
    status: yup.string().optional().nullable(),
    isDriving: yup.boolean().optional(),
    isFixedUnit: yup.boolean().optional(),
    actualUnitPerPeriod: yup.number().optional(),
    budgetedUnit: yup.number().optional(),
    remainingUnit: yup.number().optional(),
    actualUnit: yup.number().optional(),
    atCompletionUnit: yup.number().optional(),
    resourceAccount: yup.number().optional(),
    budgetedCost: yup.number().optional(),
    remainingCost: yup.number().optional(),
    actualCost: yup.number().optional(),
    atCompletionCost: yup.number().optional(),
    fixedCost: yup.number().optional(),
});

const initData = {
    id: '',
    resourceId: '',
    resourceName: '',
    status: '',
    isDriving: false,
    isFixedUnit: false,
    actualUnitPerPeriod: NaN,
    budgetedUnit: NaN,
    remainingUnit: NaN,
    actualUnit: NaN,
    atCompletionUnit: NaN,
    resourceAccount: NaN,
    budgetedCost: NaN,
    remainingCost: NaN,
    actualCost: NaN,
    atCompletionCost: NaN,
    fixedCost: NaN,
};

export const AssignResourceDetailForm = () => {
    const { errors, resetForm, validate, setErrors, setValues, setFieldValue } = useForm({
        initialValues: initData,
        validationSchema: assignResourceDetailSchema,
    });

    const onSubmit = async (body: IUpdateResourceAssignment, taskId: string) => {
        const response = await projectPlanningService.updateResourceAssignment(
            body,
            taskId,
        );
        if (response.success) {
            projectPlanningModule.setNeedReloadTaskDetail(true);
            return response.data;
        } else if (!response?.isRequestError) {
            showErrorNotificationFunction(response.message);
            return undefined;
        }
    };

    const { value: id } = useField('id');
    const { value: resourceId } = useField('resourceId');
    const { value: resourceName } = useField('resourceName');
    const { value: status } = useField('status');
    const { value: isDriving } = useField('isDriving');
    const { value: isFixedUnit } = useField('isFixedUnit');
    const { value: actualUnitPerPeriod } = useField('actualUnitPerPeriod');
    const { value: budgetedUnit } = useField('budgetedUnit');
    const { value: remainingUnit } = useField('remainingUnit');
    const { value: actualUnit } = useField('actualUnit');
    const { value: atCompletionUnit } = useField('atCompletionUnit');
    const { value: resourceAccount } = useField('resourceAccount');
    const { value: budgetedCost } = useField('budgetedCost');
    const { value: remainingCost } = useField('remainingCost');
    const { value: actualCost } = useField('actualCost');
    const { value: atCompletionCost } = useField('atCompletionCost');
    const { value: fixedCost } = useField('fixedCost');

    return {
        errors,
        onSubmit,
        resetForm,
        setErrors,
        validate,
        setValues,
        setFieldValue,
        id,
        resourceId,
        resourceName,
        status,
        isDriving,
        isFixedUnit,
        actualUnitPerPeriod,
        budgetedUnit,
        remainingUnit,
        actualUnit,
        atCompletionUnit,
        resourceAccount,
        budgetedCost,
        remainingCost,
        actualCost,
        atCompletionCost,
        fixedCost,
    };
};
