import { computed } from 'vue';

import { ElLoading } from 'element-plus';
import { useField, useForm } from 'vee-validate';

import {
    DATE_TIME_FORMAT,
    INPUT_TEXT_MAX_LENGTH,
    INTEGER_POSITIVE_MIN_VALUE,
} from '@/common/constants';
import {
    showErrorNotificationFunction,
    showSuccessNotificationFunction,
} from '@/common/helpers';
import { IBodyResponse } from '@/common/interfaces';
import {
    defaultCalendarHoursPerDay,
    defaultCalendarHoursPerMonth,
    defaultCalendarHoursPerWeek,
    defaultCalendarHoursPerYear,
    maxCalendarHoursPerDay,
    maxCalendarHoursPerMonth,
    maxCalendarHoursPerWeek,
    maxCalendarHoursPerYear,
} from '@/features/4D-planning/constants';
import { projectPlanningModule } from '@/features/4D-planning/store';
import { ABSUploadedFileExtensions } from '@/features/abs/constants';
import { projectModule } from '@/features/project/store';
import i18n from '@/plugins/vue-i18n';
import yup from '@/plugins/yup';

import { ICalendar, ICalendarConfig } from '../interfaces';
import { calendarService } from '../services/calendar.service';
import { calendarModule } from '../store';
import moment from 'moment';

const validateProjectFormSchema = yup.object({
    name: yup.string().max(INPUT_TEXT_MAX_LENGTH).required(),
    hoursPerDay: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(maxCalendarHoursPerDay)
        .transform((val) => (isNaN(val) ? null : +val))
        .nullable()
        .required(),
    hoursPerWeek: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(maxCalendarHoursPerWeek)
        .transform((val) => (isNaN(val) ? null : +val))
        .nullable()
        .required(),
    hoursPerMonth: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(maxCalendarHoursPerMonth)
        .transform((val) => (isNaN(val) ? null : +val))
        .nullable()
        .required(),
    hoursPerYear: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(maxCalendarHoursPerYear)
        .transform((val) => (isNaN(val) ? null : +val))
        .nullable()
        .required(),
    workingDayTypeId: yup.string().required(),
    weekDays: yup.array().of(yup.number().min(0).max(6)).required(),
    startYear: yup.string().required(),
    endYear: yup.string().required(),
});

export function calendarForm() {
    const initValues = {
        name: '',
        workingDayTypeId: '',
        hoursPerDay: defaultCalendarHoursPerDay,
        hoursPerWeek: defaultCalendarHoursPerWeek,
        hoursPerMonth: defaultCalendarHoursPerMonth,
        hoursPerYear: defaultCalendarHoursPerYear,
        weekDays: [],
        startYear: moment()
            .subtract(3, 'years')
            .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN),
        endYear: moment().add(3, 'years').format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN),
    };
    const { handleSubmit, errors, resetForm, validate, setFieldValue } = useForm({
        initialValues: initValues,
        validationSchema: validateProjectFormSchema,
    });

    const onOpen = async () => {
        const selectedCalendarId = computed(() => calendarModule.selectedCalendarId);
        await calendarModule.getDayTypeList(projectModule.selectedProjectId || '');
        if (selectedCalendarId.value.length) {
            const loading = ElLoading.service({
                target: '.calendar-config-form-popup',
            });
            const response = (await calendarService.getDetail(
                selectedCalendarId.value || '',
            )) as IBodyResponse<ICalendar>;
            loading.close();
            if (response.success) {
                resetForm({
                    values: {
                        name: response.data.name,
                        workingDayTypeId: response.data.workingDayTypeId,
                        hoursPerDay:
                            response.data.hoursPerDay || defaultCalendarHoursPerDay,
                        hoursPerWeek:
                            response.data.hoursPerWeek || defaultCalendarHoursPerWeek,
                        hoursPerMonth:
                            response.data.hoursPerMonth || defaultCalendarHoursPerMonth,
                        hoursPerYear:
                            response.data.hoursPerYear || defaultCalendarHoursPerYear,
                        weekDays: response.data.weekDays || [],
                        startYear:
                            response.data.startDateAt ||
                            moment()
                                .subtract(3, 'years')
                                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN),
                        endYear:
                            response.data.endDateAt ||
                            moment()
                                .add(3, 'years')
                                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN),
                    },
                });
            } else {
                showErrorNotificationFunction(response.message as string);
            }
        } else {
            resetForm({
                values: initValues,
            });
        }
    };

    const onSubmit = handleSubmit(async (values) => {
        const loading = ElLoading.service({
            target: '.calendar-config-form-popup',
        });
        let response;
        const selectedCalendarId = computed(() => calendarModule.selectedCalendarId);
        const projectId = computed(() => projectModule.selectedProjectId);
        if (!selectedCalendarId.value.length) {
            if (projectPlanningModule.planning?.planningFilePath) {
                response = (await calendarService.create({
                    name: values.name,
                    hoursPerDay: values.hoursPerDay,
                    hoursPerWeek: values.hoursPerWeek,
                    hoursPerMonth: values.hoursPerMonth,
                    hoursPerYear: values.hoursPerYear,
                    projectId: projectId.value,
                    planningId: projectPlanningModule.planning?._id,
                    workingDayTypeId: values.workingDayTypeId,
                    weekDays: values.weekDays,
                    startDateAt: moment(values.startYear)
                        .startOf('year')
                        .fmFullTimeString(),
                    endDateAt: moment(values.endYear).endOf('year').fmFullTimeString(),
                })) as unknown as IBodyResponse<ICalendar>;
            } else {
                response = (await calendarService.create({
                    name: values.name,
                    hoursPerDay: values.hoursPerDay,
                    hoursPerWeek: values.hoursPerWeek,
                    hoursPerMonth: values.hoursPerMonth,
                    hoursPerYear: values.hoursPerYear,
                    projectId: projectId.value,
                    workingDayTypeId: values.workingDayTypeId,
                    weekDays: values.weekDays,
                    startDateAt: moment(values.startYear)
                        .startOf('year')
                        .fmFullTimeString(),
                    endDateAt: moment(values.endYear).endOf('year').fmFullTimeString(),
                })) as unknown as IBodyResponse<ICalendar>;
            }
        } else {
            response = (await calendarService.update(selectedCalendarId.value, {
                name: values.name,
                hoursPerDay: values.hoursPerDay,
                hoursPerWeek: values.hoursPerWeek,
                hoursPerMonth: values.hoursPerMonth,
                hoursPerYear: values.hoursPerYear,
                workingDayTypeId: values.workingDayTypeId,
                weekDays: values.weekDays,
                startDateAt: moment(values.startYear).startOf('year').fmFullTimeString(),
                endDateAt: moment(values.endYear).endOf('year').fmFullTimeString(),
            })) as unknown as IBodyResponse<ICalendarConfig[]>;
        }

        loading.close();
        if (response.success) {
            calendarModule.setIsShowCalendarFormPopup(false);
            if (!selectedCalendarId.value.length) {
                showSuccessNotificationFunction(
                    i18n.global.t('calendar.list.message.create.success'),
                );
            } else {
                showSuccessNotificationFunction(
                    i18n.global.t('calendar.list.message.update.success'),
                );
            }
            const loading = ElLoading.service({
                target: '.main-wrapper',
            });
            if (projectPlanningModule.planning?.planningFilePath) {
                const path =
                    `${projectPlanningModule.planning?.planningFilePath}/${projectPlanningModule.planning?.name}${ABSUploadedFileExtensions.PLANNING}`.replaceAll(
                        '//',
                        '/',
                    );
                await calendarModule.getCalendarList({
                    projectId: projectId.value || '',
                    planningId: projectPlanningModule.planning._id,
                });
                await projectPlanningModule.getPlanning({
                    projectId: projectPlanningModule.planning?.projectId as string,
                    query: {
                        name: projectPlanningModule.planning?.name as string,
                        planningFilePath: projectPlanningModule.planning
                            ?.planningFilePath as string,
                        path,
                        projectId: projectId.value || '',
                    },
                });
            } else {
                await calendarModule.getCalendarList({
                    projectId: projectId.value || '',
                });
            }
            loading.close();
        } else {
            showErrorNotificationFunction(response.message as string);
        }
    });
    const { value: name } = useField('name');
    const { value: hoursPerDay } = useField('hoursPerDay');
    const { value: hoursPerWeek } = useField('hoursPerWeek');
    const { value: hoursPerMonth } = useField('hoursPerMonth');
    const { value: hoursPerYear } = useField('hoursPerYear');
    const { value: workingDayTypeId } = useField('workingDayTypeId');
    const { value: weekDays } = useField('weekDays');
    const { value: startYear } = useField('startYear');
    const { value: endYear } = useField('endYear');

    return {
        errors,
        name,
        hoursPerDay,
        hoursPerWeek,
        hoursPerMonth,
        hoursPerYear,
        validate,
        onOpen,
        onSubmit,
        resetForm,
        setFieldValue,
        workingDayTypeId,
        weekDays,
        startYear,
        endYear,
    };
}
