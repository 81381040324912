import {
    AccessModules,
    INPUT_TEXT_MAX_LENGTH,
    TEXTAREA_MAX_LENGTH,
} from '@/common/constants';
import yup from '@/plugins/yup';
import { IPermissionTree } from './interfaces';
export enum ProjectSecurityPermissionseparators {
    TABS = '_TABS_',
    GROUPS = '_GROUPS_',
    FUNCTIONS = '_FUNCTIONS_',
    TABS_COMPACT = '_T_',
    GROUPS_COMPACT = '_G_',
    FUNCTIONS_COMPACT = '_F_',
}

export enum ProfilePermissionPrefix {
    WEBVIEWER3D = '3D_WEBVIEWER_',
    TABS = '3D_WEBVIEWER_TABS_',
    GROUPS = '3D_WEBVIEWER_GROUPS_',
}

export enum ProfilePermissionCategories {
    ALL = 'ALL',
    GENERAL = 'GENERAL',
    '4DPLANNING' = '4DPLANNING',
    CONSTELLATION = 'CONSTELLATION',
    '3D_WEBVIEWER' = '3D_WEBVIEWER',
    // START Tab
    '3D_WEBVIEWER_TABS_START' = '3D_WEBVIEWER_TABS_START',
    '3D_WEBVIEWER_TABS_START_GROUPS_FILE' = '3D_WEBVIEWER_TABS_START_GROUPS_FILE',
    '3D_WEBVIEWER_TABS_START_GROUPS_UNDO' = '3D_WEBVIEWER_TABS_START_GROUPS_UNDO',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE' = '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE',
    // VIEW Tab
    '3D_WEBVIEWER_TABS_VIEW' = '3D_WEBVIEWER_TABS_VIEW',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS',
    // DOCUMENT Tab
    '3D_WEBVIEWER_TABS_DOCUMENT' = '3D_WEBVIEWER_TABS_DOCUMENT',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES',
    // MEASUREMENT Tab
    '3D_WEBVIEWER_TABS_MEASUREMENT' = '3D_WEBVIEWER_TABS_MEASUREMENT',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE',
    // ANALYZE Tab
    '3D_WEBVIEWER_TABS_ANALYZE' = '3D_WEBVIEWER_TABS_ANALYZE',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED',
    // TRANSFORMATION Tab
    '3D_WEBVIEWER_TABS_TRANSFORMATION' = '3D_WEBVIEWER_TABS_TRANSFORMATION',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING',
    // TECH_DOC Tab
    '3D_WEBVIEWER_TABS_TECH_DOC' = '3D_WEBVIEWER_TABS_TECH_DOC',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP',
    // MODEL Tab
    '3D_WEBVIEWER_TABS_MODEL' = '3D_WEBVIEWER_TABS_MODEL',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS',
    // TOOLS Tab
    '3D_WEBVIEWER_TABS_TOOLS' = '3D_WEBVIEWER_TABS_TOOLS',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS',
    // CUSTOM_FILE Tab
    '3D_WEBVIEWER_TABS_CUSTOM_FILE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE',
}

export enum ProjectSecurityPermissions {
    // ************************************************************************************** GENERAL
    GENERAL_CREATE_ABS_STRUCTURE = 'GENERAL_CREATE_ABS_STRUCTURE',
    GENERAL_ASSIGN_PBS_TO_ABS = 'GENERAL_ASSIGN_PBS_TO_ABS',
    GENERAL_CREATE_PBS_STRUCTURE = 'GENERAL_CREATE_PBS_STRUCTURE',
    GENERAL_ACCESS_TO_ADMIN = 'GENERAL_ACCESS_TO_ADMIN',
    GENERAL_MANAGE_USER_GROUP_OF_PROJECT = 'GENERAL_MANAGE_USER_GROUP_OF_PROJECT',
    GENERAL_ADD_USER_FROM_CONSTELLATION = 'GENERAL_ADD_USER_FROM_CONSTELLATION',
    GENERAL_LDAP_CONFIGURATION = 'GENERAL_LDAP_CONFIGURATION',
    GENERAL_IMPORT_CSV = 'GENERAL_IMPORT_CSV',
    GENERAL_CREATE_PROJECT_PROFILE = 'GENERAL_CREATE_PROJECT_PROFILE',
    GENERAL_ACCESS_TO_PROJECT = 'GENERAL_ACCESS_TO_PROJECT',
    GENERAL_CREATE_CALENDAR = 'GENERAL_CREATE_CALENDAR',
    GENERAL_MANAGE_ALL_APPEARANCE_PROFILE = 'GENERAL_MANAGE_ALL_APPEARANCE_PROFILE',
    GENERAL_MANAGE_ALL_ACTIVITY_CODE = 'GENERAL_MANAGE_ALL_ACTIVITY_CODE',
    GENERAL_MANAGE_ALL_USER_DEFINED_FIELD = 'GENERAL_MANAGE_ALL_USER_DEFINED_FIELD',
    GENERAL_MANAGE_ALL_RESOURCE = 'GENERAL_MANAGE_ALL_RESOURCE',
    // ************************************************************************************** 4D PLANNING
    '4DPLANNING_EDIT_PLANNING' = '4DPLANNING_EDIT_PLANNING',
    '4DPLANNING_EDIT_PLANNING_SETTINGS' = '4DPLANNING_EDIT_PLANNING_SETTINGS',
    '4DPLANNING_RESCHEDULING' = '4DPLANNING_RESCHEDULING',
    '4DPLANNING_EDIT_RESOURCE_AND_RESOURCE_GROUP' = '4DPLANNING_EDIT_RESOURCE_AND_RESOURCE_GROUP',
    '4DPLANNING_EDIT_APPEARANCE_PROFILE' = '4DPLANNING_EDIT_APPEARANCE_PROFILE',
    '4DPLANNING_CREATE_3D_PATH' = '4DPLANNING_CREATE_3D_PATH',
    '4DPLANNING_CREATE_TASK_FROM_3D_OBJECTS' = '4DPLANNING_CREATE_TASK_FROM_3D_OBJECTS',
    '4DPLANNING_CREATE_ANIMATION' = '4DPLANNING_CREATE_ANIMATION',
    '4DPLANNING_REPORTING' = '4DPLANNING_REPORTING',
    '4DPLANNING_IMPORT_EXPORT_PLANNING' = '4DPLANNING_IMPORT_EXPORT_PLANNING',
    '4DPLANNING_READ_ONLY' = '4DPLANNING_READ_ONLY',
    // ************************************************************************************** CONSTELLATION

    CONSTELLATION_REALIZE_SYNTHESIS = 'CONSTELLATION_REALIZE_SYNTHESIS',
    CONSTELLATION_BOTTOM_UP_PROCESS = 'CONSTELLATION_BOTTOM_UP_PROCESS',
    CONSTELLATION_TOP_DOWN_PROCESS = 'CONSTELLATION_TOP_DOWN_PROCESS',
    CONSTELLATION_DELEGATE_TASKS = 'CONSTELLATION_DELEGATE_TASKS',
    // ************************************************************************************** 3D WEBVIEWER

    // ********************* START
    // FILE

    '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_OPEN' = '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_OPEN',
    '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_IMPORT' = '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_IMPORT',
    '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_PRINT' = '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_PRINT',
    // UNDO

    '3D_WEBVIEWER_TABS_START_GROUPS_UNDO_FUNCTIONS_LAST_ACTIONS' = '3D_WEBVIEWER_TABS_START_GROUPS_UNDO_FUNCTIONS_LAST_ACTIONS',
    // CONTROLS

    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ROTATE' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ROTATE',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_PAN' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_PAN',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_SELECTION_FRAME' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_SELECTION_FRAME',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_TURNTABLE' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_TURNTABLE',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_MOUSE_USE_FREE_ROTATE' = '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_MOUSE_USE_FREE_ROTATE',
    // SELECTION_MODE

    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_SELECT' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_SELECT',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_NEIGHBOURHOOD_SEARCH' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_NEIGHBOURHOOD_SEARCH',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_HIDE' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_HIDE',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_DELETE' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_DELETE',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_GHOST' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_GHOST',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_COLOR' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_COLOR',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_INSTANCE' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_INSTANCE',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_PMIREFERENCE' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_PMIREFERENCE',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_FACE' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_FACE',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_ATTRIBUTES' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_ATTRIBUTES',
    // SELECTION

    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SELECT_ALL' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SELECT_ALL',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_INVERT_SELECTION' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_INVERT_SELECTION',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_ISOLATE' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_ISOLATE',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SHOW_SELECTION' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SHOW_SELECTION',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_HIDE_SELECTION' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_HIDE_SELECTION',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DELETE_SELECTION' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DELETE_SELECTION',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_GHOST_SELECTION' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_GHOST_SELECTION',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_UNGHOST' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_UNGHOST',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DESELECT' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DESELECT',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_COLOR' = '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_COLOR',
    // SHOW/HIDE

    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_SHOW_ALL' = '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_SHOW_ALL',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_UNGHOST_ALL' = '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_UNGHOST_ALL',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_MARKUPS' = '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_MARKUPS',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_PMIS' = '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_PMIS',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_INVERT_VISIBILITY' = '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_INVERT_VISIBILITY',
    // ************** END START SECTION
    // ************** DOCUMENT
    // CONTROLS

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_PAN' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_PAN',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE',
    // PAGE

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PREVIOUS' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PREVIOUS',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_NEXT' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_NEXT',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PAGE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PAGE',
    // ROTATE

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_90' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_90',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_180' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_180',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_270' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_270',
    // ZOOM

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_ALL' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_ALL',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_WIDTH' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_WIDTH',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_HEIGHT' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_HEIGHT',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT',
    // TEXT

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_SEARCH' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_SEARCH',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_COPY' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_COPY',
    // GRAPHIC_EFFECTS

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_ILLUSTRATION' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_ILLUSTRATION',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_INVERT' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_INVERT',
    // COMPARE

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE_FUNCTIONS_COMPARE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE_FUNCTIONS_COMPARE',
    // 3D_MARKUP

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_TEXT' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_TEXT',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND',
    // SHOW_PANES

    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_LAYER' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_LAYER',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS' = '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS',
    // ************** VIEW
    // VIEW

    // ALIGNMENT

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_ISOMETRIC' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_ISOMETRIC',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_TOP' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_TOP',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_LEFT' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_LEFT',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_FRONT' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_FRONT',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BOTTOM' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BOTTOM',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_RIGHT' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_RIGHT',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BACK' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BACK',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_VERTICAL' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_VERTICAL',
    // ROTATE

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_90' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_90',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_180' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_180',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_270' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_270',
    // ZOOM

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FIT_ALL' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FIT_ALL',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS_TRANSITION' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS_TRANSITION',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_SET_ORIGIN' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_SET_ORIGIN',
    // RENDERING_MODE

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_SOLID' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_SOLID',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_WIRES' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_WIRES',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_ILLUSTRATION' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_ILLUSTRATION',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_PERSPECTIVE' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_PERSPECTIVE',
    // GRAPHIC_EFFECTS

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SILHOUETTE' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SILHOUETTE',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_OPAQUE' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_OPAQUE',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SHADOW' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SHADOW',
    // FLYTHROUGH
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH_FUNCTIONS_FLYTHROUGH' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH_FUNCTIONS_FLYTHROUGH',
    // SCENES

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_SHOW_GRID' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_SHOW_GRID',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_FULL_SCREEN' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_FULL_SCREEN',
    // ANIMATION

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_SPIN' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_SPIN',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_ANIMATE' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_ANIMATE',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_CREATE_ANIMATION' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_CREATE_ANIMATION',
    // SHOW_PANES

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_MODEL_TREE' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_MODEL_TREE',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_VIEWS' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_VIEWS',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_PROPERTIES' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_PROPERTIES',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SETTINGS' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SETTINGS',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SELECTIONS_WND' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SELECTIONS_WND',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LAYER' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LAYER',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LIGHTING' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LIGHTING',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_OUTPUT' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_OUTPUT',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_RESET_UI' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_RESET_UI',
    // SHARE

    '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS_FUNCTIONS_START' = '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS_FUNCTIONS_START',
    // ************ MEASUREMENT

    // SNAP_MODES

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_FREE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_FREE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_END' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_END',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_CENTER' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_CENTER',
    // CIRCLE_MODE

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_ARC' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_ARC',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_3_POINTS' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_3_POINTS',
    // COORDINATE

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE_FUNCTIONS_POINT' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE_FUNCTIONS_POINT',
    // LENGTH

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_EDGE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_EDGE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_ACCUMULATED_EDGE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_ACCUMULATED_EDGE',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_POINT_TO_POINT_ON_WIRE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_POINT_TO_POINT_ON_WIRE',
    // CIRCLE

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_FUNCTIONS_RADIUS' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_FUNCTIONS_RADIUS',
    // DISTANCE

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_BETWEEN_TWO_POINTS' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_BETWEEN_TWO_POINTS',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_SOLIDS_TO_SOLIDS' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_SOLIDS_TO_SOLIDS',
    // ANGLE

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE_FUNCTIONS_ANGLE_BETWEEN_TWO_AXES' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE_FUNCTIONS_ANGLE_BETWEEN_TWO_AXES',
    // FACE

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_AREA' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_AREA',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_ACCUMULATED_AREA' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_ACCUMULATED_AREA',
    // WALL_THICKNESS

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS_FUNCTIONS_WALLTHICKNESS_COMPUTATION_MODE_RAY' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS_FUNCTIONS_WALLTHICKNESS_COMPUTATION_MODE_RAY',
    // BOUNDING_BOX

    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_WORLD' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_WORLD',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_COMPUTE_MINIMUM' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_COMPUTE_MINIMUM',

    // DRILLHOLE
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE_FUNCTIONS_DRILLHOLE' = '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE_FUNCTIONS_DRILLHOLE',

    // *********** ANALYZE
    // SECTION

    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION_FUNCTIONS_CUTTING_PLANE_FROM_POINT_NORMAL' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION_FUNCTIONS_CUTTING_PLANE_FROM_POINT_NORMAL',
    // COMPARE

    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_COMPARE_MODEL' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_COMPARE_MODEL',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_BREP_PART' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_BREP_PART',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA_FUNCTIONS_PROJECTED_AREA' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA_FUNCTIONS_PROJECTED_AREA',
    // CLASH

    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_CLASH_DETECTION' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_CLASH_DETECTION',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_BAND_ANALYSIS' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_BAND_ANALYSIS',

    // COMPUTE
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE_FUNCTIONS_PHYSICAL_PROPERTIES' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE_FUNCTIONS_PHYSICAL_PROPERTIES',
    // FEATURE_RECOGNITION

    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_NEUTRAL_AXIS' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_NEUTRAL_AXIS',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_DRILLHOLE' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_DRILLHOLE',
    // MOLD

    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_DRAFT_ANGLE' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_DRAFT_ANGLE',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_WALL_THICKNESS' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_WALL_THICKNESS',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_SPLIT' = '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_SPLIT',

    // *********** TRANSFORMATION
    // TRANSFORMATION

    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_FREE_DRAG' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_FREE_DRAG',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MOVE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MOVE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_ROTATE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_ROTATE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_SCALE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_SCALE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MIRROR' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MIRROR',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_EXPLODE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_EXPLODE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_RESET_TRANSFORMATION' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_RESET_TRANSFORMATION',
    // BOUNDARY_CONDITION

    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_ACTIVATE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_ACTIVATE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_BOUNDARY_CONDITION_AXIS' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_BOUNDARY_CONDITION_AXIS',
    // ALIGNING_MOVE

    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_POINT_TO_POINT' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_POINT_TO_POINT',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_PLANE_TO_PLANE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_PLANE_TO_PLANE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_POINT' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_POINT',
    // ALIGNING_ROTATE

    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_NORMAL_TO_NORMAL' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_NORMAL_TO_NORMAL',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_POINT_TO_POINT' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_POINT_TO_POINT',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER',
    // ALIGNING_MOVE_ROTATE

    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_COORDINATE_SYSTEM_TO_COORDINATE_SYSTEM' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_COORDINATE_SYSTEM_TO_COORDINATE_SYSTEM',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_CIRCLE_AXIS_TO_CIRCLE_AXIS' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_CIRCLE_AXIS_TO_CIRCLE_AXIS',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_PLANE_TO_PLANE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_PLANE_TO_PLANE',
    // MODEL_ALIGNING

    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_SOLID_TO_SOLID' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_SOLID_TO_SOLID',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_LINE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_LINE',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_PLANE' = '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_PLANE',
    // *********** TECH_DOC
    // GENERAL

    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL_FUNCTIONS_BOM' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL_FUNCTIONS_BOM',
    // ALIGNMENT

    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_ALIGN_TOP' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_ALIGN_TOP',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_AUTO_ALIGNMENT' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_AUTO_ALIGNMENT',
    // CALLOUTS

    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CREATE' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CREATE',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_FOR_SELECTION' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_FOR_SELECTION',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_SETTINGS' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_SETTINGS',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SHOW_ALL' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SHOW_ALL',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_LOCK_CALLOUTS' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_LOCK_CALLOUTS',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_UNLOCK_CALLOUTS' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_UNLOCK_CALLOUTS',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_ASSOCIATED_NODES' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_ASSOCIATED_NODES',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_MULTIPLE_CONNECTIONS' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_MULTIPLE_CONNECTIONS',
    // CONNECTIONS

    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_EDIT' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_EDIT',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_DELETE' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_DELETE',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_INVERT_VISIBILITY' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_INVERT_VISIBILITY',
    // VISIBLE_CALLOUTS

    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_VISIBLE' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_VISIBLE',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_HIDE_VISIBLE' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_HIDE_VISIBLE',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_DELETE_VISIBLE' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_DELETE_VISIBLE',
    // 3D-MARKUP

    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_TEXT' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_TEXT',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT' = '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT',

    // *********** MODEL
    // RETESSELATE

    '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE_FUNCTIONS_RETESSELATE' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE_FUNCTIONS_RETESSELATE',
    // COORDINATE_SYSTEM

    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CYLINDER_AXIS' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CYLINDER_AXIS',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CURRENT_GLOBAL' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CURRENT_GLOBAL',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_SELECTED_PART' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_SELECTED_PART',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_SETTINGS' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_SETTINGS',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_ACTIVATE_COORDINATESYSTEM' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_ACTIVATE_COORDINATESYSTEM',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_RESET_COORDINATESYSTEM' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_RESET_COORDINATESYSTEM',
    // CREATE_POINT

    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_CIRCLE_CENTER' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_CIRCLE_CENTER',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_POINT' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_POINT',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_LINE_TO_LINE' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_LINE_TO_LINE',
    // CREATE_WIRE

    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_CIRCLE_AXIS' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_CIRCLE_AXIS',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_POINT_TO_POINT' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_POINT_TO_POINT',
    // MODIFY_SOLIDS

    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_EXTRACT_FACES' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_EXTRACT_FACES',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS_HIERARCHICAL' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS_HIERARCHICAL',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_CONVEX_HULL' = '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_CONVEX_HULL',

    // ************ TOOLS
    // SCREENSHOT

    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT_FUNCTIONS_CLIPBOARD' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT_FUNCTIONS_CLIPBOARD',
    // 3D_MARKUP

    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_TEXT' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_TEXT',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND',

    // SEARCH

    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH_FUNCTIONS_SEARCH' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH_FUNCTIONS_SEARCH',
    // GEOMETRY_TOOLS

    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS_FUNCTIONS_GEOMETRY_TOOLS' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS_FUNCTIONS_GEOMETRY_TOOLS',
    // IP_PROTECTION

    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_DISTORT' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_DISTORT',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_VISIBILITY_TEST' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_VISIBILITY_TEST',
    // EXTRAS

    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS_FUNCTIONS_COMMAND' = '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS_FUNCTIONS_COMMAND',

    // // OPEN

    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_FILE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_FILE',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_REPOSITORY' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_REPOSITORY',
    // //IMPORT

    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_FILE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_FILE',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_REPOSITORY' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_REPOSITORY',
    //3D Broker
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_4D_BOX' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_4D_BOX',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_ZONE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_ZONE',

    //EXPORT_2D

    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_EXPORT_FILE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_EXPORT_FILE',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_FILE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_FILE',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_AS_FILE' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_AS_FILE',
    // Node Mode

    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE_FUNCTIONS_REMOVE_EMPTY_NODES' = '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE_FUNCTIONS_REMOVE_EMPTY_NODES',
}

export const DenyPermission3DProjectProfile = [
    ProjectSecurityPermissions[
        '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_FILE'
    ],
];
export const DenyPermission3DWebViewerTree = [
    ProfilePermissionCategories['3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER'],
];

export const PermissionGroups = {
    [ProjectSecurityPermissions.GENERAL_CREATE_ABS_STRUCTURE]: [
        ProjectSecurityPermissions.GENERAL_ASSIGN_PBS_TO_ABS,
        ProjectSecurityPermissions[
            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_REPOSITORY'
        ],
    ],
    [ProjectSecurityPermissions.GENERAL_MANAGE_USER_GROUP_OF_PROJECT]: [
        ProjectSecurityPermissions.GENERAL_IMPORT_CSV,
        ProjectSecurityPermissions.GENERAL_LDAP_CONFIGURATION,
        ProjectSecurityPermissions.GENERAL_ADD_USER_FROM_CONSTELLATION,
    ],
    [ProjectSecurityPermissions.GENERAL_ACCESS_TO_ADMIN]: [
        ProjectSecurityPermissions.GENERAL_MANAGE_USER_GROUP_OF_PROJECT,
        ProjectSecurityPermissions.GENERAL_CREATE_PBS_STRUCTURE,
        ProjectSecurityPermissions.GENERAL_CREATE_PROJECT_PROFILE,
    ],
    [ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT]: [
        ProjectSecurityPermissions.GENERAL_CREATE_CALENDAR,
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_APPEARANCE_PROFILE,
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_ACTIVITY_CODE,
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_USER_DEFINED_FIELD,
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_RESOURCE,
        ProjectSecurityPermissions['4DPLANNING_READ_ONLY'],
    ],
    [ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING']]: [
        ProjectSecurityPermissions.GENERAL_CREATE_CALENDAR,
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_ACTIVITY_CODE,
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_USER_DEFINED_FIELD,
    ],
    [ProjectSecurityPermissions['4DPLANNING_EDIT_APPEARANCE_PROFILE']]: [
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_APPEARANCE_PROFILE,
    ],
    [ProjectSecurityPermissions['4DPLANNING_EDIT_RESOURCE_AND_RESOURCE_GROUP']]: [
        ProjectSecurityPermissions.GENERAL_MANAGE_ALL_RESOURCE,
    ],
    [ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING']]: [
        ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING_SETTINGS'],
        ProjectSecurityPermissions['4DPLANNING_RESCHEDULING'],
        ProjectSecurityPermissions['4DPLANNING_EDIT_RESOURCE_AND_RESOURCE_GROUP'],
        ProjectSecurityPermissions['4DPLANNING_EDIT_APPEARANCE_PROFILE'],
        ProjectSecurityPermissions['4DPLANNING_IMPORT_EXPORT_PLANNING'],
    ],
};

export const Viewer3DPermissions: IPermissionTree[] = [
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_START'],
        children: [
            {
                name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_START_GROUPS_FILE'],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_OPEN'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_IMPORT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_PRINT'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_START_GROUPS_UNDO'],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_UNDO_FUNCTIONS_LAST_ACTIONS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ROTATE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_PAN'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_SELECTION_FRAME'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_TURNTABLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_MOUSE_USE_FREE_ROTATE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_SELECT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_NEIGHBOURHOOD_SEARCH'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_HIDE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_DELETE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_GHOST'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_COLOR'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_INSTANCE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_PMIREFERENCE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_FACE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_ATTRIBUTES'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SELECT_ALL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_INVERT_SELECTION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_ISOLATE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SHOW_SELECTION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_HIDE_SELECTION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DELETE_SELECTION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_GHOST_SELECTION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_UNGHOST'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DESELECT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_COLOR'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_SHOW_ALL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_UNGHOST_ALL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_MARKUPS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_PMIS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_INVERT_VISIBILITY'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_DOCUMENT'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_PAN'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PREVIOUS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_NEXT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PAGE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_90'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_180'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_270'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_ALL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_WIDTH'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_HEIGHT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_SEARCH'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_COPY'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_ILLUSTRATION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_INVERT'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE_FUNCTIONS_COMPARE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_TEXT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_LAYER'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_VIEW'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_ISOMETRIC'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_TOP'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_LEFT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_FRONT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BOTTOM'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_RIGHT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BACK'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_VERTICAL'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE'],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_90'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_180'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_270'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM'],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FIT_ALL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS_TRANSITION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_SET_ORIGIN'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_SOLID'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_WIRES'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_ILLUSTRATION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_PERSPECTIVE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SILHOUETTE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_OPAQUE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SHADOW'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH_FUNCTIONS_FLYTHROUGH'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES'],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_SHOW_GRID'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_FULL_SCREEN'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_SPIN'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_ANIMATE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_CREATE_ANIMATION'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_MODEL_TREE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_VIEWS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_PROPERTIES'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SETTINGS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SELECTIONS_WND'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LAYER'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LIGHTING'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_OUTPUT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_RESET_UI'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS_FUNCTIONS_START'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_MEASUREMENT'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_FREE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_END'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_CENTER'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_ARC'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_3_POINTS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE_FUNCTIONS_POINT'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_EDGE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_ACCUMULATED_EDGE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_POINT_TO_POINT_ON_WIRE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_FUNCTIONS_RADIUS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_BETWEEN_TWO_POINTS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_SOLIDS_TO_SOLIDS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE_FUNCTIONS_ANGLE_BETWEEN_TWO_AXES'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_AREA'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_ACCUMULATED_AREA'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS_FUNCTIONS_WALLTHICKNESS_COMPUTATION_MODE_RAY'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_WORLD'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_COMPUTE_MINIMUM'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE_FUNCTIONS_DRILLHOLE'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_ANALYZE'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION_FUNCTIONS_CUTTING_PLANE_FROM_POINT_NORMAL'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_COMPARE_MODEL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_BREP_PART'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA_FUNCTIONS_PROJECTED_AREA'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_CLASH_DETECTION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_BAND_ANALYSIS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE_FUNCTIONS_PHYSICAL_PROPERTIES'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_NEUTRAL_AXIS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_DRILLHOLE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_DRAFT_ANGLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_WALL_THICKNESS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_SPLIT'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_TRANSFORMATION'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_FREE_DRAG'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MOVE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_ROTATE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_SCALE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MIRROR'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_EXPLODE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_RESET_TRANSFORMATION'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_ACTIVATE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_BOUNDARY_CONDITION_AXIS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_POINT_TO_POINT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_PLANE_TO_PLANE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_POINT'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_NORMAL_TO_NORMAL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_POINT_TO_POINT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_COORDINATE_SYSTEM_TO_COORDINATE_SYSTEM'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_CIRCLE_AXIS_TO_CIRCLE_AXIS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_PLANE_TO_PLANE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_SOLID_TO_SOLID'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_LINE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_PLANE'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_TECH_DOC'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL_FUNCTIONS_BOM'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_ALIGN_TOP'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_AUTO_ALIGNMENT'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CREATE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_FOR_SELECTION'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_SETTINGS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SHOW_ALL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_LOCK_CALLOUTS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_UNLOCK_CALLOUTS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_ASSOCIATED_NODES'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_MULTIPLE_CONNECTIONS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_EDIT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_DELETE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_INVERT_VISIBILITY'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_VISIBLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_HIDE_VISIBLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_DELETE_VISIBLE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_TEXT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_MODEL'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE_FUNCTIONS_RETESSELATE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CYLINDER_AXIS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CURRENT_GLOBAL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_SELECTED_PART'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_SETTINGS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_ACTIVATE_COORDINATESYSTEM'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_RESET_COORDINATESYSTEM'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_CIRCLE_CENTER'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_POINT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_LINE_TO_LINE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_CIRCLE_AXIS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_POINT_TO_POINT'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_EXTRACT_FACES'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS_HIERARCHICAL'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_CONVEX_HULL'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_TOOLS'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT_FUNCTIONS_CLIPBOARD'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_TEXT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH_FUNCTIONS_SEARCH'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS_FUNCTIONS_GEOMETRY_TOOLS'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_DISTORT'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_VISIBILITY_TEST'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS_FUNCTIONS_COMMAND'
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: ProfilePermissionCategories['3D_WEBVIEWER_TABS_CUSTOM_FILE'],
        children: [
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_FILE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_REPOSITORY'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_FILE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_REPOSITORY'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_4D_BOX'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_ZONE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_EXPORT_FILE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_FILE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_AS_FILE'
                        ],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories[
                    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE'
                ],
                children: [
                    {
                        name: ProjectSecurityPermissions[
                            '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE_FUNCTIONS_REMOVE_EMPTY_NODES'
                        ],
                    },
                ],
            },
        ],
    },
];

export const ProfilePermissionTree: IPermissionTree[] = [
    {
        name: ProfilePermissionCategories.ALL,
        children: [
            {
                name: ProfilePermissionCategories.GENERAL,
                children: [
                    {
                        name: ProjectSecurityPermissions.GENERAL_CREATE_ABS_STRUCTURE,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_ASSIGN_PBS_TO_ABS,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_CREATE_PBS_STRUCTURE,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_ACCESS_TO_ADMIN,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_MANAGE_USER_GROUP_OF_PROJECT,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_ADD_USER_FROM_CONSTELLATION,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_LDAP_CONFIGURATION,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_IMPORT_CSV,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_CREATE_PROJECT_PROFILE,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_CREATE_CALENDAR,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_MANAGE_ALL_APPEARANCE_PROFILE,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_MANAGE_ALL_ACTIVITY_CODE,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_MANAGE_ALL_USER_DEFINED_FIELD,
                    },
                    {
                        name: ProjectSecurityPermissions.GENERAL_MANAGE_ALL_RESOURCE,
                    },
                ],
            },
            {
                name: ProfilePermissionCategories['4DPLANNING'],
                children: [
                    {
                        name: ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING'],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '4DPLANNING_EDIT_PLANNING_SETTINGS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions['4DPLANNING_RESCHEDULING'],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '4DPLANNING_EDIT_RESOURCE_AND_RESOURCE_GROUP'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '4DPLANNING_EDIT_APPEARANCE_PROFILE'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '4DPLANNING_IMPORT_EXPORT_PLANNING'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions['4DPLANNING_CREATE_3D_PATH'],
                    },
                    {
                        name: ProjectSecurityPermissions[
                            '4DPLANNING_CREATE_TASK_FROM_3D_OBJECTS'
                        ],
                    },
                    {
                        name: ProjectSecurityPermissions['4DPLANNING_CREATE_ANIMATION'],
                    },
                    {
                        name: ProjectSecurityPermissions['4DPLANNING_REPORTING'],
                    },
                    {
                        name: ProjectSecurityPermissions['4DPLANNING_READ_ONLY'],
                    },
                ],
            },
            {
                name: ProfilePermissionCategories.CONSTELLATION,
                children: [
                    {
                        name: ProjectSecurityPermissions.CONSTELLATION_REALIZE_SYNTHESIS,
                    },
                    {
                        name: ProjectSecurityPermissions.CONSTELLATION_BOTTOM_UP_PROCESS,
                    },
                    {
                        name: ProjectSecurityPermissions.CONSTELLATION_TOP_DOWN_PROCESS,
                    },
                    {
                        name: ProjectSecurityPermissions.CONSTELLATION_DELEGATE_TASKS,
                    },
                ],
            },
            {
                name: ProfilePermissionCategories['3D_WEBVIEWER'],
                children: Viewer3DPermissions.map((value) => {
                    if (
                        value.name ===
                        ProfilePermissionCategories['3D_WEBVIEWER_TABS_CUSTOM_FILE']
                    ) {
                        return {
                            name: value.name,
                            children: value.children?.filter((value) => value?.name),
                        };
                    }
                    return value;
                }),
            },
        ],
    },
];

export const Viewer3DPermissionTree: IPermissionTree[] = [
    {
        name: ProfilePermissionCategories.ALL,
        children: [
            {
                name: ProfilePermissionCategories['3D_WEBVIEWER'],
                // Delete permision 3D Broker in Viewer3DPermissionTree
                children: Viewer3DPermissions,
            },
        ],
    },
];

export const profileSchema = yup.object({
    description: yup
        .string()
        .trim()
        .nullable()
        .max(TEXTAREA_MAX_LENGTH)
        .label('description'),
    name: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required().label('profileName'),
    projectId: yup.string().when('accessModule', {
        is: AccessModules.SPACIALYTIC_CONSTELLATION,
        then: yup.string().required(),
        otherwise: yup.string().optional(),
    }),
    accessModule: yup
        .string()
        .oneOf([...Object.values(AccessModules)])
        .required(),
    permissions: yup.array().min(1).required().label('permissions'),
    isDefaultSelect: yup.boolean().required(),
});

export const initProjectProfile = {
    id: '',
    name: '',
    description: '',
    permissions: [],
    isDefaultSelect: false,
};
