import { DimensionType } from './../3D-viewer/constant';
import { ExportFormat2D, ExportFormat3D } from './../../common/constants';
import { IResourceViewTree } from '@/common/interfaces';
export enum TaskType {
    STANDARD = 'standard',
    RESOURCE_DEPENDENT = 'resource_dependent',
    START_MILESTONE = 'start_milestone',
    FINISH_MILESTONE = 'finish_milestone',
    MILESTONE = 'milestone',
    LEVEL_EFFORT = 'level_effort',
    WBS_SUMMARY = 'wbs_summary',
    // for displaying, not store in backend
    PROJECT = 'project',
}

export enum GanttTaskType {
    STANDARD = 'standard',
    PROJECT = 'project',
}

export enum UserFieldMode {
    IN_PLANNING = 1,
    IN_GLOBAL = 2,
}

export enum UserFieldTypeEnum {
    TASK = 'task',
    RESOURCE = 'resource',
    RESOURCE_GROUP = 'resource_group',
}

export const FilterUserFieldType = Object.keys(UserFieldTypeEnum).map((key) => {
    return {
        label: key,
        value: UserFieldTypeEnum[key as keyof typeof UserFieldTypeEnum],
    };
});

export enum LinkType {
    START_TO_START = 'start_to_start',
    START_TO_FINISH = 'start_to_finish',
    FINISH_TO_START = 'finish_to_start',
    FINISH_TO_FINISH = 'finish_to_finish',
}

export enum PlanningStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    PLANNED = 'planned',
}

export enum TaskStatus {
    TODO = 'todo',
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished',
}

export const DEFAULT_RESOURCE_ACCOUNT = 1;
export const DEFAULT_FIXED_COST = 0;

export enum TaskConstraint {
    // As Soon As Possible
    ASAP = 'asap',
    // As Late As Possible
    ALAP = 'alap',
    // Start No Earlier Than
    SNET = 'snet',
    // Start No Later Than
    SNLT = 'snlt',
    // Finish No Earlier Than
    FNET = 'fnet',
    // Finish No Later Than
    FNLT = 'fnlt',
    // Must Start On
    MSO = 'mso',
    // Must Finish On
    MFO = 'mfo',
    // Start On
    SO = 'so',
    // Finish On
    FO = 'fo',
}

export enum TaskDuration {
    STANDARD = 'standard',
    RESOURCE_UNITS_DEPENDENT = 'resource_units_dependent',
    PHYSICAL_QUANTITY_DEPENDENT = 'physical_quantity_dependent',
}

export enum TaskPercentageComplete {
    PHYSICAL_COMPLETE = 'physical_complete',
    DURATION_COMPLETE = 'duration_complete',
    MANUAL_COMPLETE = 'manual_complete',
}

export enum TaskPhysicalQuantityUnit {
    BAG = 'bag',
    BOX = 'box',
    BUCKET = 'bucket',
    BUNDLE = 'bundle',
    CRANE = 'crane',
    CARTON = 'carton',
    CUBIC_FEET = 'cubic_feet',
    CUBIC_METRE = 'cubic_METRE',
    CUBIC_YARD = 'cubic_yard',
    DOZEN = 'dozen',
    FEET = 'feet',
    GRAMME = 'gramme',
    HOUR = 'hour',
    ITEM = 'item',
    KILO = 'kilo',
    LENGTH = 'length',
    LITRE = 'litre',
    METRE = 'metre',
    MILLIMETRE = 'millimetre',
    PACK = 'pack',
    PAIR = 'pair',
    PERSON = 'person',
    POUND = 'pound',
    SACHET = 'sachet',
    SET = 'set',
    SHEET = 'sheet',
    SQ_FOOT = 'sq_foot',
    SQ_METRE = 'sq_metre',
    SQ_YARD = 'sq_yard',
    TON = 'ton',
}

export enum PlanningOrderBy {
    NAME = 'name',
    CREATED_AT = 'createdAt',
}

export enum ResourceType {
    EQUIPMENT = 'equipment',
    HUMAN_RESOURCE = 'human_resource',
    MATERIAL = 'material',
    LOCATION = 'location',
}

export const ResourceTypeOptions = [
    {
        label: `planning.resource.resourceType.${ResourceType.EQUIPMENT}`,
        value: ResourceType.EQUIPMENT,
    },
    {
        label: `planning.resource.resourceType.${ResourceType.HUMAN_RESOURCE}`,
        value: ResourceType.HUMAN_RESOURCE,
    },
    {
        label: `planning.resource.resourceType.${ResourceType.MATERIAL}`,
        value: ResourceType.MATERIAL,
    },
    {
        label: `planning.resource.resourceType.${ResourceType.LOCATION}`,
        value: ResourceType.LOCATION,
    },
];

export enum ResourceOtherUnitType {
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export enum ResourceMaterialUnitType {
    BAG = 'bag',
    BOX = 'box',
    BUCKET = 'bucket',
    BUNDLE = 'bundle',
    CRANE = 'crane',
    CARTON = 'carton',
    CUBIC_FEET = 'cubic_feet',
    CUBIC_METRE = 'cubic_METRE',
    CUBIC_YARD = 'cubic_yard',
    DOZEN = 'dozen',
    FEET = 'feet',
    GRAMME = 'gramme',
    HOUR = 'hour',
    ITEM = 'item',
    KILO = 'kilo',
    LENGTH = 'length',
    LITRE = 'litre',
    METRE = 'metre',
    MILLIMETRE = 'millimetre',
    PACK = 'pack',
    PAIR = 'pair',
    PERSON = 'person',
    POUND = 'pound',
    SACHET = 'sachet',
    SET = 'set',
    SHEET = 'sheet',
    SQ_FOOT = 'sq_foot',
    SQ_METRE = 'sq_metre',
    SQ_YARD = 'sq_yard',
    TON = 'ton',
}

export enum BaselinePosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    AROUND = 'around',
}

export const BaselineSettingDefault = {
    position: BaselinePosition.AROUND,
    display: true,
    color: '#FFD180',
};

export const PositionBaselineOptions = [
    {
        label: `planning.baseline.position.${BaselinePosition.TOP}`,
        value: BaselinePosition.TOP,
    },
    {
        label: `planning.baseline.position.${BaselinePosition.BOTTOM}`,
        value: BaselinePosition.BOTTOM,
    },
    {
        label: `planning.baseline.position.${BaselinePosition.AROUND}`,
        value: BaselinePosition.AROUND,
    },
];

export const ExportFormat3DOptions = Object.keys(ExportFormat3D).map((key) => {
    return {
        label: key,
        value: ExportFormat3D[key as keyof typeof ExportFormat3D],
    };
});

export const ExportFormat2DOptions = Object.keys(ExportFormat2D).map((key) => {
    return {
        label: key,
        value: ExportFormat2D[key as keyof typeof ExportFormat2D],
    };
});

export const ExportFileType = Object.keys(DimensionType).map((key) => {
    return {
        label: key,
        value: DimensionType[key as keyof typeof DimensionType],
    };
});

export const CRITICAL_TASK_COLOR = 'red';

export const ConstraintTypeMapping = {
    [TaskType.STANDARD]: Object.values(TaskConstraint),
    [TaskType.PROJECT]: [],
    [TaskType.MILESTONE]: [],
    [TaskType.START_MILESTONE]: [
        TaskConstraint.ASAP,
        TaskConstraint.ALAP,
        TaskConstraint.SNET,
        TaskConstraint.SNLT,
        TaskConstraint.MSO,
        TaskConstraint.SO,
    ],
    [TaskType.FINISH_MILESTONE]: [
        TaskConstraint.ASAP,
        TaskConstraint.ALAP,
        TaskConstraint.FNET,
        TaskConstraint.FNLT,
        TaskConstraint.MFO,
        TaskConstraint.FO,
    ],
    [TaskType.RESOURCE_DEPENDENT]: Object.values(TaskConstraint),
    [TaskType.LEVEL_EFFORT]: Object.values(TaskConstraint),
    [TaskType.WBS_SUMMARY]: [],
};

export const ConstraintTypeMappingStatus = {
    [TaskStatus.TODO]: Object.values(TaskConstraint),
    [TaskStatus.IN_PROGRESS]: [
        TaskConstraint.ASAP,
        TaskConstraint.ALAP,
        TaskConstraint.FNET,
        TaskConstraint.FNLT,
        TaskConstraint.MFO,
        TaskConstraint.FO,
    ],
    [TaskStatus.FINISHED]: Object.values(TaskConstraint),
};

export enum MilestoneType {
    TOP_DOWN = 'topDown',
    BOTTOM_UP = 'bottomUp',
    TOP_DOWN_DELEGATE_IM = 'topDownDelegateIM',
    TOP_DOWN_DELEGATE_FL = 'topDownDelegateFL',
    TOP_DOWN_DELEGATE_CF = 'topDownDelegateCF',
    BOTTOM_UP_DELEGATE_IM = 'bottomUpDelegateIM',
    BOTTOM_UP_DELEGATE_FL = 'bottomUpDelegateFL',
    BOTTOM_UP_DELEGATE_CF = 'bottomUpDelegateCF',
}

export enum TaskFieldDataType {
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    STRING = 'string',
    START_DATE = 'start_date',
    FINISH_DATE = 'finish_date',
    COST = 'cost',
    INTEGER = 'integer',
    DATE = 'date',
}

export enum DelegateOptions {
    NEW_PLANNING = 'newPlanning',
    EXIST_PLANNING = 'existPlanning',
}

export const PreserveTaskFields = [
    'Activity ID',
    'Activity status',
    'Activity type',
    'WBS code',
    'WBS name',
    'Activity name',
    'Calendar name',
    'Start',
    'Actual start',
    'Early start',
    'Late start',
    'Planned start',
    'BL start',
    'Finish',
    'Actual finish',
    'Early finish',
    'Late finish',
    'Planned finish',
    'BL finish',
    'Primary constraint',
    'Primary constraint date',
    'Expected finish',
    'Duration type',
    'Planned duration',
    'Original duration',
    'Actual duration',
    'Remaining duration',
    'At complete duration',
    'BL duration',
    'Variance - BL duration',
    'Variance - BL Finish Date',
    'Variance - BL Start Date',
    'Free float',
    'Total float',
    'Critical',
    'Percent complete type',
    'Physical % Complete',
    'Duration % Complete',
    'Manual % Complete',
    'Physical quantity unit',
    'Physical quantity',
    'Actual physical quantity',
    'Remaining physical quantity',
    'Rules',
    'Activity code',
    'Appreance profile',
    'Resource 3D',
    'Resource group',
    'Activity code',
    'Activity code value',
    'All columns',
];

export enum AppearanceType {
    INSTALL = 'install',
    MAINTAIN = 'maintain',
    REMOVE = 'remove',
    TEMPORARY = 'temporary',
}

export enum GrowthSimulation {
    BOTTOM_TOP = 'bottom_top',
    TOP_BOTTOM = 'top_bottom',
    LEFT_RIGHT = 'left_right',
    RIGHT_LEFT = 'right_left',
    FRONT_BACK = 'front_back',
    BACK_FRONT = 'back_front',
    NONE = 'none',
}

export const AppearanceTypeOptions = [
    {
        label: `planning.appearanceProfile.appearanceType.${AppearanceType.INSTALL}`,
        value: AppearanceType.INSTALL,
    },
    {
        label: `planning.appearanceProfile.appearanceType.${AppearanceType.MAINTAIN}`,
        value: AppearanceType.MAINTAIN,
    },
    {
        label: `planning.appearanceProfile.appearanceType.${AppearanceType.REMOVE}`,
        value: AppearanceType.REMOVE,
    },
    {
        label: `planning.appearanceProfile.appearanceType.${AppearanceType.TEMPORARY}`,
        value: AppearanceType.TEMPORARY,
    },
];

export const GrowthSimulationOptions = [
    {
        label: `planning.appearanceProfile.growthSimulation.${GrowthSimulation.BOTTOM_TOP}`,
        value: GrowthSimulation.BOTTOM_TOP,
    },
    {
        label: `planning.appearanceProfile.growthSimulation.${GrowthSimulation.TOP_BOTTOM}`,
        value: GrowthSimulation.TOP_BOTTOM,
    },
    {
        label: `planning.appearanceProfile.growthSimulation.${GrowthSimulation.LEFT_RIGHT}`,
        value: GrowthSimulation.LEFT_RIGHT,
    },
    {
        label: `planning.appearanceProfile.growthSimulation.${GrowthSimulation.RIGHT_LEFT}`,
        value: GrowthSimulation.RIGHT_LEFT,
    },
    {
        label: `planning.appearanceProfile.growthSimulation.${GrowthSimulation.FRONT_BACK}`,
        value: GrowthSimulation.FRONT_BACK,
    },
    {
        label: `planning.appearanceProfile.growthSimulation.${GrowthSimulation.BACK_FRONT}`,
        value: GrowthSimulation.BACK_FRONT,
    },
    {
        label: `planning.appearanceProfile.growthSimulation.${GrowthSimulation.NONE}`,
        value: GrowthSimulation.NONE,
    },
];

export enum DragDropEventType {
    PREV = 'prev',
    INNER = 'inner',
    NEXT = 'next',
}

export const GanttZoomLevel = ['day', 'week', 'month', 'quarter', 'year'];

export const DefaultDurationTaskInHour = {
    projectTask: 9,
    milestoneTask: 0,
    regularTask: 45,
};

export enum CurrencyType {
    DOLLAR = 'dollar',
    EURO = 'euro',
}

export enum AbbreviatedCurrency {
    dollar = '$',
    euro = '€',
}

export enum AbbreviatedResourceUnit {
    hour = 'h',
    day = 'd',
    week = 'w',
    month = 'm',
    year = 'y',
    bag = 'bg',
    box = 'bx',
    bucket = 'bkt',
    bundle = 'bdl',
    crane = 'cr',
    carton = 'ctn',
    cubic_feet = 'ft³',
    cubic_METRE = 'm³',
    cubic_yard = 'yd³',
    dozen = 'dz',
    feet = 'ft',
    gramme = 'gm',
    item = 'item',
    kilo = 'kg',
    length = 'length',
    litre = 'L',
    metre = 'm',
    millimetre = 'mm',
    pack = 'pack',
    pair = 'pr',
    person = 'person',
    pound = 'lbs',
    sachet = 'sachet',
    set = 'set',
    sheet = 'sheet',
    sq_foot = 'ft²',
    sq_metre = 'm²',
    sq_yard = 'yd²',
    ton = 'T',
}

export enum TaskDurationFormat {
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
    MINUTE = 'minute',
}

export const TaskDurationDecimalFormat = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
};

export const DEFAULT_DURATION = 5;
export const DEFAULT_DURATION_FORMAT_DECIMAL = 2;

export enum DefaultNameTask {
    CONTENT = 'Content',
    NEW_TASK = 'New task',
}

export enum MilestoneLinkTo {
    START = 'start',
    FINISH = 'finish',
}

export enum ImportResourceOption {
    SKIP = 'skip',
    CREATE = 'create',
    UPDATE = 'update',
}
export enum XML_TYPE {
    MSP = 'mps',
    P6 = 'p6',
}

export enum ACCEPT_FILE_TYPE {
    XML = '.xml',
    XLSX = '.xlsx',
}

export enum ProjectFieldName {
    NAME = 'name',
    P6_ID = 'p6Id',
    DATA_DATE = 'dataDate',
}

export enum TaskFieldName {
    NAME = 'name',
    FINISH = 'finish',
    START = 'start',
    ACTUAL_DURATION = 'actualDuration',
    ACTUAL_FINISH = 'actualFinish',
    ACTUAL_START = 'actualStart',
    DURATION = 'duration',
    P6_ID = 'p6Id',
    PARENT_ID = 'parentId',
    RESOURCE_IDS = 'resourceIds',
}

export enum ActivityCodeFieldName {
    P6_ID = 'p6Id',
    NAME = 'name',
    MAX_LENGTH = 'maxLength',
}

export enum ActivityCodeValueFieldName {
    P6_ID = 'p6Id',
    ACTIVITY_CODE_ID = 'activityCodeId',
    PARENT_ID = 'parentId',
    NAME = 'name',
    COLOR_CODE = 'colorCode',
}

export enum UserFieldFieldName {
    P6_ID = 'p6Id',
    NAME = 'name',
    DATA_TYPE = 'dataType',
}

export enum ResourceFieldName {
    NAME = 'name',
    P6_ID = 'p6Id',
    TYPE = 'type',
}

export enum LinkFieldName {
    SOURCE = 'source',
    TARGET = 'target',
    TYPE = 'type',
    LAG = 'lag',
}

export const convertLinkType = [
    LinkType.FINISH_TO_START,
    LinkType.START_TO_START,
    LinkType.FINISH_TO_FINISH,
    LinkType.START_TO_FINISH,
];

export const FINISH_TO_START = '0' as string;
export const START_TO_START = '1' as string;
export const FINISH_TO_FINISH = '2' as string;
export const START_TO_FINISH = '3' as string;

export enum ImportOption {
    UNDER_SELECTED_TASK = 'under_selected_task',
    END_OF_PROJECT = 'end_of_project',
    REPLACE_PLANNING = 'replace_planning',
}

export enum ExportObjectList {
    TASKS = 'tasks',
    LINKS = 'links',
    CALENDARS = 'calendars',
    RESOURCES = 'resources',
    RESOURCE_ASSIGNMENTS = 'resource_assignments',
    USER_FIELDS = 'user_fields',
    USER_FIELD_VALUES = 'user_field_values',
    ACTIVITY_CODES = 'activity_codes',
    ACTIVITY_CODE_ASSIGNMENTS = 'activity_code_assignments',
}

export enum ExportObjectListExcel {
    TASKS = 'tasks',
    LINKS = 'links',
    RESOURCES = 'resources',
    RESOURCE_ASSIGNMENTS = 'resource_assignments',
    RESOURCE_GROUPS = 'resource_groups',
    RESOURCE_GROUP_ASSIGNMENTS = 'resource_group_assignments',
    ACTIVITY_CODES = 'activity_codes',
    RESOURCE_UNITS = 'resource_units',
    DURATION_UNITS = 'duration_units',
}

export enum ImportObjectListExcel {
    TASKS = 'tasks',
    LINKS = 'links',
    RESOURCES = 'resources',
    RESOURCE_ASSIGNMENTS = 'resource_assignments',
    RESOURCE_GROUPS = 'resource_groups',
    RESOURCE_GROUP_ASSIGNMENTS = 'resource_group_assignments',
    ACTIVITY_CODES = 'activity_codes',
}

export enum ExportOption {
    RESOURCE_UNITS = 'Resource units',
    DURATION_UNITS = 'Duration units',
}

export enum LinkDependency {
    PREDECESSOR = 'predecessor',
    SUCCESSOR = 'successor',
}

export const AppearnceColorOptions = [
    {
        label: 'Original Color',
        value: 'Original',
    },
    {
        label: 'Custom Color',
        value: 'Custom',
    },
];
export const AppearanceTransparencyOptions = [
    {
        label: 'Original Transparency',
        value: 'Original',
    },
    {
        label: 'Custom Transparency',
        value: 'Custom',
    },
];
export enum AppearanceOptions {
    CUSTOM = 'Custom',
    ORIGINAL = 'Original',
}
export const FullTimeCalendarId = 'fulltime';

export enum AssignToExistingResourceOption {
    ASSIGN_TO_RESOURCE_WITHOUT_BULDING_TREE = 'assignToResourceWithoutBuildingTree',
    ASSIGN_TO_RESOURCE_UNDERNEATH_WITHOUT_BULDING_TREE = 'assignToResourceUnderneathWithoutBuildingTree',
    ASSIGN_TO_RESOURCE_BUILD_TREE = 'assignToResourceUnderneathBuildTree',
}

export const defaultWorkTimeBlocks = ['9:00-18:00'];

export enum TaskPopupTab {
    UPDATE_TASK = 'updateTask',
    ASSIGN_RESOURCE = 'assignResource',
    ASSIGN_RESOURCE_GROUP = 'assignResourceGroup',
    ASSIGN_ACTIVITY_CODE = 'assignActivityCode',
    ASSIGN_TASK_FIELD = 'assignTaskField',
}

export enum ResourceTab {
    DEFAULT_TAB = 'defaultTab',
    USER_FIELD_TAB = 'userFieldTab',
}

export enum ResourceAssignmentSettingTab {
    COLUMNS = 'columns',
    FILTER = 'filter',
}

export enum SearchTaskOption {
    EXACT = 'exact',
    APPROPRIATE = 'appropriate',
}

// columnWithType: columnType + VIEW_COLUMN_TYPE_SEPARATOR + columnName
// Used in key string to differentiate 3 types of column
// e.g. filter for ID column has the following key: predefined_id
export const VIEW_COLUMN_TYPE_SEPARATOR = '_';
export const DEFAULT_TASK_GROUP_BY = null + VIEW_COLUMN_TYPE_SEPARATOR + null;

export enum GanttColumn {
    ID = 'id',
    NAME = 'name',
    PARENT_ID = 'parentId',
    PARENT_NAME = 'parentName',
    STATUS = 'status',
    TYPE = 'type',
    START = 'start',
    PLANNED_START = 'plannedStart',
    ACTUAL_START = 'actualStart',
    EARLY_START = 'earlyStart',
    LATE_START = 'lateStart',
    BL_START = 'blStart',
    FINISH = 'finish',
    ACTUAL_FINISH = 'actualFinish',
    EARLY_FINISH = 'earlyFinish',
    LATE_FINISH = 'lateFinish',
    PLANNED_FINISH = 'plannedFinish',
    BL_FINISH = 'blFinish',
    PR_CONSTRAINT = 'prConstraint',
    PR_CONSTRAINT_DATE = 'prConstraintDate',
    EXPECTED_FINISH = 'expectedFinish',
    DURATION_TYPE = 'durationType',
    AT_COMPLETE_DURATION = 'atCompleteDuration',
    BL_DURATION = 'blDuration',
    VBL_DURATION = 'VBLDuration',
    VBL_FINISH_DATE = 'VBLFinishDate',
    VBL_START_DATE = 'VBLStartDate',
    FREE_FLOAT = 'freeFloat',
    TOTAL_FLOAT = 'totalFloat',
    CRITICAL = 'critical',
    PERCENT_COMPLETE_TYPE = 'percentCompleteType',
    PHYSICAL_PERCENTAGE = 'physicalPercentage',
    DURATION_PERCENTAGE = 'durationPercentage',
    MANUAL_PERCENTAGE = 'manualPercentage',
    PHYSICAL_UNIT = 'physicalUnit',
    REMAIN_PHYSICAL_QUANTITY = 'remainPhysicalQuantity',
    RESOURCE_3D = 'resouce3D',
    RESOURCE_GROUP = 'resourceGroup',
    ACTIVITY_CODE = 'activityCode',
    ACTIVITY_CODE_VALUE = 'activityCodeValue',
    CALENDAR_NAME = 'calendarName',
    ORIGINAL_DURATION = 'originalDuration',
    ACTUAL_DURATION = 'actualDuration',
    REMAINING_DURATION = 'remainingDuration',
    PLANNED_DURATION = 'plannedDuration',
    PHYSICAL_QUANTITY = 'physicalQuantity',
    ACTUAL_PHYSICAL_QUANTITY = 'actualPhysicalQuantity',
    RULES = 'rules',
    APPEARANCE_PROFILE = 'appearanceProfile',
    ACTUAL_HR_COST = 'actualHRCost', // Human Resource
    ACTUAL_MATERIAL_COST = 'actualMaterialCost',
    ACTUAL_EQUIPMENT_COST = 'actualEquipmentCost',
    ACTUAL_LOCATION_COST = 'actualLocationCost',
    ACTUAL_TOTAL_COST = 'actualTotalCost',
    AT_COMPLETE_HR_COST = 'atCompleteHRCost',
    AT_COMPLETE_MATERIAL_COST = 'atCompleteMaterialCost',
    AT_COMPLETE_EQUIPMENT_COST = 'atCompleteEquipmentCost',
    AT_COMPLETE_LOCATION_COST = 'atCompleteLocationCost',
    AT_COMPLETE_TOTAL_COST = 'atCompleteTotalCost',
    BUDGET_HR_COST = 'budgetHRCost',
    BUDGET_MATERIAL_COST = 'budgetMaterialCost',
    BUDGET_EQUIPMENT_COST = 'budgetEquipmentCost',
    BUDGET_LOCATION_COST = 'budgetLocationCost',
    BUDGET_TOTAL_COST = 'budgetTotalCost',
    HR_FIXED_COST = 'hrFixedCost',
    MATERIAL_FIXED_COST = 'materialFixedCost',
    EQUIPMENT_FIXED_COST = 'equipmentFixedCost',
    LOCATION_FIXED_COST = 'locationFixedCost',
    TOTAL_FIXED_COST = 'totalFixedCost',
    BL_HR_COST = 'blHRCost',
    BL_MATERIAL_COST = 'blMaterialCost',
    BL_EQUIPMENT_COST = 'blEquipmentCost',
    BL_LOCATION_COST = 'blLocationCost',
    BL_TOTAL_COST = 'blTotalCost',
    BL_FIXED_COST = 'blFixedCost',
    REMAIN_HR_COST = 'remainHRCost',
    REMAIN_MATERIAL_COST = 'remainMaterialCost',
    REMAIN_EQUIPMENT_COST = 'remainEquipmentCost',
    REMAIN_LOCATION_COST = 'remainLocationCost',
    REMAIN_TOTAL_COST = 'remainTotalCost',
    BL_ACTUAL_DURATION = 'blActualDuration',
    BL_REMAIN_DURATION = 'blRemainDuration',
    BL_FREE_FLOAT = 'blFreeFloat',
    BL_TOTAL_FLOAT = 'blTotalFloat',
    LONGEST_PATH = 'longestPath', // a.k.a. critical path
    PERCENT_COMPLETE = 'percentComplete',
    BL_DURATION_PERCENTAGE = 'blDurationPercentage',
    BL_MANUAL_PERCENTAGE = 'blManualPercentage',
    BL_PHYSICAL_PERCENTAGE = 'blPhysicalPercentage',
    COMPLETE_PHYSICAL_QUANTITY = 'atCompletePhysicalQuantity',
    ESTIMATED_RATE = 'estimatedRate',
    PREDECESSORS = 'predecessors',
    PREDECESSOR_DETAILS = 'predecessorDetails',
    SUCCESSORS = 'successors',
    SUCCESSOR_DETAILS = 'successorDetails',
    ACTUAL_HR_UNIT = 'actualHRUnit',
    ACTUAL_MATERIAL_UNIT = 'actualMaterialUnit',
    ACTUAL_EQUIPMENT_UNIT = 'actualEquipmentUnit',
    ACTUAL_LOCATION_UNIT = 'actualLocationUnit',
    AT_COMPLETE_HR_UNIT = 'atCompleteHRUnit',
    AT_COMPLETE_MATERIAL_UNIT = 'atCompleteMaterialUnit',
    AT_COMPLETE_EQUIPMENT_UNIT = 'atCompleteEquipmentUnit',
    AT_COMPLETE_LOCATION_UNIT = 'atCompleteLocationUnit',
    BUDGET_HR_UNIT = 'budgetHRUnit',
    BUDGET_MATERIAL_UNIT = 'budgetMaterialUnit',
    BUDGET_EQUIPMENT_UNIT = 'budgetEquipmentUnit',
    BUDGET_LOCATION_UNIT = 'budgetLocationUnit',
    BL_HR_UNIT = 'blHRUnit',
    BL_MATERIAL_UNIT = 'blMaterialUnit',
    BL_EQUIPMENT_UNIT = 'blEquipmentUnit',
    BL_LOCATION_UNIT = 'blLocationUnit',
    REMAIN_HR_UNIT = 'remainHRUnit',
    REMAIN_MATERIAL_UNIT = 'remainMaterialUnit',
    REMAIN_EQUIPMENT_UNIT = 'remainEquipmentUnit',
    REMAIN_LOCATION_UNIT = 'remainLocationUnit',
}

export enum InlineColumn {
    TASK_TYPE = 'taskType',
    CALENDAR_ID = 'calendarId',
    PRIMARY_CONSTRAINTS = 'primaryConstraints',
    PRIMARY_CONSTRAINT_DATE = 'primaryConstraintDate',
    PERCENTAGE_COMPLETE = 'percentageComplete',
    PHYSICAL_QUANTITY_UNIT = 'physicalQuantityUnit',
    GANTT_ID = 'ganttId',
    RULES = 'rules',
}

export enum GanttColumnGroups {
    ASSIGNMENTS = 'assignments',
    COSTS = 'costs',
    DATES = 'dates',
    DURATION = 'duration',
    GENERAL = 'general',
    PERCENT_COMPLETE = 'percentCompletes',
    PHYSICAL_QUANTITY = 'physicalQuantity',
    RELATIONSHIPS = 'relationships',
    UNIT = 'unit',
}

export const GANTT_COLUMN_GROUP_MAPPING = {
    [GanttColumnGroups.ASSIGNMENTS]: [
        GanttColumn.APPEARANCE_PROFILE,
        GanttColumn.RESOURCE_3D, // TODO: correct to resouRce
        GanttColumn.RESOURCE_GROUP,
    ],
    [GanttColumnGroups.COSTS]: [
        GanttColumn.ACTUAL_HR_COST,
        GanttColumn.ACTUAL_MATERIAL_COST,
        GanttColumn.ACTUAL_EQUIPMENT_COST,
        GanttColumn.ACTUAL_LOCATION_COST,
        GanttColumn.ACTUAL_TOTAL_COST,
        GanttColumn.AT_COMPLETE_HR_COST,
        GanttColumn.AT_COMPLETE_MATERIAL_COST,
        GanttColumn.AT_COMPLETE_EQUIPMENT_COST,
        GanttColumn.AT_COMPLETE_LOCATION_COST,
        GanttColumn.AT_COMPLETE_TOTAL_COST,
        GanttColumn.BUDGET_HR_COST,
        GanttColumn.BUDGET_MATERIAL_COST,
        GanttColumn.BUDGET_EQUIPMENT_COST,
        GanttColumn.BUDGET_LOCATION_COST,
        GanttColumn.BUDGET_TOTAL_COST,
        GanttColumn.HR_FIXED_COST,
        GanttColumn.MATERIAL_FIXED_COST,
        GanttColumn.EQUIPMENT_FIXED_COST,
        GanttColumn.LOCATION_FIXED_COST,
        GanttColumn.TOTAL_FIXED_COST,
        GanttColumn.BL_HR_COST,
        GanttColumn.BL_MATERIAL_COST,
        GanttColumn.BL_EQUIPMENT_COST,
        GanttColumn.BL_LOCATION_COST,
        GanttColumn.BL_TOTAL_COST,
        GanttColumn.BL_FIXED_COST,
        GanttColumn.REMAIN_HR_COST,
        GanttColumn.REMAIN_MATERIAL_COST,
        GanttColumn.REMAIN_EQUIPMENT_COST,
        GanttColumn.REMAIN_LOCATION_COST,
        GanttColumn.REMAIN_TOTAL_COST,
    ],
    [GanttColumnGroups.DATES]: [
        GanttColumn.ACTUAL_FINISH,
        GanttColumn.ACTUAL_START,
        GanttColumn.BL_FINISH,
        GanttColumn.BL_START,
        GanttColumn.EARLY_FINISH,
        GanttColumn.EARLY_START,
        GanttColumn.EXPECTED_FINISH,
        GanttColumn.START,
        GanttColumn.FINISH,
        GanttColumn.LATE_FINISH,
        GanttColumn.LATE_START,
        GanttColumn.PLANNED_FINISH,
        GanttColumn.PLANNED_START,
        GanttColumn.PR_CONSTRAINT_DATE,
    ],
    [GanttColumnGroups.DURATION]: [
        GanttColumn.ACTUAL_DURATION,
        GanttColumn.AT_COMPLETE_DURATION,
        GanttColumn.BL_ACTUAL_DURATION,
        GanttColumn.BL_DURATION,
        GanttColumn.BL_REMAIN_DURATION,
        GanttColumn.BL_FREE_FLOAT,
        GanttColumn.BL_TOTAL_FLOAT,
        GanttColumn.FREE_FLOAT,
        GanttColumn.ORIGINAL_DURATION,
        GanttColumn.PLANNED_DURATION,
        GanttColumn.REMAINING_DURATION,
        GanttColumn.TOTAL_FLOAT,
        GanttColumn.VBL_DURATION,
        GanttColumn.VBL_FINISH_DATE,
        GanttColumn.VBL_START_DATE,
    ],
    [GanttColumnGroups.GENERAL]: [
        GanttColumn.ID,
        GanttColumn.NAME,
        GanttColumn.STATUS,
        GanttColumn.TYPE,
        GanttColumn.CALENDAR_NAME,
        GanttColumn.CRITICAL,
        GanttColumn.LONGEST_PATH,
        GanttColumn.DURATION_TYPE,
        GanttColumn.PR_CONSTRAINT,
        GanttColumn.PARENT_ID,
        GanttColumn.PARENT_NAME,
    ],
    [GanttColumnGroups.PERCENT_COMPLETE]: [
        GanttColumn.PERCENT_COMPLETE,
        GanttColumn.DURATION_PERCENTAGE,
        GanttColumn.MANUAL_PERCENTAGE,
        GanttColumn.PHYSICAL_PERCENTAGE,
        GanttColumn.PERCENT_COMPLETE_TYPE,
        GanttColumn.BL_DURATION_PERCENTAGE,
        GanttColumn.BL_MANUAL_PERCENTAGE,
        GanttColumn.BL_PHYSICAL_PERCENTAGE,
    ],
    [GanttColumnGroups.PHYSICAL_QUANTITY]: [
        GanttColumn.COMPLETE_PHYSICAL_QUANTITY,
        GanttColumn.ACTUAL_PHYSICAL_QUANTITY,
        GanttColumn.ESTIMATED_RATE,
        GanttColumn.PHYSICAL_QUANTITY,
        GanttColumn.PHYSICAL_UNIT,
        GanttColumn.REMAIN_PHYSICAL_QUANTITY,
        GanttColumn.RULES,
    ],
    [GanttColumnGroups.RELATIONSHIPS]: [
        GanttColumn.PREDECESSORS,
        GanttColumn.PREDECESSOR_DETAILS,
        GanttColumn.SUCCESSORS,
        GanttColumn.SUCCESSOR_DETAILS,
    ],
    [GanttColumnGroups.UNIT]: [
        GanttColumn.ACTUAL_HR_UNIT,
        GanttColumn.ACTUAL_MATERIAL_UNIT,
        GanttColumn.ACTUAL_EQUIPMENT_UNIT,
        GanttColumn.ACTUAL_LOCATION_UNIT,
        GanttColumn.AT_COMPLETE_HR_UNIT,
        GanttColumn.AT_COMPLETE_MATERIAL_UNIT,
        GanttColumn.AT_COMPLETE_EQUIPMENT_UNIT,
        GanttColumn.AT_COMPLETE_LOCATION_UNIT,
        GanttColumn.BUDGET_HR_UNIT,
        GanttColumn.BUDGET_MATERIAL_UNIT,
        GanttColumn.BUDGET_EQUIPMENT_UNIT,
        GanttColumn.BUDGET_LOCATION_UNIT,
        GanttColumn.BL_HR_UNIT,
        GanttColumn.BL_MATERIAL_UNIT,
        GanttColumn.BL_EQUIPMENT_UNIT,
        GanttColumn.BL_LOCATION_UNIT,
        GanttColumn.REMAIN_HR_UNIT,
        GanttColumn.REMAIN_MATERIAL_UNIT,
        GanttColumn.REMAIN_EQUIPMENT_UNIT,
        GanttColumn.REMAIN_LOCATION_UNIT,
    ],
};

export enum ColumnType {
    PREDEFINED = 'predefined',
    ADDITIONAL_TASK_FIELD = 'additionalTaskField',
    ACTIVITY_CODE = 'activityCode',
}

export const RibbonGroup = {
    // General
    general: {
        name: 'planning.buttons.general',
        children: {
            appearanceProfile: {
                name: 'planning.buttons.appearanceProfile',
                iconLink: require('@/assets/icons/planning-icon/pla_appearance_profile.svg'),
            },
            manageView: {
                name: 'planning.buttons.manageView',
                iconLink: require('@/assets/icons/planning-icon/pla_grid_setting.svg'),
            },
            import: {
                name: 'planning.buttons.import',
                iconLink: require('@/assets/icons/planning-icon/pla_import.svg'),
            },
            importXML: {
                name: 'planning.buttons.importXML',
                iconLink: require('@/assets/icons/planning-icon/pla_import_xml.svg'),
            },
            importExcel: {
                name: 'planning.buttons.importExcel',
                iconLink: require('@/assets/icons/planning-icon/pla_import_xlsx.svg'),
            },
            export: {
                name: 'planning.buttons.export',
                iconLink: require('@/assets/icons/planning-icon/pla_export.svg'),
            },
            exportXML: {
                name: 'planning.buttons.exportXML',
                iconLink: require('@/assets/icons/planning-icon/pla_export_xml.svg'),
            },
            exportExcel: {
                name: 'planning.buttons.exportExcel',
                iconLink: require('@/assets/icons/planning-icon/pla_export_xlsx.svg'),
            },
            saveAs: {
                name: 'planning.buttons.saveAs',
                iconLink: require('@/assets/icons/planning-icon/pla_save_as.svg'),
            },
            copy: {
                name: 'planning.task.copy.button.copy',
                iconLink: require('@/assets/icons/planning-icon/copy.svg'),
            },
            paste: {
                name: 'planning.task.paste.button.paste',
                iconLink: require('@/assets/icons/planning-icon/paste.svg'),
            },
        },
    },
    planning: {
        name: 'planning.buttons.planning',
        children: {
            userField: {
                name: 'planning.buttons.userField',
                iconLink: require('@/assets/icons/planning-icon/pla_user_field.svg'),
            },
            activityCode: {
                children: {
                    activityCode: {
                        name: 'planning.buttons.activityCode',
                        iconLink: require('@/assets/icons/planning-icon/pla_activity_code.svg'),
                    },
                    assignActivityCode: {
                        name: 'planning.buttons.assignActivityCode',
                        iconLink: require('@/assets/icons/planning-icon/pla_assign_activity_code.svg'),
                    },
                },
            },
            resource: {
                children: {
                    resource: {
                        name: 'planning.buttons.resource',
                        iconLink: require('@/assets/icons/planning-icon/pla_resource.svg'),
                    },
                    assignResource: {
                        name: 'planning.buttons.assignResource',
                        iconLink: require('@/assets/icons/planning-icon/pla_assign_resource.svg'),
                    },
                },
            },
            rules: {
                children: {
                    rules: {
                        name: 'planning.buttons.rules',
                        iconLink: require('@/assets/icons/planning-icon/pla_resource.svg'),
                    },
                    assignRules: {
                        name: 'planning.buttons.assignRules',
                        iconLink: require('@/assets/icons/planning-icon/pla_assign_resource.svg'),
                    },
                },
            },
            resourceGroup: {
                children: {
                    resourceGroup: {
                        name: 'planning.buttons.resourceGroup',
                        iconLink: require('@/assets/icons/planning-icon/pla_resource_group.svg'),
                    },
                    assignResourceGroup: {
                        name: 'planning.buttons.assignResourceGroup',
                        iconLink: require('@/assets/icons/planning-icon/pla_assign_resource_group.svg'),
                    },
                },
            },
            baseLine: {
                name: 'planning.buttons.baseLine',
                iconLink: require('@/assets/icons/planning-icon/pla_baseline.svg'),
            },
            calendar: {
                name: 'planning.buttons.calendar',
                iconLink: require('@/assets/icons/planning-icon/pla_calendar.svg'),
                children: {
                    calendar: {
                        name: 'planning.buttons.calendar',
                        iconLink: require('@/assets/icons/planning-icon/pla_calendar.svg'),
                    },
                    assignCalendar: {
                        name: 'planning.buttons.assignCalendar',
                        iconLink: require('@/assets/icons/planning-icon/pla_calendar.svg'),
                    },
                },
            },
            task: {
                name: 'planning.buttons.task',
                iconLink: require('@/assets/icons/planning-icon/pla_user_field.svg'),
            },
        },
    },
    constellation: {
        name: 'planning.buttons.constellation',
        children: {
            delegate: {
                name: 'planning.buttons.delegate',
                iconLink: require('@/assets/icons/planning-icon/pla_delegate.svg'),
            },
            topDown: {
                name: 'planning.buttons.topDown',
                iconLink: require('@/assets/icons/planning-icon/pla_top_down.svg'),
            },
            bottomUp: {
                name: 'planning.buttons.bottomUp',
                iconLink: require('@/assets/icons/planning-icon/pla_bottom_up.svg'),
            },
            synthesis: {
                name: 'planning.buttons.synthesis',
                iconLink: require('@/assets/icons/planning-icon/pla_synthesis.svg'),
            },
            delete: {
                name: 'planning.buttons.delete',
                iconLink: require('@/assets/icons/planning-icon/pla_delete.svg'),
            },
        },
    },
};

export enum ValuesGenerateCaseSameValue {
    OPTION_1 = 'Modify the value in authoring tool',
    OPTION_2 = 'Generate customId for all objects',
    OPTION_3 = 'Generate customId for selection objects',
    OPTION_4 = 'Update customId',
    OPTION_5 = 'Clear customId for all objects',
    OPTION_6 = 'Clear customId for selection objects',
}

export const OptionsGenerateCaseSameValue = [
    {
        label: 'Modify the value in authoring tool',
        value: ValuesGenerateCaseSameValue.OPTION_1,
    },
    {
        label: 'Generate customId for all objects',
        value: ValuesGenerateCaseSameValue.OPTION_2,
    },
    {
        label: 'Generate customId for selection objects',
        value: ValuesGenerateCaseSameValue.OPTION_3,
    },
];

export const OptionsGenerateCaseSameValueWithClearCustomId = [
    {
        label: 'Clear customId for all objects',
        value: ValuesGenerateCaseSameValue.OPTION_5,
    },
    {
        label: 'Clear customId for selection objects',
        value: ValuesGenerateCaseSameValue.OPTION_6,
    },
];

export const OptionsGenerateCaseSameValueWithExitsCustomId = [
    {
        label: 'Modify the value in authoring tool',
        value: ValuesGenerateCaseSameValue.OPTION_1,
    },
    {
        label: 'Generate customId for all objects',
        value: ValuesGenerateCaseSameValue.OPTION_2,
    },
    {
        label: 'Generate customId for selection objects',
        value: ValuesGenerateCaseSameValue.OPTION_3,
    },
    {
        label: 'Update customId',
        value: ValuesGenerateCaseSameValue.OPTION_4,
    },
];

export const TaskDurationFormatString = {
    [TaskDurationFormat.HOUR]: 'h',
    [TaskDurationFormat.DAY]: 'd',
    [TaskDurationFormat.WEEK]: 'w',
    [TaskDurationFormat.MONTH]: 'm',
    [TaskDurationFormat.YEAR]: 'y',
    [TaskDurationFormat.MINUTE]: 'mi',
};

export const defaultCalendarUnit = 1;
export const defaultCalendarHoursPerDay = 9;
export const defaultCalendarHoursPerWeek = 45;
export const defaultCalendarHoursPerMonth = 194;
export const defaultCalendarHoursPerYear = 2250;
export const defaultCalendarHoursPerMinute = 1 / 60;
export const maxCalendarHoursPerDay = 24;
export const maxCalendarHoursPerWeek = 168;
export const maxCalendarHoursPerMonth = 744;
export const maxCalendarHoursPerYear = 8784;

export const AddColumnName = 'add';
export const AddColumnWidth = 44;
export enum CalendarConfigOrderBy {
    ID = '_id',
    DATE = 'date',
}

export const DefaultMaxDurationComplete = 100;
export const DefaultMinDurationComplete = 0;
export const DEFAULT_CRITICAL_THRESHOLD = 0;
export const ONE_HOUR_BY_MILLISECOND = 3600000; // 1000 * 60 * 60

export enum ManageViewTabs {
    COLUMN_TAB = 'columnTab',
    FILTER_TAB = 'filterTab',
    GANTT_TAB = 'ganttTab',
    BARS_TAB = 'barsTab',
    GROUP_TAB = 'groupTab',
}

export const DECIMAL_BY_HOUR = 2;
export enum GanttFilterLogic {
    AND = 'and',
    OR = 'or',
}
export enum GanttFilterOperation {
    EQUALS = 'equals',
    DOES_NOT_EQUAL = 'doesNotEqual',
    CONTAINS = 'contains',
    DOES_NOT_CONTAIN = 'doesNotContain',
    MATCHES = 'matches',
    DOES_NOT_MATCH = 'doesNotMatch',
    IS_GREATER_THAN = 'isGreaterThan',
    IS_GREATER_THAN_OR_EQUAL_TO = 'isGreaterThanOrEqualTo',
    IS_LESS_THAN = 'isLessThan',
    IS_LESS_THAN_OR_EQUAL_TO = 'isLessThanOrEqualTo',
}
export const MAP_COLUMN_NAME_TO_VALUE_TYPE = {
    [GanttColumn.ID]: TaskFieldDataType.STRING,
    [GanttColumn.NAME]: TaskFieldDataType.STRING,
    [GanttColumn.PARENT_ID]: TaskFieldDataType.STRING,
    [GanttColumn.PARENT_NAME]: TaskFieldDataType.STRING,
    [GanttColumn.STATUS]: TaskFieldDataType.STRING,
    [GanttColumn.TYPE]: TaskFieldDataType.STRING,
    [GanttColumn.START]: TaskFieldDataType.DATE,
    [GanttColumn.PLANNED_START]: TaskFieldDataType.DATE,
    [GanttColumn.ACTUAL_START]: TaskFieldDataType.DATE,
    [GanttColumn.EARLY_START]: TaskFieldDataType.DATE,
    [GanttColumn.LATE_START]: TaskFieldDataType.DATE,
    [GanttColumn.BL_START]: TaskFieldDataType.DATE,
    [GanttColumn.FINISH]: TaskFieldDataType.DATE,
    [GanttColumn.ACTUAL_FINISH]: TaskFieldDataType.DATE,
    [GanttColumn.EARLY_FINISH]: TaskFieldDataType.DATE,
    [GanttColumn.LATE_FINISH]: TaskFieldDataType.DATE,
    [GanttColumn.PLANNED_FINISH]: TaskFieldDataType.DATE,
    [GanttColumn.BL_FINISH]: TaskFieldDataType.DATE,
    [GanttColumn.PR_CONSTRAINT]: TaskFieldDataType.STRING,
    [GanttColumn.PR_CONSTRAINT_DATE]: TaskFieldDataType.DATE,
    [GanttColumn.EXPECTED_FINISH]: TaskFieldDataType.DATE,
    [GanttColumn.DURATION_TYPE]: TaskFieldDataType.STRING,
    [GanttColumn.AT_COMPLETE_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.VBL_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.VBL_FINISH_DATE]: TaskFieldDataType.DATE,
    [GanttColumn.VBL_START_DATE]: TaskFieldDataType.DATE,
    [GanttColumn.FREE_FLOAT]: TaskFieldDataType.NUMBER,
    [GanttColumn.TOTAL_FLOAT]: TaskFieldDataType.NUMBER,
    [GanttColumn.CRITICAL]: TaskFieldDataType.BOOLEAN,
    [GanttColumn.PERCENT_COMPLETE_TYPE]: TaskFieldDataType.STRING,
    [GanttColumn.PHYSICAL_PERCENTAGE]: TaskFieldDataType.NUMBER,
    [GanttColumn.DURATION_PERCENTAGE]: TaskFieldDataType.NUMBER,
    [GanttColumn.MANUAL_PERCENTAGE]: TaskFieldDataType.NUMBER,
    [GanttColumn.PHYSICAL_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_PHYSICAL_QUANTITY]: TaskFieldDataType.NUMBER,
    [GanttColumn.RESOURCE_3D]: TaskFieldDataType.STRING,
    [GanttColumn.RESOURCE_GROUP]: TaskFieldDataType.STRING,
    [GanttColumn.ACTIVITY_CODE]: TaskFieldDataType.STRING,
    [GanttColumn.ACTIVITY_CODE_VALUE]: TaskFieldDataType.STRING,
    [GanttColumn.CALENDAR_NAME]: TaskFieldDataType.STRING,
    [GanttColumn.ORIGINAL_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAINING_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.PLANNED_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.PHYSICAL_QUANTITY]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_PHYSICAL_QUANTITY]: TaskFieldDataType.NUMBER,
    [GanttColumn.RULES]: TaskFieldDataType.STRING,
    [GanttColumn.APPEARANCE_PROFILE]: TaskFieldDataType.STRING,
    [GanttColumn.ACTUAL_HR_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_MATERIAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_EQUIPMENT_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_LOCATION_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_TOTAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_HR_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_MATERIAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_EQUIPMENT_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_LOCATION_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_TOTAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_HR_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_MATERIAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_EQUIPMENT_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_LOCATION_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_TOTAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.HR_FIXED_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.MATERIAL_FIXED_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.EQUIPMENT_FIXED_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.LOCATION_FIXED_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.TOTAL_FIXED_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_HR_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_MATERIAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_EQUIPMENT_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_LOCATION_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_TOTAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_FIXED_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_HR_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_MATERIAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_EQUIPMENT_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_LOCATION_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_TOTAL_COST]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_ACTUAL_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_REMAIN_DURATION]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_FREE_FLOAT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_TOTAL_FLOAT]: TaskFieldDataType.NUMBER,
    [GanttColumn.LONGEST_PATH]: TaskFieldDataType.BOOLEAN,
    [GanttColumn.PERCENT_COMPLETE]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_DURATION_PERCENTAGE]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_MANUAL_PERCENTAGE]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_PHYSICAL_PERCENTAGE]: TaskFieldDataType.NUMBER,
    [GanttColumn.COMPLETE_PHYSICAL_QUANTITY]: TaskFieldDataType.NUMBER,
    [GanttColumn.ESTIMATED_RATE]: TaskFieldDataType.NUMBER,
    [GanttColumn.PREDECESSORS]: TaskFieldDataType.STRING,
    [GanttColumn.PREDECESSOR_DETAILS]: TaskFieldDataType.STRING,
    [GanttColumn.SUCCESSORS]: TaskFieldDataType.STRING,
    [GanttColumn.SUCCESSOR_DETAILS]: TaskFieldDataType.STRING,
    [GanttColumn.ACTUAL_HR_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_MATERIAL_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_EQUIPMENT_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.ACTUAL_LOCATION_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_HR_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_MATERIAL_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_EQUIPMENT_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.AT_COMPLETE_LOCATION_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_HR_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_MATERIAL_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_EQUIPMENT_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BUDGET_LOCATION_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_HR_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_MATERIAL_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_EQUIPMENT_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.BL_LOCATION_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_HR_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_MATERIAL_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_EQUIPMENT_UNIT]: TaskFieldDataType.NUMBER,
    [GanttColumn.REMAIN_LOCATION_UNIT]: TaskFieldDataType.NUMBER,
};

export const PARSE_DATA_TO_GANTT_DELAY = 500; // miliseconds
export const REGISTER_ELEMENT_TO_GANTT_DELAY = 2000; // miliseconds

export const DEFAULT_COLUMN_WIDTH = 130;
export const DURATION_ZERO_WITH_MILESTONE = 0;

export enum ManageViewNameDialogPurpose {
    CREATE = 'create',
    RENAME = 'rename',
    SAVE_AS = 'saveAs',
}

export const DEFAULT_MAX_DELETE_TASK_BY_KEYBOARD = 1;

export enum InProgressTaskOption {
    RETAINED_LOGIC = 'retained_logic',
    PROGRESS_OVERRIDE = 'progress_override',
    ACTUAL_DATES = 'actual_dates',
}

export const SpeedRunSimulationOption = {
    FIFTEEN_MINUTES: '15 minutes',
    THIRDTY_MINUTES: '30 minutes',
    ONE_HOUR: '1 hour',
    TWO_HOURS: '2 hours',
    THREE_HOURS: '3 hours',
    ONE_DAY: '1 day',
    TWO_DAYS: '2 days',
    THREE_DAYS: '3 days',
    ONE_WEEK: '1 week',
    TWO_WEEKS: '2 weeks',
    THREE_WEEKS: '3 weeks',
};

export enum CalendarForDelayLinkOption {
    PREDECESSOR = 'predecessor',
    SUCCESSOR = 'successor',
    DEFAULT = 'default',
}

export const DefaultZeroLinkLag = 0;

export const ResourceTreeViewLabel = {
    ID: 'ID',
    RESOURCE_NAME: 'Resource Name',
    STATUS: 'Status',
    DRIVING: 'Driving',
    FIXED_UNIT: 'Fixed Unit',
    ACTUAL_UNIT: 'Actual Unit',
    ACTUAL_UNIT_PERIOD: 'Actual Unit/Period',
    BUDGETED_UNIT: 'Budgeted Unit',
    REMAINING_UNIT: 'Remaining Unit',
    BUDGETED_COST: 'Budgeted Cost',
    REMAINING_COST: 'Remaining cost',
    ACTUAL_COST: 'Actual cost',
    FIXED_COST: 'Fixed cost',
    AT_COMPLETION_UNIT: 'At Completion Unit',
    RESOURCE_ACCOUNT: 'Resource Account',
    AT_COMPLETION_COST: 'At Completion Cost',
};

export const ResourceTreeViewData: IResourceViewTree[] = [
    {
        id: 0,
        label: ResourceTreeViewLabel.ID,
        value: 'resourceId',
        checked: false,
        width: 100,
    },
    {
        id: 1,
        label: ResourceTreeViewLabel.RESOURCE_NAME,
        value: 'label',
        width: 170,
        checked: false,
    },
    {
        id: 2,
        label: ResourceTreeViewLabel.STATUS,
        value: 'status',
        width: 150,
        checked: false,
    },
    {
        id: 3,
        label: ResourceTreeViewLabel.DRIVING,
        value: 'driving',
        width: 170,
        checked: false,
    },
    {
        id: 4,
        label: ResourceTreeViewLabel.FIXED_UNIT,
        value: 'fixedUnit',
        checked: false,
        width: 170,
    },
    {
        id: 5,
        label: ResourceTreeViewLabel.ACTUAL_UNIT_PERIOD,
        value: 'actualUnitPerPeriod',
        checked: false,
        width: 170,
    },
    {
        id: 6,
        label: ResourceTreeViewLabel.BUDGETED_UNIT,
        value: 'budgetedUnit',
        checked: false,
        width: 170,
    },
    {
        id: 7,
        label: ResourceTreeViewLabel.REMAINING_UNIT,
        value: 'remainingUnit',
        checked: false,
        width: 170,
    },
    {
        id: 8,
        label: ResourceTreeViewLabel.ACTUAL_UNIT,
        value: 'actualUnit',
        checked: false,
        width: 170,
    },
    {
        id: 9,
        label: ResourceTreeViewLabel.AT_COMPLETION_UNIT,
        value: 'atCompletionUnit',
        checked: false,
        width: 180,
    },
    {
        id: 10,
        label: ResourceTreeViewLabel.RESOURCE_ACCOUNT,
        value: 'resourceAccount',
        checked: false,
        width: 165,
    },
    {
        id: 11,
        label: ResourceTreeViewLabel.ACTUAL_COST,
        value: 'actualCost',
        checked: false,
        width: 170,
    },
    {
        id: 12,
        label: ResourceTreeViewLabel.BUDGETED_COST,
        value: 'budgetedCost',
        width: 170,
        checked: false,
    },
    {
        id: 13,
        label: ResourceTreeViewLabel.REMAINING_COST,
        value: 'remainingCost',
        checked: false,
        width: 170,
    },
    {
        id: 14,
        label: ResourceTreeViewLabel.AT_COMPLETION_COST,
        value: 'atCompletionCost',
        checked: false,
        width: 200,
    },
    {
        id: 15,
        label: ResourceTreeViewLabel.FIXED_COST,
        value: 'fixedCost',
        width: 170,
        checked: false,
    },
];
export enum DelegateStep {
    SELECT_TYPE = 0,
    SELECT_DELEGATE_TASK = 1,
    FILL_DELEGATE_FORM = 2,
}

export enum DelegateType {
    DELEGATE = 'delegate',
    SELECTIVE_DELEGATE = 'selectiveDelegate',
}

export const CLASS_DRIVING_RELATIONSHIP = 'gantt_task_link driving-relationship';
export const CLASS_NON_DRIVING_RELATIONSHIP = 'gantt_task_link non-driving-relationship';
export const CLASS_LINK_ON_CRITICAL_PATH = 'gantt_task_link link-on-critical-path';
export const CLASS_HIDE_RELATIONSHIP_LINE = 'hide-relationship-line';

export const MAX_TIMESTAMP = 7999999999999999; // year 255479

export const LIMIT_GET_SESSION_MILLISECOND = 60000;
export const DELAY_GET_SESSION_MILLISECOND = 2000;

export const IS_NUMBER_DRIVING_RELATIONSHIP = 0;

export const defaultWeekDays = [0, 1, 1, 1, 1, 1, 0];

export const DEFAULT_GANTT_SETTING = {
    isShowDataDateLine: true,
    isShowCriticalRelationships: true,
    isShowNonDrivingRelationships: false,
    isShowSimulationLine: true,
    isShowNonWorkTimeShading: false,
};

export const ResourceSettingColumns = [
    {
        label: 'ID',
        value: 'resourceId',
        type: 'resource_field',
    },
    {
        label: 'Name',
        value: 'name',
        type: 'resource_field',
    },
    {
        label: 'Description',
        value: 'description',
        type: 'resource_field',
    },
    {
        label: 'Type',
        value: 'type',
        type: 'resource_field',
    },
    {
        label: 'Unit',
        value: 'unit',
        type: 'resource_field',
    },
    {
        label: '3D Object Name',
        value: '3dObjectName',
        type: 'resource_field',
    },
    {
        label: 'Task Count',
        value: 'taskCount',
        type: 'resource_field',
    },
];

export const ResourceColumnsParentLabel = {
    ALL_COLUMNS: 'All Columns',
    RESOURCE_COLUMNS: 'Resource Columns',
    USER_FIELD_COLUMNS: 'User field Columns',
};

export const ResourceColumnType = {
    RESOURCE_FIELD: 'resource_field',
    USER_FIELD: 'user_field',
};
export enum Bars {
    LOE_REMAINING = 'loeRemaining',
    LOE_ACTUAL = 'loeActual',
    STANDARD_ACTUAL = 'standardActual',
    STANDARD_REMAINING_NO_CRITICAL = 'standardRemainingNoCritical',
    STANDARD_REMAINING_CRITICAL = 'standardRemainingCritical',
    MILESTONE = 'milestone',
    PLAN_BAR = 'planBar',
    CURRENT_BAR = 'currentBar',
    POSITIVE_FLOAT_BAR = 'positiveFloatBar',
    NEGATIVE_FLOAT_BAR = 'negativeFloatBar',
}

export const BarsConfig = {
    [Bars.LOE_REMAINING]: {
        color: '#008000',
        startField: 'dataDate',
        endField: 'earlyFinish',
        taskTypes: [TaskType.LEVEL_EFFORT],
    },
    [Bars.LOE_ACTUAL]: {
        color: '#000080',
        startField: 'actualStart',
        endField: 'actualFinish',
        taskTypes: [TaskType.LEVEL_EFFORT],
    },
    [Bars.STANDARD_ACTUAL]: {
        color: '#0000FF',
        startField: 'actualStart',
        endField: 'actualFinish',
        taskTypes: [TaskType.STANDARD],
    },
    [Bars.STANDARD_REMAINING_CRITICAL]: {
        color: '#00FF00',
        startField: 'earlyStart',
        endField: 'earlyFinish',
        taskTypes: [TaskType.STANDARD],
    },
    [Bars.STANDARD_REMAINING_NO_CRITICAL]: {
        color: '#FF0000',
        startField: 'earlyStart',
        endField: 'earlyFinish',
        taskTypes: [TaskType.STANDARD],
    },
    [Bars.PLAN_BAR]: {
        color: '#556B2F',
        startField: 'plannedStart',
        endField: 'plannedFinish',
    },
    [Bars.POSITIVE_FLOAT_BAR]: {
        color: '#fffb00',
        startField: 'earlyFinish',
        endField: 'lateFinish',
    },
    [Bars.NEGATIVE_FLOAT_BAR]: {
        color: '#7272f5',
        startField: 'lateFinish',
        endField: 'earlyFinish',
    },
};

export const MAX_BARS_COUNT = 10;

export enum ExcelSheetEnum {
    SETTING_SHEET = 'Settings',
    TASK_SHEET = 'Tasks',
    LINKS_SHEET = 'Links',
    CALENDAR_SHEET = 'Calendars',
    RESOURCES_SHEET = 'Resources',
    RESOURCE_GROUPS_SHEET = 'Resource Groups',
    RESOURCES_ASSIGNMENTS_SHEET = 'Resource assignments',
    GROUP_ASSIGNMENTS_SHEET = 'Resource group assignments',
    USER_FIELDS = 'User fields',
    USER_FIELD_VALUES = 'User field values',
    ACTIVITY_CODES = 'Activity Codes',
    ACTIVITY_CODES_ASSIGNMENTS = 'Activity Codes assignments',
}

export const importExcelHeader = {
    [ExcelSheetEnum.SETTING_SHEET]: [
        'durationUnits',
        'resourceUnits',
        'planningId',
        'name',
        'dataDate',
    ],
};
