import table from './table.en';
import fileForm from './fileForm.en';
import folderForm from './folderForm.en';
import uploadFormPopup from './uploadFormPopup.en';
import assignToPBSForm from './assignToPBSForm.en';
import assignToFileForm from './assignToFileForm.en';
export default {
    table,
    folderForm,
    uploadFormPopup,
    fileForm,
    assignToPBSForm,
    assignToFileForm,
    breadcrumb: 'ABS',
};
