export default {
    title: 'Calendar list',
    button: {
        create: 'Create',
    },
    default: 'Default',
    assignTitle: 'Assign calendar',
    popup: {
        title: {
            create: 'Create calendar',
            update: 'Update calendar',
        },
        name: {
            label: 'Name',
            placeholder: 'Name',
        },
        hoursPerDay: {
            label: 'Hours/Day',
            placeholder: 'Hours/Day',
        },
        hoursPerWeek: {
            label: 'Hours/Week',
            placeholder: 'Hours/Week',
        },
        hoursPerMonth: {
            label: 'Hours/Month',
            placeholder: 'Hours/Month',
        },
        hoursPerYear: {
            label: 'Hours/Year',
            placeholder: 'Hours/Year',
        },
        weekDay: {
            label: 'Week day',
            placeholder: 'Week day',
        },
        startYear: {
            label: 'Start year',
            placeholder: 'Start year',
        },
        endYear: {
            label: 'End year',
            placeholder: 'End year',
        },
        button: {
            cancel: 'Cancel',
            save: 'Save',
        },
    },
    message: {
        create: {
            success: 'Calendar has been created',
        },
        update: {
            success: 'Calendar has been updated',
        },
        delete: {
            success: 'Calendar has been deleted',
            confirm: {
                title: 'Confirm delete',
                message: 'Are you sure to delete this calendar?',
            },
        },
        setDefault: {
            success: 'Set default calendar successful',
        },
        setGlobal: {
            success: 'Set global calendar successful',
            confirm: {
                title: 'Confirm set to global',
                message: 'Are you sure to set this calendar to global data?',
            },
        },
    },
    option: {
        edit: 'Edit',
        setAsDefault: 'Set as default',
        delete: 'Delete',
        setGlobal: 'Set as global',
    },
    table: {
        header: {
            name: 'Name',
            planning: 'Planning',
            createdBy: 'Created by',
            action: 'Action',
        },
        button: {
            assign: 'Assign',
            unassign: 'Unassign',
            view: 'View',
        },
        filter: {
            placeholder: 'Filter',
        },
        badge: {
            default: 'Default',
        },
    },
    filterForm: {
        name: {
            label: 'Name',
            placeholder: 'Enter name',
        },
        createdBy: {
            label: 'Created by',
            placeholder: 'Enter created by name',
        },
        planning: {
            label: 'Planning name',
            placeholder: 'Enter planning name',
        },
        abs: {
            label: 'ABS',
            placeholder: 'Enter ABS',
        },
    },
    weekDay: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    },
};
