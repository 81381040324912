import table from './table.fr';
import folderForm from './folderForm.fr';
import fileForm from './fileForm.fr';
import uploadFormPopup from './uploadFormPopup.fr';
import assignToFileForm from './assignToFileForm.fr';
export default {
    table,
    folderForm,
    fileForm,
    uploadFormPopup,
    assignToFileForm,
    breadcrumb: 'ABS',
};
