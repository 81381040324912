export enum AbsViewMode {
    GRID = 'grid',
    TREE = 'tree',
    '3D_MODELING' = '3d-modeling',
}

export enum AbsDataType {
    FOLDER = 'folder',
    FILE = 'file',
}

export enum AbsFileAction {
    OPEN = 'open',
    RENAME = 'rename',
    DOWNLOAD = 'download',
    DELETE = 'delete',
}

export const fileActionOptions = [
    {
        label: 'abs.table.action.rename',
        value: AbsFileAction.RENAME,
    },
    {
        label: 'abs.table.action.download',
        value: AbsFileAction.DOWNLOAD,
    },
    {
        label: 'abs.table.action.delete',
        value: AbsFileAction.DELETE,
    },
];

export enum ABSUploadedFileExtensions {
    PLANNING = '.4DSP',
    PLANNING_XML = '.xml',
    PLANNING_XER = '.xer',
    PLANNING_XLS = '.xls',
    PLANNING_XLSX = '.xlsx',
    VIEWER_3D = '.3dvs',
    VIEWER_4D = '.4dvs',
    VIEWER_4D_BOX = '.4dvs-box',
    FILE_IFC = '.ifc',
    FILE_CATPART = '.CATPart',
    FILE_CATPRODUCT = '.CATProduct',
    FILE_STEP = '.stpz',
    FILE_JT = '.jt',
    FILE_IGES = '.iges',
    FILE_PARASOLID = '.x_t',
    FILE_STL = '.stl',
    FILE_PARASOLIDe = '.x_b',
    FILE_IGS = '.igs',
    FILE_ACIS = '.sat',
    FILE_ACISe = '.sab',
    FILE_U3D = '.u3d',
    FILE_3DSP = '.3dsp',
    FILE_3D_PDF = '.pdf',
    FILE_3DVIEWSTATION = '.vsxml',
    FILE_3DMF = '.3mf',
    FILE_AUTODESK3DS = '.3ds',
    FILE_DWG = '.dwg',
    FILE_DXF = '.dxf',
    FILE_DWF = '.dwf',
    FILE_DWFX = '.dwfx',
    FILE_IPT = '.ipt',
    FILE_IAM = '.iam',
    FILE_NWD = '.nwd',
    FILE_RVT = '.rvt',
    FILE_RFA = '.rfa',
    FILE_SESSION = '.session',
    FILE_CATSHAPE = '.CATshape',
    FILE_CGR = '.cgr',
    FILE_COLLADA = '.dae',
    FILE_CPIXML = '.cpixml',
    FILE_XPR = '.xpr',
    FILE_ASM = '.asm',
    FILE_XAS = '.xas',
    FILE_NEU = '.neu',
    FILE_FBX = '.fbx',
    FILE_GLTF = '.gltf',
    FILE_GLB = '.glb',
    FILE_MF1 = '.mf1',
    FILE_ARC = '.arc',
    FILE_UNV = '.unv',
    FILE_PKG = '.pkg',
    FILE_E57 = '.e57',
    FILE_NBT = '.nbt',
    FILE_PLMXML = '.plmxml',
    FILE_PRC = '.prc',
    FILE_3DM = '.3dm',
    FILE_PAR = '.par',
    FILE_PWD = '.pwd',
    FILE_PSM = '.psm',
    FILE_SLDASM = '.sldasm',
    FILE_SLDPRT = '.sldprt',
    FILE_PRT = '.prt',
    FILE_STPZ = '.stpz',
    FILE_STPX = '.stpx',
    FILE_STPXZ = '.stpxz',
    FILE_VDA = '.vda',
    FILE_WRL = '.wrl',
    FILE_VRML = '.vrml',
    FILE_OBJ = '.obj',
    FILE_MIL = '.mil',
    FILE_CATDRAWING = '.CATDrawing',
    FILE_CGM = '.cgm',
    FILE_DRW = '.drw',
    FILE_GER = '.ger',
    FILE_GIF = '.gif',
    FILE_PLT = '.plt',
    FILE_JPEG = '.jpeg',
    FILE_JPG = '.jpg',
    FILE_JP2 = '.jp2',
    FILE_PPTX = '.pptx',
    FILE_PPT = '.ppt',
    FILE_DOC = '.doc',
    FILE_DOCX = '.docx',
    FILE_ODG = '.odg',
    FILE_ODP = '.odp',
    FILE_ODS = '.ods',
    FILE_ODT = '.odt',
    FILE_PNG = '.png',
    FILE_DFT = '.dft',
    FILE_SLDDRW = '.slddrw',
    FILE_SVG = '.svg',
    FILE_TIF = '.tif',
    FILE_TIFF = '.tiff',
    FILE_BMP = '.bmp',
    FILE_WEBP = '.webp',
    FILE_SP = '.sp',
    FILE_SPX = '.spx',
    FILE_RVM = '.rvm',
    FILE_DLV = '.dlv',
    FILE_EXP = '.exp',
    FILE_DGN = '.dgn',
    FILE_MODEL = '.model',
    FILE_3DXML = '.3dxml',
}

export const Viewer4DExtensions = [ABSUploadedFileExtensions.VIEWER_4D];
export const PlanningExtensions = [
    ABSUploadedFileExtensions.PLANNING,
    ABSUploadedFileExtensions.PLANNING_XER,
    ABSUploadedFileExtensions.PLANNING_XLS,
    ABSUploadedFileExtensions.PLANNING_XML,
    ABSUploadedFileExtensions.PLANNING_XLSX,
];
export const Box4DExtensions = [
    ABSUploadedFileExtensions.VIEWER_3D,
    ABSUploadedFileExtensions.VIEWER_4D,
    ABSUploadedFileExtensions.VIEWER_4D_BOX,
];

export const synchroLinkExtensions = [
    ABSUploadedFileExtensions.FILE_SP,
    ABSUploadedFileExtensions.FILE_SPX,
];

export const viewer3DFileExtensions = [
    ABSUploadedFileExtensions.FILE_PRT,
    ABSUploadedFileExtensions.FILE_3DXML,
    ABSUploadedFileExtensions.FILE_EXP,
    ABSUploadedFileExtensions.FILE_DLV,
    ABSUploadedFileExtensions.FILE_MODEL,
    ABSUploadedFileExtensions.FILE_DWG,
    ABSUploadedFileExtensions.VIEWER_3D,
    ABSUploadedFileExtensions.FILE_IFC,
    ABSUploadedFileExtensions.FILE_CATPART,
    ABSUploadedFileExtensions.FILE_CATPRODUCT,
    ABSUploadedFileExtensions.FILE_STEP,
    ABSUploadedFileExtensions.FILE_JT,
    ABSUploadedFileExtensions.FILE_IGES,
    ABSUploadedFileExtensions.FILE_PARASOLID,
    ABSUploadedFileExtensions.FILE_STL,
    ABSUploadedFileExtensions.FILE_PARASOLIDe,
    ABSUploadedFileExtensions.FILE_IGS,
    ABSUploadedFileExtensions.FILE_ACIS,
    ABSUploadedFileExtensions.FILE_ACISe,
    ABSUploadedFileExtensions.FILE_U3D,
    ABSUploadedFileExtensions.FILE_3DSP,
    ABSUploadedFileExtensions.FILE_3DVIEWSTATION,
    ABSUploadedFileExtensions.FILE_3DMF,
    ABSUploadedFileExtensions.FILE_AUTODESK3DS,
    ABSUploadedFileExtensions.FILE_DWFX,
    ABSUploadedFileExtensions.FILE_IPT,
    ABSUploadedFileExtensions.FILE_IAM,
    ABSUploadedFileExtensions.FILE_NWD,
    ABSUploadedFileExtensions.FILE_RVT,
    ABSUploadedFileExtensions.FILE_RFA,
    ABSUploadedFileExtensions.FILE_SESSION,
    ABSUploadedFileExtensions.FILE_CATSHAPE,
    ABSUploadedFileExtensions.FILE_CGR,
    ABSUploadedFileExtensions.FILE_COLLADA,
    ABSUploadedFileExtensions.FILE_CPIXML,
    ABSUploadedFileExtensions.FILE_XPR,
    ABSUploadedFileExtensions.FILE_ASM,
    ABSUploadedFileExtensions.FILE_XAS,
    ABSUploadedFileExtensions.FILE_NEU,
    ABSUploadedFileExtensions.FILE_FBX,
    ABSUploadedFileExtensions.FILE_GLTF,
    ABSUploadedFileExtensions.FILE_GLB,
    ABSUploadedFileExtensions.FILE_MF1,
    ABSUploadedFileExtensions.FILE_ARC,
    ABSUploadedFileExtensions.FILE_UNV,
    ABSUploadedFileExtensions.FILE_PKG,
    ABSUploadedFileExtensions.FILE_E57,
    ABSUploadedFileExtensions.FILE_NBT,
    ABSUploadedFileExtensions.FILE_PLMXML,
    ABSUploadedFileExtensions.FILE_PRC,
    ABSUploadedFileExtensions.FILE_3DM,
    ABSUploadedFileExtensions.FILE_PAR,
    ABSUploadedFileExtensions.FILE_PWD,
    ABSUploadedFileExtensions.FILE_PSM,
    ABSUploadedFileExtensions.FILE_SLDASM,
    ABSUploadedFileExtensions.FILE_SLDPRT,
    ABSUploadedFileExtensions.FILE_STPZ,
    ABSUploadedFileExtensions.FILE_STPX,
    ABSUploadedFileExtensions.FILE_STPXZ,
    ABSUploadedFileExtensions.FILE_VDA,
    ABSUploadedFileExtensions.FILE_WRL,
    ABSUploadedFileExtensions.FILE_VRML,
    ABSUploadedFileExtensions.FILE_OBJ,
    ABSUploadedFileExtensions.FILE_DWF,
    ABSUploadedFileExtensions.FILE_3D_PDF,
    ABSUploadedFileExtensions.FILE_DXF,
];

export const viewer2DFileExtensions = [
    ABSUploadedFileExtensions.FILE_DGN,
    ABSUploadedFileExtensions.FILE_DWFX,
    ABSUploadedFileExtensions.FILE_3D_PDF,
    ABSUploadedFileExtensions.FILE_DWF,
    ABSUploadedFileExtensions.FILE_DWG,
    ABSUploadedFileExtensions.FILE_DXF,
    ABSUploadedFileExtensions.FILE_MIL,
    ABSUploadedFileExtensions.FILE_CATDRAWING,
    ABSUploadedFileExtensions.FILE_CGM,
    ABSUploadedFileExtensions.FILE_DRW,
    ABSUploadedFileExtensions.FILE_GER,
    ABSUploadedFileExtensions.FILE_GIF,
    ABSUploadedFileExtensions.FILE_PLT,
    ABSUploadedFileExtensions.FILE_JPEG,
    ABSUploadedFileExtensions.FILE_JPG,
    ABSUploadedFileExtensions.FILE_JP2,
    ABSUploadedFileExtensions.FILE_PPTX,
    ABSUploadedFileExtensions.FILE_DOC,
    ABSUploadedFileExtensions.FILE_DOCX,
    ABSUploadedFileExtensions.FILE_ODG,
    ABSUploadedFileExtensions.FILE_ODP,
    ABSUploadedFileExtensions.FILE_ODS,
    ABSUploadedFileExtensions.FILE_ODT,
    ABSUploadedFileExtensions.FILE_PNG,
    ABSUploadedFileExtensions.FILE_DFT,
    ABSUploadedFileExtensions.FILE_SLDDRW,
    ABSUploadedFileExtensions.FILE_SVG,
    ABSUploadedFileExtensions.FILE_TIF,
    ABSUploadedFileExtensions.FILE_TIFF,
    ABSUploadedFileExtensions.FILE_BMP,
    ABSUploadedFileExtensions.FILE_WEBP,
    ABSUploadedFileExtensions.FILE_MODEL,
    ABSUploadedFileExtensions.FILE_DLV,
    ABSUploadedFileExtensions.FILE_EXP,
    ABSUploadedFileExtensions.FILE_3DXML,
    ABSUploadedFileExtensions.PLANNING_XLS,
    ABSUploadedFileExtensions.PLANNING_XLSX,
    ABSUploadedFileExtensions.FILE_PPT,
    ABSUploadedFileExtensions.FILE_PRT,
];

export const rootFolderIndex = 1;
