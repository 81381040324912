export const errors = {
    404: {
        title: 'Error 404',
        description: 'Page not found',
        goHomepage: 'GO TO HOMEPAGE',
    },

    403: {
        title: 'Error 403 - Forbidden',
        description: "You dont't have permission to access this page",
    },
};
