export const WEBVIEWER3D_URL = process.env.VUE_APP_WEBVIEWER3D_URL;

export enum Viewer3DActions {
    UPLOAD_FROM_LOCAL = 'uploadFromLocal',
    IMPORT_FROM_LOCAL = 'importFromLocal',
    CREATE_ZONE = 'createZone',
    CREATE_4D_BOX = 'create4dBox',
    OPEN_FROM_REPOSITORY = 'openFromRepository',
    IMPORT_FROM_REPOSITORY = 'importFromRepository',
    EXPORT = 'exportFile',
    SAVE = 'saveFile',
    SAVE_AS = 'saveAsFile',
    GENERATE_CUSTOM_ID = 'generateCustomId',
    SYNCHRONISE_FILE = 'synchroniseFile',
    SYNCHRONISE_FILE_BOTTOM_UP = 'synchroniseBottomUp',
    SYNCHRONISE_FILE_TOP_DOWN = 'synchroniseTopDown',
    SET_NODE_PROPERTIES = 'setNodeProperties',
    SAVE_SELECTED_NODE = 'saveSelectedNode',
    CREATE_RESOURCE = 'createResource3D',
    GET_SELECTED_TAKS = 'getSelectedTasks',
}

export enum DimensionType {
    '2D' = '2D',
    '3D' = '3D',
}

export enum DialogType {
    SAVE = 'SAVE',
    IMPORT = 'IMPORT',
    OPEN = 'OPEN',
}

export enum ImportSource {
    ABS = 'abs',
    REPOSITORY = 'repository',
}

export const minutesPerHour = 60;

export const defaultOptionsGenerateCustomId = {
    nodeId: 0,
    recursive: true,
    overwrite: false,
    uniqueIds: true,
    includePartNodes: true,
    mode: 'NodePath',
};

export const LIMIT_AMOUNT_FILE_GENERATE_CUSTOMID = 1;
