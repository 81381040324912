import { useField, useForm } from 'vee-validate';

import yup from '@/plugins/yup';

import { DEFAULT_TASK_GROUP_BY } from '../constants';
import { extractColumnWithTypeInGroupBy } from '../helper';
import { projectPlanningModule } from '../store';

const groupTaskSchema = yup.object({
    columnWithType: yup.string().trim().required(),
});

export const setupGroupTaskForm = () => {
    const initialValues = {
        columnWithType: DEFAULT_TASK_GROUP_BY,
    };

    const { handleSubmit, errors, resetForm } = useForm({
        initialValues,
        validationSchema: groupTaskSchema,
    });

    const onSubmit = handleSubmit((values) => {
        const { columnName, columnType } = extractColumnWithTypeInGroupBy(
            values.columnWithType!,
        );
        projectPlanningModule.setTempPlanningViewGroupBy({
            columnName,
            columnType,
        });
    });

    const { value: columnWithType } = useField('columnWithType');

    return {
        columnWithType,
        errors,
        onSubmit,
        resetForm,
    };
};
