import cloneDeep from 'lodash/cloneDeep';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import localStorageAuthService from '@/common/authStorage';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_ORDER_BY,
    LIMIT_PER_PAGE,
    OrderDirection,
} from '@/common/constants';
import { showErrorNotificationFunction } from '@/common/helpers';
import {
    IBodyResponse,
    IFolderStructureTree,
    IGetListResponse,
    IProjectFile,
    IQueryDropdownFile,
    IResourceViewResponse,
    IResourceViewTree,
} from '@/common/interfaces';
import { commonService } from '@/common/services/common.service';
import store from '@/plugins/vuex';

import { webViewer3DService } from '../3D-viewer/services/api.service';
import { projectModule } from '../project/store';
import {
    DelegateOptions,
    DelegateType,
    GanttColumn,
    GanttFilterLogic,
    ResourceTreeViewData,
    UserFieldTypeEnum,
} from './constants';
import {
    IActivityCodeListItem,
    IAdditionalTaskField,
    IAppearanceProfile,
    IAppearanceProfileListQuery,
    IBar,
    IBaselinePlanning,
    IBaselinePlanningListQuery,
    IColumnFilter,
    IColumnSetting2,
    IDelegateOption,
    IDelegationHasModifyResponse,
    IGanttChartTask,
    IGanttColumnsDisplayStatus,
    IGanttView,
    IGetTaskFieldListQueryString,
    IGroupBy,
    IImportProjectExcel,
    IImportProjectXml,
    ILinkDetail,
    IPlanning,
    IPlanningListQuery,
    IPlanningQuery,
    IPlanningView,
    IProjectTask,
    IResource,
    IResourceGroup,
    IResourceListQuery,
    ISelectedFilterColumn,
    ITaskFieldParams,
    ITaskLink,
    ITaskRuleList,
    TaskActions,
} from './interfaces';
import { projectPlanningService } from './services/planning.service';

interface ITaskPopupParam {
    selectedTask: IGanttChartTask | null;
    selectedTaskId: string | null;
    parentOfSelectedTask: IGanttChartTask | null;
    show: boolean;
}

interface ILinkPopupParam {
    selectedTask: IGanttChartTask | null;
    show: boolean;
    isCreate?: boolean;
}

interface IDelegationPopupParam {
    show: boolean;
    tabSelected: DelegateOptions;
}

interface IGanttContextMenuParam {
    task: IGanttChartTask | null;
    link: ITaskLink | null;
    show: boolean;
    top?: number;
    left?: number;
    permissionAddLink?: boolean | null;
    permissionCurrentLink?: boolean | null;
    parentTask?: IGanttChartTask;
}

interface ILinkFormPopupParam {
    dependency: null | string;
    taskLinkToList: ILinkDetail[];
    show: boolean;
    edit?: boolean;
}

interface IActivityCodePopupParam {
    show: boolean;
    isCreate: boolean;
    activityCodeIdSelected: string;
    activityCodeValueSelected: string;
}
interface ILinkDetailFormPopupParam {
    linkSelected: ITaskLink | null;
    show: boolean;
}

interface IBaselineIdSelectedInPlanning {
    planningId: string;
    baselineId: string;
}

export const initBaselineQueryString = {
    page: DEFAULT_FIRST_PAGE,
    limit: LIMIT_PER_PAGE,
    orderBy: DEFAULT_ORDER_BY,
    orderDirection: OrderDirection.DESCENDING,
    keyword: '',
    planningId: '',
};

export const SynchroniseFilePopupMode = {
    FROM: 'from',
    BOTTOM_UP: 'bottom up',
    TOP_DOWN: 'top down',
};

@Module({ dynamic: true, namespaced: true, store, name: 'project-planning' })
class ProjectPlanningModule extends VuexModule {
    isShowBaselinePopup = false;
    initialDelegationPopupParam = {
        show: false,
        tabSelected: DelegateOptions.NEW_PLANNING,
    };
    delegationPopupParam = cloneDeep(this.initialDelegationPopupParam);
    isShowManageViewPopup = false;
    isShowSynthesisPopup = false;
    isShowResourcePopup = false;
    isShowResourceDetailForm = false;
    isShowResourceGroupPopup = false;
    isShowCalendarPopup = false;
    isShowResourceGroupDetailForm = false;
    isShowRescheduleOptionForm = false;
    isShowSimulationOptionForm = false;
    isShowCircularLinkPopup = false;
    ganttContextMenuParam: IGanttContextMenuParam = {
        task: null,
        link: null,
        show: false,
        top: 0,
        left: 0,
    };
    linkDetailFormPopupParam: ILinkDetailFormPopupParam = {
        linkSelected: null,
        show: false,
    };
    linkPopupParam: ILinkPopupParam = {
        selectedTask: null,
        show: false,
        isCreate: false,
    };
    linkFormPopupParam: ILinkFormPopupParam = {
        dependency: null,
        taskLinkToList: [],
        show: false,
    };
    bulkCreateLinkList: ILinkDetail[] = [];

    resourceList: IResource[] = [];
    resourceGroupList: IResourceGroup[] = [];
    planningList: IPlanning[] = [];
    planning: IPlanning | null = null;
    selectedPlannings: IPlanning[] = [];
    totalPlanning = 0;
    initialTaskPopupParams: ITaskPopupParam = {
        selectedTaskId: null,
        show: false,
        selectedTask: null,
        parentOfSelectedTask: null,
    };
    taskPopupParams: ITaskPopupParam = {
        ...this.initialTaskPopupParams,
    };
    selectedResource: IResource | null = null;
    selectedResourceGroup: IResourceGroup | null = null;
    isDisabledSaveButton = false;
    projectFileList: IProjectFile[] = [];

    baselineIdSelected = '';
    baselineListQueryString: IBaselinePlanningListQuery = initBaselineQueryString;
    baselineList: IBaselinePlanning[] = [];
    totalBaselineItems = 0;
    selectedTaskIds: string[] = [];
    filteredTaskIds: string[] = [];
    planningId = '';
    baselineIdsSelected: IBaselineIdSelectedInPlanning[] = [];
    totalBaselineSelected = 0;
    selectedBaselineIdToUpdate: string | null = null;
    isShowAssignResourceForm = false;
    isShowAssignResourceGroupForm = false;
    updatedTasks: IProjectTask[] = [];
    hasTaskDeleted = false;
    latestCreatedLink: ITaskLink | null = null;
    editedTaskIds: string[] = [];
    selectedTaskIdList: string[] = [];
    taskFieldsParams: ITaskFieldParams = {
        open: false,
        action: null,
        selectedField: null,
        type: UserFieldTypeEnum.TASK,
    };
    needReload3DViewer = false;
    isShowAppearanceProfilePopup = false;
    isShowAppearanceProfileDetailForm = false;
    appearanceProfileList: IAppearanceProfile[] = [];
    totalAppearanceProfile = 0;
    selectedAppearanceProfile: IAppearanceProfile | null = null;
    isShowTopDownFormPopup = false;
    selectedActivityCodeId = '';
    selectedActivityCodeFolderId = '';
    isShowBottomUpFormPopup = false;
    isMovingFocusTime = false;
    taskLayerId = '';
    assignedTaskNames: string[] = [];
    isShowAssignedTaskNamesPopup = false;
    isShowPlanningPopup = false;
    isDisableButtonAdd = false;
    isShowExportPlanningPopup = false;
    isShowExportExcelPopup = false;
    delegationHasModify: IDelegationHasModifyResponse[] = [];
    isShowImportXmlFileForm = false;
    isShowImportXmlDetail = false;
    isShowImportXmlSummary = false;
    isShowImportExcelFileForm = false;
    isShowImportExcelDetail = false;
    isShowImportExcelSummary = false;
    importProjectXml: IImportProjectXml = {
        p6Id: '',
        id: '',
        name: '',
        dataDate: undefined as unknown as Date,
    };
    importProjectExcel: IImportProjectExcel = {
        p6Id: '',
        name: '',
        dataDate: undefined as unknown as Date,
    };
    createdLink = false;
    rootTaskHasPermissionCreateChild: IProjectTask[] = [];
    resourceTree: IFolderStructureTree[] = [
        {
            name: '',
            children: [],
            path: '/',
            level: 0,
        },
    ];
    resourceAssignmentTree: any[] = [];
    selectedResourceNodeId = '';
    isShowImportResourcePopup = false;
    extendPermission: string[] = [];
    isShowSaveAsPopup = false;

    activityCodePopupParam: IActivityCodePopupParam = {
        show: false,
        isCreate: false,
        activityCodeIdSelected: '',
        activityCodeValueSelected: '',
    };
    isShowAssignActivityCodePopup = false;
    activityCodeList: IActivityCodeListItem[] = [];

    planningViewList: IPlanningView[] = [];
    tempPlanningView: IPlanningView | null = null;

    editedLinkIds: string[] = [];
    selectedTaskId = '';
    selectedFilePaths: string[] = [];
    activityCodeTree: IFolderStructureTree[] = [];
    taskFieldList: IAdditionalTaskField[] = [];
    resourceFieldList: IAdditionalTaskField[] = [];
    resourceGroupFieldList: IAdditionalTaskField[] = [];
    totalTaskField = 0;
    totalResourceField = 0;
    totalResourceGroupField = 0;
    isShowGenerateCustomIdPopup = false;
    attributeNodeDetail: Record<string, string[]> = {};
    isShowSynchroniseFilePopup = false;
    synchroniseFilePopupMode: any = SynchroniseFilePopupMode.FROM;
    currentLinkList: ILinkDetail[] = [];

    showAssignCalendarFormPopup = false;
    selectedTask: null | IProjectTask = null;

    copiedTaskIds: string[] = [];

    showFilterGanttChartFormPopup = false;
    selectedFilterColumn: ISelectedFilterColumn | null = null;
    planningFilters: Record<string, IColumnFilter[]> = {};
    selectedColumnFilterDetail: IColumnFilter | null = null;
    logicBetweenFilters = GanttFilterLogic.AND;
    delegateOption: IDelegateOption = {
        delegateType: DelegateType.DELEGATE,
        selectedDelegateTaskIds: [],
        selectedStartTaskToGenerateMilestoneId: '',
        selectedFinishTaskToGenerateMilestoneId: '',
    };
    selectedFilterIds: string[] = [];

    taskRuleList: ITaskRuleList[] = [];
    isShowAssignTaskRulePopup = false;
    taskRuleListAssignment: any[] = [];
    isDisabledInlineEdit = false;
    taskBarIds: string[] = [];

    get ganttColumnsDisplayStatus(): IGanttColumnsDisplayStatus {
        const { columns } = this.planning?.setting ?? {};
        if (!columns) return {};

        // https://stackoverflow.com/a/47755096/5959593
        const ganttColumns = Object.values<string>(GanttColumn);
        const result = columns
            .filter((col) => ganttColumns.includes(col.name))
            .reduce((agg, col) => {
                const name = col.name as GanttColumn;
                agg[name] = col.display;
                return agg;
            }, {} as IGanttColumnsDisplayStatus);

        // Name is a required column, but old planning.settings can still has
        // display: false for
        result[GanttColumn.NAME] = true;

        return result;
    }

    resourceView: IResourceViewResponse | null = null;
    resourceTreeView: IResourceViewTree[] = ResourceTreeViewData;
    isShowResourceAssignmentSettingPopup = false;

    isShowResourceAssignmentDetailPopup = false;
    selectedResourceAssignmentDetail: string | number = -1;

    isShowResourceAssignmentFilterPopup = false;

    isSetFileIdsInResourceDetail = false;

    isShowTaskRulePopup: {
        isOpen: boolean;
        action: TaskActions;
        selectedTaskRule: null | string;
        isPlanningSelected?: boolean;
    } = {
        isOpen: false,
        action: TaskActions.READ,
        selectedTaskRule: null,
    };

    isShowResourceSetting = false;
    listResourceSetting: any[] = [];

    taskFieldValues: Record<any, any> = {};

    isShowTaskFieldDetail = false;

    isShowSetNodePropertiesPopup = false;

    needReloadTaskDetail = false;

    @Mutation
    MUTATE_SET_NEED_RELOAD_TASK_DETAIL(value: boolean) {
        this.needReloadTaskDetail = value;
    }

    @Action
    setNeedReloadTaskDetail(value: boolean) {
        this.MUTATE_SET_NEED_RELOAD_TASK_DETAIL(value);
    }

    @Mutation
    MUTATE_SET_IS_SHOW_SET_NODE_PROPERTIES_POPUP(value: boolean) {
        this.isShowSetNodePropertiesPopup = value;
    }

    @Action
    setIsShowSetNodePropertiesPopup(value: boolean) {
        this.MUTATE_SET_IS_SHOW_SET_NODE_PROPERTIES_POPUP(value);
    }

    @Mutation
    MUTATE_SET_IS_SHOW_TASK_FIELD_DETAIL(value: boolean) {
        this.isShowTaskFieldDetail = value;
    }

    @Action
    setIsShowTaskFieldDetail(value: boolean) {
        this.MUTATE_SET_IS_SHOW_TASK_FIELD_DETAIL(value);
    }

    @Mutation
    MUTATE_SET_TASK_FIELD_VALUE(value: any) {
        this.taskFieldValues = value;
    }

    @Action
    setTaskFieldValue(value: any) {
        this.MUTATE_SET_TASK_FIELD_VALUE(value);
    }

    circularLinks: { tasks: IGanttChartTask[] }[] = [];

    @Mutation
    MUTATE_SET_CIRCULAR_LINKS(value: { tasks: IGanttChartTask[] }[]) {
        this.circularLinks = value;
    }

    @Action
    setCircularLinks(value: { tasks: IGanttChartTask[] }[]) {
        this.MUTATE_SET_CIRCULAR_LINKS(value);
    }

    @Mutation
    MUTATE_SET_LIST_RESOURCE_SETTING(value: any[]) {
        this.listResourceSetting = value;
    }

    @Action
    setListResourceSetting(value: any[]) {
        this.MUTATE_SET_LIST_RESOURCE_SETTING(value);
    }

    @Action
    async getListResourceSetting() {
        const response = await projectPlanningService.getResourceSetting(
            projectPlanningModule.planning?._id || '',
        );

        if (response.success) {
            projectPlanningModule.setListResourceSetting(response.data.columns);
        }
    }

    @Mutation
    MUTATE_SET_IS_SHOW_RESOURCE_SETTING(value: boolean) {
        this.isShowResourceSetting = value;
    }

    @Action
    setIsShowResourceSetting(value: boolean) {
        this.MUTATE_SET_IS_SHOW_RESOURCE_SETTING(value);
    }

    @Mutation
    MUTATE_SET_LIST_TASK_RULE_ASSIGNMENT(value: any) {
        this.taskRuleListAssignment = value;
    }

    @Action
    setTaskRuleListAssignment(value: any) {
        this.MUTATE_SET_LIST_TASK_RULE_ASSIGNMENT(value);
    }

    @Mutation
    MUTATE_SET_IS_SHOW_ASSIGN_TASK_RULE(value: boolean) {
        this.isShowAssignTaskRulePopup = value;
    }

    @Action
    setIsShowAssignTaskRulePopup(value: boolean) {
        this.MUTATE_SET_IS_SHOW_ASSIGN_TASK_RULE(value);
    }

    @Mutation
    MUTATE_SET_TASK_RULE_LIST(value: ITaskRuleList[]) {
        this.taskRuleList = value;
    }

    @Action
    setTaskRuleList(value: ITaskRuleList[]) {
        this.MUTATE_SET_TASK_RULE_LIST(value);
    }

    @Mutation
    MUTATE_IS_SHOW_TASK_RULE_POP_UP(value: any) {
        this.isShowTaskRulePopup = value;
    }

    @Action
    setIsShowTaskRulePopup(value: any) {
        this.MUTATE_IS_SHOW_TASK_RULE_POP_UP(value);
    }

    @Action
    setIsShowResourceAssignmentFilterPopup(value: boolean) {
        this.MUTATE_RESOURCE_ASSIGNMENT_FILTER_POPUP(value);
    }

    @Mutation
    MUTATE_RESOURCE_ASSIGNMENT_FILTER_POPUP(value: boolean) {
        this.isShowResourceAssignmentFilterPopup = value;
    }

    @Action
    setSelectedResourceAssignmentDetail(value: string | number) {
        this.MUTATE_SELECTED_RESOURCE_ASSSIGNMENT_DETAIL(value);
    }

    @Mutation
    MUTATE_SELECTED_RESOURCE_ASSSIGNMENT_DETAIL(value: number | string) {
        this.selectedResourceAssignmentDetail = value;
    }

    @Action
    setIsShowResourceAssignmentDetailPopup(value: boolean) {
        this.MUTATE_RESOURCE_ASSIGNMENT_DETAIL_POPUP(value);
    }

    @Mutation
    MUTATE_RESOURCE_ASSIGNMENT_DETAIL_POPUP(value: boolean) {
        this.isShowResourceAssignmentDetailPopup = value;
    }

    @Action
    setIsShowResourceAssignmentSettingPopup(value: boolean) {
        this.MUTATE_RESOURCE_ASSIGNMENT_SETTING_POPUP(value);
    }

    @Mutation
    MUTATE_RESOURCE_ASSIGNMENT_SETTING_POPUP(value: boolean) {
        this.isShowResourceAssignmentSettingPopup = value;
    }

    @Action
    setResourceTreeView(resourceTreeView: IResourceViewTree[]) {
        this.MUTATE_RESOURCE_TREE_VIEW(resourceTreeView);
    }

    @Mutation
    MUTATE_RESOURCE_TREE_VIEW(resourceTreeView: IResourceViewTree[]) {
        this.resourceTreeView = resourceTreeView;
    }

    @Action
    setTempPlanningView(tempPlanningView: IPlanningView | null) {
        this.MUTATE_TEMP_PLANNING_VIEW(tempPlanningView);
    }

    @Mutation
    MUTATE_TEMP_PLANNING_VIEW(tempPlanningView: IPlanningView | null) {
        this.tempPlanningView = tempPlanningView;
    }

    @Action
    setPlanningViewColumns(columns: IColumnSetting2[]) {
        this.MUTATE_PLANNING_VIEW_COLUMNS(columns);
    }

    @Mutation
    MUTATE_PLANNING_VIEW_COLUMNS(columns: IColumnSetting2[]) {
        if (!this.planning?.view?.columns) return;
        this.planning.view.columns = columns;
    }

    @Action
    setTempPlanningViewGroupBy(groupBy: IGroupBy) {
        this.MUTATE_TEMP_PLANNING_VIEW_GROUP_BY(groupBy);
    }

    @Mutation
    MUTATE_TEMP_PLANNING_VIEW_GROUP_BY(groupBy: IGroupBy) {
        if (!this.tempPlanningView) return;
        this.tempPlanningView.groupBy = groupBy;
    }

    @Action
    setTempPlanningViewColumns(columns: IColumnSetting2[]) {
        this.MUTATE_TEMP_PLANNING_COLUMNS(columns);
    }

    @Mutation
    MUTATE_TEMP_PLANNING_COLUMNS(columns: IColumnSetting2[]) {
        if (!this.tempPlanningView?.columns) return;
        this.tempPlanningView.columns = columns;
    }

    @Action
    setTempPlanningViewBars(columns: IBar[]) {
        this.MUTATE_TEMP_PLANNING_BARS(columns);
    }

    @Mutation
    MUTATE_TEMP_PLANNING_BARS(columns: IBar[]) {
        if (!this.tempPlanningView?.bars) return;
        this.tempPlanningView.bars = columns;
    }

    @Action
    async getActivityCodeList() {
        const response = await projectPlanningService.getActivityCodeList();
        if (response.success) {
            this.MUTATE_SET_ACTIVITY_CODE_LIST(response.data);
        }
        return response;
    }

    @Action
    async getActivityCodeTree() {
        const response = await projectPlanningService.getActivityCodeTree();
        if (response.success) {
            this.MUTATE_SET_ACTIVITY_CODE_TREE(response.data);
        }
        return response;
    }

    @Action
    async setActivityCodeTree(activityCodeTree: IFolderStructureTree[]) {
        this.MUTATE_SET_ACTIVITY_CODE_TREE(activityCodeTree);
    }

    @Action
    async getAttributeNodeDetailOfFile3D(sessionToken: string) {
        const response = await webViewer3DService.getAttributeFile(sessionToken);
        if (response.success) {
            this.MUTATE_ATTRIBUTE_NODE_DETAIL(response.data?.attributeNodes);
        } else {
            this.MUTATE_ATTRIBUTE_NODE_DETAIL({});
        }
        return response;
    }

    @Action
    async setAttributeNodeDetailOfFile3D(value: any) {
        this.MUTATE_ATTRIBUTE_NODE_DETAIL(value);
    }

    @Mutation
    MUTATE_SET_ACTIVITY_CODE_LIST(value: IActivityCodeListItem[]) {
        this.activityCodeList = value;
    }
    @Action
    setActivityCodePopupParam(value: IActivityCodePopupParam) {
        this.MUTATE_SET_ACTIVITY_CODE_POPUP_PARAM(value);
    }

    @Mutation
    MUTATE_SET_ACTIVITY_CODE_POPUP_PARAM(value: IActivityCodePopupParam) {
        this.activityCodePopupParam = value;
    }

    @Action
    setIsShowAssignActivityCodePopup(value: boolean) {
        this.MUTATE_SET_IS_SHOW_ASSIGN_ACTIVITY_CODE__POPUP(value);
    }

    @Mutation
    MUTATE_SET_IS_SHOW_ASSIGN_ACTIVITY_CODE__POPUP(value: boolean) {
        this.isShowAssignActivityCodePopup = value;
    }

    @Action
    async getRootTaskHasPermissionCreateChild() {
        const response = await projectPlanningService.getRootTaskHasPermissionCreateChild(
            this.planningId,
        );
        if (response.success) {
            this.MUTATE_ROOT_TASK_HAS_PERMISSION_CREATE_TASK(response.data);
        }
        return response;
    }

    @Action
    async getDelegatePlanningListHasModifyFromOriginal(planningId: null | string = null) {
        const response =
            await projectPlanningService.getDelegatePlanningListHasModifyFromOriginal(
                planningId || this.planningId,
            );

        if (response.success) {
            this.MUTATE_DELEGATE_PLANNING_HAS_MODIFY(response?.data || []);
        }
        return response;
    }

    @Action
    async getBaselineList(): Promise<IBodyResponse<IGetListResponse<IBaselinePlanning>>> {
        this.baselineListQueryString.planningId = this.planning?._id || '';

        const response = (await projectPlanningService.getBaselineList({
            ...this.baselineListQueryString,
        })) as IBodyResponse<IGetListResponse<IBaselinePlanning>>;

        if (response.success) {
            this.MUTATE_BASELINE_LIST(response?.data?.items || []);
            this.MUTATE_TOTAL_BASELINE(response?.data?.totalItems || 0);
        } else {
            this.MUTATE_BASELINE_LIST([]);
            this.MUTATE_TOTAL_BASELINE(0);
        }
        return response;
    }

    @Action
    async getPlanning(data: { projectId: string; query: IPlanningQuery }) {
        const response = await projectPlanningService.getPlanning(
            data.projectId,
            data.query,
        );

        if (response.success) {
            this.setPlanning(response.data);

            const baselineIdSelected = this.baselineIdsSelected.find((item) => {
                return item.planningId === response.data._id;
            })?.baselineId;
            if (baselineIdSelected) {
                this.MUTATE_BASELINE_PLANNING_SELECTED(baselineIdSelected);
            }
        } else if (!response.isRequestError) {
            showErrorNotificationFunction(response.message);
        }
        return response;
    }

    @Action
    async getPlanningList(data: { projectId: string; query: IPlanningListQuery }) {
        const response = await projectPlanningService.getPlanningList(data.projectId, {
            ...data.query,
        });

        if (response.success) {
            this.MUTATE_PLANNING_LIST(response.data.items);
            this.MUTATE_TOTAL_PLANNING(response.data.totalItems);
        } else if (!response.isRequestError) {
            showErrorNotificationFunction(response.message);
        }
        return response;
    }

    @Action
    async getSynthesizedFromFileList(planningId: string, query: IPlanningListQuery) {
        const response = await projectPlanningService.getTopDownFileList(planningId, {
            ...query,
        });

        if (response.success) {
            this.MUTATE_PLANNING_LIST(response.data.items);
            this.MUTATE_TOTAL_PLANNING(response.data.totalItems);
        } else if (!response.isRequestError) {
            showErrorNotificationFunction(response.message);
        }
        return response;
    }

    @Action
    async getDeligatedFromFileList(planningId: string, query: IPlanningListQuery) {
        const response = await projectPlanningService.getBottomUpFileList(planningId, {
            ...query,
        });

        if (response.success) {
            this.MUTATE_PLANNING_LIST(response.data.items);
            this.MUTATE_TOTAL_PLANNING(response.data.totalItems);
        } else if (!response.isRequestError) {
            showErrorNotificationFunction(response.message);
        }
        return response;
    }

    @Action
    async getOriginalPlanning(
        data: { planningId: string; path?: null | string } = {
            path: null,
            planningId: '',
        },
    ) {
        const response = await projectPlanningService.getPlanningInformation(
            data.planningId,
            {
                path:
                    data.path ||
                    localStorageAuthService.getPlanningPermissions()?.path ||
                    '',
                projectId: projectModule.selectedProjectId || '',
            },
        );

        if (response.success) {
            this.MUTATE_PLANNING_LIST([response.data]);
            this.MUTATE_TOTAL_PLANNING(1);
        } else if (!response.isRequestError) {
            showErrorNotificationFunction(response.message);
        }
        return response;
    }

    @Action
    async getResourceTree(query: IResourceListQuery) {
        const planningId = projectPlanningModule.planning
            ? projectPlanningModule.planning?._id
            : undefined;
        const response = await projectPlanningService.getResourceTree({
            ...query,
            planningId,
        });
        if (response.success) {
            this.setResourceTree(response.data);
        } else {
            this.MUTATE_RESOURCE_TREE([
                {
                    name: '',
                    children: [],
                    path: '/',
                    level: 0,
                },
            ]);
        }
    }

    @Action
    async getResourceAssignmentTree(taskId: string, query: IResourceListQuery) {
        const planningId = projectPlanningModule.planning
            ? projectPlanningModule.planning?._id
            : undefined;

        const projectId = projectModule.selectedProjectId || '';
        const response = await projectPlanningService.getResourceAssignmentTree(taskId, {
            ...query,
            planningId,
            projectId,
        });
        if (response.success) {
            this.setResourceAssignmentTree(response.data);
        } else {
            this.MUTATE_RESOURCE_ASSIGNMENT_TREE([
                {
                    name: '',
                    children: [],
                    path: '/',
                    level: 0,
                },
            ]);
        }
    }

    @Action
    async getResourceList(query: IResourceListQuery) {
        const planningId =
            query.planningId ??
            (projectPlanningModule.planning
                ? projectPlanningModule.planning?._id
                : undefined);
        const response = await projectPlanningService.getResourceList({
            ...query,
            planningId,
        });
        if (response.success) {
            this.MUTATE_RESOURCE_LIST(response.data.items);
        } else {
            this.MUTATE_RESOURCE_LIST([]);
        }
    }

    @Action
    async getProjectFileList(query: IQueryDropdownFile) {
        const response = await commonService.getProjectFileList(query);

        if (response.success) {
            this.MUTATE_PROJECT_FILE_LIST(response.data.items);
        } else {
            this.MUTATE_PROJECT_FILE_LIST([]);
        }
    }

    @Action
    async getAppearanceProfileList(query: IAppearanceProfileListQuery) {
        const planningId = projectPlanningModule.planning
            ? projectPlanningModule.planning?._id
            : undefined;
        const response = await projectPlanningService.getAppearanceProfileList({
            ...query,
            planningId,
        });
        if (response.success) {
            this.MUTATE_APPEARANCE_PROFILE_LIST(response.data.items || []);
            this.MUTATE_TOTAL_APPEARANCE_PROFILE(response.data.totalItems);
        } else {
            this.MUTATE_APPEARANCE_PROFILE_LIST([]);
            this.MUTATE_TOTAL_APPEARANCE_PROFILE(0);
        }
        return response;
    }

    @Action
    async getResourceGroupList(query: IResourceListQuery) {
        const response = await projectPlanningService.getResourceGroupList(query);

        if (response.success) {
            this.MUTATE_RESOURCE_GROUP_LIST(response.data.items);
        } else {
            this.MUTATE_RESOURCE_GROUP_LIST([]);
        }
    }

    @Action
    setDelegatePopupParam(value: IDelegationPopupParam) {
        this.MUTATE_DELEGATE_POPUP_PARAM(value);
    }

    @Action
    setIsShowBaselinePopup(value: boolean) {
        this.MUTATE_IS_SHOW_BASELINE_POPUP(value);
    }

    @Action
    setIsShowSynthesisPopup(value: boolean) {
        this.MUTATE_IS_SHOW_SYNTHESIS_POPUP(value);
    }

    @Action
    setIsShowRescheduleOptionForm(value: boolean) {
        this.MUTATE_IS_SHOW_RESCHEDULE_OPTION_FORM(value);
    }

    @Action
    setIsShowSimulationOptionForm(value: boolean) {
        this.MUTATE_IS_SHOW_SIMULATION_OPTION_FORM(value);
    }

    @Mutation
    MUTATE_IS_SHOW_SIMULATION_OPTION_FORM(value: boolean) {
        this.isShowSimulationOptionForm = value;
    }

    @Action
    setIsShowCircularLinkPopup(value: boolean) {
        this.MUTATE_IS_SHOW_CIRCULAR_LINK_POPUP(value);
    }

    @Action
    setSelectedTaskIdList(value: string[]) {
        this.MUTATE_SELECTED_TASK_ID_LIST(value);
    }

    @Action
    setIsShowManageViewPopup(value: boolean) {
        this.MUTATE_IS_SHOW_GRID_SETTINGS_POPUP(value);
    }

    @Action
    setPlanningList(value: IPlanning[]) {
        this.MUTATE_PLANNING_LIST(value);
    }

    @Action
    setTaskPopupParams(value: Partial<ITaskPopupParam>) {
        this.MUTATE_TASK_POPUP_PARAMS({
            ...this.taskPopupParams,
            ...value,
        });
    }

    @Action
    setPlanning(value: IPlanning | null) {
        this.MUTATE_PLANNING(value);
    }

    @Action
    setBaselinePlanningSelected(baselineId: string) {
        this.MUTATE_BASELINE_PLANNING_SELECTED(baselineId);

        const index = this.baselineIdsSelected.findIndex((baselineIdSelected) => {
            return baselineIdSelected.planningId === this.planningId;
        });
        if (index === -1) {
            this.MUTATE_BASELINE_IDS_SELECTED(
                this.baselineIdsSelected.concat({
                    planningId: this.planningId,
                    baselineId: baselineId,
                }),
            );
            this.MUTATE_TOTAL_BASELINE_SELECTED(this.totalBaselineSelected + 1);
        } else {
            const baselineIdsSelected = this.baselineIdsSelected;
            baselineIdsSelected.splice(index, 1);
            this.MUTATE_BASELINE_IDS_SELECTED(
                baselineIdsSelected.concat({
                    planningId: this.planningId,
                    baselineId: baselineId,
                }),
            );
        }
    }

    @Action
    setBaselineListQueryString(query: IBaselinePlanningListQuery) {
        this.MUTATE_BASELINE_LIST_QUERY_STRING(query);
    }

    @Action
    resetBaselineList() {
        this.MUTATE_BASELINE_LIST([]);
        this.MUTATE_TOTAL_BASELINE(0);
    }

    @Action
    setSelectedPlannings(value: IPlanning[]) {
        this.MUTATE_SELECTED_PLANNINGS(value);
    }

    @Action
    resetStore() {
        this.MUTATE_DELEGATE_POPUP_PARAM({ ...this.initialDelegationPopupParam });
        this.MUTATE_IS_SHOW_SYNTHESIS_POPUP(false);
        this.MUTATE_PLANNING(null);
        this.MUTATE_TASK_POPUP_PARAMS({ ...this.initialTaskPopupParams });
        this.MUTATE_BASELINE_LIST_QUERY_STRING(initBaselineQueryString);
        this.MUTATE_SELECTED_PLANNINGS([]);
        this.MUTATE_SELECTED_RESOURCE(null);
        this.MUTATE_SELECTED_RESOURCE_GROUP(null);
        this.MUTATE_IS_DISABLE_SAVE_BUTTON(false);
        this.MUTATE_SELECTED_TASK_IDS([]);
        this.MUTATE_PLANNING_ID('');
        this.MUTATE_SELECTED_BASELINE_ID_TO_UPDATE(null);
        this.MUTATE_UPDATED_TASKS([]);
        this.MUTATE_HAS_TASK_DELETED(false);
        this.MUTATE_LATEST_CREATED_LINK(null);
        this.MUTATE_EDITED_TASK_IDS([]);
        this.MUTATE_SELECTED_TASK_ID_LIST([]);
        this.MUTATE_IS_DISABLE_BUTTON_ADD(false);
        this.MUTATE_PLANNING_VIEW_LIST([]);
    }

    @Action
    setIsShowResourcePopup(value: boolean) {
        this.MUTATE_IS_SHOW_RESOURCES_POP_UP(value);
    }

    @Action
    setIsShowResourceDetailForm(value: boolean) {
        this.MUTATE_IS_SHOW_RESOURCES_DETAIL_FORM(value);
    }

    @Action
    setSelectedResource(resource: IResource | null) {
        this.MUTATE_SELECTED_RESOURCE(resource);
    }

    @Action
    setIsShowResourceGroupPopup(value: boolean) {
        this.MUTATE_IS_SHOW_RESOURCES_GROUP_POP_UP(value);
    }

    @Action
    setIsShowCalendarPopup(value: boolean) {
        this.MUTATE_IS_SHOW_CALENDAR_POP_UP(value);
    }

    @Action
    setIsShowResourceGroupDetailForm(value: boolean) {
        this.MUTATE_IS_SHOW_RESOURCES_GROUP_DETAIL_FORM(value);
    }

    @Action
    setSelectedResourceGroup(resourceGroup: IResourceGroup | null) {
        this.MUTATE_SELECTED_RESOURCE_GROUP(resourceGroup);
    }

    @Action
    setIsDisableSaveButton(value: boolean) {
        this.MUTATE_IS_DISABLE_SAVE_BUTTON(value);
    }

    @Action
    setSelectedTaskIds(selectedTaskIds: string[]) {
        this.MUTATE_SELECTED_TASK_IDS(selectedTaskIds);
    }

    @Action
    setFilteredTaskIds(filteredTaskIds: string[]) {
        this.MUTATE_FILTERED_TASK_IDS(filteredTaskIds);
    }

    @Action
    setPlanningId(planningId: string) {
        this.MUTATE_PLANNING_ID(planningId);
    }

    @Action
    setSelectedBaslineIdToUpdate(value: string | null) {
        this.MUTATE_SELECTED_BASELINE_ID_TO_UPDATE(value);
    }

    @Action
    setIsShowAssignResourceForm(value: boolean) {
        this.MUTATE_IS_SHOW_ASSIGN_RESOURCE_FORM(value);
    }

    @Action
    setUpdatedTasks(tasks: IProjectTask[]) {
        this.MUTATE_UPDATED_TASKS(tasks);
    }

    @Action
    setHasTaskDeleted(value: boolean) {
        this.MUTATE_HAS_TASK_DELETED(value);
    }

    @Action
    setTaskFieldParams(value: Partial<ITaskFieldParams>) {
        this.MUTATE_TASK_FIELD_PARAMS({
            ...this.taskFieldsParams,
            ...value,
        });
    }

    @Action
    setEditedTaskIds(editedTaskIds: string[]) {
        this.MUTATE_EDITED_TASK_IDS(editedTaskIds);
    }

    @Action
    setNeedReload3DViewer(needReload3DViewer: boolean) {
        this.MUTATE_NEED_RELOAD_3D_VIEWER(needReload3DViewer);
    }

    @Action
    setIsShowAppearanceProfilePopup(value: boolean) {
        this.MUTATE_IS_SHOW_APPEARANCE_PROFILE_POPUP(value);
    }

    @Action
    setIsShowAppearanceProfileDetailForm(value: boolean) {
        this.MUTATE_IS_SHOW_APPEARANCE_PROFILE_DETAIL_FORM(value);
    }

    @Action
    setSelectedAppearanceProfile(appearanceProfile: IAppearanceProfile | null) {
        this.MUTATE_SELECTED_APPEARANCE_PROFILE(appearanceProfile);
    }

    @Action
    setIsShowTopDownFormPopup(isShowTopDownFormPopup: boolean) {
        this.MUTATE_IS_SHOW_TOP_DOWN_FORM_POPUP(isShowTopDownFormPopup);
    }

    @Action
    setSelectedActivityCodeId(activityCodeId: string) {
        this.MUTATE_SELECTED_ACTIVITY_CODE_ID(activityCodeId);
    }

    @Action
    setSelectedActivityCodeFolderId(activityCodeFolderId: string) {
        this.MUTATE_SELECTED_ACTIVITY_CODE_FOLDER_ID(activityCodeFolderId);
    }

    @Action
    setIsShowBottomUpFormPopup(isShowBottomUpFormPopup: boolean) {
        this.MUTATE_IS_SHOW_BOTTOM_UP_FORM_POPUP(isShowBottomUpFormPopup);
    }

    @Action
    setIsMovingFocusTime(isMovingFocusTime: boolean) {
        this.MUTATE_IS_MOVING_FOCUS_TIME(isMovingFocusTime);
    }

    @Action
    setTaskLayerId(value: string) {
        this.MUTATE_TASK_LAYER_ID(value);
    }

    @Action
    setTaskBarIds(values: string[]) {
        this.MUTATE_TASK_BAR_IDS(values);
    }

    @Action
    setAssignedTaskNames(assignedTaskNames: string[]) {
        this.MUTATE_ASSIGNED_TASK_NAMES(assignedTaskNames);
    }

    @Action
    setIsShowAssignedTaskNamesPopup(isShow: boolean) {
        this.MUTATE_IS_SHOW_ASSIGNED_TASK_NAMES_POPUP(isShow);
    }

    @Action
    setIsShowPlanningPopup(value: boolean) {
        this.MUTATE_IS_SHOW_PLANNING_POPUP(value);
    }

    @Action
    setIsDisableButtonAdd(value: boolean) {
        this.MUTATE_IS_DISABLE_BUTTON_ADD(value);
    }

    @Action
    setIsShowXmlFileForm(value: boolean) {
        this.MUTATE_IS_SHOW_IMPORT_XML_FILE_FORM(value);
    }

    @Action
    setIsShowImportExcelFileForm(value: boolean) {
        this.MUTATE_IS_SHOW_IMPORT_EXCEL_FILE_FORM(value);
    }

    @Action
    setCreatedLink(createdLink: boolean) {
        this.MUTATE_CREATED_LINK(createdLink);
    }

    @Action
    setResourceTree(resourceTree: IFolderStructureTree[]) {
        this.MUTATE_RESOURCE_TREE(resourceTree);
    }

    @Action
    setResourceAssignmentTree(resourceAssignmentTree: IFolderStructureTree[]) {
        this.MUTATE_RESOURCE_ASSIGNMENT_TREE(resourceAssignmentTree);
    }

    @Action
    setSelectedResourceNodeId(selectedResourceNodeId: string) {
        this.MUTATE_SELECTED_RESOURCE_NODE_ID(selectedResourceNodeId);
    }

    @Action
    setIsShowImportResourcePopup(isShowImportResourcePopup: boolean) {
        this.MUTATE_IS_SHOW_IMPORT_RESOURCE_POPUP(isShowImportResourcePopup);
    }
    @Action
    setIsSetFileIdsInResourceDetail(value: boolean) {
        this.MUTATE_SET_FILE_IDS_IN_RESOURCE_DETAILS(value);
    }

    @Action
    setIsShowExportPlanningPopup(value: boolean) {
        this.MUTATE_IS_SHOW_EXPORT_PLANNING_POPUP(value);
    }

    @Action
    setIsShowExportExcelPopup(value: boolean) {
        this.MUTATE_IS_SHOW_EXPORT_EXCEL_POPUP(value);
    }

    @Action
    setIsShowImportXmlDetail(value: boolean) {
        this.MUTATE_IS_SHOW_IMPORT_XML_DETAIL(value);
    }

    @Action
    setIsShowImportXmlSummary(value: boolean) {
        this.MUTATE_IS_SHOW_IMPORT_XML_SUMMARY(value);
    }

    @Action
    setIsShowImportExcelDetail(value: boolean) {
        this.MUTATE_IS_SHOW_IMPORT_EXCEL_DETAIL(value);
    }

    @Action
    setIsShowImportExcelSummary(value: boolean) {
        this.MUTATE_IS_SHOW_IMPORT_EXCEL_SUMMARY(value);
    }

    @Action
    setImportProjectXml(value: IImportProjectXml) {
        this.MUTATE_SET_IMPORT_PROJECT_XML(value);
    }

    @Action
    setImportProjectExcel(value: IImportProjectExcel) {
        this.MUTATE_SET_IMPORT_PROJECT_EXCEL(value);
    }

    @Action
    setGanttContextMenuParam(value: IGanttContextMenuParam) {
        this.MUTATE_SET_GANTT_CONTEXT_MENU_PARAM(value);
    }

    @Action
    setLinkPopupParam(value: ILinkPopupParam) {
        this.MUTATE_SET_LINK_POPUP_PARAM(value);
    }

    @Action
    setLinkFormPopupParam(value: ILinkFormPopupParam) {
        this.MUTATE_SET_LINK_FORM_POPUP_PARAM(value);
    }

    @Action
    setBulkCreateLinkList(value: ILinkDetail[]) {
        this.MUTATE_SET_BULK_CREATE_LINK_LIST(value);
    }

    @Action
    setIsShowSaveAsPopup(isShowSaveAsPopup: boolean) {
        this.MUTATE_IS_SHOW_SAVE_AS_POPUP(isShowSaveAsPopup);
    }

    @Action
    setIsShowAssignResourceGroupForm(isShowAssignResourceGroupForm: boolean) {
        this.MUTATE_IS_SHOW_ASSIGN_RESOURCE_GROUP_FORM(isShowAssignResourceGroupForm);
    }
    @Action
    setLinkDetailPopupFormParam(value: ILinkDetailFormPopupParam) {
        this.MUTATE_SET_LINK_DETAIL_POPUP_FORM_PARAM(value);
    }

    @Action
    setEditedLinkIds(value: string[]) {
        this.MUTATE_EDITED_LINK_IDS(value);
    }

    @Action
    setIsShowGenerateCustomIdPopup(value: boolean) {
        this.MUTATE_IS_SHOW_GENERATE_CUSTOM_ID_POPUP(value);
    }

    @Action
    setSelectedTaskId(value: string) {
        this.MUTATE_SELECTED_TASK_ID(value);
    }

    @Action
    setIsShowSynchroniseFilePopup(isShowSynchroniseFilePopup: boolean) {
        this.MUTATE_IS_SHOW_SHYNCRONISE_FILE_POPUP(isShowSynchroniseFilePopup);
    }

    @Action
    setSynchroniseFilePopupMode(data: any) {
        this.MUTATE_SHYNCRONISE_FILE_POPUP_MODE(data);
    }

    @Action
    setCurrentLinkList(value: ILinkDetail[]) {
        this.MUTATE_SET_CURRENT_LINK_LIST(value);
    }
    @Action
    setSelectedTask(task: IProjectTask) {
        this.MUTATE_SELECTED_TASK(task);
    }
    @Action
    setCopiedTaskId(ids: string[]) {
        this.MUTATE_COPIED_TASK_IDS(ids);
    }
    @Action
    async getSelectedPlanningViewDetail(id: string) {
        const response = await projectPlanningService.getPlanningViewDetail(
            this.planning?._id || '',
            id,
        );
        if (response.success) {
            this.MUTATE_TEMP_PLANNING_VIEW(response.data);
            this.MUTATE_LOGIC_BETWEEN_FILTERS(
                response.data?.logicBetweenFilters || GanttFilterLogic.AND,
            );
        }
    }
    @Action
    setSelectedColumnFilterDetail(value: IColumnFilter | null) {
        this.MUTATE_SELECTED_COLUMN_FILTER_DETAIL(value);
    }

    @Action
    setDelegateOption(value: IDelegateOption) {
        this.MUTATE_DELEGATE_OPTION(value);
    }

    @Action
    setIsDisableInlineEdit(value: boolean) {
        this.SET_IS_DISABLE_INLINE_EDIT(value);
    }

    @Action
    setGanttOptionView(payload: { field: keyof IGanttView; value: boolean }) {
        this.MUTATE_GANTT_OPTION_VIEW(payload);
    }
    @Mutation
    MUTATE_GANTT_OPTION_VIEW(payload: { field: keyof IGanttView; value: boolean }) {
        if (this.tempPlanningView?.gantt) {
            this.tempPlanningView.gantt = {
                ...this.tempPlanningView?.gantt,
                [payload.field]: payload.value,
            };
        }
    }

    @Action
    setPlanningGanttSettingView(payload: IGanttView) {
        this.MUTATE_PLANNING_GANTT_SETTING_VIEW(payload);
    }
    @Mutation
    MUTATE_PLANNING_GANTT_SETTING_VIEW(payload: IGanttView) {
        if (this.planning?.view?.gantt) {
            this.planning.view.gantt = payload;
        }
    }

    @Mutation
    MUTATE_DELEGATE_PLANNING_HAS_MODIFY(value: IDelegationHasModifyResponse[]) {
        this.delegationHasModify = value;
    }

    @Mutation
    MUTATE_DELEGATE_POPUP_PARAM(value: IDelegationPopupParam) {
        this.delegationPopupParam = value;
    }

    @Mutation
    MUTATE_IS_SHOW_BASELINE_POPUP(value: boolean) {
        this.isShowBaselinePopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_SYNTHESIS_POPUP(value: boolean) {
        this.isShowSynthesisPopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_GRID_SETTINGS_POPUP(value: boolean) {
        this.isShowManageViewPopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_RESCHEDULE_OPTION_FORM(value: boolean) {
        this.isShowRescheduleOptionForm = value;
    }

    @Mutation
    MUTATE_IS_SHOW_CIRCULAR_LINK_POPUP(value: boolean) {
        this.isShowCircularLinkPopup = value;
    }

    @Mutation
    MUTATE_PLANNING(value: IPlanning | null) {
        this.planning = value;
    }

    @Mutation
    MUTATE_PLANNING_LIST(value: IPlanning[]) {
        this.planningList = value;
    }

    @Mutation
    MUTATE_TOTAL_PLANNING(value: number) {
        this.totalPlanning = value;
    }

    @Mutation
    MUTATE_TASK_POPUP_PARAMS(value: ITaskPopupParam) {
        this.taskPopupParams = value;
    }

    @Mutation
    MUTATE_BASELINE_PLANNING_SELECTED(value: string) {
        this.baselineIdSelected = value;
    }

    @Mutation
    MUTATE_BASELINE_LIST_QUERY_STRING(query: IBaselinePlanningListQuery) {
        this.baselineListQueryString = {
            ...this.baselineListQueryString,
            ...query,
        };
    }

    @Mutation
    MUTATE_TOTAL_BASELINE(total: number) {
        this.totalBaselineItems = total;
    }

    @Mutation
    MUTATE_BASELINE_LIST(baselines: IBaselinePlanning[]) {
        this.baselineList = baselines;
    }

    @Mutation
    MUTATE_BASELINE_IDS_SELECTED(value: IBaselineIdSelectedInPlanning[]) {
        this.baselineIdsSelected = value;
    }

    @Mutation
    MUTATE_TOTAL_BASELINE_SELECTED(value: number) {
        this.totalBaselineSelected = value;
    }

    @Mutation
    MUTATE_SELECTED_PLANNINGS(value: IPlanning[]) {
        this.selectedPlannings = value;
    }

    @Mutation
    MUTATE_RESOURCE_LIST(value: IResource[]) {
        this.resourceList = value;
    }

    @Mutation
    MUTATE_IS_SHOW_RESOURCES_POP_UP(value: boolean) {
        this.isShowResourcePopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_RESOURCES_DETAIL_FORM(value: boolean) {
        this.isShowResourceDetailForm = value;
    }

    @Mutation
    MUTATE_SELECTED_RESOURCE(resource: IResource | null) {
        this.selectedResource = resource;
    }

    @Mutation
    MUTATE_RESOURCE_GROUP_LIST(resourceGroups: IResourceGroup[]) {
        this.resourceGroupList = resourceGroups;
    }

    @Mutation
    MUTATE_IS_SHOW_RESOURCES_GROUP_POP_UP(value: boolean) {
        this.isShowResourceGroupPopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_CALENDAR_POP_UP(value: boolean) {
        this.isShowCalendarPopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_RESOURCES_GROUP_DETAIL_FORM(value: boolean) {
        this.isShowResourceGroupDetailForm = value;
    }

    @Mutation
    MUTATE_SELECTED_RESOURCE_GROUP(resourceGroup: IResourceGroup | null) {
        this.selectedResourceGroup = resourceGroup;
    }

    @Mutation
    MUTATE_IS_DISABLE_SAVE_BUTTON(value: boolean) {
        this.isDisabledSaveButton = value;
    }

    @Mutation
    MUTATE_PROJECT_FILE_LIST(files: IProjectFile[]) {
        this.projectFileList = files;
    }

    @Mutation
    MUTATE_SELECTED_TASK_IDS(selectedTaskIds: string[]) {
        this.selectedTaskIds = selectedTaskIds;
    }

    @Mutation
    MUTATE_FILTERED_TASK_IDS(filteredTaskIds: string[]) {
        this.filteredTaskIds = filteredTaskIds;
    }

    @Mutation
    MUTATE_PLANNING_ID(planningId: string) {
        this.planningId = planningId;
    }

    @Mutation
    MUTATE_SELECTED_BASELINE_ID_TO_UPDATE(value: string | null) {
        this.selectedBaselineIdToUpdate = value;
    }

    @Mutation
    MUTATE_TASK_LAYER_ID(value: string) {
        this.taskLayerId = value;
    }

    @Mutation
    MUTATE_TASK_BAR_IDS(values: string[]) {
        this.taskBarIds = values;
    }

    @Mutation
    MUTATE_IS_SHOW_ASSIGN_RESOURCE_FORM(isShow: boolean) {
        this.isShowAssignResourceForm = isShow;
    }

    @Mutation
    MUTATE_UPDATED_TASKS(tasks: IProjectTask[]) {
        this.updatedTasks = tasks;
    }

    @Mutation
    MUTATE_HAS_TASK_DELETED(value: boolean) {
        this.hasTaskDeleted = value;
    }

    @Mutation
    MUTATE_LATEST_CREATED_LINK(value: ITaskLink | null) {
        this.latestCreatedLink = value;
    }

    @Mutation
    MUTATE_EDITED_TASK_IDS(editedTaskIds: string[]) {
        this.editedTaskIds = editedTaskIds;
    }

    @Mutation
    MUTATE_SELECTED_TASK_ID_LIST(value: string[]) {
        this.selectedTaskIdList = value;
    }

    @Mutation
    MUTATE_TASK_FIELD_PARAMS(value: ITaskFieldParams) {
        this.taskFieldsParams = value;
    }

    @Mutation
    MUTATE_NEED_RELOAD_3D_VIEWER(needReload3DViewer: boolean) {
        this.needReload3DViewer = needReload3DViewer;
    }

    @Mutation
    MUTATE_IS_SHOW_APPEARANCE_PROFILE_POPUP(value: boolean) {
        this.isShowAppearanceProfilePopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_APPEARANCE_PROFILE_DETAIL_FORM(value: boolean) {
        this.isShowAppearanceProfileDetailForm = value;
    }

    @Mutation
    MUTATE_APPEARANCE_PROFILE_LIST(value: IAppearanceProfile[]) {
        this.appearanceProfileList = value;
    }

    @Mutation
    MUTATE_SELECTED_APPEARANCE_PROFILE(appearanceProfile: IAppearanceProfile | null) {
        this.selectedAppearanceProfile = appearanceProfile;
    }

    @Mutation
    MUTATE_IS_SHOW_TOP_DOWN_FORM_POPUP(isShowTopDownFormPopup: boolean) {
        this.isShowTopDownFormPopup = isShowTopDownFormPopup;
    }

    @Mutation
    MUTATE_SELECTED_ACTIVITY_CODE_ID(value: string) {
        this.selectedActivityCodeId = value;
    }

    @Mutation
    MUTATE_SELECTED_ACTIVITY_CODE_FOLDER_ID(value: string) {
        this.selectedActivityCodeFolderId = value;
    }

    @Mutation
    MUTATE_IS_SHOW_BOTTOM_UP_FORM_POPUP(isShowBottomUpFormPopup: boolean) {
        this.isShowBottomUpFormPopup = isShowBottomUpFormPopup;
    }

    @Mutation
    MUTATE_IS_MOVING_FOCUS_TIME(isMovingFocusTime: boolean) {
        this.isMovingFocusTime = isMovingFocusTime;
    }

    @Mutation
    MUTATE_ASSIGNED_TASK_NAMES(assignedTaskNames: string[]) {
        this.assignedTaskNames = assignedTaskNames;
    }

    @Mutation
    MUTATE_IS_SHOW_ASSIGNED_TASK_NAMES_POPUP(isShow: boolean) {
        this.isShowAssignedTaskNamesPopup = isShow;
    }

    @Mutation
    MUTATE_IS_SHOW_PLANNING_POPUP(value: boolean) {
        this.isShowPlanningPopup = value;
    }

    @Mutation
    MUTATE_IS_DISABLE_BUTTON_ADD(value: boolean) {
        this.isDisableButtonAdd = value;
    }

    @Mutation
    MUTATE_IS_SHOW_IMPORT_XML_FILE_FORM(isShow: boolean) {
        this.isShowImportXmlFileForm = isShow;
    }

    @Mutation
    MUTATE_IS_SHOW_IMPORT_EXCEL_FILE_FORM(isShow: boolean) {
        this.isShowImportExcelFileForm = isShow;
    }

    @Mutation
    MUTATE_CREATED_LINK(createdLink: boolean) {
        this.createdLink = createdLink;
    }

    @Mutation
    MUTATE_IS_SHOW_EXPORT_PLANNING_POPUP(value: boolean) {
        this.isShowExportPlanningPopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_EXPORT_EXCEL_POPUP(value: boolean) {
        this.isShowExportExcelPopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_IMPORT_XML_DETAIL(value: boolean) {
        this.isShowImportXmlDetail = value;
    }

    @Mutation
    MUTATE_IS_SHOW_IMPORT_XML_SUMMARY(value: boolean) {
        this.isShowImportXmlSummary = value;
    }

    @Mutation
    MUTATE_IS_SHOW_IMPORT_EXCEL_DETAIL(value: boolean) {
        this.isShowImportExcelDetail = value;
    }

    @Mutation
    MUTATE_IS_SHOW_IMPORT_EXCEL_SUMMARY(value: boolean) {
        this.isShowImportExcelSummary = value;
    }

    @Mutation
    MUTATE_ROOT_TASK_HAS_PERMISSION_CREATE_TASK(tasks: IProjectTask[]) {
        this.rootTaskHasPermissionCreateChild = tasks;
    }

    @Mutation
    MUTATE_RESOURCE_TREE(resourceTree: IFolderStructureTree[]) {
        this.resourceTree = resourceTree;
    }

    @Mutation
    MUTATE_RESOURCE_ASSIGNMENT_TREE(resourceAssignmentTree: IFolderStructureTree[]) {
        this.resourceAssignmentTree = resourceAssignmentTree;
    }

    @Mutation
    MUTATE_SELECTED_RESOURCE_NODE_ID(selectedResourceNodeId: string) {
        this.selectedResourceNodeId = selectedResourceNodeId;
    }

    @Mutation
    MUTATE_IS_SHOW_IMPORT_RESOURCE_POPUP(isShowImportResourcePopup: boolean) {
        this.isShowImportResourcePopup = isShowImportResourcePopup;
    }

    @Mutation
    MUTATE_SET_FILE_IDS_IN_RESOURCE_DETAILS(value: boolean) {
        this.isSetFileIdsInResourceDetail = value;
    }

    @Mutation
    MUTATE_SET_IMPORT_PROJECT_XML(importProjectXml: IImportProjectXml) {
        this.importProjectXml = importProjectXml;
    }

    @Mutation
    MUTATE_SET_IMPORT_PROJECT_EXCEL(importProjectExcel: IImportProjectExcel) {
        this.importProjectExcel = importProjectExcel;
    }

    @Mutation
    MUTATE_SET_GANTT_CONTEXT_MENU_PARAM(value: IGanttContextMenuParam) {
        this.ganttContextMenuParam = value;
    }

    @Mutation
    MUTATE_SET_LINK_POPUP_PARAM(value: ILinkPopupParam) {
        this.linkPopupParam = value;
    }

    @Mutation
    MUTATE_SET_LINK_FORM_POPUP_PARAM(value: ILinkFormPopupParam) {
        this.linkFormPopupParam = value;
    }

    @Mutation
    MUTATE_SET_BULK_CREATE_LINK_LIST(value: ILinkDetail[]) {
        this.bulkCreateLinkList = value;
    }

    @Mutation
    MUTATE_IS_SHOW_SAVE_AS_POPUP(isShowSaveAsPopup: boolean) {
        this.isShowSaveAsPopup = isShowSaveAsPopup;
    }
    @Mutation
    MUTATE_IS_SHOW_ASSIGN_RESOURCE_GROUP_FORM(isShowAssignResourceGroupForm: boolean) {
        this.isShowAssignResourceGroupForm = isShowAssignResourceGroupForm;
    }

    @Mutation
    MUTATE_SET_LINK_DETAIL_POPUP_FORM_PARAM(value: ILinkDetailFormPopupParam) {
        this.linkDetailFormPopupParam = value;
    }

    @Mutation
    MUTATE_EDITED_LINK_IDS(value: string[]) {
        this.editedLinkIds = value;
    }

    @Mutation
    MUTATE_SELECTED_TASK_ID(value: string) {
        this.selectedTaskId = value;
    }

    @Mutation
    MUTATE_SELECTED_FILE_PATHS(paths: string[]) {
        this.selectedFilePaths = paths;
    }

    @Mutation
    MUTATE_SET_ACTIVITY_CODE_TREE(value: IFolderStructureTree[]) {
        this.activityCodeTree = value;
    }

    @Mutation
    MUTATE_TASK_FIELD_LIST(taskFieldList: IAdditionalTaskField[]) {
        this.taskFieldList = taskFieldList;
    }

    @Action
    setTaskFieldList(taskFieldList: IAdditionalTaskField[]) {
        this.MUTATE_TASK_FIELD_LIST(taskFieldList);
    }

    @Mutation
    MUTATE_RESOURCE_FIELD_LIST(resourceFieldList: IAdditionalTaskField[]) {
        this.resourceFieldList = resourceFieldList;
    }

    @Action
    setResourceFieldList(resourceFieldList: IAdditionalTaskField[]) {
        this.MUTATE_RESOURCE_FIELD_LIST(resourceFieldList);
    }

    @Mutation
    MUTATE_RESOURCE_GROUP_FIELD_LIST(resourceGroupFieldList: IAdditionalTaskField[]) {
        this.resourceGroupFieldList = resourceGroupFieldList;
    }

    @Action
    setResourceGroupFieldList(resourceGroupFieldList: IAdditionalTaskField[]) {
        this.MUTATE_RESOURCE_GROUP_FIELD_LIST(resourceGroupFieldList);
    }

    @Mutation
    MUTATE_PLANNING_VIEW_LIST(planningViewList: IPlanningView[]) {
        this.planningViewList = planningViewList;
    }

    @Action
    setPlanningViewList(planningViewList: IPlanningView[]) {
        this.MUTATE_PLANNING_VIEW_LIST(planningViewList);
    }

    @Action
    async getTaskFieldList(query: IGetTaskFieldListQueryString = {}) {
        const planningId = projectPlanningModule.planning
            ? projectPlanningModule.planning?._id
            : undefined;

        const response = await projectPlanningService.getAdditionalTaskFieldList({
            ...query,
            planningId,
        });
        if (response.success) {
            this.setTaskFieldList(response.data.items);
            this.setTotalTaskField(response.data.totalItems);
        }
        return response;
    }

    @Action
    async getResourceGroupFieldList(query: IGetTaskFieldListQueryString = {}) {
        const planningId = projectPlanningModule.planning
            ? projectPlanningModule.planning?._id
            : undefined;

        const response = await projectPlanningService.getAdditionalTaskFieldList({
            ...query,
            planningId,
        });
        if (response.success) {
            this.setResourceGroupFieldList(response.data.items);
            this.setTotalResourceGroupField(response.data.totalItems);
        }
        return response;
    }

    @Action
    async getResourceFieldList(query: IGetTaskFieldListQueryString = {}) {
        const planningId = projectPlanningModule.planning
            ? projectPlanningModule.planning?._id
            : undefined;

        const response = await projectPlanningService.getAdditionalTaskFieldList({
            ...query,
            planningId,
        });
        if (response.success) {
            this.setResourceFieldList(response.data.items);
            this.setTotalResourceField(response.data.totalItems);
        }
        return response;
    }

    @Action
    async getPlanningViewList() {
        const response = await projectPlanningService.getPlanningViewList(
            this.planningId,
        );
        if (!response.success) return;
        this.setPlanningViewList(response.data.items);
    }

    @Action
    async setShowAssignCalendarFormPopup(value: boolean) {
        this.MUTATE_SHOW_ASSIGN_CALENDAR_FORM_POPUP(value);
    }
    @Action
    async setShowFilterGanttChartFormPopup(value: boolean) {
        this.MUTATE_SHOW_FILTER_GANTT_CHART_FORM_POPUP(value);
    }
    @Action
    setSelectedFilterColumn(value: ISelectedFilterColumn) {
        this.MUTATE_SELECTED_FILTER_COLUMN(value);
    }
    @Action
    setPlanningFilters(value: Record<string, IColumnFilter[]>) {
        this.MUTATE_PLANNING_FILTERS(value);
    }
    @Action
    setLogicBetweenFilters(value: GanttFilterLogic) {
        this.MUTATE_LOGIC_BETWEEN_FILTERS(value);
    }

    @Mutation
    MUTATE_TOTAL_TASK_FIELD(totalTaskField: number) {
        this.totalTaskField = totalTaskField;
    }
    @Action
    setTotalTaskField(totalTaskField: number) {
        this.MUTATE_TOTAL_TASK_FIELD(totalTaskField);
    }

    @Mutation
    MUTATE_TOTAL_RESOURCE_FIELD(totalResourceField: number) {
        this.totalResourceField = totalResourceField;
    }
    @Action
    setTotalResourceField(totalResourceField: number) {
        this.MUTATE_TOTAL_RESOURCE_FIELD(totalResourceField);
    }

    @Mutation
    MUTATE_TOTAL_RESOURCE_GROUP_FIELD(totalResourceGroupField: number) {
        this.totalResourceGroupField = totalResourceGroupField;
    }
    @Action
    setTotalResourceGroupField(totalResourceGroupField: number) {
        this.MUTATE_TOTAL_RESOURCE_GROUP_FIELD(totalResourceGroupField);
    }

    @Action
    setSelectedFilterIds(value: string[]) {
        this.MUTATE_SELECTED_FILTER_ID(value);
    }

    @Mutation
    MUTATE_TOTAL_APPEARANCE_PROFILE(totalAppearanceProfile: number) {
        this.totalAppearanceProfile = totalAppearanceProfile;
    }

    @Mutation
    MUTATE_ATTRIBUTE_NODE_DETAIL(value: Record<string, string[]>) {
        this.attributeNodeDetail = value;
    }

    @Mutation
    MUTATE_IS_SHOW_GENERATE_CUSTOM_ID_POPUP(value: boolean) {
        this.isShowGenerateCustomIdPopup = value;
    }

    @Mutation
    MUTATE_IS_SHOW_SHYNCRONISE_FILE_POPUP(value: boolean) {
        this.isShowSynchroniseFilePopup = value;
    }

    @Mutation
    MUTATE_SHYNCRONISE_FILE_POPUP_MODE(value: any) {
        this.synchroniseFilePopupMode = value;
    }

    @Mutation
    MUTATE_SET_CURRENT_LINK_LIST(value: ILinkDetail[]) {
        this.currentLinkList = value;
    }
    @Mutation
    MUTATE_SHOW_ASSIGN_CALENDAR_FORM_POPUP(value: boolean) {
        this.showAssignCalendarFormPopup = value;
    }
    @Mutation
    MUTATE_SELECTED_TASK(task: IProjectTask) {
        this.selectedTask = task;
    }
    @Mutation
    MUTATE_COPIED_TASK_IDS(ids: string[]) {
        this.copiedTaskIds = ids;
    }
    @Mutation
    MUTATE_SHOW_FILTER_GANTT_CHART_FORM_POPUP(value: boolean) {
        this.showFilterGanttChartFormPopup = value;
    }
    @Mutation
    MUTATE_SELECTED_FILTER_COLUMN(value: ISelectedFilterColumn) {
        this.selectedFilterColumn = value;
    }
    @Mutation
    MUTATE_PLANNING_FILTERS(value: Record<string, IColumnFilter[]>) {
        this.planningFilters = value;
    }
    @Mutation
    MUTATE_SELECTED_COLUMN_FILTER_DETAIL(value: IColumnFilter | null) {
        this.selectedColumnFilterDetail = value;
    }

    @Action
    setPlanningView(planningView: IPlanningView) {
        this.MUTATE_PLANNING_VIEW(planningView);
    }

    @Mutation
    MUTATE_PLANNING_VIEW(planningView: IPlanningView) {
        if (!this.planning) return;
        this.planning.view = planningView;
    }

    @Mutation
    MUTATE_LOGIC_BETWEEN_FILTERS(value: GanttFilterLogic) {
        this.logicBetweenFilters = value;
    }

    @Action
    async getResourceView(planningId?: string) {
        const response = await projectPlanningService.getResourceView(planningId);
        if (response.code !== 200) return;
        this.setResourceView(response.data);
        const columns = response.data.columns;
        if (columns) {
            for (let i = 0; i < columns?.length; ++i) {
                const newResourceView = this.resourceTreeView.map((v) => {
                    return v.label === columns?.[i]?.label
                        ? {
                              ...v,
                              checked: true,
                          }
                        : v;
                });
                this.setResourceTreeView(newResourceView);
            }
        }
    }

    @Action
    setResourceView(resourceView: IResourceViewResponse) {
        this.MUTATE_RESOURCE_VIEW(resourceView);
    }

    @Mutation
    MUTATE_RESOURCE_VIEW(resourceView: IResourceViewResponse) {
        this.resourceView = resourceView;
    }
    @Mutation
    MUTATE_DELEGATE_OPTION(value: IDelegateOption) {
        this.delegateOption = value;
    }

    @Mutation
    MUTATE_SELECTED_FILTER_ID(value: string[]) {
        this.selectedFilterIds = value;
    }

    @Mutation
    SET_IS_DISABLE_INLINE_EDIT(value: boolean) {
        this.isDisabledInlineEdit = value;
    }
}

export const projectPlanningModule = getModule(ProjectPlanningModule);
