export const viewer3d = {
    breadcrumb: {
        viewer3d: '3D WebViewer',
    },
    uploadForm: {
        title: 'Upload file',
        save: 'Save',
        synchronise: 'Synchronise file',
    },
    importForm: {
        title: 'Import file',
    },
    success: {
        upload: 'Your file has been uploaded and being processed',
        load: 'Your file is being processed',
        save: 'Your file is being saved',
    },
    repositoryPopup: {
        title: {
            select: 'Select file from repository',
            import: 'Import file from repository',
            save: 'Save file to',
        },
        selectLocation: 'Select location',
        message: {
            import: 'Your file is being imported',
        },
    },
    open: {
        confirm: {
            title: 'Import file',
            message:
                'Your current changes will be lost, are you sure that you want to open a new file?',
        },
    },
    import: {
        confirm: {
            title: 'Import files',
            message:
                'These files {importedFiles} will be imported to file {currentFile}, are you sure to continue?',
        },
    },
    generateCustomId: {
        errors: {
            amountInvalid: 'Too many file generation custom id',
            exsit: 'These files {importedFiles} will be generation custom id',
            notExsit: 'These files {importedFiles} is not exsit custom id',
        },
    },
    synchronise: {
        fileNotFound: 'Please select a file to sync',
        openedFile: `Can't sync with opened file`,
        manyFiles: 'Only 1 file can be selected',
    },
    setNodeForm: {
        title: 'Set node properties',
        submit: 'Submit',
        add: 'Add',
        cancel: 'Cancel',
        checkbox: 'Set Node properties for file 3D',
        message: {
            success: 'Set node properties successfully',
        },
        label: {
            keyForm: 'Key',
            valueForm: 'Value',
        },
        placehoder: {
            keyForm: 'Enter Key',
            valueForm: 'Enter Value',
        },
    },
};
