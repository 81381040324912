import {
    DATE_TIME_FORMAT,
    FILE_NAME_MAX_LENGTH,
    INPUT_TEXT_MAX_LENGTH,
    INTEGER_POSITIVE_MAX_VALUE,
    INTEGER_POSITIVE_MIN_VALUE,
} from '@/common/constants';
import { showErrorNotificationFunction } from '@/common/helpers';
import { calendarModule } from '@/features/calendar/store';
import yup from '@/plugins/yup';
import moment from 'moment';
import { useField, useForm } from 'vee-validate';
import {
    CurrencyType,
    TaskDuration,
    TaskDurationFormat,
    TaskPercentageComplete,
    TaskType,
} from '../constants';
import { convertDurationFormat } from '../helper';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';

const planningFormSchema = yup.object({
    planningId: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
    name: yup.string().trim().max(FILE_NAME_MAX_LENGTH).required(),
    taskIdPrefix: yup.string().max(INPUT_TEXT_MAX_LENGTH).trim().required(),
    taskIdSuffix: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    taskIdIncrement: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    resourceIdPrefix: yup.string().max(INPUT_TEXT_MAX_LENGTH).trim().required(),
    resourceIdSuffix: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    resourceIdIncrement: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    currency: yup
        .string()
        .oneOf([...Object.values(CurrencyType)])
        .required(),
    durationType: yup
        .string()
        .oneOf([...Object.values(TaskDuration)])
        .required(),
    durationFormat: yup
        .string()
        .oneOf([...Object.values(TaskDurationFormat)])
        .required(),
    defaultDuration: yup
        .number()
        .positive()
        .min(0)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    durationDecimalFormat: yup
        .number()
        .required()
        .transform((val) => (isNaN(val) ? 0 : +val)),
    projectStart: yup.date().required(),
    dataDate: yup
        .date()
        .test({
            name: 'dataDate',
            message: 'planning.planningForm.messages.errors.minDataDate',
            exclusive: false,
            params: {},
            test: function (value) {
                if (value && moment(this.parent.projectStart).isValid())
                    return (
                        moment(value)
                            .startOfDay()
                            .diff(
                                moment(this.parent.projectStart).startOfDay(),
                                'millisecond',
                            ) >= 0
                    );

                return true;
            },
        })
        .required(),
    activityType: yup
        .string()
        .oneOf([...Object.values(TaskType)])
        .required(),
    defaultCalendar: yup.string().required(),
    percentageComplete: yup
        .string()
        .oneOf([...Object.values(TaskPercentageComplete)])
        .required(),
    criticalThreshold: yup
        .number()
        .transform((val) => (isNaN(val) ? 0 : val))
        .required(),
    assignedActivityCodeId: yup
        .string()
        .trim()
        .max(INPUT_TEXT_MAX_LENGTH)
        .optional()
        .nullable(),
    isDisplayHourOfAllDateTime: yup.boolean().required(),
});

export const usePlanningForm = () => {
    const initValues = {
        planningId: '',
        name: '',
        taskIdPrefix: '',
        taskIdSuffix: undefined,
        taskIdIncrement: undefined,
        resourceIdPrefix: '',
        resourceIdSuffix: undefined,
        resourceIdIncrement: undefined,
        currency: undefined,
        durationType: undefined,
        durationFormat: undefined,
        defaultDuration: undefined,
        durationDecimalFormat: undefined,
        activityType: undefined,
        percentageComplete: undefined,
        projectStart: undefined,
        dataDate: undefined,
        defaultCalendar: undefined,
        criticalThreshold: undefined,
        assignedActivityCodeId: undefined,
        isDisplayHourOfAllDateTime: false,
    };

    const { handleSubmit, setValues, setFieldValue, resetForm, errors } = useForm({
        initialValues: initValues,
        validationSchema: planningFormSchema,
    });

    const onSubmit = handleSubmit(async (values) => {
        const calendar = calendarModule.calendarList.find(
            (calendar) => calendar._id === values.defaultCalendar,
        );
        const response = await projectPlanningService.updatePlanning(
            projectPlanningModule.planning?._id as string,
            {
                ...values,
                dataDate: moment(values.dataDate)
                    .utc()
                    .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
                projectStart: moment(values.projectStart)
                    .utc()
                    .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
                currency: values.currency as CurrencyType,
                durationType: values.durationType as TaskDuration,
                durationFormat: values.durationFormat as TaskDurationFormat,
                durationDecimalFormat: values.durationDecimalFormat,
                activityType: values.activityType as TaskType,
                percentageComplete: values.percentageComplete as TaskPercentageComplete,
                defaultDuration: convertDurationFormat(
                    values.defaultDuration as number,
                    values.durationFormat as TaskDurationFormat,
                    TaskDurationFormat.HOUR,
                    calendar,
                ),
                assignedActivityCodeId: values.assignedActivityCodeId || undefined,
            },
        );
        if (response.success) {
            return response.data;
        } else {
            showErrorNotificationFunction(response.message);
            return undefined;
        }
    });

    const { value: planningId } = useField('planningId');
    const { value: name } = useField('name');
    const { value: taskIdPrefix } = useField('taskIdPrefix');
    const { value: taskIdSuffix } = useField('taskIdSuffix');
    const { value: taskIdIncrement } = useField('taskIdIncrement');
    const { value: resourceIdPrefix } = useField('resourceIdPrefix');
    const { value: resourceIdSuffix } = useField('resourceIdSuffix');
    const { value: resourceIdIncrement } = useField('resourceIdIncrement');
    const { value: currency } = useField('currency');
    const { value: durationType } = useField('durationType');
    const { value: durationFormat } = useField('durationFormat');
    const { value: defaultDuration } = useField('defaultDuration');
    const { value: activityType } = useField('activityType');
    const { value: percentageComplete } = useField('percentageComplete');
    const { value: projectStart } = useField('projectStart');
    const { value: dataDate } = useField('dataDate');
    const { value: defaultCalendar } = useField('defaultCalendar');
    const { value: criticalThreshold } = useField('criticalThreshold');
    const { value: assignedActivityCodeId } = useField('assignedActivityCodeId');
    const { value: isDisplayHourOfAllDateTime } = useField('isDisplayHourOfAllDateTime');
    const { value: durationDecimalFormat } = useField('durationDecimalFormat');
    return {
        onSubmit,
        setValues,
        resetForm,
        setFieldValue,
        errors,
        planningId,
        name,
        taskIdPrefix,
        taskIdSuffix,
        taskIdIncrement,
        resourceIdPrefix,
        resourceIdSuffix,
        resourceIdIncrement,
        currency,
        durationType,
        durationFormat,
        defaultDuration,
        activityType,
        percentageComplete,
        projectStart,
        dataDate,
        defaultCalendar,
        initValues,
        criticalThreshold,
        assignedActivityCodeId,
        isDisplayHourOfAllDateTime,
        durationDecimalFormat,
    };
};
