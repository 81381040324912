import { PageName } from '@/common/constants';
import { notificationModule } from '@/features/notification/store';
import { computed } from 'vue';

export const sidebarPlatform = [
    // Admin
    {
        iconLink: require('@/assets/icons/user.svg'),
        name: 'app.sidebar.platform.admin',
        active: false,
        to: '/user',
        pageName: PageName.USER_LIST_PAGE,
    },

    // Notifications
    {
        iconLink: require('@/assets/icons/bell.svg'),
        name: 'app.sidebar.platform.notifications',
        active: false,
        to: '/notification',
        pageName: PageName.NOTIFICATION_LIST_PAGE,
        badge: () => {
            return computed(() => notificationModule.pendingNotificationCount).value;
        },
    },
    {
        iconLink: require('@/assets/icons/document-text.svg'),
        name: 'app.sidebar.platform.accessLog.title',
        active: false,
        children: [
            {
                name: 'app.sidebar.platform.accessLog.userLog',
                active: false,
                to: '/log/user',
                pageName: PageName.USER_LOG_LIST_PAGE,
            },
            {
                name: 'app.sidebar.platform.accessLog.projectLogHistory',
                active: false,
                to: '/log/project',
                pageName: PageName.PROJECT_LOG_HISTORY_LIST_PAGE,
            },
            {
                name: 'app.sidebar.platform.accessLog.projectLogReport',
                active: false,
                to: '/log/report',
                pageName: PageName.PROJECT_LOG_REPORT_PAGE,
            },
            {
                name: 'app.sidebar.platform.accessLog.projectLogServer',
                active: false,
                to: '/log/server',
                pageName: PageName.PROJECT_LOG_SERVER_PAGE,
            },
        ],
    },

    // Help
    {
        iconLink: require('@/assets/icons/question-mark-circle.svg'),
        name: 'app.sidebar.platform.help',
        active: false,
        to: '/support-request',
        pageName: PageName.SUPPORT_REQUEST_LIST_PAGE,
    },
];
