export default {
    ALL: 'All',
    GENERAL: 'General',
    GENERAL_CREATE_ABS_STRUCTURE: 'Create ABS structure',
    GENERAL_ASSIGN_PBS_TO_ABS: 'Assign PBS to ABS',
    GENERAL_CREATE_PBS_STRUCTURE: 'Create PBS structure',
    GENERAL_ACCESS_TO_ADMIN: 'Access to Admin',
    GENERAL_MANAGE_USER_GROUP_OF_PROJECT: 'Manage user group of project',
    GENERAL_ADD_USER_FROM_CONSTELLATION: 'Add user from constellation',
    GENERAL_LDAP_CONFIGURATION: 'LDAP configuration',
    GENERAL_IMPORT_CSV: 'Import csv',
    GENERAL_CREATE_PROJECT_PROFILE: 'Create project profile',
    GENERAL_ACCESS_TO_PROJECT: 'Access to project',
    GENERAL_CREATE_CALENDAR: 'Manage all calendars',
    GENERAL_MANAGE_ALL_APPEARANCE_PROFILE: 'Manage all appearance profiles',
    GENERAL_MANAGE_ALL_ACTIVITY_CODE: 'Manage all activity codes',
    GENERAL_MANAGE_ALL_USER_DEFINED_FIELD: 'Manage all user defined fields',
    GENERAL_MANAGE_ALL_RESOURCE: 'Manage all resources',
    '4DPLANNING': '4D/Planning',

    '4DPLANNING_EDIT_PLANNING': 'Edit planning',
    '4DPLANNING_EDIT_PLANNING_SETTINGS': 'Edit planning settings',
    '4DPLANNING_RESCHEDULING': 'Rescheduling',
    '4DPLANNING_EDIT_RESOURCE_AND_RESOURCE_GROUP': 'Edit resource and resource group',
    '4DPLANNING_EDIT_APPEARANCE_PROFILE': 'Edit appearance profile',
    '4DPLANNING_CREATE_3D_PATH': 'Create 3D path',
    '4DPLANNING_CREATE_TASK_FROM_3D_OBJECTS': 'Create task from 3D objects',
    '4DPLANNING_CREATE_ANIMATION': 'Create animation',
    '4DPLANNING_REPORTING': 'Reporting',
    '4DPLANNING_IMPORT_EXPORT_PLANNING': 'Import and export planning',
    '4DPLANNING_READ_ONLY': 'Read only',

    CONSTELLATION: 'Constellation',
    CONSTELLATION_REALIZE_SYNTHESIS: 'Realize synthesis',
    CONSTELLATION_BOTTOM_UP_PROCESS: 'Bottom up process',
    CONSTELLATION_TOP_DOWN_PROCESS: 'Top down process',
    CONSTELLATION_DELEGATE_TASKS: 'Delegate tasks',
    CONSTELLATION_CREATE_4DBOX: 'Create 4Dbox',
    CONSTELLATION_CREATE_AREA: 'Create area',
    '3D_WEBVIEWER': '3D webviewer',
    '3D_WEBVIEWER_TABS_START': 'Start',
    '3D_WEBVIEWER_TABS_START_GROUPS_FILE': 'Groups file',
    '3D_WEBVIEWER_TABS_START_GROUPS_UNDO': 'Groups undo',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS': 'Groups controls',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE': 'Groups selection mode',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION': 'Groups selection',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE': 'Groups show/hide',
    '3D_WEBVIEWER_TABS_VIEW': 'View',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT': 'Groups alignment',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE': 'Groups rotate',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM': 'Groups zoom',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE': 'Rendering mode',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS': 'Graphic effects',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH': 'Flythrough',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES': 'Groups scenes',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION': 'Groups animation',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES': 'Show panes',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS': 'Multisessions',
    '3D_WEBVIEWER_TABS_DOCUMENT': 'Document',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS': 'Controls',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE': 'Groups page',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE': 'Rotate',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM': 'Groups zoom',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT': 'Groups text',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS': 'Graphic effects',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE': 'Compare',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP': '3D markup',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES': 'Show panes',
    '3D_WEBVIEWER_TABS_MEASUREMENT': 'Measurement',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES': 'Snap modes',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE': 'Circle mode',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE': 'Coordinate',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH': 'Length',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE': 'Circle',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE': 'Distance',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE': 'Angle',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE': 'Face',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS': 'Wall thickness',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX': 'Bounding box',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE': 'Drillhole',
    '3D_WEBVIEWER_TABS_ANALYZE': 'Analyze',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION': 'Section',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE': 'Compare',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA': 'Projected area',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH': 'Analyze groups clash',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE': 'Compute',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION': 'Feature recognition',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED': 'Advanced',
    '3D_WEBVIEWER_TABS_TRANSFORMATION': 'Transformation',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION': 'Transformation',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION': 'Boundary condition',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE': 'Aligning move',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE': 'Aligning rotate',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE':
        'Aligning move rotate',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING': 'Model aligning',
    '3D_WEBVIEWER_TABS_TECH_DOC': 'Tech doc',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL': 'Callouts general panel',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL':
        'Callouts alignment panel',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL': 'Callouts panel',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL': 'Connections panel',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL': 'Visible callouts panel',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP': '3D markup',
    '3D_WEBVIEWER_TABS_MODEL': 'Model',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE': 'Retesselate',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM': 'Coordinate system',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT': 'Create point',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE': 'Create wire',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS': 'Modify solids',
    '3D_WEBVIEWER_TABS_TOOLS': 'Tools',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT': 'Screenshot',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP': '3D markup',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH': 'Tools groups search',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS': 'Geometry tools',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION': 'Ip protection',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS': 'Extras',
    '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_OPEN': 'Open',
    '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_IMPORT': 'Import',
    '3D_WEBVIEWER_TABS_START_GROUPS_FILE_FUNCTIONS_PRINT': 'Print',
    '3D_WEBVIEWER_TABS_START_GROUPS_UNDO_FUNCTIONS_LAST_ACTIONS': 'Last actions',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE': 'Zoom rectangle',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ROTATE': 'Rotate',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_PAN': 'Pan',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_SELECTION_FRAME':
        'Selection frame',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_TURNTABLE': 'Turntable',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_ZOOM': 'Zoom',
    '3D_WEBVIEWER_TABS_START_GROUPS_CONTROLS_FUNCTIONS_MOUSE_USE_FREE_ROTATE':
        'Mouse use free rotate',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_SELECT': 'Select',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_NEIGHBOURHOOD_SEARCH':
        'Neighbourhood search',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_HIDE': 'Hide',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_DELETE': 'Delete',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_GHOST': 'Ghost',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_COLOR': 'Color',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_INSTANCE': 'Instance',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_PMIREFERENCE':
        'Pmireference',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_FACE': 'Face',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_MODE_FUNCTIONS_ATTRIBUTES': 'Attributes',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SELECT_ALL': 'Select all',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_INVERT_SELECTION':
        'Invert selection',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_ISOLATE': 'Isolate',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_SHOW_SELECTION': 'Show selection',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_HIDE_SELECTION': 'Hide selection',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DELETE_SELECTION':
        'Delete selection',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_GHOST_SELECTION':
        'Ghost selection',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_UNGHOST': 'Unghost',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_DESELECT': 'Deselect',
    '3D_WEBVIEWER_TABS_START_GROUPS_SELECTION_FUNCTIONS_COLOR': 'Color',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_SHOW_ALL': 'Show all',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_UNGHOST_ALL': 'Unghost all',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_MARKUPS':
        'Hide all markups',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_HIDE_ALL_PMIS': 'Hide all pmis',
    '3D_WEBVIEWER_TABS_START_GROUPS_SHOW/HIDE_FUNCTIONS_INVERT_VISIBILITY':
        'Invert visibility',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_PAN': 'Pan',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_CONTROLS_FUNCTIONS_ZOOM_RECTANGLE':
        'Zoom rectangle',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PREVIOUS': 'Previous',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_NEXT': 'Next',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_PAGE_FUNCTIONS_PAGE': 'Page',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_90': '90',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_180': '180',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ROTATE_FUNCTIONS_270': '270',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_ALL': 'Fit all',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_WIDTH': 'Fit width',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_FIT_HEIGHT': 'Fit height',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN': 'Zoom in',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT': 'Zoom out',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_SEARCH': 'Search',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_TEXT_FUNCTIONS_TEXT_COPY': 'Copy',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_ILLUSTRATION':
        'Illustration',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_INVERT': 'Invert',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_COMPARE_FUNCTIONS_COMPARE': 'Compare',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_TEXT': 'Text',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE': 'Circle',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT': 'Fixed text',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE': 'Rectangle',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND': 'Freehand',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_LAYER': 'Layer',
    '3D_WEBVIEWER_TABS_DOCUMENT_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS': 'Attachments',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_ISOMETRIC': 'Isometric',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_TOP': 'Top',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_LEFT': 'Left',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_FRONT': 'Front',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BOTTOM': 'Bottom',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_RIGHT': 'Right',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_BACK': 'Back',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ALIGNMENT_FUNCTIONS_VERTICAL': 'Vertical',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_90': '90',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_180': '180',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ROTATE_FUNCTIONS_270': '270',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FIT_ALL': 'Fit all',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_IN': 'Zoom in',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_ZOOM_OUT': 'Zoom out',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS': 'Focus',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_FOCUS_TRANSITION': 'Focus transition',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ZOOM_FUNCTIONS_SET_ORIGIN': 'Set origin',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_SOLID': 'Solid',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_WIRES': 'Wires',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_ILLUSTRATION': 'Illustration',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_RENDERING_MODE_FUNCTIONS_PERSPECTIVE': 'Perspective',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SILHOUETTE': 'Silhouette',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_OPAQUE': 'Opaque',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_GRAPHIC_EFFECTS_FUNCTIONS_SHADOW': 'Shadow',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_FLYTHROUGH_FUNCTIONS_FLYTHROUGH': 'Flythrough',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_SHOW_GRID': 'Show grid',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SCENES_FUNCTIONS_FULL_SCREEN': 'Full screen',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_SPIN': 'Spin',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_ANIMATE': 'Animate',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_ANIMATION_FUNCTIONS_CREATE_ANIMATION':
        'Create animation',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_MODEL_TREE': 'Model tree',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_VIEWS': 'Views',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_PROPERTIES': 'Properties',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SETTINGS': 'Settings',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_SELECTIONS_WND': 'Selections wnd',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LAYER': 'Layer',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_ATTACHMENTS': 'Attachments',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_LIGHTING': 'Lighting',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_OUTPUT': 'Output',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_SHOW_PANES_FUNCTIONS_RESET_UI': 'Reset ui',
    '3D_WEBVIEWER_TABS_VIEW_GROUPS_MULTISESSIONS_FUNCTIONS_START': 'Start',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_FREE': 'Free',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_END': 'End',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_SNAP_MODES_FUNCTIONS_SNAP_MODE_CENTER':
        'Center',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_ARC': 'Arc',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_MODE_FUNCTIONS_3_POINTS': '3 points',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_COORDINATE_FUNCTIONS_POINT': 'Point',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_EDGE': 'Edge',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_ACCUMULATED_EDGE':
        'Accumulated edge',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_LENGTH_FUNCTIONS_POINT_TO_POINT_ON_WIRE':
        'Point to point on wire',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_CIRCLE_FUNCTIONS_RADIUS': 'Radius',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_BETWEEN_TWO_POINTS':
        'Between two points',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DISTANCE_FUNCTIONS_DISTANCE_SOLIDS_TO_SOLIDS':
        'Solids to solids',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_ANGLE_FUNCTIONS_ANGLE_BETWEEN_TWO_AXES':
        'Between two axes',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_AREA': 'Area',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_FACE_FUNCTIONS_ACCUMULATED_AREA':
        'Accumulated area',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_WALL_THICKNESS_FUNCTIONS_WALLTHICKNESS_COMPUTATION_MODE_RAY':
        'Computation mode ray',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_WORLD': 'World',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_BOUNDING_BOX_FUNCTIONS_COMPUTE_MINIMUM':
        'Compute minimum',
    '3D_WEBVIEWER_TABS_MEASUREMENT_GROUPS_DRILLHOLE_FUNCTIONS_DRILLHOLE': 'Drillhole',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_SECTION_FUNCTIONS_CUTTING_PLANE_FROM_POINT_NORMAL':
        'Cutting plane from point normal',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_COMPARE_MODEL': 'Compare model',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPARE_FUNCTIONS_BREP_PART': 'Brep part',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_PROJECTED_AREA_FUNCTIONS_PROJECTED_AREA':
        'Projected area',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_CLASH_DETECTION': 'Clash detection',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_CLASH_FUNCTIONS_BAND_ANALYSIS': 'Band analysis',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_COMPUTE_FUNCTIONS_PHYSICAL_PROPERTIES':
        'Physical properties',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_NEUTRAL_AXIS':
        'Neutral axis',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_FEATURE_RECOGNITION_FUNCTIONS_DRILLHOLE':
        'Drillhole',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_DRAFT_ANGLE': 'Draft angle',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_WALL_THICKNESS':
        'Wall thickness',
    '3D_WEBVIEWER_TABS_ANALYZE_GROUPS_ADVANCED_FUNCTIONS_SPLIT': 'Split',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_FREE_DRAG':
        'Free drag',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MOVE': 'Move',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_ROTATE': 'Rotate',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_SCALE': 'Scale',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_MIRROR': 'Mirror',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_EXPLODE': 'Explode',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_TRANSFORMATION_FUNCTIONS_RESET_TRANSFORMATION':
        'Reset transformation',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_ACTIVATE':
        'Activate',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_BOUNDARY_CONDITION_FUNCTIONS_BOUNDARY_CONDITION_AXIS':
        'Boundary condition axis',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_POINT_TO_POINT':
        'Point to point',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_PLANE_TO_PLANE':
        'Plane to plane',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER':
        'Circle center to circle center',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_FUNCTIONS_CIRCLE_CENTER_TO_POINT':
        'Circle center to point',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_NORMAL_TO_NORMAL':
        'Normal to normal',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_POINT_TO_POINT':
        'Point to point',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_ROTATE_FUNCTIONS_CIRCLE_CENTER_TO_CIRCLE_CENTER':
        'Circle center to circle center',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_COORDINATE_SYSTEM_TO_COORDINATE_SYSTEM':
        'Coordinate system to coordinate system',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_CIRCLE_AXIS_TO_CIRCLE_AXIS':
        'Circle axis to circle axis',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_ALIGNING_MOVE_ROTATE_FUNCTIONS_PLANE_TO_PLANE':
        'Plane to plane',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_SOLID_TO_SOLID':
        'Solid to solid',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_LINE': 'Line',
    '3D_WEBVIEWER_TABS_TRANSFORMATION_GROUPS_MODEL_ALIGNING_FUNCTIONS_PLANE': 'Plane',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_GENERAL_PANEL_FUNCTIONS_BOM': 'Bom',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_ALIGN_TOP':
        'Align top',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_ALIGNMENT_PANEL_FUNCTIONS_CALLOUTS_AUTO_ALIGNMENT':
        'Auto alignment',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CREATE': 'Create',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_FOR_SELECTION':
        'Callouts for selection',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_SETTINGS': 'Settings',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SHOW_ALL':
        'Callouts show all',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_LOCK_CALLOUTS':
        'Lock callouts',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_UNLOCK_CALLOUTS':
        'Unlock callouts',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_ASSOCIATED_NODES':
        'Callouts select associated nodes',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_MULTIPLE_CONNECTIONS':
        'Callouts multiple connections',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_EDIT': 'Edit',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_DELETE': 'Delete',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_CONNECTIONS_PANEL_FUNCTIONS_INVERT_VISIBILITY':
        'Invert visibility',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_SELECT_VISIBLE':
        'Select visible',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_HIDE_VISIBLE':
        'Hide visible',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_VISIBLE_CALLOUTS_PANEL_FUNCTIONS_CALLOUTS_DELETE_VISIBLE':
        'Delete visible',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_TEXT': 'Text',
    '3D_WEBVIEWER_TABS_TECH_DOC_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT': 'Fixed text',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_RETESSELATE_FUNCTIONS_RETESSELATE': 'Retesselate',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CYLINDER_AXIS':
        'By cylinder axis',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_CURRENT_GLOBAL':
        'By current global',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_BY_SELECTED_PART':
        'By selected part',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_SETTINGS': 'Settings',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_ACTIVATE_COORDINATESYSTEM':
        'Activate coordinatesystem',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_COORDINATE_SYSTEM_FUNCTIONS_RESET_COORDINATESYSTEM':
        'Reset coordinatesystem',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_CIRCLE_CENTER':
        'Circle center',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_POINT': 'Point',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_POINT_FUNCTIONS_LINE_TO_LINE': 'Line to line',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_CIRCLE_AXIS': 'Circle axis',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_CREATE_WIRE_FUNCTIONS_POINT_TO_POINT':
        'Point to point',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_EXTRACT_FACES':
        'Extract faces',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS':
        'Merge objects',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_MERGE_OBJECTS_HIERARCHICAL':
        'Merge objects hierarchical',
    '3D_WEBVIEWER_TABS_MODEL_GROUPS_MODIFY_SOLIDS_FUNCTIONS_CONVEX_HULL': 'Convex hull',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SCREENSHOT_FUNCTIONS_CLIPBOARD': 'Clipboard',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_TEXT': 'Text',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_CIRCLE': 'Circle',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FIXED_TEXT': 'Fixed text',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_RECTANGLE': 'Rectangle',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_3D_MARKUP_FUNCTIONS_FREEHAND': 'Freehand',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_SEARCH_FUNCTIONS_SEARCH': 'Search',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_GEOMETRY_TOOLS_FUNCTIONS_GEOMETRY_TOOLS':
        'Geometry tools',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_DISTORT': 'Distort',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_IP_PROTECTION_FUNCTIONS_VISIBILITY_TEST':
        'Visibility test',
    '3D_WEBVIEWER_TABS_TOOLS_GROUPS_EXTRAS_FUNCTIONS_COMMAND': 'Command',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE': 'File',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN': 'Open',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT': 'Import',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT': 'Export',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER': '3D Broker',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE': 'Nodes Mode',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_FILE':
        'Open File',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_OPEN_FUNCTIONS_CUSTOM_OPEN_REPOSITORY':
        'Open From Repository',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_FILE':
        'Add to Scene',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_IMPORT_FUNCTIONS_CUSTOM_IMPORT_REPOSITORY':
        'Add From Repository',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_EXPORT_FILE': 'Export',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_FILE': 'Save',
    //3D Broker
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_4D_BOX':
        'Create 4D box',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_3D_BROKER_FUNCTIONS_CREATE_ZONE': 'Create Zone',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_NODES_MODE_FUNCTIONS_REMOVE_EMPTY_NODES':
        'Remove node empty',
    '3D_WEBVIEWER_TABS_CUSTOM_FILE_GROUPS_CUSTOM_EXPORT_FUNCTIONS_SAVE_AS_FILE':
        'Save as',
};
