import yup from '@/plugins/yup';
import { ElLoading } from 'element-plus';
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { webViewer3DService } from '../services/api.service';
import { webViewer3DModule } from '../store';
import {
    showErrorNotificationFunction,
    showSuccessNotificationFunction,
} from '@/common/helpers';

export const setNodePropertiesSchema = yup.object({
    attributes: yup.array().of(
        yup.object({
            key: yup.string().required(),
            value: yup.string().required(),
        }),
    ),
    setPropertiesForAllNode: yup.boolean(),
});

export const initData = {
    attributes: [{ key: undefined, value: undefined }],
    setPropertiesForAllNode: false,
};
export const setNodePropertiesForm = () => {
    const { t } = useI18n();
    const {
        handleSubmit,
        errors,
        resetForm,
        validate,
        setErrors,
        setFieldValue,
        setFieldError,
        handleReset,
    } = useForm({
        initialValues: initData,
        validationSchema: setNodePropertiesSchema,
    });

    const onSubmit = handleSubmit(async (values) => {
        const loading = ElLoading.service({});
        const res = await webViewer3DService.setNodeProperties({
            sessionToken: webViewer3DModule.sessionToken || '',
            attributes: (values.attributes as any[]) || [],
            setPropertiesForAllNode: values.setPropertiesForAllNode,
        });
        if (res.success) {
            showSuccessNotificationFunction('Set Node Properties successfully');
        } else {
            showErrorNotificationFunction(res.message);
        }
        loading.close();
    });

    const { value: attributes } = useField('attributes');
    const { value: setPropertiesForAllNode } = useField('setPropertiesForAllNode');

    return {
        attributes,
        setPropertiesForAllNode,
        errors,
        onSubmit,
        resetForm,
        validate,
        setErrors,
        setFieldValue,
        setFieldError,
        handleReset,
        init: initData,
    };
};
