import { Week } from '@/common/constants';
import { ICommonGetListQuery } from '@/common/interfaces';
import {
    CalendarForDelayLinkOption,
    GanttFilterLogic,
    GanttFilterOperation,
    InProgressTaskOption,
    DelegateType,
    UserFieldTypeEnum,
    ResourceMaterialUnitType,
    ResourceOtherUnitType,
    Bars,
} from '@/features/4D-planning/constants';
import {
    AppearanceOptions,
    AppearanceType,
    AssignToExistingResourceOption,
    ColumnType,
    CurrencyType,
    GanttColumn,
    GrowthSimulation,
    LinkType,
    MilestoneLinkTo,
    MilestoneType,
    PlanningOrderBy,
    PlanningStatus,
    ResourceType,
    SearchTaskOption,
    TaskConstraint,
    TaskDuration,
    TaskDurationFormat,
    TaskFieldDataType,
    TaskPercentageComplete,
    TaskPhysicalQuantityUnit,
    TaskStatus,
    TaskType,
} from './constants';

export interface IProjectTask {
    _id: string;
    ganttId: string;
    name: string;
    parentId: string | null;
    parentGanttId: string | null;
    planningId: string;
    delegatedTo: null | string;
    taskType: TaskType;
    status: TaskStatus;
    createdAt: Date;
    code: string;
    start: Date;
    actualStart: Date | null;
    plannedStart: Date | null;
    baselineStart: Date | null;
    finish: Date;
    actualFinish: Date | null;
    plannedFinish: Date | null;
    baselineFinish: Date | null;
    primaryConstraints: TaskConstraint | null;
    primaryConstraintDate: Date | null;
    expectedFinish: Date | null;
    durationType: TaskDuration;
    originalDuration?: number | null;
    actualDuration?: number | null;
    remainingDuration?: number | null;
    plannedDuration?: number | null;
    percentageComplete: TaskPercentageComplete;
    manualComplete: number;
    physicalQuantityUnit: TaskPhysicalQuantityUnit | null;
    physicalQuantity: number;
    actualPhysicalQuantity: number;
    remainingPhysicalQuantity?: number | string | null;
    atCompletePhysicalQuantity?: number | string | null;
    rules: string;
    isMilestoneFolder?: boolean;
    isStaticMilestone?: boolean;
    milestoneType?: MilestoneType;
    milestoneLinkTo?: MilestoneLinkTo;
    isTopDownFolder: boolean;
    resourceIds: string[];
    resourceGroupIds: string[];
    appearanceProfileId: string;
    selectedActivityCodeValueId: string | null;
    assignedActivityCodeValueIds: string[];
    additionalFields: Record<string, string | number | boolean | Date>;
    clonedFromTaskId?: string;
    inheritedFromTaskId?: string;
    linkedTaskId?: string;
    linkedLinkId?: string;
    calendarId?: string;
    isSynthesizedToOtherTask?: boolean;
    canEdit?: boolean;
    color?: string;
    lateStart?: string | null;
    lateFinish?: string | null;
    earlyStart?: string | null;
    earlyFinish?: string | null;
    isRootWbs?: boolean;
    durationFormat?: TaskDurationFormat;
    VBLFinishDate?: number | null;
    freeFloat?: number | null;
    totalFloat?: number | null;
    critical?: boolean;
    isOnCriticalPath?: boolean;
    physicalPercentage?: number | null;
    durationPercentage?: number | null;
    calendarName?: string; // Currently flaky: some API returns this, some not
    estimatedRate?: number | null;
}

export interface IUpdateTaskResponse {
    updatedTasksFlow: IProjectTask[];
    updatedTask: IProjectTask;
    currentTask: IProjectTask;
    updatedMilestones: IProjectTask[];
}
export interface ITaskLink {
    _id: string;
    source: string;
    target: string;
    createdAt: Date;
    type: LinkType;
    lag: number;
    isDrivingRelationship?: boolean;
    isOnCriticalPath?: boolean;
    freeFloat?: number;
}

export interface ICreateLinkResponse {
    link: ITaskLink;
}

export interface IBulkCreateLinkResponse {
    taskLinks: ITaskLink[];
}
export interface ICreateTaskFieldBody {
    name: string;
    dataType: TaskFieldDataType;
    projectId: string;
    planningId: string;
    type?: string;
}

export interface IUpdateTaskFieldBody {
    name: string;
    projectId: string;
    type?: string;
}

export interface IAdditionalTaskField extends ICreateTaskFieldBody {
    _id: string;
}
export interface ITimeBlock {
    startTime: string;
    endTime: string;
}
export interface IProjectCalendarConfig {
    date: string;
    startDateAt: string;
    endDateAt: string;
    weekDay: Week;
    dayType: {
        timeBlocks: ITimeBlock[];
    };
}

export interface IProjectCalendar {
    _id: string;
    name: string;
    hoursPerDay: number;
    hoursPerWeek: number;
    hoursPerMonth: number;
    hoursPerYear: number;
    isDefaultCalendar: boolean;
    configs: IProjectCalendarConfig[];
    weekDays?: Week[];
    startDateAt?: string;
    endDateAt?: string;
    dayType?: {
        timeBlocks: ITimeBlock[];
    };
    updatedAt?: string;
}

export interface IPlanning {
    _id: string;
    status: PlanningStatus;
    planningId: string;
    name: string;
    planningFilePath: string;
    // additionalTaskFields: IAdditionalTaskField[];
    taskLinks: ITaskLink[];
    tasks: IProjectTask[];
    projectId: string;
    taskIds: string[];
    createdAt: Date;
    isTemplate?: boolean;
    synthesizedFromPlanningIds?: string[];
    clonedFromPlanningIds?: string[];
    appliedBaselineId?: string;
    isSynthesized?: boolean;
    taskIdPrefix: string;
    taskIdSuffix: number;
    taskIdIncrement: number;
    resourceIdPrefix: string;
    resourceIdSuffix: number;
    resourceIdIncrement: number;
    currency: CurrencyType;
    durationType: TaskDuration;
    durationFormat: TaskDurationFormat;
    defaultDuration: number;
    durationDecimalFormat?: number;
    activityType: TaskType;
    percentageComplete: TaskPercentageComplete;
    projectStart: Date;
    dataDate: Date;
    defaultCalendar: string;
    autoScheduling: boolean;
    p6Id?: string;
    fileId?: string;
    filePath?: string;
    forceEnableTopdown?: boolean;
    forceEnableBottomup?: boolean;
    disableTopdownAndBottomup?: boolean;
    delegatedFromPlanningId?: string;
    calendars?: IProjectCalendar[];
    setting?: IPlanningSetting;
    view?: IPlanningView;
    criticalThreshold?: number;
    inProgressTaskOption?: InProgressTaskOption;
    calendarForDelayLink?: CalendarForDelayLinkOption;
    useExpectedFinish?: boolean;
    criticalTaskWithoutSuccessor?: boolean;
    disableScheduleTaskWithoutLinkAndConstraints?: boolean;
    assignedActivityCodeId?: string;
    isDisplayHourOfAllDateTime?: boolean;
    isFirstTimeInPlanning?: boolean;
    baseFile3D?: string;
}

export interface IPlanningSetting {
    planningId: string;
    baselines?: IBaselineSetting;
    columns?: IColumnSetting[];
}

export interface IPlanningView {
    _id: string;
    planningId: string;
    userId: string;
    name: string;
    selected: boolean;
    columns: IColumnSetting2[];
    filters: IColumnFilter[];
    logicBetweenFilters?: GanttFilterLogic;
    gantt: IGanttView;
    bars?: IBar[];
    groupBy: IGroupBy;
}

export interface IGanttView {
    isShowDataDateLine: boolean;
    isShowCriticalRelationships: boolean;
    isShowNonDrivingRelationships: boolean;
    isShowSimulationLine: boolean;
    isShowNonWorkTimeShading: boolean;
}

export interface IUpdatePlanningView
    extends Partial<
        Pick<
            IPlanningView,
            'columns' | 'selected' | 'name' | 'gantt' | 'bars' | 'groupBy'
        >
    > {
    logicBetweenFilters?: GanttFilterLogic;
    filterIds?: string[];
}

export interface ICreatePlanningView {
    columns?: IColumnSetting2[];
    name: string;
    filters?: IColumnFilter[];
    gantt?: IGanttView;
}

export interface IUpdatePlanning {
    name?: string;
    taskIdPrefix?: string;
    taskIdSuffix?: number;
    taskIdIncrement?: number;
    resourceIdPrefix?: string;
    resourceIdSuffix?: number;
    resourceIdIncrement?: number;
    currency?: CurrencyType;
    durationType?: TaskDuration;
    durationFormat?: TaskDurationFormat;
    defaultDuration?: number;
    durationDecimalFormat?: number;
    activityType?: TaskType;
    percentageComplete?: TaskPercentageComplete;
    projectStart?: Date | string;
    dataDate?: Date | string;
    defaultCalendar?: string;
    autoScheduling?: boolean;
    assignedActivityCodeId?: string;
    isDisplayHourOfAllDateTime?: boolean;
}

export interface IUpdateRescheduleOptions {
    inProgressTaskOption?: InProgressTaskOption;
    calendarForDelayLink?: CalendarForDelayLinkOption;
    useExpectedFinish?: boolean;
    criticalTaskWithoutSuccessor?: boolean;
    disableScheduleTaskWithoutLinkAndConstraints?: boolean;
    autoScheduling?: boolean;
}

export interface IGanttChartTask extends Omit<IProjectTask, 'taskType'> {
    taskType: TaskType;
    // type of ganttChart to display task, project or milestone
    type: TaskType;
    id: string;
    start_date: Date | string | null;
    parent: string | number;
    progress: number;
    duration?: number;
    text?: string;
    atCompleteDuration: number;
    plannedDuration: number | null;
    baselineDuration: number | null;
    varianceBLDuration: number | null;
    varianceBLFinishDate: number | null;
    varianceBLStartDate: number | null;
    remainingPhysicalQuantity: number | undefined | null;
    // bl_start and bl_finish for display baseline bar of task only
    bl_start?: Date;
    bl_finish?: Date;
    open?: boolean;
    end_date?: Date | string | null;
    row_height?: number;
    bar_height?: number | string;
    canEdit?: boolean;
    editable?: boolean;
    rollup?: boolean;
    hede_bar?: boolean;
    baselineCurrentStart?: Date;
    baselineCurrentFinish?: Date;
    resourceIds: string[];
    resourceGroupIds: string[];
    color?: string;
    activityCode: string | null;
    activityCodeValue: string | null;
    additionalFields: Record<string, string | number | boolean | Date>;
    calendarDuration?: number;
    constraint_type?: TaskConstraint;
    constraint_date?: Date;
    auto_scheduling?: boolean;
    actualHRCost?: number | null;
    actualMaterialCost?: number | null;
    actualEquipmentCost?: number | null;
    actualLocationCost?: number | null;
    actualTotalCost?: number | null;
    atCompleteHRCost?: number | null;
    atCompleteMaterialCost?: number | null;
    atCompleteEquipmentCost?: number | null;
    atCompleteLocationCost?: number | null;
    atCompleteTotalCost?: number | null;
    budgetHRCost?: number | null;
    budgetMaterialCost?: number | null;
    budgetEquipmentCost?: number | null;
    budgetLocationCost?: number | null;
    budgetTotalCost?: number | null;
    hrFixedCost?: number | null;
    materialFixedCost?: number | null;
    equipmentFixedCost?: number | null;
    locationFixedCost?: number | null;
    totalFixedCost?: number | null;
    blHRCost?: number | null;
    blMaterialCost?: number | null;
    blEquipmentCost?: number | null;
    blLocationCost?: number | null;
    blTotalCost?: number | null;
    blFixedCost?: number | null;
    remainHRCost?: number | null;
    remainMaterialCost?: number | null;
    remainEquipmentCost?: number | null;
    remainLocationCost?: number | null;
    remainTotalCost?: number | null;
    blActualDuration?: number | null;
    blRemainDuration?: number | null;
    blFreeFloat?: string | null;
    blTotalFloat?: string | null;
    longestPath?: string | null;
    percentComplete?: string | null;
    blDurationPercentage?: string | null;
    blManualPercentage?: string | null;
    blPhysicalPercentage?: string | null;
    atCompletePhysicalQuantity?: string | null;
    predecessors?: string | null;
    predecessorDetails?: string | null;
    successors?: string | null;
    successorDetails?: string | null;
    actualHRUnit?: string | null;
    actualMaterialUnit?: string | null;
    actualEquipmentUnit?: string | null;
    actualLocationUnit?: string | null;
    atCompleteHRUnit?: string | null;
    atCompleteMaterialUnit?: string | null;
    atCompleteEquipmentUnit?: string | null;
    atCompleteLocationUnit?: string | null;
    budgetHRUnit?: string | null;
    budgetMaterialUnit?: string | null;
    budgetEquipmentUnit?: string | null;
    budgetLocationUnit?: string | null;
    blHRUnit?: string | null;
    blMaterialUnit?: string | null;
    blEquipmentUnit?: string | null;
    blLocationUnit?: string | null;
    remainHRUnit?: string | null;
    remainMaterialUnit?: string | null;
    remainEquipmentUnit?: string | null;
    remainLocationUnit?: string | null;
    $source?: Array<string>;
    $target?: Array<string>;
    readonly?: boolean;
}

export interface IGanttChartColumn {
    hide: boolean;
    label: string;
    min_width: number;
    name: string;
    resize: boolean;
    width: number;
}

export interface IGanttChartColumnConfig {
    width: number | string;
    order: number;
}

export interface IPlanningQuery {
    name: string;
    planningFilePath: string;
    projectId: string;
    path: string;
}

export interface IPlanningListQuery extends ICommonGetListQuery {
    orderBy: PlanningOrderBy;
    fileIds?: string[];
    allowSynthesizedPlanning?: boolean;
    projectId?: string;
    path?: string;
}

export type ICreateProjectTaskDto = IUpdateProjectTaskDto;

export interface ICreateLinkDto {
    source: string;
    target: string;
    type: LinkType;
    lag: number;
    projectId?: string;
    path?: string;
    isDrivingRelationship?: boolean;
    freeFloat?: number;
    isOnCriticalPath?: boolean;
}

export type IUpdateLinkDto = ICreateLinkDto;

export interface IBulkUpdateLinkBody {
    items: Array<ITaskLink>;
}

export interface IBulkCreateLinkBody {
    items: ICreateLinkDto[];
    projectId: string;
    path: string;
}

export interface ILinkListGetByTaskIdResponse {
    predecessors: ILinkDetail[];
    successors: ILinkDetail[];
}

export interface IUpdateProjectTaskDto {
    ganttId: string | null;
    name: string;
    taskType: TaskType;
    status: TaskStatus;
    start: Date | string | null;
    actualStart: Date | string | null;
    plannedStart: Date | string | null;
    finish: Date | string | null;
    actualFinish: Date | string | null;
    plannedFinish: Date | string | null;

    lateStart?: string | null;
    lateFinish?: string | null;
    earlyStart?: string | null;
    earlyFinish?: string | null;

    primaryConstraints: TaskConstraint | null;
    primaryConstraintDate: Date | string | null;
    expectedFinish: Date | string | null;
    durationType: TaskDuration;
    originalDuration: number | null;
    actualDuration: number | null;
    remainingDuration: number | null;
    plannedDuration: number | null;
    percentageComplete: TaskPercentageComplete;
    manualComplete: number | null;
    physicalQuantityUnit: TaskPhysicalQuantityUnit | null;
    physicalQuantity: number | null;
    actualPhysicalQuantity: number | null;
    remainingPhysicalQuantity?: number | null;
    atCompletePhysicalQuantity?: number | null;
    rules?: string | null;
    parentId: string | null;
    parentGanttId: string | null;

    additionalFields?: Record<string, unknown>;
    appearanceProfileId?: string | null;

    calendarId?: string;
    projectId?: string;
    path?: string;
    durationFormat?: TaskDurationFormat;

    freeFloat?: number | null;
    totalFloat?: number | null;
    critical?: boolean;
    isOnCriticalPath?: boolean;
    physicalPercentage?: number | null;
    durationPercentage?: number | null;
    atCompleteDuration?: number | null;

    estimatedRate?: number | null;

    //baseline
    baselineStart: Date | string | null;
    baselineFinish: Date | string | null;
    blDuration?: number | null;
    blActualDuration?: number | null;
    blRemainDuration?: number | null;
    blFreeFloat?: number | null;
    blTotalFloat?: number | null;
    VBLDuration?: number | null;
    VBLStartDate?: number | null;
    VBLFinishDate?: number | null;
    blDurationPercentage?: number | null;
    blManualPercentage?: number | null;
    blPhysicalPercentage?: number | null;
    blHRCost?: number | null;
    blMaterialCost?: number | null;
    blEquipmentCost?: number | null;
    blLocationCost?: number | null;
    blTotalCost?: number | null;
    blFixedCost?: number | null;
    blMaterialUnit?: number | null;
    blEquipmentUnit?: number | null;
    blLocationUnit?: number | null;
}

export interface IDelegateTaskBody {
    name: string;
    planningFilePath: string;
    taskIds: string[];
    projectId: string;
    path: string;
}

export interface IDelegateTaskToExistDelegationBody extends IDelegateOption {
    taskIds: string[];
    projectId: string;
    path: string;
}

export interface IDelegateResponse {
    // includes new milestones and tasks has update "delegateTo"
    tasks: IProjectTask[];
    links: ITaskLink[];
    // for delegate to new planning
    delegation?: IPlanning;
}

export interface IBaselineTaskBody {
    name: string;
    planningId: string;
    projectId: string;
    path: string;
}

export interface IBaselineTask {
    taskId: string;
    baselineStart: Date;
    baselineFinish: Date;
    baselineHumanResourceCost?: number;
    baselineMaterialCost?: number;
    baselineEquipmentCost?: number;
    baselineLocationCost?: number;
    baselineTotalCost?: number;
    baselineFixedCost?: number;
    baselineActualDuration?: number;
    baselineRemainingDuration?: number;
    baselineDuration?: number;
    baselineFreeFloat?: number;
    baselineTotalFloat?: number;
    baselineHumanResourceUnit?: number;
    baselineMaterialUnit?: number;
    baselineEquipmentUnit?: number;
    baselineLocationUnit?: number;
    baselinePhysicalPercentage?: number | null;
    baselineDurationPercentage?: number | null;
    baselineManualPercentage?: number | null;
    varianceBLDuration?: number;
    varianceBLStart?: number;
    varianceBLFinish?: number;
}
export interface IBaselinePlanning {
    _id: string;
    name: string;
    planningId: string;
    baselineTasks: IBaselineTask[];
}

export interface IBaselinePlanningListQuery extends ICommonGetListQuery {
    planningId?: string;
}

export interface IBaselineSetting {
    _id?: string;
    display: boolean;
    color: string;
    position: string;
}

export interface IColumnSetting {
    _id?: string; // TODO: when is this not available?
    width: number;
    order: number;
    display: boolean;
    name: string;
}

export interface IColumnSetting2 {
    name: string;
    type: ColumnType;
    width: number;
}

export interface ISynthesisPlanningBody {
    name: string;
    planningIds: string[];
    planningFilePath: string;
    projectId: string;
}

export interface IResourceWorkloadAndCapacity {
    effectiveDate: Date | null | undefined;
    unitPerPeriod: number | null | undefined;
    pricePerUnit: number | null | undefined;
}

export interface IResource {
    _id?: string;
    resourceId?: string;
    name: string;
    type: ResourceType;
    defaultUnitPerPeriod?: number;
    unit: string;
    planningId?: string;
    fileIds: string[];
    projectId: string;
    description?: string;
    parentId?: string | null;
    calendar: string | null;
    workloadAndCapacity: IResourceWorkloadAndCapacity[];
    assignToExistingResourceOption?: AssignToExistingResourceOption;
    sessionToken?: string;
    resourceCosts?: any;
    additionalFields?: Record<string, string | number | boolean | Date>;
}

export interface IResourceGroup {
    resourceGroupId?: string;
    _id?: string;
    name: string;
    resourceIds: string[];
    planningId: string;
    projectId: string;
    description?: string;
    resources?: any;
    additionalFields?: Record<string, string | number | boolean | Date>;
}

export interface IPlanningFileLocation {
    name: string;
    path: string;
}

export type IGanttColumnsDisplayStatus = Partial<{ [key in GanttColumn]: boolean }>;
export interface IAssignResource {
    resourceIds: string[];
    taskIds: string[];
    appearanceProfileId: string;
    projectId: string;
    resourceCosts?: any[];
}

export interface IUnAssignResource {
    resourceIds: string[];
    taskIds: string[];
    projectId?: string;
}

export interface IAssignResourceGroup {
    resourceGroupIds: string[];
    taskIds: string[];
    projectId?: string;
}
export interface IResourceListQuery extends ICommonGetListQuery {
    planningId?: string;
    projectId: string;
    isGlobalOnly?: boolean;
}

export enum TaskActions {
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
}

export interface ITaskFieldParams {
    open: boolean;
    selectedField: IAdditionalTaskField | null;
    action: null | TaskActions;
    type?: UserFieldTypeEnum;
}
export interface IGetTaskByIdsQueryString {
    taskIds: string[];
    projectId: string;
    path: string;
}

export interface IBulkUpdateTask extends IUpdateProjectTaskDto {
    taskId: string;
}

export interface IBulkUpdateTaskBody {
    items: IBulkUpdateTask[];
    projectId: string;
    path: string;
}

export interface IAppearanceProfile {
    _id?: string;
    name: string;
    type: AppearanceType;
    growthSimulation: GrowthSimulation;
    assignFileIds?: string[];
    startAppearanceProfile?: IStartAppearanceProfile;
    activeAppearanceProfile?: IActiveAppearanceProfile;
    endAppearanceProfile?: IEndAppearanceProfile;
    projectId?: string;
    planningId?: string;
}

export interface IUpdateAppearanceProfile extends IAppearanceProfile {
    notAssignFileIds?: string[];
}

export interface IAppearanceProfileListQuery extends ICommonGetListQuery {
    planningId?: string;
    projectId?: string;
}

export interface IFilePathsByFocusTimeQuery {
    sessionToken: string;
    planningId: string;
    focusTime: string;
    projectId: string;
    path?: string;
}

export interface IActivityCodeListItem {
    _id: string;
    name: string;
    maxLength: number;
    projectId?: string;
    activityCodeValues: IActivityCodeValueItem[] | [];
    parentActivityCodeId?: string;
    planningId?: string;
}

export interface IActivityCodeValueItem {
    _id?: string;
    name: string;
    description: string;
    colorCode: string;
    activityCodeId: string;
    parentId: string | null;
    projectId?: string;
    planningId?: string;
}

export interface IActivityCode {
    _id?: string;
    name: string;
    maxLength: number;
    projectId?: string;
    planningId?: string;
}

export interface IActivityCodeColumns {
    activityCode: string | null;
    activityCodeValue: string | null;
    activityCodeValueColor?: string;
}

export interface IPlanningGetListQuery extends ICommonGetListQuery {
    projectId: string;
    path: string;
}

export interface IAssignActivityCodeValue {
    taskIds: string[];
    assignedActivityCodeValueIds: string[] | null;
    projectId: string;
}

export interface IAssignCalendar {
    calendarId: string;
    projectId: string;
    taskIds: string[];
}

export interface ITopDownResponse {
    taskToBeInsertedInThisPlanning: IProjectTask[];
}

export interface ITaskUpdateDelegation {
    _id: string;
    name: string;
    ganttId: string;
    planningId: string;
    startModified: Date | string;
    finishModified: Date | string;
}

export interface IMilestoneUpdateOriginalPlanning {
    _id: string;
    // task linked in synthesis planning
    milestoneLinkTo: MilestoneLinkTo | null;
    linkedLinkId: string;
    linkedTaskId: string;
    startModified: Date | string | null;
    finishModified: Date | string | null;
}

export type ITaskUpdateOriginalPlanning = ITaskUpdateDelegation;

export interface IDelegationHasModifyResponse extends IPlanning {
    tasksHasModify: IProjectTask[];
}

export interface IExportPlanningQuery {
    projectId: string;
    name: string;
    planningFilePath: string;
    socketClientId: string;
}

export interface IPlanningByPathNameQuery {
    projectId: string;
    name: string;
    planningFilePath: string;
    path: string;
}

export interface IResourceIdQueryString {
    resourceId: string;
    projectId: string;
    path: string;
}

export interface ITaskDragUpdate {
    originalDuration: number | null;
    actualDuration: number | null;
    remainingDuration: number | null;
    plannedDuration: number | null;
    finish: Date | string | null;
    actualFinish: Date | string | null;
    plannedFinish: Date | string | null;
    start: Date | string | null;
    actualStart: Date | string | null;
    plannedStart: Date | string | null;
}

export interface IExportPlanningToPrimaveraP6 {
    projectId: string;
    planningId: string;
    planningName: string;
    selectedObjects: string[];
    fileName: string;
    savePath: string;
    taskIds: string[] | null;
}

export interface IImportPlanningFromABS {
    projectId: string;
    parentTaskId: string;
    fileId: string;
    importObjects: string[];
    isReplace: boolean;
}

export interface ILinkDetail {
    id?: string;
    dependency: string;
    taskLinkToId: string;
    taskLinkToGanttId: string;
    taskLinkToName: string;
    taskLinkToStart: Date | null;
    taskLinkToFinish: Date | null;
    type: LinkType;
    taskLag: number;
}

export interface IStartAppearanceProfile {
    colorType: AppearanceOptions;
    colorValue: string;
    transparencyType: AppearanceOptions;
    transparencyValue: number;
}
export interface IEndAppearanceProfile {
    colorType: AppearanceOptions;
    colorValue: string;
    transparencyType: AppearanceOptions;
    transparencyValue: number;
}

export interface IActiveAppearanceProfile {
    colorType: AppearanceOptions;
    colorValue: string;
    transparencyType: AppearanceOptions;
    transparencyStartValue: number;
    transparencyInterpolation: boolean;
    transparencyEndValue: number;
}

export interface ISearchTask {
    searchValue: string;
    searchColumn: GanttColumn;
    searchType: SearchTaskOption;
}
export interface IUpdateTaskName {
    name?: string;
}

export interface IGetTaskFieldListQueryString extends ICommonGetListQuery {
    projectId?: string;
    planningId?: string;
    type?: UserFieldTypeEnum;
}

export interface IAttributeNodeDetail {
    attributeName: string;
    isDuplicate: boolean;
}

export interface IResponseGetAttributeFile {
    attributeNodes: IAttributeNodeDetail[];
}

export interface IDeleteTaskBody {
    projectId: string;
    path: string;
    taskIds: string[];
}

export interface IDeleteTaskResponse {
    deletedTaskIds: string[];
}

export interface IImportProjectXml {
    p6Id: string;
    id: string;
    name: string;
    dataDate: Date;
}

export interface IImportProjectExcel {
    p6Id: string;
    name: string;
    dataDate: Date;
}

export interface IImportSummary {
    activityCode?: { created: number; updated: number };
    activityCodeValue?: { created: number; updated: number };
    calendar?: { created: number; updated: number };
    link?: { created: number; updated: number };
    resource?: { created: number; updated: number };
    task?: { created: number; updated: number };
    userField?: { created: number; updated: number };
}

export interface IImportExcelSummary {
    activityCode?: { created: number; updated: number; deleted: number };
    activityCodeValue?: { created: number; updated: number; deleted: number };
    calendar?: { created: number; updated: number; deleted: number };
    link?: { created: number; updated: number; deleted: number };
    resource?: { created: number; updated: number; deleted: number };
    task?: { created: number; updated: number; deleted: number };
    userField?: { created: number; updated: number; deleted: number };
}

export interface IExportSummary {
    activityCode?: number;
    activityCodeValue?: number;
    calendar?: number;
    link?: number;
    resource?: number;
    task?: number;
    userField?: number;
    resourceGroup?: number;
    resourceAssignment?: number;
    resourceGroupAssignment?: number;
}

export interface IPasteOption {
    activityCodeValue: boolean;
    userField: boolean;
    resource: boolean;
    allRelationShips: boolean;
    relationshipsBetweenTasks: boolean;
}

export interface ISelectedFilterColumn {
    columnType: ColumnType;
    columnName: string;
}

export interface ITaskRuleList {
    createdBy?: string;
    description?: string;
    name?: string;
    period?: ResourceOtherUnitType;
    planningId?: string;
    projectId?: string;
    rate?: number;
    ruleId?: string;
    unit?: ResourceMaterialUnitType;
    _id: string;
}

export interface IGanttFilter {
    logic: GanttFilterLogic;
    operation: GanttFilterOperation;
    value: string | number | Date;
    _id?: string;
}

export interface IColumnFilter {
    _id?: string;
    name: string;
    columnName: string;
    columnType: ColumnType;
    configs: IGanttFilter[];
    isFromSavedFilter?: boolean;
    selected?: boolean;
}

export interface IConnectedGroup {
    links: Array<string>;
    tasks: Array<string>;
}

export interface IDelegateOption {
    delegateType: DelegateType;
    selectedDelegateTaskIds: string[];
    selectedStartTaskToGenerateMilestoneId: string | null;
    selectedFinishTaskToGenerateMilestoneId: string | null;
}
export interface IDrivingTask {
    link: string;
    target: string;
    freeFloat: number;
    isDrivingRelationship?: boolean;
}

export interface IUpdateResourceAssignment {
    resource: {
        id?: string;
        resourceId?: string;
        resourceName?: string;
        isDriving?: boolean;
        isFixedUnit?: boolean;
        actualUnitPerPeriod?: number;
        budgetedUnit?: number;
        remainingUnit?: number;
        actualUnit?: number;
        atCompletionUnit?: number;
        resourceAccount?: number;
        budgetedCost?: number;
        remainingCost?: number;
        actualCost?: number;
        atCompletionCost?: number;
        fixedCost?: number;
    };
    task?: IUpdateResourceAssignmentTask;
}

export interface IUpdateResourceAssignmentTask {
    plannedDuration?: number | null;
    remainingDuration?: number | null;
    originalDuration?: number | null;
    actualDuration?: number | null;
    physicalQuantity?: number | null;
    start?: Date | null;
    finish?: Date | null;
    plannedStart?: Date | null;
    plannedFinish?: Date | null;
    actualStart?: Date | null;
    actualFinish?: Date | null;
}

export interface RemainingDurationOfInProgressTasks {
    remainingDuration: number;
    startDate: Date;
    backupStartDate: Date;
    endDate: Date;
}

export interface GroupConstraint {
    constraintType: TaskConstraint;
    constraintDate: Date;
}

export interface IBar {
    barType: Bars;
    order: number;
}

export interface ICircularLink {
    links: Array<string>;
    tasks: Array<string>;
}

// When columnType is null, columnName is also null, and is treated as default
// group criterion (WBS)
export type IGroupBy = { columnName: string | null; columnType: ColumnType | null };
