import { computed, ref } from 'vue';
import { GanttStatic } from 'dhtmlx-gantt';
import { ElLoading, UploadFile, UploadFiles } from 'element-plus';
import lodash, { sortBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import localStorageAuthService from '@/common/authStorage';
import {
    ACTION,
    DATE_TIME_FORMAT,
    DEFAULT_FIRST_PAGE,
    HttpStatus,
    LIMIT_FOR_DROPDOWN,
    SidebarWidth,
    defaultWorkingDay,
} from '@/common/constants';
import ganttChartStorage from '@/common/ganttChartStorage';
import {
    getDaysBetweenDates,
    showConfirmPopUpFunction,
    showErrorNotificationFunction,
    showSuccessNotificationFunction,
} from '@/common/helpers';
import { ProjectSecurityPermissions } from '@/features/3D-viewer-profile/constants';
import { ABSUploadedFileExtensions } from '@/features/abs/constants';
import { IStartTimeEnds } from '@/features/calendar/interfaces';
import { calendarModule } from '@/features/calendar/store';
import { commonModule } from '@/features/common/common.store';
import { projectModule } from '@/features/project/store';
import {
    DefaultDurationTaskInHour,
    DefaultNameTask,
    GanttColumn,
    GanttFilterLogic,
    GanttFilterOperation,
    LinkFieldName,
    LinkType,
    MilestoneType,
    SearchTaskOption,
    TaskDuration,
    TaskDurationFormat,
    TaskPercentageComplete,
    TaskStatus,
    TaskType,
    defaultWorkTimeBlocks,
} from '../constants';
import {
    calcDateIgnoreNonWorkingTime,
    calculateDateWithoutConvert,
    calculateEndDateToEndOfDay,
    calculateStartDateToStartOfDay,
    convertDurationFormat,
    convertGanttTaskToTaskDto,
    convertToGanttTask,
    extractColumnWithType,
    getBaselineTask,
    getVisibleColumnConfiguration,
} from '../helper';
import {
    GroupConstraint,
    IBaselinePlanning,
    IBaselineTask,
    IBulkUpdateTask,
    ICircularLink,
    IColumnSetting,
    IConnectedGroup,
    ICreateProjectTaskDto,
    IGanttChartColumn,
    IGanttChartTask,
    IGanttFilter,
    IGanttView,
    IGroupBy,
    IMilestoneUpdateOriginalPlanning,
    IPlanning,
    IPlanningSetting,
    IProjectTask,
    ITaskLink,
    ITaskUpdateOriginalPlanning,
    RemainingDurationOfInProgressTasks,
} from '../interfaces';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';
import {
    Bars,
    BarsConfig,
    CLASS_DRIVING_RELATIONSHIP,
    CLASS_HIDE_RELATIONSHIP_LINE,
    CLASS_LINK_ON_CRITICAL_PATH,
    CLASS_NON_DRIVING_RELATIONSHIP,
    CalendarForDelayLinkOption,
    ColumnType,
    DEFAULT_CRITICAL_THRESHOLD,
    DefaultMaxDurationComplete,
    DefaultZeroLinkLag,
    FINISH_TO_FINISH,
    FINISH_TO_START,
    IS_NUMBER_DRIVING_RELATIONSHIP,
    MAP_COLUMN_NAME_TO_VALUE_TYPE,
    MAX_BARS_COUNT,
    MAX_TIMESTAMP,
    PARSE_DATA_TO_GANTT_DELAY,
    START_TO_FINISH,
    START_TO_START,
    TaskConstraint,
    TaskFieldDataType,
    UserFieldTypeEnum,
    VIEW_COLUMN_TYPE_SEPARATOR,
    defaultWeekDays,
} from './../constants';
import { webViewer3DModule } from '@/features/3D-viewer/store';

export const useGantt = (gantt: GanttStatic) => {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const planningId = computed(() => {
        return projectPlanningModule.planning?._id;
    });
    const searchValue = ref<string>('');
    const searchColumn = ref<GanttColumn>(GanttColumn.NAME);
    const searchType = ref<SearchTaskOption>(SearchTaskOption.APPROPRIATE);
    const disableScheduleTaskWithoutLinkAndConstraints = computed(() => {
        return !!projectPlanningModule.planning
            ?.disableScheduleTaskWithoutLinkAndConstraints;
    });
    const autoScheduling = computed(() => {
        return !!projectPlanningModule.planning?.autoScheduling;
    });
    const criticalTaskWithoutSuccessor = computed(() => {
        return !!projectPlanningModule.planning?.criticalTaskWithoutSuccessor;
    });
    const currentMarkerFocusTimeId = ref<string | null>(null);
    let taskIdHasChangeList: string[] = [];
    //
    let onMouseMoveEventId = '';
    let eventInitialized = false;
    let gridWidthWithoutScrollbar = ganttChartStorage.getGridWidth() || 500;
    // add links for render, block call api to create link
    let addingLinksForRender = false;
    // add milestone after delegate for render, block bulkUpdateTask
    let addingMilestoneAfterDelegate = false;
    let needMoreRescheduling = false;
    let intervalSimulation: any = null;

    const shouldAutoScheduling = () => {
        if (autoScheduling.value) {
            const cycles = handleCircularLinks();
            if (cycles.length) {
                const convertCycles = convertCircularLinks(cycles);
                projectPlanningModule.setCircularLinks(convertCycles);
                projectPlanningModule.setIsShowCircularLinkPopup(true);
            } else {
                configAutoScheduling(true);
                reschedulingGantt();
            }
        }
    };

    const getPlanning = async () => {
        const { name, planningFilePath } = route.query as {
            name?: string;
            planningFilePath?: string;
        };
        const projectId = localStorageAuthService.getSelectedProjectId();
        const path =
            `${route.query?.planningFilePath}/${route.query?.name}${ABSUploadedFileExtensions.PLANNING}`.replace(
                '//',
                '/',
            );

        if (!name || !planningFilePath || !projectId) {
            router.push({
                path: '/404',
            });
            return;
        }
        const loading = ElLoading.service({});
        const planningResponse = await projectPlanningModule.getPlanning({
            projectId,
            query: {
                name,
                planningFilePath,
                path,
                projectId,
            },
        });

        if (planningResponse.success) {
            const planning = planningResponse.data;

            const planningId = planning._id;
            Promise.all([
                projectPlanningModule.getTaskFieldList({
                    planningId,
                    projectId,
                    type: UserFieldTypeEnum.TASK,
                }),
                projectPlanningModule.getResourceFieldList({
                    planningId,
                    projectId,
                    type: UserFieldTypeEnum.RESOURCE,
                }),
                projectPlanningModule.getResourceGroupFieldList({
                    planningId,
                    projectId,
                    type: UserFieldTypeEnum.RESOURCE_GROUP,
                }),
                projectPlanningModule.getResourceView(planningId),
            ]);

            const taskRules = await projectPlanningService.getListTaskRule({
                projectId: projectModule.selectedProjectId,
                planningId: projectPlanningModule.planning?._id,
            });

            if (taskRules.success) {
                projectPlanningModule?.setTaskRuleListAssignment(taskRules.data.items);
            }

            loading.close();

            projectPlanningModule.setPlanningId(planning._id);
            if (planning.appliedBaselineId) {
                projectPlanningModule.setBaselinePlanningSelected(
                    planning.appliedBaselineId,
                );
            }
            await projectPlanningModule.getActivityCodeList();
            await projectPlanningModule.getRootTaskHasPermissionCreateChild();
            projectPlanningModule.setIsDisableButtonAdd(!planning.isTemplate || false);
            await calendarModule.getCalendarList({
                projectId,
                limit: LIMIT_FOR_DROPDOWN,
                page: DEFAULT_FIRST_PAGE,
                planningId: projectPlanningModule.planning?._id,
            });
        } else if (
            !planningResponse.success &&
            [HttpStatus.ITEM_NOT_FOUND, HttpStatus.BAD_REQUEST].includes(
                planningResponse.code,
            )
        ) {
            router.push({
                path: '/404',
            });
            loading.close();
            return;
        }
        loading.close();
        return planningResponse;
    };

    const getLinkType = (numericValue: number): LinkType => {
        const keys = Object.keys(LinkType);
        let result = LinkType.START_TO_FINISH;
        keys.forEach((key) => {
            const enumValue = LinkType[key as keyof typeof LinkType];
            if (Number(gantt.config[enumValue]) === numericValue) {
                result = enumValue;
                return result;
            }
        });

        return result;
    };

    const getLinkKeyFromNumericType = (type: LinkType): number => {
        return gantt.config?.[type];
    };

    const handleMarkerMove = (gridWidth?: number) => {
        if (onMouseMoveEventId) {
            gantt.detachEvent(onMouseMoveEventId);
        }
        onMouseMoveEventId = gantt.attachEvent(
            'onMouseMove',
            function (id, e) {
                if (!currentMarkerFocusTimeId.value) {
                    return true;
                }
                const marker = gantt.getMarker(currentMarkerFocusTimeId.value);

                const sidebarWidth = commonModule.openSidebar
                    ? +SidebarWidth.expand.split('px')[0] + 20
                    : +SidebarWidth.collapse.split('px')[0] + 20;
                const mousePosition =
                    e.clientX -
                    (gridWidth ? gridWidth : gridWidthWithoutScrollbar) -
                    sidebarWidth;
                const rightDate = gantt.dateFromPos(
                    gantt.getScrollState().x + (gantt as any).$task.offsetWidth,
                );
                const maxDate = gantt.getState().max_date;

                if (
                    moment(rightDate).diff(moment(maxDate), 'days') &&
                    mousePosition > (gantt as any).$task.offsetWidth - 80
                ) {
                    gantt.scrollTo(gantt.getScrollState().x + 20, null);
                }

                const mouseDate = gantt.dateFromPos(
                    mousePosition + gantt.getScrollState().x,
                );

                if (mouseDate && marker) {
                    marker.start_date = mouseDate;
                    marker.text = moment(mouseDate).fmDHTMLXString();
                    gantt.updateMarker(currentMarkerFocusTimeId.value);
                }
                return true;
            },
            null,
        );
    };

    const hanldeRunAutoSimulation = (
        startDate: Date,
        endDate: Date,
        speed: string,
        delay: number,
    ) => {
        const parseSpeed = speed.split(' ');

        let currentDate = moment(startDate);

        if (!currentMarkerFocusTimeId.value) {
            return true;
        }

        intervalSimulation = setInterval(async () => {
            if (currentDate.isAfter(endDate)) {
                clearInterval(intervalSimulation);
                intervalSimulation = null;
                // for moving focus time line
                document
                    .querySelector(`[data-marker-id="${currentMarkerFocusTimeId.value}"]`)
                    ?.addEventListener('click', async (e) => {
                        e.stopPropagation();
                        await onFilter();
                    });
                return;
            }

            const marker = gantt.getMarker(currentMarkerFocusTimeId.value ?? '');
            if (marker) {
                marker.start_date = currentDate.toDate();
                marker.text = currentDate.fmDHTMLXString();
                gantt.updateMarker(currentMarkerFocusTimeId.value ?? '');
                if (webViewer3DModule.sessionToken) {
                    const focusTime = moment(marker.start_date).format(
                        DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON,
                    );

                    await projectPlanningService.setFileByFocusTime({
                        focusTime: focusTime,
                        sessionToken: webViewer3DModule.sessionToken as string,
                        planningId: projectPlanningModule.planning?._id || '',
                        projectId: projectModule.selectedProjectId || '',
                        path:
                            localStorageAuthService.getPlanningPermissions()?.path || '',
                    });
                }
            }
            currentDate = currentDate.add(parseSpeed[0] as any, parseSpeed[1]);
        }, delay * 1000);
    };

    const stopMarkerMover = () => {
        if (intervalSimulation) {
            clearInterval(intervalSimulation);
            intervalSimulation = null;
            // for moving focus time line
            document
                .querySelector(`[data-marker-id="${currentMarkerFocusTimeId.value}"]`)
                ?.addEventListener('click', async (e) => {
                    e.stopPropagation();
                    await onFilter();
                });
        }
    };

    const handleFileUpload = (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
        gantt.importFromMSProject({
            data: uploadFile.raw,
            callback: function (project: any) {
                if (project) {
                    gantt.destructor();
                    gantt.init('gantt-chart-4d-planning');
                    initChart(false);
                    if (project.config.duration_unit) {
                        gantt.config.duration_unit = project.config.duration_unit;
                    }

                    gantt.parse(project.data);
                    gantt.render();
                }
            },
        });
    };

    const onFilter = async () => {
        const projectStart = moment(
            projectPlanningModule.planning?.projectStart,
        ).toDate();
        // move to the start time
        gantt.showDate(projectStart);

        projectPlanningModule.setIsMovingFocusTime(true);
        handleMarkerMove();
    };

    const configGanttLabels = () => {
        gantt.locale.labels.section_name = t('planning.gantt.lightbox.labels.name');
        gantt.locale.labels.section_time = t('planning.gantt.lightbox.labels.time');
    };

    const initMakers = () => {
        if (projectPlanningModule?.planning?.view?.gantt?.isShowSimulationLine) {
            // focus time marker
            const markerId = gantt.addMarker({
                start_date: moment(projectPlanningModule.planning.dataDate),
                css: 'status_line',
                text: moment().fmDHTMLXString(),
            });
            currentMarkerFocusTimeId.value = markerId;

            // for moving focus time line
            document
                .querySelector(`[data-marker-id="${currentMarkerFocusTimeId.value}"]`)
                ?.addEventListener('click', async (e) => {
                    e.stopPropagation();
                    await onFilter();
                });
        }

        // data date marker
        if (projectPlanningModule?.planning?.view?.gantt?.isShowDataDateLine) {
            const dataDate = moment(projectPlanningModule.planning?.dataDate).toDate();
            const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
            if (!dataDate) {
                return;
            }

            gantt.config.project_start = dataDate;
            gantt.addMarker({
                start_date: gantt.config.project_start,
                text: t('planning.gantt.marker.dataDate.text'),
                css: 'data_date_line',
                title: dateToStr(dataDate),
            });
        }
    };

    const handleCreateTask = async (newTask: ICreateProjectTaskDto) => {
        const loading = ElLoading.service({});
        const response = await projectPlanningService.createTask(
            projectPlanningModule.planning?._id as string,
            {
                ...newTask,
                path: localStorageAuthService.getPlanningPermissions()?.path || '',
                projectId: projectModule.selectedProjectId || '',
            },
        );
        loading.close();

        if (!response.success) {
            showErrorNotificationFunction(response.message);
            return;
        }

        const task = response.data;
        // update the planning in store
        const oldPlanning = cloneDeep(projectPlanningModule.planning);
        const parentTask = oldPlanning?.tasks.find((_task) => {
            return _task.ganttId === task.parentGanttId;
        });
        if (task && parentTask && oldPlanning) {
            parentTask.taskType = TaskType.WBS_SUMMARY;
            projectPlanningModule.setPlanning({
                ...oldPlanning,
                tasks: [...oldPlanning?.tasks, task],
            });
        }

        // update the gantt chart
        const baselineTask = getBaselineTask(task._id);
        const responseTask = convertToGanttTask(
            gantt,
            task,
            projectPlanningModule.taskFieldList || [],
            baselineTask,
        );
        const taskId = gantt.addTask(responseTask, responseTask.parent?.toString() || 0);
        showSuccessNotificationFunction(t('planning.task.messages.createdTask'));
        return taskId;
    };

    const checkPermissionToCreateChidTask = (task: IGanttChartTask) => {
        const ganttIdsRootTaskHasPermissionCreateChild =
            projectPlanningModule.rootTaskHasPermissionCreateChild?.map((rootTask) => {
                return rootTask.ganttId;
            });
        // can't add chid-task for milestone
        if (task.type === TaskType.MILESTONE) {
            return false;
        }
        if (projectPlanningModule.planning?.isTemplate) {
            return true;
        }

        while (task.parentGanttId) {
            if (ganttIdsRootTaskHasPermissionCreateChild.includes(task.ganttId)) {
                return true;
            } else {
                task = gantt.getTask(task.parentGanttId);
            }
        }
        return false;
    };

    const checkPermissionToAddLink = (taskId: string) => {
        let task = gantt.getTask(taskId);
        const ganttIdsRootTaskHasPermissionCreateChild =
            projectPlanningModule.rootTaskHasPermissionCreateChild?.map((rootTask) => {
                return rootTask.ganttId;
            });

        // can't add link with  WBS_SUMMARY or milestone render by BE
        if (task.type === TaskType.PROJECT || task.milestoneType) {
            return false;
        }

        if (projectPlanningModule.planning?.isTemplate) {
            return true;
        }

        while (task.parentGanttId) {
            if (ganttIdsRootTaskHasPermissionCreateChild.includes(task.ganttId)) {
                return true;
            } else {
                task = gantt.getTask(task.parentGanttId);
            }
        }
        return false;
    };

    const checkPermissionToCurrentLink = (taskId: string) => {
        const task = gantt.getTask(taskId);

        if (task.type === TaskType.PROJECT || task.isRootWbs) {
            return false;
        }
        return true;
    };

    const calculateMaxFinishOfPlanningSingle = (): Date | null => {
        let maxFinishDate: Date | null = null;
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (
                !task.isRootWbs &&
                taskInGantt?.end_date &&
                (!maxFinishDate ||
                    new Date(taskInGantt.end_date).getTime() >
                        new Date(maxFinishDate).getTime())
            ) {
                maxFinishDate = taskInGantt.end_date as Date;
            }
        });
        return maxFinishDate;
    };

    const calculateMaxFinishOfPlanning = (dataDate: Date, backupLink: any[]) => {
        //

        const calcWorkingDay = (day: Date, calendar: any) => {
            const dayOfWeek = moment(day).weekday();
            const currentTimeWorking = moment(day);

            if (!calendar?.dates[dayOfWeek]) {
                return false;
            }

            const calendarHoursWorking = calendar?.hours[0]?.toString().split('-');

            const startTime = moment(day)
                .startOf('day')
                .add(moment.duration(calendarHoursWorking[0]));
            const endTime = moment(day)
                .startOf('day')
                .add(moment.duration(calendarHoursWorking[1]));

            if (!currentTimeWorking.isBetween(startTime, endTime, null, '[]')) {
                return false;
            }

            return true;
        };

        const taskIdConstraints = new Map<
            string,
            {
                startDate: Date;
                finishDate: Date;
            } & GroupConstraint
        >();
        const remainingDurationOfInProgressTasks = new Map<
            string,
            RemainingDurationOfInProgressTasks
        >();
        const scheduleFromEnd = gantt.config.schedule_from_end;
        const projectStart = gantt.config.project_start;
        const taskFinishOrStartOn: Array<string> = [];
        const predeTaskOfInprogressAndFinished = new Map<string, GroupConstraint>();
        const inProgressTasks: Array<string> = [];

        gantt.config.auto_scheduling = false;

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (taskInGantt?.constraint_date) {
                const calendar = gantt.getCalendar(task?.calendarId || '');

                const isWorkDay = calcWorkingDay(
                    taskInGantt?.constraint_date,
                    calendar._worktime,
                );

                let newConstraintDate = taskInGantt?.constraint_date;
                if (!isWorkDay) {
                    newConstraintDate = calculateEndDateToEndOfDay(
                        moment(taskInGantt?.constraint_date).startOf('day').toDate(),
                        calendar,
                    );

                    if (taskInGantt.taskType === TaskType.FINISH_MILESTONE) {
                        newConstraintDate = calculateEndDateToEndOfDay(
                            moment(taskInGantt?.constraint_date).endOf('day').toDate(),
                            calendar,
                        );
                    }
                } else {
                    newConstraintDate = taskInGantt?.constraint_date;

                    if (taskInGantt.taskType === TaskType.FINISH_MILESTONE) {
                        newConstraintDate = calculateEndDateToEndOfDay(
                            taskInGantt?.constraint_date,
                            calendar,
                        );
                    }
                }

                if (
                    moment(taskInGantt.constraint_date).diff(
                        newConstraintDate,
                        'millisecond',
                    )
                ) {
                    (taskInGantt as any).constraintDateWork = newConstraintDate;
                    taskInGantt.constraint_date = newConstraintDate;
                }
            }

            const assignConstraint = () => {
                taskIdConstraints.set(taskInGantt.ganttId, {
                    startDate: taskInGantt?.start_date as Date,
                    finishDate: taskInGantt?.end_date as Date,
                    constraintType: taskInGantt?.constraint_type as TaskConstraint,
                    constraintDate: taskInGantt?.constraint_date as Date,
                });
            };

            if (taskInGantt?.taskType === TaskType.WBS_SUMMARY) {
                return;
            }

            if (taskInGantt?.taskType === TaskType.LEVEL_EFFORT) {
                taskInGantt.auto_scheduling = false;
                (taskInGantt as any).should_not_auto_scheduling = true;
                deleteLinkInGanttWithTask(cloneDeep(taskInGantt), backupLink);
                return;
            }

            if (taskInGantt?.taskType === TaskType.FINISH_MILESTONE) {
                (taskInGantt.start_date as Date).setTime(new Date(dataDate).getTime());
                (taskInGantt.end_date as Date).setTime(new Date(dataDate).getTime());
            }

            if (taskInGantt.status === TaskStatus.FINISHED) {
                taskInGantt.constraint_type = TaskConstraint.MSO;
                taskInGantt.constraint_date = taskInGantt?.start_date as Date;
                deleteLinkInGanttWithTaskFinished(
                    cloneDeep(taskInGantt),
                    backupLink,
                    predeTaskOfInprogressAndFinished,
                    taskIdConstraints,
                );
                taskInGantt.auto_scheduling = false;
                (taskInGantt as any).should_not_auto_scheduling = true;
            } else if (taskInGantt.status === TaskStatus.IN_PROGRESS) {
                let finishOfRemainingDuration = taskInGantt?.end_date as Date;
                (taskInGantt as any).back_up_start_date = taskInGantt.start_date;
                if (
                    projectPlanningModule.planning?.useExpectedFinish &&
                    taskInGantt.expectedFinish
                ) {
                    finishOfRemainingDuration = taskInGantt.expectedFinish;
                }
                const newRemainingDuration = calculateDuration(
                    new Date(dataDate),
                    new Date(finishOfRemainingDuration),
                    taskInGantt?.calendarId as string,
                    TaskDurationFormat.MINUTE,
                );
                remainingDurationOfInProgressTasks.set(taskInGantt.ganttId, {
                    remainingDuration:
                        newRemainingDuration || (taskInGantt.remainingDuration as number),
                    endDate: taskInGantt?.end_date as Date,
                    startDate: taskInGantt?.start_date as Date,
                    backupStartDate: (taskInGantt as any)?.back_up_start_date,
                });

                if (
                    taskInGantt.constraint_type !== TaskConstraint.FO &&
                    taskInGantt.constraint_type !== TaskConstraint.FNLT &&
                    taskInGantt.constraint_type !== TaskConstraint.ALAP
                ) {
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                }
                if (taskInGantt.constraint_type === TaskConstraint.FO) {
                    taskInGantt.constraint_type = TaskConstraint.FNLT;
                }

                deleteLinkInGanttWithTaskInprogress(
                    cloneDeep(taskInGantt),
                    backupLink,
                    predeTaskOfInprogressAndFinished,
                    taskIdConstraints,
                );
                inProgressTasks.push(taskInGantt.ganttId);

                const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');

                const newStartDate = calendar.calculateEndDate({
                    start_date: new Date(taskInGantt?.end_date as Date),
                    duration: -(
                        newRemainingDuration || (taskInGantt.originalDuration as number)
                    ),
                    unit: TaskDurationFormat.MINUTE,
                });
                (taskInGantt as any).back_up_start_date = taskInGantt.start_date;
                taskInGantt.start_date = newStartDate;
                (taskInGantt as any).back_up_duration = taskInGantt.duration;
                taskInGantt.duration = newRemainingDuration as number;
            } else if (taskInGantt.status === TaskStatus.TODO) {
                if (
                    taskInGantt.constraint_type === TaskConstraint.ALAP ||
                    task.primaryConstraints === TaskConstraint.SNLT ||
                    task.primaryConstraints === TaskConstraint.FNLT
                ) {
                    assignConstraint();
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                } else if (taskInGantt.constraint_type === TaskConstraint.SO) {
                    assignConstraint();
                    taskInGantt.constraint_type = TaskConstraint.MSO;
                    taskFinishOrStartOn.push(taskInGantt.ganttId);
                } else if (taskInGantt.constraint_type === TaskConstraint.FO) {
                    assignConstraint();
                    taskInGantt.constraint_type = TaskConstraint.MFO;
                    taskFinishOrStartOn.push(taskInGantt.ganttId);
                }
            }
        });
        gantt.config.auto_scheduling = true;
        gantt.config.schedule_from_end = false;
        gantt.config.project_start = new Date(dataDate as Date);

        gantt.autoSchedule();

        taskFinishOrStartOn.forEach((taskId) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(taskId);

            if (taskInGantt.$target?.length) {
                taskInGantt.$target.some((linkId) => {
                    const linkInGantt = gantt.getLink(linkId);
                    const taskSourceInGantt: IGanttChartTask = gantt.getTask(
                        linkInGantt.source,
                    );
                    if (
                        moment(taskSourceInGantt.finish).isAfter(taskInGantt.start_date)
                    ) {
                        taskInGantt.constraint_type = TaskConstraint.ASAP;
                        gantt.autoSchedule(taskInGantt.ganttId);
                        return true;
                    }
                });
            }
        });

        let projectEnd: any = dataDate;

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);

            // re-update constraint
            const constraintTask = taskIdConstraints.get(taskInGantt.ganttId);
            if (constraintTask) {
                taskInGantt.constraint_type = constraintTask.constraintType;
                taskInGantt.constraint_date = constraintTask.constraintDate;
                taskInGantt.start_date = constraintTask.startDate;
                taskInGantt.end_date = constraintTask.finishDate;
            }
        });

        //Store contraint_type
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);

            if (taskInGantt.status === TaskStatus.TODO) {
                if (
                    task.primaryConstraints === TaskConstraint.SNLT ||
                    task.primaryConstraints === TaskConstraint.FNLT ||
                    task.isRootWbs
                )
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                else if (
                    task.primaryConstraints === TaskConstraint.SO &&
                    moment(taskInGantt?.start_date).isSameOrBefore(
                        moment(taskInGantt?.constraint_date),
                    )
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MSO;
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = calculateStartDateToStartOfDay(
                        new Date(taskInGantt?.constraint_date as Date),
                        calendar,
                    );
                } else if (
                    task.primaryConstraints === TaskConstraint.FO &&
                    moment(taskInGantt?.end_date).isSameOrBefore(
                        moment(taskInGantt?.constraint_date),
                    )
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MFO;
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = calculateStartDateToStartOfDay(
                        new Date(taskInGantt?.constraint_date as Date),
                        calendar,
                    );
                } else if (
                    taskInGantt.constraint_type === TaskConstraint.MSO &&
                    moment(taskInGantt.constraint_date).isBefore(dataDate)
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = new Date(
                        calculateStartDateToStartOfDay(dataDate, calendar),
                    );
                } else if (
                    taskInGantt.constraint_type === TaskConstraint.MFO &&
                    taskInGantt.constraint_date
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    const newStartDate = calendar.calculateEndDate({
                        start_date: new Date(taskInGantt.constraint_date),
                        duration: -(taskInGantt.originalDuration || 0),
                        unit: TaskDurationFormat.MINUTE,
                    });
                    if (moment(newStartDate).isBefore(dataDate)) {
                        const newFinishDate = calendar.calculateEndDate({
                            start_date: new Date(dataDate),
                            duration: taskInGantt.originalDuration || 0,
                            unit: TaskDurationFormat.MINUTE,
                        });
                        taskInGantt.constraint_date = new Date(newFinishDate);
                    }
                } else if (task.primaryConstraints === TaskConstraint.ALAP) {
                    taskInGantt.constraint_type = TaskConstraint.ALAP;
                } else
                    taskInGantt.constraint_type =
                        task.primaryConstraints || TaskConstraint.ASAP;
            } else if (taskInGantt.status === TaskStatus.IN_PROGRESS) {
                if (task.primaryConstraints === TaskConstraint.ALAP) {
                    taskInGantt.constraint_type = TaskConstraint.ALAP;
                } else {
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                }
            }
        });

        gantt.autoSchedule();

        if (inProgressTasks.length) {
            inProgressTasks.forEach((taskId) => {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);

                const remainingDurationOfTask = remainingDurationOfInProgressTasks.get(
                    taskInGantt.ganttId,
                );

                taskInGantt.duration = (taskInGantt as any).back_up_duration;
                if (
                    moment(remainingDurationOfTask?.startDate).isBefore(dataDate) &&
                    !taskInGantt.isRootWbs
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MSO;
                    taskInGantt.constraint_date =
                        remainingDurationOfTask?.backupStartDate as Date;
                    gantt.autoSchedule(taskInGantt.ganttId);
                }
            });
        }

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (
                task.taskType !== TaskType.WBS_SUMMARY &&
                moment(taskInGantt.start_date).isBefore(moment(dataDate)) &&
                taskInGantt.status === TaskStatus.TODO
            ) {
                handleTaskExceedDataDate(taskInGantt, dataDate);
            }
        });

        gantt.autoSchedule();

        //calc projectEnd
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (
                !task.isRootWbs &&
                task.taskType !== TaskType.WBS_SUMMARY &&
                taskInGantt?.end_date &&
                task.taskType !== TaskType.LEVEL_EFFORT &&
                new Date(taskInGantt.end_date).getTime() >
                    new Date(projectEnd).getTime() &&
                taskInGantt.status !== TaskStatus.FINISHED
            ) {
                projectEnd = taskInGantt.end_date as Date;
            }
        });

        const taskExceededProjectEnd = new Map<string, string>();

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);

            if (
                moment(taskInGantt.end_date).isAfter(moment(projectEnd)) &&
                task.taskType !== TaskType.WBS_SUMMARY
            ) {
                return taskExceededProjectEnd.set(
                    taskInGantt.ganttId,
                    taskInGantt?.constraint_type as string,
                );
            }
        });

        if (inProgressTasks.length) {
            inProgressTasks.forEach((taskId) => {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);

                const newDuration = calculateDuration(
                    new Date(dataDate),
                    new Date(taskInGantt.end_date as Date),
                    taskInGantt?.calendarId as string,
                    TaskDurationFormat.MINUTE,
                );
                taskInGantt.duration = newDuration as number;
                taskInGantt.constraint_date = taskInGantt.primaryConstraintDate
                    ? new Date(taskInGantt.primaryConstraintDate as Date)
                    : undefined;

                if (
                    taskInGantt.constraint_type !== TaskConstraint.FO &&
                    taskInGantt.constraint_type !== TaskConstraint.FNLT &&
                    taskInGantt.constraint_type !== TaskConstraint.ALAP
                ) {
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                }

                gantt.autoSchedule(taskInGantt.ganttId);
            });
        }

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (taskInGantt.status === TaskStatus.TODO) {
                if (task.isRootWbs) taskInGantt.constraint_type = TaskConstraint.ASAP;
                else if (
                    task.primaryConstraints === TaskConstraint.SO &&
                    moment(taskInGantt?.start_date).isSameOrBefore(
                        moment(taskInGantt?.constraint_date),
                    )
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MSO;
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = calculateStartDateToStartOfDay(
                        new Date(taskInGantt?.constraint_date as Date),
                        calendar,
                    );
                } else if (
                    task.primaryConstraints === TaskConstraint.FO &&
                    moment(taskInGantt?.end_date).isSameOrBefore(
                        moment(taskInGantt?.constraint_date),
                    )
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MFO;
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = calculateStartDateToStartOfDay(
                        new Date(taskInGantt?.constraint_date as Date),
                        calendar,
                    );
                } else if (
                    taskInGantt.constraint_type === TaskConstraint.MSO &&
                    moment(taskInGantt.constraint_date).isBefore(dataDate)
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = new Date(
                        calculateStartDateToStartOfDay(dataDate, calendar),
                    );
                } else if (
                    taskInGantt.constraint_type === TaskConstraint.MFO &&
                    taskInGantt.constraint_date
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    const newStartDate = calendar.calculateEndDate({
                        start_date: new Date(taskInGantt.constraint_date),
                        duration: -(taskInGantt.originalDuration || 0),
                        unit: TaskDurationFormat.MINUTE,
                    });
                    if (moment(newStartDate).isBefore(dataDate)) {
                        const newFinishDate = calendar.calculateEndDate({
                            start_date: new Date(dataDate),
                            duration: taskInGantt.originalDuration || 0,
                            unit: TaskDurationFormat.MINUTE,
                        });
                        taskInGantt.constraint_date = new Date(newFinishDate);
                    }
                } else if (task.primaryConstraints === TaskConstraint.ALAP) {
                    taskInGantt.constraint_type = TaskConstraint.ALAP;
                } else if (
                    task.primaryConstraints === TaskConstraint.FNLT &&
                    task.primaryConstraintDate
                ) {
                    taskInGantt.constraint_type = TaskConstraint.FNLT;
                    taskInGantt.constraint_date = new Date(
                        task.primaryConstraintDate || '',
                    );
                } else if (
                    task.primaryConstraints === TaskConstraint.SNLT &&
                    task.primaryConstraintDate
                ) {
                    taskInGantt.constraint_type = TaskConstraint.SNLT;
                    taskInGantt.constraint_date = new Date(
                        task.primaryConstraintDate || '',
                    );
                } else {
                    taskInGantt.constraint_type =
                        task.primaryConstraints || TaskConstraint.ASAP;
                    taskInGantt.constraint_date = taskInGantt.primaryConstraintDate
                        ? new Date(taskInGantt.primaryConstraintDate as Date)
                        : undefined;
                }
            } else if (taskInGantt.status === TaskStatus.IN_PROGRESS) {
                if (
                    (taskInGantt.primaryConstraints === TaskConstraint.FNLT &&
                        task.primaryConstraintDate) ||
                    (taskInGantt.primaryConstraints === TaskConstraint.FO &&
                        task.primaryConstraintDate)
                ) {
                    taskInGantt.constraint_type = TaskConstraint.FNLT;
                    taskInGantt.constraint_date = new Date(
                        task.primaryConstraintDate || '',
                    );
                }
            }
        });

        gantt.autoSchedule();

        gantt.config.schedule_from_end = scheduleFromEnd;
        (gantt.config.project_start as Date | null) = projectStart;
        gantt.config.is_forward = false;

        return {
            projectEnd,
            remainingDurationOfInProgressTasks,
            inProgressTasks,
            predeTaskOfInprogressAndFinished,
            taskExceededProjectEnd,
        };
    };

    const deleteLinkInGanttWithTask = (task: IGanttChartTask, backupLink: Array<any>) => {
        if (task.$source?.length) {
            task.$source.forEach((linkId) => {
                const linkInGantt = gantt.getLink(linkId);
                if (!linkInGantt) return;
                backupLink.push(linkInGantt);
                gantt.deleteLink(linkId);
            });
        }
        if (task.$target?.length) {
            task.$target.forEach((linkId) => {
                const linkInGantt = gantt.getLink(linkId);
                if (!linkInGantt) return;
                backupLink.push(linkInGantt);
                gantt.deleteLink(linkId);
            });
        }
    };

    const deleteLinkInGanttWithTaskFinished = (
        task: IGanttChartTask,
        backupLink: Array<any>,
        predeTaskOfInprogressAndFinished: Map<string, GroupConstraint>,
        taskIdConstraints: Map<
            string,
            {
                startDate: Date;
                finishDate: Date;
            } & GroupConstraint
        >,
    ) => {
        if (task.$source?.length) {
            task.$source.forEach((linkId) => {
                const linkInGantt = gantt.getLink(linkId);
                if (!linkInGantt) return;
                backupLink.push(linkInGantt);
                gantt.deleteLink(linkId);
            });
        }
        if (task.$target?.length) {
            task.$target.forEach((linkId) => {
                const linkInGantt = gantt.getLink(linkId);
                const taskInGantt: IGanttChartTask = gantt.getTask(linkInGantt?.source);
                if (!linkInGantt || !taskInGantt) return;

                backupLink.push(linkInGantt);
                gantt.deleteLink(linkId);

                const constraintOfTask = taskIdConstraints.get(taskInGantt.ganttId);
                if (!taskInGantt?.$source?.length) {
                    predeTaskOfInprogressAndFinished.set(taskInGantt.ganttId, {
                        constraintType:
                            constraintOfTask?.constraintType ??
                            (taskInGantt?.constraint_type as TaskConstraint),
                        constraintDate:
                            constraintOfTask?.constraintDate ??
                            (taskInGantt.constraint_date as Date),
                    });
                }
                (taskInGantt as any).taskTodoAfterTaskInprogress = true;
            });
        }
    };

    const deleteLinkInGanttWithTaskInprogress = (
        task: IGanttChartTask,
        backupLink: Array<any>,
        predeTaskOfInprogressAndFinished: Map<string, GroupConstraint>,
        taskIdConstraints: Map<
            string,
            {
                startDate: Date;
                finishDate: Date;
            } & GroupConstraint
        >,
    ) => {
        if (task.$target?.length) {
            task.$target.forEach((linkId) => {
                const linkInGantt = gantt.getLink(linkId);
                const taskInGantt: IGanttChartTask = gantt.getTask(linkInGantt?.source);
                if (!linkInGantt || !taskInGantt) return;

                backupLink.push(linkInGantt);
                gantt.deleteLink(linkId);

                if (!taskInGantt?.$source?.length) {
                    const constraintOfTask = taskIdConstraints.get(taskInGantt.ganttId);
                    predeTaskOfInprogressAndFinished.set(taskInGantt.ganttId, {
                        constraintType:
                            constraintOfTask?.constraintType ??
                            (taskInGantt?.constraint_type as TaskConstraint),
                        constraintDate:
                            constraintOfTask?.constraintDate ??
                            (taskInGantt.constraint_date as Date),
                    });
                }
                (taskInGantt as any).taskTodoAfterTaskInprogress = true;
            });
        }
    };

    const beforeAutoSchedulingBackward = async (dataDate: Date) => {
        gantt.config.is_forward = false;
        const backupLink: Array<any> = [];
        const {
            projectEnd,
            remainingDurationOfInProgressTasks,
            inProgressTasks,
            predeTaskOfInprogressAndFinished,
            taskExceededProjectEnd,
        } = calculateMaxFinishOfPlanning(dataDate, backupLink);
        const taskConstraintALAP: Array<string> = [];

        // revert constraint
        projectPlanningModule.planning?.tasks?.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);

            if (taskInGantt.constraint_type === TaskConstraint.ASAP && !task.isRootWbs) {
                if (disableScheduleTaskWithoutLinkAndConstraints.value) {
                    // disable schedule task without link and constraints
                    const connectedGroupTask: IConnectedGroup = gantt.getConnectedGroup(
                        taskInGantt.ganttId,
                    );
                    if (connectedGroupTask.links.length === 0)
                        taskInGantt.auto_scheduling = false;
                    else taskInGantt.constraint_type = TaskConstraint.ALAP;
                } else {
                    // normal
                    taskInGantt.constraint_type = TaskConstraint.ALAP;
                    taskInGantt.auto_scheduling = true;
                }
            } else if (task.primaryConstraints === TaskConstraint.ALAP) {
                if (criticalTaskWithoutSuccessor.value) {
                    if (
                        taskInGantt.status === TaskStatus.TODO &&
                        !(taskInGantt as any).taskTodoAfterTaskInprogress
                    ) {
                        taskInGantt.constraint_type = TaskConstraint.ASAP;
                    }
                    taskConstraintALAP.push(taskInGantt.ganttId);
                }
            } else if (taskInGantt.constraint_type === TaskConstraint.SO) {
                taskInGantt.constraint_type = TaskConstraint.SO;
            } else if (taskInGantt.constraint_type === TaskConstraint.FO) {
                taskInGantt.constraint_type = TaskConstraint.FO;
            }
        });

        if (criticalTaskWithoutSuccessor.value) {
            (gantt.config.project_end as Date | null) = null;
        } else {
            gantt.config.project_end = new Date(projectEnd as Date);
        }
        gantt.config.schedule_from_end = true;

        return {
            projectEnd,
            inProgressTasks,
            remainingDurationOfInProgressTasks,
            backupLink,
            predeTaskOfInprogressAndFinished,
            taskConstraintALAP,
            taskExceededProjectEnd,
        };
    };

    const afterAutoSchedulingBackward = async (
        dataDate: Date,
        projectEnd: Date,
        inProgressTasks: Array<string>,
        remainingDurationOfInProgressTasks: Map<
            string,
            RemainingDurationOfInProgressTasks
        >,
        predeTaskOfInprogressAndFinished: Map<string, GroupConstraint>,
        taskExceededProjectEnd: Map<string, string>,
    ) => {
        if (!criticalTaskWithoutSuccessor.value) {
            for (const [taskId, _] of taskExceededProjectEnd.entries()) {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);
                taskInGantt.constraint_type = TaskConstraint.MFO;
                taskInGantt.constraint_date = new Date(projectEnd);
            }

            gantt.autoSchedule();

            for (const [taskId, constraintType] of taskExceededProjectEnd.entries()) {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);
                if (
                    taskInGantt.primaryConstraints === TaskConstraint.SNLT ||
                    taskInGantt.primaryConstraints === TaskConstraint.FNLT
                ) {
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                } else {
                    taskInGantt.constraint_type = constraintType as TaskConstraint;
                }
                (taskInGantt.constraint_date as Date | null) = null;
            }
        } else {
            gantt.config.project_end = new Date(projectEnd as Date);
            predeTaskOfInprogressAndFinished.forEach((constraintType, taskId) => {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);
                if (taskInGantt.$target?.length) {
                    taskInGantt.$target.forEach((linkId) => {
                        const linkInGantt = gantt.getLink(linkId);
                        gantt.autoSchedule(linkInGantt.source);
                    });
                }
                gantt.autoSchedule(taskId);
            });
            (gantt.config.project_end as Date | null) = null;
        }

        const taskWBSSummary = new Map<string, IStartTimeEnds>();
        let shouldReschedule = false;

        gantt.autoSchedule();

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (moment(taskInGantt.end_date).isAfter(moment(projectEnd))) {
                const duration = calculateDuration(
                    new Date(projectEnd),
                    new Date(taskInGantt.end_date as Date),
                    taskInGantt.calendarId as string,
                    TaskDurationFormat.MINUTE,
                );

                const taskCalendar = gantt.getCalendar(task.calendarId as string);

                const newLateStart = taskCalendar.calculateEndDate({
                    start_date: new Date(taskInGantt.start_date as Date),
                    duration: -(duration || 0),
                    unit: TaskDurationFormat.MINUTE,
                });

                taskInGantt.lateStart = cloneDeep(
                    moment(newLateStart).fmFullTimeString(),
                );
                taskInGantt.lateFinish = cloneDeep(moment(projectEnd).fmFullTimeString());
            } else {
                taskInGantt.lateStart = cloneDeep(
                    moment(taskInGantt.start_date).fmFullTimeString(),
                );
                taskInGantt.lateFinish = cloneDeep(
                    moment(taskInGantt.end_date).fmFullTimeString(),
                );
            }

            if (task.primaryConstraintDate && taskInGantt.status === TaskStatus.TODO) {
                if (
                    task.primaryConstraints === TaskConstraint.MSO ||
                    task.primaryConstraints === TaskConstraint.SO
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    const newLateFinish = calendar.calculateEndDate({
                        start_date: new Date(task.primaryConstraintDate),
                        duration: taskInGantt.originalDuration || 0,
                        unit: TaskDurationFormat.MINUTE,
                    });

                    taskInGantt.lateStart = moment(
                        calculateStartDateToStartOfDay(
                            task.primaryConstraintDate,
                            calendar,
                        ),
                    ).fmFullTimeString();
                    taskInGantt.lateFinish = moment(
                        calculateEndDateToEndOfDay(newLateFinish, calendar),
                    ).fmFullTimeString();
                } else if (
                    task.primaryConstraints === TaskConstraint.MFO ||
                    task.primaryConstraints === TaskConstraint.FO
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    const newLateStart = calendar.calculateEndDate({
                        start_date: new Date(task.primaryConstraintDate),
                        duration: -(taskInGantt.originalDuration || 0),
                        unit: TaskDurationFormat.MINUTE,
                    });

                    taskInGantt.lateStart = moment(
                        calculateStartDateToStartOfDay(newLateStart, calendar),
                    ).fmFullTimeString();
                    taskInGantt.lateFinish = moment(
                        calculateEndDateToEndOfDay(task.primaryConstraintDate, calendar),
                    ).fmFullTimeString();
                }
            }

            // filter all task is WBS
            if (task.taskType === TaskType.WBS_SUMMARY) {
                const lateWBS: Record<any, any> = {
                    lateStart: new Date(MAX_TIMESTAMP),
                    lateFinish: taskInGantt.end_date,
                };

                if (moment(taskInGantt.end_date).isAfter(moment(projectEnd))) {
                    lateWBS.lateFinish = projectEnd;
                }
                if (!gantt.hasChild(task.ganttId)) {
                    lateWBS.lateStart = projectEnd;
                    lateWBS.lateFinish = projectEnd;
                }

                taskWBSSummary.set(task._id, lateWBS);
            }

            if (taskInGantt.status === TaskStatus.TODO) {
                if (task.primaryConstraints === TaskConstraint.SO) {
                    taskInGantt.constraint_type = TaskConstraint.SO;
                    shouldReschedule = true;
                } else if (task.primaryConstraints === TaskConstraint.FO) {
                    taskInGantt.constraint_type = TaskConstraint.FO;
                    shouldReschedule = true;
                }
            }
        });

        if (shouldReschedule) gantt.autoSchedule();

        // update constraint store to gantts
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);

            if (taskInGantt.status === TaskStatus.TODO) {
                if (
                    task.primaryConstraints === TaskConstraint.SNLT ||
                    task.primaryConstraints === TaskConstraint.FNLT ||
                    task.isRootWbs
                )
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                else if (
                    task.primaryConstraints === TaskConstraint.SO &&
                    moment(taskInGantt?.start_date).isSameOrBefore(
                        moment(taskInGantt?.constraint_date),
                    )
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MSO;
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = calculateStartDateToStartOfDay(
                        new Date(taskInGantt?.constraint_date as Date),
                        calendar,
                    );
                } else if (
                    task.primaryConstraints === TaskConstraint.FO &&
                    moment(taskInGantt?.end_date).isSameOrBefore(
                        moment(taskInGantt?.constraint_date),
                    )
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MFO;
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = calculateStartDateToStartOfDay(
                        new Date(taskInGantt?.constraint_date as Date),
                        calendar,
                    );
                } else if (
                    taskInGantt.constraint_type === TaskConstraint.MSO &&
                    moment(taskInGantt.constraint_date).isBefore(dataDate)
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    taskInGantt.constraint_date = new Date(
                        calculateStartDateToStartOfDay(dataDate, calendar),
                    );
                } else if (
                    taskInGantt.constraint_type === TaskConstraint.MFO &&
                    taskInGantt.constraint_date
                ) {
                    const calendar = gantt.getCalendar(taskInGantt?.calendarId || '');
                    const newStartDate = calendar.calculateEndDate({
                        start_date: new Date(taskInGantt.constraint_date),
                        duration: -(taskInGantt.originalDuration || 0),
                        unit: TaskDurationFormat.MINUTE,
                    });
                    if (moment(newStartDate).isBefore(dataDate)) {
                        const newFinishDate = calendar.calculateEndDate({
                            start_date: new Date(dataDate),
                            duration: taskInGantt.originalDuration || 0,
                            unit: TaskDurationFormat.MINUTE,
                        });
                        taskInGantt.constraint_date = new Date(newFinishDate);
                    }
                } else if (task.primaryConstraints === TaskConstraint.ALAP) {
                    taskInGantt.constraint_type = TaskConstraint.ALAP;
                } else
                    taskInGantt.constraint_type =
                        task.primaryConstraints || TaskConstraint.ASAP;
            } else if (taskInGantt.status === TaskStatus.IN_PROGRESS) {
                if (task.primaryConstraints === TaskConstraint.ALAP) {
                    taskInGantt.constraint_type = TaskConstraint.ALAP;
                } else {
                    taskInGantt.constraint_type = TaskConstraint.ASAP;
                }
            }

            if (
                disableScheduleTaskWithoutLinkAndConstraints.value &&
                !taskInGantt.auto_scheduling
            ) {
                const calender = gantt.getCalendar(taskInGantt.calendarId as string);
                const startDate =
                    task.taskType === TaskType.FINISH_MILESTONE ||
                    task.taskType === TaskType.START_MILESTONE
                        ? calculateDateWithoutConvert(
                              taskInGantt.start_date as Date,
                              calender,
                          )
                        : calculateStartDateToStartOfDay(
                              taskInGantt.start_date as Date,
                              calender,
                          );

                const endDate =
                    task.taskType === TaskType.FINISH_MILESTONE ||
                    task.taskType === TaskType.START_MILESTONE
                        ? calculateDateWithoutConvert(
                              taskInGantt.end_date as Date,
                              calender,
                          )
                        : calculateEndDateToEndOfDay(
                              taskInGantt.end_date as Date,
                              calender,
                          );

                taskInGantt.start_date = startDate;
                taskInGantt.end_date = endDate;
                taskInGantt.lateStart = startDate;
                taskInGantt.lateFinish = endDate;
            }

            // calculate milestone
            if (task.taskType === TaskType.START_MILESTONE) {
                taskInGantt.lateFinish = taskInGantt.lateStart;
            } else if (task.taskType === TaskType.FINISH_MILESTONE) {
                taskInGantt.lateStart = taskInGantt.lateFinish;
            }

            if (taskInGantt.parentId) {
                const parentOfTask = taskWBSSummary.get(taskInGantt.parentId ?? '');

                if (
                    taskInGantt?.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType !== TaskType.WBS_SUMMARY &&
                    taskInGantt?.lateStart &&
                    parentOfTask?.lateStart &&
                    new Date(parentOfTask?.lateStart).getTime() >
                        new Date(taskInGantt?.lateStart).getTime()
                ) {
                    parentOfTask.lateStart = taskInGantt.lateStart;
                }

                if (
                    taskInGantt?.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType !== TaskType.WBS_SUMMARY &&
                    taskInGantt?.lateFinish &&
                    parentOfTask?.lateFinish &&
                    new Date(parentOfTask?.lateFinish).getTime() <
                        new Date(taskInGantt?.lateFinish).getTime()
                ) {
                    parentOfTask.lateFinish = taskInGantt.lateFinish;
                }

                if (parentOfTask) taskWBSSummary.set(taskInGantt.parentId, parentOfTask);
            }
        });

        predeTaskOfInprogressAndFinished.forEach((constraint, taskId) => {
            const taskInGantt: any = gantt.getTask(taskId);
            taskInGantt.constraint_type = constraint.constraintType;
            taskInGantt.constraint_date = constraint.constraintDate || null;
        });

        const refreshTaskAutoScheduling: Array<string> = [];
        if (inProgressTasks.length) {
            inProgressTasks.forEach((taskId) => {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);

                const remainingDurationOfTask = remainingDurationOfInProgressTasks.get(
                    taskInGantt.ganttId,
                );

                if (moment(remainingDurationOfTask?.startDate).isBefore(dataDate)) {
                    (taskInGantt as any).should_not_auto_scheduling = true;
                    refreshTaskAutoScheduling.push(taskInGantt.ganttId);
                }
            });
        }

        gantt.config.schedule_from_end = false;
        gantt.config.project_start = new Date(dataDate);
        gantt.config.is_forward = true;

        return { taskWBSSummary, refreshTaskAutoScheduling };
    };

    const countPriorityPredecessor = (
        taskId: string,
        targetDivideSource: Map<string, string>,
    ): number => {
        const taskIdPredecessor = targetDivideSource.get(taskId);
        if (!taskIdPredecessor) {
            return 0;
        } else return countPriorityPredecessor(taskIdPredecessor, targetDivideSource) + 1;
    };

    const calcTaskHighestPriority = (
        predecessorTasks: Array<string>,
        targetDivideSource: Map<string, string>,
    ): string => {
        let priority = countPriorityPredecessor(predecessorTasks[0], targetDivideSource);
        let taskIdPriority = predecessorTasks[0];
        predecessorTasks.forEach((predecessorTaskId) => {
            const newPriority = countPriorityPredecessor(
                predecessorTaskId,
                targetDivideSource,
            );
            if (priority >= newPriority) {
                priority = newPriority;
                taskIdPriority = predecessorTaskId;
            }
        });

        return taskIdPriority;
    };

    const handleTaskExceedDataDate = (task: IGanttChartTask, dataDate: Date) => {
        const taskExceedDataDate: string[] = [];
        const targetDivideSource = new Map<string, string>(); // key -> targetTask : value -> sourceTask
        const connectedGroup: IConnectedGroup = gantt.getConnectedGroup(task.ganttId);

        //setup
        connectedGroup.links.forEach((link) => {
            const linkInGantt = gantt.getLink(link);
            targetDivideSource.set(linkInGantt.target, linkInGantt.source);
        });
        connectedGroup.tasks.forEach((taskIdGroup) => {
            const taskGroupInGantt: IGanttChartTask = gantt.getTask(taskIdGroup);

            if (
                (taskGroupInGantt as any).should_not_auto_scheduling ||
                task.taskType === TaskType.LEVEL_EFFORT ||
                task.taskType === TaskType.WBS_SUMMARY
            ) {
                return;
            }
            if (moment(taskGroupInGantt.start_date).isBefore(moment(dataDate))) {
                taskExceedDataDate.push(taskGroupInGantt.ganttId);
            }
        });

        // find task not predecessor on constant taskExceedDataDate
        if (!taskExceedDataDate.length) return;
        const taskNotPredecessorIsExceed: Array<string> = [];
        taskExceedDataDate.forEach((taskIdExceed) => {
            const taskPredecessorId = targetDivideSource.get(taskIdExceed);
            const hasTaskPredecessor = taskExceedDataDate.includes(
                taskPredecessorId as string,
            );

            if (!hasTaskPredecessor) {
                taskNotPredecessorIsExceed.push(taskIdExceed);
            }
        });

        // find task id has hightest priority
        const taskIdPriority = calcTaskHighestPriority(
            taskNotPredecessorIsExceed,
            targetDivideSource,
        );

        // move task to dataDate
        const taskGantt: IGanttChartTask = gantt.getTask(taskIdPriority);
        const calendar = gantt.getCalendar(taskGantt.calendarId as string);
        taskGantt.constraint_type = TaskConstraint.MSO;
        taskGantt.constraint_date = calculateStartDateToStartOfDay(
            new Date(dataDate),
            calendar,
        );
        gantt.autoSchedule(taskGantt.ganttId);

        // continue find task exceed dataDate
        taskNotPredecessorIsExceed.forEach((taskIdExceed) => {
            const taskExceedGantt: IGanttChartTask = gantt.getTask(taskIdExceed);
            if (
                taskIdPriority !== taskExceedGantt.ganttId &&
                moment(taskExceedGantt.start_date).isBefore(moment(dataDate))
            ) {
                handleTaskExceedDataDate(taskExceedGantt, dataDate);
            }
        });
    };

    const afterAutoSchedulingForward = (
        dataDate: Date,
        projectEnd: Date,
        taskWBSSummaryBackward: Map<string, IStartTimeEnds>,
        backupLink: Array<any>,
        refreshTaskAutoScheduling: Array<string>,
        taskConstraintALAP: Array<string>,
        inProgressTasks: Array<string>,
        remainingDurationOfInProgressTasks: Map<
            string,
            RemainingDurationOfInProgressTasks
        >,
    ) => {
        if (taskConstraintALAP.length) {
            const projectEndConfig = gantt.config.project_end;
            gantt.config.project_end = new Date(projectEnd as Date);
            taskConstraintALAP.forEach((taskId) => {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);
                taskInGantt.constraint_type = TaskConstraint.ALAP;
                gantt.autoSchedule(taskId);
            });
            gantt.config.project_end = projectEndConfig;
        }

        // filter all task is wbs
        const taskWBSSummaryForward = new Map<string, IStartTimeEnds>();
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (task.taskType === TaskType.WBS_SUMMARY) {
                const earlyWBS = {
                    earlyStart: new Date(MAX_TIMESTAMP),
                    earlyFinish: taskInGantt.end_date,
                    plannedStart: new Date(MAX_TIMESTAMP),
                    plannedFinish: taskInGantt.end_date,
                    actualStart: new Date(MAX_TIMESTAMP),
                    actualFinish: taskInGantt.end_date,
                };

                if (!gantt.hasChild(task.ganttId)) {
                    earlyWBS.earlyStart = dataDate;
                    earlyWBS.earlyFinish = dataDate;
                    earlyWBS.plannedStart = taskInGantt.start_date as Date;
                    earlyWBS.plannedFinish = taskInGantt.end_date as Date;
                    earlyWBS.actualStart = taskInGantt.start_date as Date;
                    earlyWBS.actualFinish = taskInGantt.end_date as Date;
                }

                if (moment(taskInGantt.end_date).isAfter(moment(projectEnd))) {
                    earlyWBS.earlyFinish = projectEnd;
                }
                taskWBSSummaryForward.set(task._id, earlyWBS);
            }

            if (
                moment(taskInGantt.start_date).isBefore(moment(dataDate)) &&
                task.taskType !== TaskType.WBS_SUMMARY
            ) {
                handleTaskExceedDataDate(taskInGantt, dataDate);
            }

            taskInGantt.earlyStart = moment(taskInGantt.start_date).fmFullTimeString();
            taskInGantt.earlyFinish = moment(taskInGantt.end_date).fmFullTimeString();
        });

        const taskInrpgressALAPIsAfterDataDate: Array<string> = [];
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);

            // calculate milestoneconst calendar = gantt.getCalendar(task.calendarId as string);
            const calendar = gantt.getCalendar(taskInGantt.calendarId as string);
            if (task.taskType === TaskType.START_MILESTONE) {
                const earlyStart = calculateDateWithoutConvert(
                    taskInGantt?.start_date as Date,
                    calendar,
                );
                taskInGantt.earlyStart = moment(earlyStart).fmFullTimeString();
                taskInGantt.earlyFinish = moment(earlyStart).fmFullTimeString();
            }

            if (task.taskType === TaskType.FINISH_MILESTONE) {
                const earlyFinish = calculateDateWithoutConvert(
                    taskInGantt?.end_date as Date,
                    calendar,
                );
                taskInGantt.earlyStart = moment(earlyFinish).fmFullTimeString();
                taskInGantt.earlyFinish = moment(earlyFinish).fmFullTimeString();
            }

            if (
                task.taskType === TaskType.FINISH_MILESTONE ||
                task.taskType === TaskType.START_MILESTONE
            ) {
                const dataDate = projectPlanningModule.planning?.dataDate;
                const calendar = gantt?.getCalendar(taskInGantt?.calendarId || '');
                if (
                    taskInGantt?.start_date &&
                    dataDate &&
                    new Date(taskInGantt?.start_date).getTime() <=
                        new Date(dataDate).getTime() &&
                    taskInGantt.status !== TaskStatus.FINISHED
                ) {
                    const tempEndDate = calendar?.calculateEndDate({
                        start_date: new Date(dataDate),
                        duration: 1,
                        unit: TaskDurationFormat.MINUTE,
                    });
                    const startDateToEndOfDay = calendar?.calculateEndDate({
                        start_date: tempEndDate,
                        duration: -1,
                        unit: TaskDurationFormat.MINUTE,
                    });

                    taskInGantt.start_date = startDateToEndOfDay;
                    taskInGantt.end_date = startDateToEndOfDay;
                }
            }

            if (
                task.status === TaskStatus.IN_PROGRESS &&
                task.primaryConstraints === TaskConstraint.ALAP
            ) {
                taskInGantt.constraint_type = TaskConstraint.ASAP;
                taskInrpgressALAPIsAfterDataDate.push(taskInGantt.ganttId);
            }

            if (taskInGantt.parentId) {
                const parentOfTask = taskWBSSummaryForward.get(taskInGantt.parentId);

                if (
                    taskInGantt.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType !== TaskType.WBS_SUMMARY &&
                    taskInGantt?.start_date &&
                    parentOfTask?.earlyStart &&
                    new Date(parentOfTask?.earlyStart).getTime() >
                        new Date(taskInGantt?.start_date).getTime()
                ) {
                    parentOfTask.earlyStart = taskInGantt.start_date;
                }

                if (
                    taskInGantt.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType !== TaskType.WBS_SUMMARY &&
                    taskInGantt?.end_date &&
                    parentOfTask?.earlyFinish &&
                    new Date(parentOfTask?.earlyFinish).getTime() <
                        new Date(taskInGantt?.end_date).getTime()
                ) {
                    if (moment(taskInGantt.end_date).isAfter(moment(projectEnd))) {
                        parentOfTask.earlyFinish = projectEnd;
                    } else {
                        parentOfTask.earlyFinish = taskInGantt.end_date;
                    }
                }

                if (
                    taskInGantt?.actualStart &&
                    parentOfTask?.actualStart &&
                    new Date(parentOfTask?.actualStart).getTime() >
                        new Date(taskInGantt?.actualStart).getTime()
                ) {
                    parentOfTask.actualStart = new Date(taskInGantt.actualStart);
                }

                if (
                    taskInGantt?.actualFinish &&
                    parentOfTask?.actualFinish &&
                    new Date(parentOfTask?.actualFinish).getTime() <
                        new Date(taskInGantt?.actualFinish).getTime()
                ) {
                    parentOfTask.actualFinish = new Date(taskInGantt.actualFinish);
                }

                if (
                    taskInGantt?.plannedStart &&
                    parentOfTask?.plannedStart &&
                    new Date(parentOfTask?.plannedStart).getTime() >
                        new Date(taskInGantt?.plannedStart).getTime()
                ) {
                    parentOfTask.plannedStart = new Date(taskInGantt.plannedStart);
                }

                if (
                    taskInGantt?.plannedFinish &&
                    parentOfTask?.plannedFinish &&
                    new Date(parentOfTask?.plannedFinish).getTime() <
                        new Date(taskInGantt?.plannedFinish).getTime()
                ) {
                    parentOfTask.plannedFinish = new Date(taskInGantt.plannedFinish);
                }

                if (parentOfTask)
                    taskWBSSummaryForward.set(taskInGantt.parentId, parentOfTask);
            }

            if (taskInGantt.status === TaskStatus.FINISHED) {
                taskInGantt.earlyStart = moment(dataDate).fmFullTimeString();
                taskInGantt.earlyFinish = moment(dataDate).fmFullTimeString();
                taskInGantt.lateStart = moment(dataDate).fmFullTimeString();
                taskInGantt.lateFinish = moment(dataDate).fmFullTimeString();
            }
        });

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (taskInGantt.parentId) {
                const parentOfTaskForward = taskWBSSummaryForward.get(
                    taskInGantt.parentId,
                );

                const childrentOfTaskForward = taskWBSSummaryForward.get(taskInGantt._id);

                const parentOfTaskBackward = taskWBSSummaryBackward.get(
                    taskInGantt.parentId,
                );

                const childrentOfTaskBackward = taskWBSSummaryBackward.get(
                    taskInGantt._id,
                );

                if (
                    taskInGantt.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType === TaskType.WBS_SUMMARY &&
                    childrentOfTaskForward?.earlyStart &&
                    parentOfTaskForward?.earlyStart &&
                    new Date(parentOfTaskForward?.earlyStart).getTime() >
                        new Date(childrentOfTaskForward?.earlyStart).getTime()
                ) {
                    parentOfTaskForward.earlyStart = childrentOfTaskForward?.earlyStart;
                }

                if (
                    taskInGantt.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType === TaskType.WBS_SUMMARY &&
                    childrentOfTaskForward?.earlyFinish &&
                    parentOfTaskForward?.earlyFinish &&
                    new Date(parentOfTaskForward?.earlyFinish).getTime() <
                        new Date(childrentOfTaskForward?.earlyFinish).getTime()
                ) {
                    if (
                        moment(childrentOfTaskForward.earlyFinish).isAfter(
                            moment(projectEnd),
                        )
                    ) {
                        parentOfTaskForward.earlyFinish = projectEnd;
                    } else {
                        parentOfTaskForward.earlyFinish =
                            childrentOfTaskForward.earlyFinish;
                    }
                }

                if (
                    taskInGantt.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType === TaskType.WBS_SUMMARY &&
                    parentOfTaskBackward?.lateStart &&
                    childrentOfTaskBackward?.lateStart &&
                    new Date(parentOfTaskBackward?.lateStart).getTime() >
                        new Date(childrentOfTaskBackward?.lateStart).getTime()
                ) {
                    parentOfTaskBackward.lateStart = childrentOfTaskBackward?.lateStart;
                }

                if (
                    taskInGantt.status !== TaskStatus.FINISHED &&
                    taskInGantt.taskType === TaskType.WBS_SUMMARY &&
                    parentOfTaskBackward?.lateFinish &&
                    childrentOfTaskBackward?.lateFinish &&
                    new Date(parentOfTaskBackward?.lateFinish).getTime() <
                        new Date(childrentOfTaskBackward?.lateFinish).getTime()
                ) {
                    if (
                        moment(parentOfTaskBackward.lateFinish).isAfter(
                            moment(projectEnd),
                        )
                    ) {
                        parentOfTaskBackward.lateFinish = projectEnd;
                    } else {
                        parentOfTaskBackward.lateFinish =
                            childrentOfTaskBackward.lateFinish;
                    }
                }

                if (parentOfTaskForward)
                    taskWBSSummaryForward.set(taskInGantt.parentId, parentOfTaskForward);

                if (parentOfTaskBackward)
                    taskWBSSummaryBackward.set(
                        taskInGantt.parentId,
                        parentOfTaskBackward,
                    );
            }
        });

        if (taskInrpgressALAPIsAfterDataDate.length) {
            taskInrpgressALAPIsAfterDataDate.forEach((taskId) => {
                gantt.autoSchedule(taskId);
            });
        }
        if (refreshTaskAutoScheduling.length) {
            refreshTaskAutoScheduling.forEach((taskId) => {
                const taskAutoInGantt = gantt.getTask(taskId);
                taskAutoInGantt.auto_scheduling = true;
            });
        }

        if (inProgressTasks.length) {
            inProgressTasks.forEach((taskId) => {
                const taskInGantt: IGanttChartTask = gantt.getTask(taskId);

                const remainingDurationOfTask = remainingDurationOfInProgressTasks.get(
                    taskInGantt.ganttId,
                );

                taskInGantt.duration = (taskInGantt as any).back_up_duration;
                if (
                    moment(remainingDurationOfTask?.startDate).isBefore(dataDate) &&
                    !taskInGantt.isRootWbs
                ) {
                    taskInGantt.constraint_type = TaskConstraint.MSO;
                    taskInGantt.constraint_date =
                        remainingDurationOfTask?.startDate as Date;
                    gantt.autoSchedule(taskInGantt.ganttId);
                }
            });
        }

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (
                task.taskType !== TaskType.WBS_SUMMARY &&
                moment(taskInGantt.start_date).isBefore(moment(dataDate)) &&
                taskInGantt.status === TaskStatus.TODO
            ) {
                handleTaskExceedDataDate(taskInGantt, dataDate);
            }
        });

        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (
                taskInGantt.status === TaskStatus.TODO &&
                taskInGantt.taskType !== TaskType.WBS_SUMMARY &&
                taskInGantt.taskType !== TaskType.START_MILESTONE &&
                taskInGantt.taskType !== TaskType.FINISH_MILESTONE &&
                taskInGantt.taskType !== TaskType.LEVEL_EFFORT &&
                taskInGantt.primaryConstraints === TaskConstraint.ASAP
            ) {
                taskInGantt.earlyStart = moment(
                    taskInGantt.start_date,
                ).fmFullTimeString();
                taskInGantt.earlyFinish = moment(taskInGantt.end_date).fmFullTimeString();
            }
        });

        // final => update start time ends of the task WBS summary
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (task.taskType === TaskType.WBS_SUMMARY) {
                const taskWBSBackward = taskWBSSummaryBackward.get(taskInGantt._id);
                const taskWBSForward = taskWBSSummaryForward.get(taskInGantt._id);

                taskInGantt.earlyStart = moment(taskWBSForward?.earlyStart).isSame(
                    moment(MAX_TIMESTAMP),
                )
                    ? moment(taskInGantt.start).fmFullTimeString()
                    : moment(taskWBSForward?.earlyStart).fmFullTimeString();
                taskInGantt.earlyFinish = moment(taskInGantt.end_date).isAfter(
                    moment(projectEnd),
                )
                    ? moment(projectEnd).fmFullTimeString()
                    : moment(taskWBSForward?.earlyFinish).fmFullTimeString();
                taskInGantt.lateStart = moment(taskWBSBackward?.lateStart).isSame(
                    moment(MAX_TIMESTAMP),
                )
                    ? moment(taskInGantt.start).fmFullTimeString()
                    : moment(taskWBSBackward?.lateStart).fmFullTimeString();
                taskInGantt.lateFinish = moment(
                    taskWBSBackward?.lateFinish,
                ).fmFullTimeString();

                taskInGantt.plannedStart = moment(taskWBSForward?.plannedStart).toDate();
                taskInGantt.plannedFinish = moment(
                    taskWBSForward?.plannedFinish,
                ).toDate();
                taskInGantt.actualStart = moment(taskWBSForward?.actualStart).isSame(
                    moment(MAX_TIMESTAMP),
                )
                    ? taskInGantt.start
                    : moment(taskWBSForward?.actualStart).toDate();
                taskInGantt.actualFinish = moment(taskWBSForward?.actualFinish).isSame(
                    moment(MAX_TIMESTAMP),
                )
                    ? taskInGantt.finish
                    : moment(taskWBSForward?.actualFinish).toDate();
            }
        });

        gantt.config.is_forward = false;
        gantt.config.auto_scheduling = false;
        backupLink.forEach((linkInGantt) => {
            gantt.addLink({ ...linkInGantt, isBackupLink: true });
        });
        gantt.config.auto_scheduling = true;
    };

    const markCriticalTask = (tasks: IGanttChartTask[]) => {
        for (const task of tasks) {
            const criticalthreshold = convertDurationFormat(
                +(
                    projectPlanningModule.planning?.criticalThreshold ||
                    DEFAULT_CRITICAL_THRESHOLD
                ),
                projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.MINUTE,
                TaskDurationFormat.MINUTE,
            );

            task.critical =
                task?.totalFloat !== undefined &&
                task?.totalFloat !== null &&
                task?.totalFloat <= criticalthreshold;
        }
    };

    const markCriticalPath = (tasks: IGanttChartTask[], dataLinks: ITaskLink[]) => {
        const lastTasks = findLastTasks();
        lastTasks.forEach(markCriticalPathFrom);

        function findLastTasks() {
            const lastTasks: IGanttChartTask[] = [];

            for (const task of tasks) {
                if (lastTasks.length === 0) {
                    lastTasks.push(task);
                    continue;
                }

                // .earlyFinish is actually an ISO 8601 string, which is naturally
                // sortable, yet I'm forced to use this
                const lastEndDateSoFar = moment(lastTasks[0].earlyFinish);
                const taskEndDate = moment(task.earlyFinish);

                if (lastEndDateSoFar.isBefore(taskEndDate)) {
                    lastTasks.splice(0, lastTasks.length, task);
                } else if (lastEndDateSoFar.isSame(taskEndDate)) {
                    lastTasks.push(task);
                }
            }

            return lastTasks;
        }

        function markCriticalPathFrom(lastTask: IGanttChartTask) {
            const taskGanttIds = tasks.map((task) => task.ganttId);

            const stack = [lastTask];
            while (stack.length) {
                const task = stack.pop()!;
                task.isOnCriticalPath = true;

                task.$target
                    ?.map((linkId) => gantt.getLink(linkId))
                    .filter(isDrivingLink)
                    .filter(isSourceTaskInFilteredList)
                    .forEach(markLinkInCriticalPath);
            }

            function isDrivingLink(linkInGantt: any) {
                return linkInGantt.isDrivingRelationship;
            }

            // The target task of the link is guaranteed to be in `tasks` list
            function isSourceTaskInFilteredList(linkInGantt: any) {
                return taskGanttIds.includes(linkInGantt.source);
            }

            function markLinkInCriticalPath(linkInGantt: any) {
                linkInGantt.isOnCriticalPath = true;
                const link = dataLinks.find((link) => link._id === linkInGantt._id);
                if (link) {
                    link.isOnCriticalPath = true;
                }

                // Manually update CSS, dunno why but the function handling
                // isDrivingRelationship has to do the same shit...
                updateDomWithLinkClass(linkInGantt._id, { isOnCriticalPath: true });

                const sourceTask: IGanttChartTask = gantt.getTask(linkInGantt.source);
                stack.push(sourceTask);
            }
        }
    };

    /**
     * Filter tasks to mark critical task & critical/longest path
     * - Tasks in content folder.
     *   Those in top-down/bottom-up are ignored
     * - Tasks which are not WBS or LOE
     */
    const filterTasksToMarkCritical = (tasks: IGanttChartTask[]) => {
        const milestoneFoldersTasksIds = tasks
            .filter((task) => task.isMilestoneFolder)
            .map((task) => task._id);
        const isContentTask = (task: IProjectTask) => {
            if (!task.parentId) return false;
            // Task in milestone folders can't have children, so no need to build tree
            const isInMilestoneFolder = milestoneFoldersTasksIds.includes(task.parentId);
            return !isInMilestoneFolder;
        };

        const isNotWbsOrLoe = (task: IProjectTask) =>
            task.taskType !== TaskType.WBS_SUMMARY &&
            task.taskType !== TaskType.LEVEL_EFFORT;

        return tasks.filter(isContentTask).filter(isNotWbsOrLoe);
    };

    // Re init critical task & critical/longest path
    const reinitCritical = (tasks: IGanttChartTask[], dataLinks: ITaskLink[]) => {
        // Actually only the tasks filtered in filterTasksToMarkCritical() is
        // needed. This is just a safe measure.
        tasks.forEach((taskInGantt) => {
            taskInGantt.isOnCriticalPath = false;
            taskInGantt.critical = false;
        });

        const links = gantt.getLinks();
        links.forEach((link) => (link.isOnCriticalPath = false));

        dataLinks.forEach((link) => (link.isOnCriticalPath = false));
    };

    const afterForwardComplete = async () => {
        const tasksInGantt: IGanttChartTask[] =
            projectPlanningModule.planning?.tasks.map((task) =>
                gantt.getTask(task.ganttId),
            ) ?? [];

        tasksInGantt.forEach((taskInGantt) => {
            // calculate total float
            taskInGantt.totalFloat = calculateTotalFloat(taskInGantt);
            // calculate free float
            const earlyFinishOfPlanning = calculateMaxEarlyFinishOfPlanning();
            taskInGantt.freeFloat = handleFreeFloat(taskInGantt, earlyFinishOfPlanning);
        });

        // calculate driving relationship
        const dataLinks = handleDrivingRelationship();

        // Mark critical task & critical/longest path
        reinitCritical(tasksInGantt, dataLinks);
        const tasksInGanttToMark = filterTasksToMarkCritical(tasksInGantt);
        markCriticalPath(tasksInGanttToMark, dataLinks);
        markCriticalTask(tasksInGanttToMark);

        const response = await projectPlanningService.bulkUpdateLink(
            projectPlanningModule.planningId,
            { items: dataLinks },
        );
        if (!response.success) {
            showErrorNotificationFunction(response.message);
        }
    };

    const isDifferentDate = (
        date: Date | string | undefined | null,
        compareDate: Date | string | undefined | null,
    ): boolean => {
        return new Date(date || '').toString() !== new Date(compareDate || '').toString();
    };

    const bulkUpdateTaskAutoScheduling = async () => {
        // check timeline wbs_summary has change
        const summaryTaskIdHasUpdate: string[] = [];
        const mapTaskIdToType = new Map<string, TaskType>();
        projectPlanningModule.planning?.tasks.forEach((task) => {
            mapTaskIdToType.set(task._id, task.taskType);
            if (task.taskType === TaskType.LEVEL_EFFORT) {
                updateLevelEffort(task);
                summaryTaskIdHasUpdate.push(task.ganttId);
            }
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            taskInGantt.constraint_type = task.primaryConstraints || TaskConstraint.ASAP;
            taskInGantt.constraint_date =
                task.primaryConstraintDate &&
                task.primaryConstraints !== TaskConstraint.ASAP &&
                task.primaryConstraints !== TaskConstraint.ALAP
                    ? moment(task.primaryConstraintDate).toDate()
                    : undefined;

            summaryTaskIdHasUpdate.push(task.ganttId);

            if (
                task.expectedFinish &&
                projectPlanningModule.planning?.useExpectedFinish &&
                !moment(task.expectedFinish).isSame(taskInGantt.end_date)
            ) {
                summaryTaskIdHasUpdate.push(task.ganttId);
            }
        });

        taskIdHasChangeList = uniq([...taskIdHasChangeList, ...summaryTaskIdHasUpdate]);

        taskIdHasChangeList.forEach((ganttId) => {
            const task = gantt.getTask(ganttId);

            const taskInStore = projectPlanningModule.planning?.tasks.find(
                (t) => t.ganttId === task.id,
            );
            let isUpdateStart = false;
            let isUpdateFinish = false;

            if (taskInStore) {
                if (
                    moment(task.start_date).fmFullTimeString() !==
                    moment(taskInStore.start).fmFullTimeString()
                ) {
                    isUpdateStart = true;
                }
                if (
                    moment(task.end_date).fmFullTimeString() !==
                    moment(taskInStore.finish).fmFullTimeString()
                ) {
                    isUpdateFinish = true;
                }
            }

            const taskLinks = projectPlanningModule.planning?.taskLinks.filter((link) => {
                if (
                    isUpdateStart &&
                    (([LinkType.FINISH_TO_START, LinkType.START_TO_START].includes(
                        link.type,
                    ) &&
                        link.target === task._id) ||
                        ([LinkType.START_TO_START, LinkType.START_TO_FINISH].includes(
                            link.type,
                        ) &&
                            link.source === task._id))
                ) {
                    return true;
                }
                if (
                    isUpdateFinish &&
                    (([LinkType.START_TO_FINISH, LinkType.FINISH_TO_FINISH].includes(
                        link.type,
                    ) &&
                        link.target === task._id) ||
                        ([LinkType.FINISH_TO_START, LinkType.FINISH_TO_FINISH].includes(
                            link.type,
                        ) &&
                            link.source === task._id))
                ) {
                    return true;
                }
                return false;
            });
            taskLinks?.forEach((taskLink) => {
                const newIds = [];
                if (mapTaskIdToType.get(taskLink.source) === TaskType.STANDARD) {
                    newIds.push(taskLink.source);
                }
                if (mapTaskIdToType.get(taskLink.target) === TaskType.STANDARD) {
                    newIds.push(taskLink.target);
                }
                projectPlanningModule.setEditedTaskIds(
                    uniq([...projectPlanningModule.editedTaskIds, ...newIds]),
                );
                if (newIds.length) {
                    projectPlanningModule.setEditedLinkIds(
                        uniq([...projectPlanningModule.editedLinkIds, taskLink._id]),
                    );
                }
            });
        });

        const taskHasChangeList: IBulkUpdateTask[] = [];
        taskIdHasChangeList.map((taskId) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(taskId);
            if (taskInGantt) {
                const dataConvertGanttTaskToTaskDto = convertGanttTaskToTaskDto(
                    durationStringFormat,
                    calculateDuration,
                    gantt,
                    {
                        ...taskInGantt,
                        calendarDuration: gantt.calculateDuration(taskInGantt),
                    },
                );

                taskHasChangeList.push({
                    ...dataConvertGanttTaskToTaskDto,
                    taskId: taskInGantt._id,
                });
            }
        });

        if (!taskHasChangeList) {
            return;
        }

        const response = await projectPlanningService.bulkUpdateTasks({
            items: taskHasChangeList,
            projectId: projectModule.selectedProjectId || '',
            path: localStorageAuthService.getPlanningPermissions()?.path || '',
        });

        if (response.success) {
            response.data.forEach((task) => {
                const currentTaskIndex = projectPlanningModule.planning?.tasks.findIndex(
                    (_task) => _task.ganttId === task.ganttId,
                );
                if (currentTaskIndex !== undefined && currentTaskIndex !== -1) {
                    const clonedPlanning = cloneDeep(projectPlanningModule.planning);
                    if (clonedPlanning?.tasks) {
                        clonedPlanning.tasks[currentTaskIndex] = cloneDeep(task);
                        projectPlanningModule.setPlanning(clonedPlanning);
                    }
                }
                const ganttTask = convertToGanttTask(
                    gantt,
                    task,
                    projectPlanningModule.taskFieldList || [],
                );

                gantt.updateTask(task.ganttId, ganttTask);
            });
            taskIdHasChangeList = [];
        } else {
            showErrorNotificationFunction(response.message);
        }
    };

    // config gantt templates
    const initGanttTemplates = () => {
        gantt.templates.task_text = (start, end, task) => {
            return task.name;
        };

        gantt.templates.scale_cell_class = function (date) {
            if (date.getDay() == 0 || date.getDay() == 6) {
                return 'gantt-cell-weekend';
            }
            return '';
        };

        gantt.templates.timeline_cell_class = function (task, date) {
            if (!gantt.isWorkTime({ date: date, task: task, unit: 'day' })) {
                return 'gantt-timeline-cell-weekend';
            }
            return '';
        };

        gantt.templates.leftside_text = function (start, end, task) {
            const startTime =
                task.taskType !== TaskType.WBS_SUMMARY &&
                task.taskType !== TaskType.LEVEL_EFFORT
                    ? gantt.getTaskCalendar(task).getClosestWorkTime({
                          date: new Date(start),
                          dir: 'future',
                          unit: TaskDurationFormat.HOUR,
                      })
                    : start;

            const endTime = gantt.getTaskCalendar(task).getClosestWorkTime({
                date: new Date(end),
                dir: 'past',
                unit: TaskDurationFormat.HOUR,
            });
            let taskPrefixName = t('planning.gantt.task.prefixStart', {
                date: moment(startTime).fmHourMinuteString(),
            });
            if (task.taskType === TaskType.FINISH_MILESTONE) {
                taskPrefixName = t('planning.gantt.task.prefixEnd', {
                    date: moment(endTime).fmHourMinuteString(),
                });
            }

            return taskPrefixName;
        };

        gantt.templates.link_description = (link) => {
            const fromObject = gantt.getTask(link.source);
            const toObject = gantt.getTask(link.target);
            return t(`planning.task.link.popupTitle.${getLinkType(link.type)}`, {
                from: fromObject.name,
                to: toObject.name,
            });
        };

        // add class for regular task (task type === task)
        gantt.templates.grid_row_class = function (start, end, task: IGanttChartTask) {
            let additionalClass = '';

            // TODO add more type to marked task is delegated
            if (task.type !== TaskType.PROJECT) {
                additionalClass += ' child_task';
            }
            if (task.delegatedTo) {
                additionalClass += ' delegated_task';
            }

            return additionalClass;
        };

        gantt.templates.drag_link = (from, from_start, to, to_start) => {
            const fromTask = gantt.getTask(from);
            const fromTaskName =
                fromTask.name?.length > 100
                    ? `${fromTask.name.slice(0, 100)}...`
                    : fromTask.name;
            const text = `From: <b>${fromTaskName}</b><br/>`;
            if (to) {
                const toTask = gantt.getTask(to);
                const fromTaskName =
                    toTask.name?.length > 100
                        ? `${toTask.name.slice(0, 100)}...`
                        : toTask.name;
                return text.concat(`To: <b>${fromTaskName}</b><br/>`);
            }
            return text;
        };

        gantt.templates.link_class = (link: ITaskLink) => {
            if (link?.isDrivingRelationship == undefined) return '';

            const ganttSettingView: IGanttView | undefined =
                projectPlanningModule.planning?.view?.gantt;
            if (
                !ganttSettingView?.isShowCriticalRelationships &&
                !ganttSettingView?.isShowNonDrivingRelationships
            )
                return `${CLASS_HIDE_RELATIONSHIP_LINE}`;

            let linkClass = '';
            if (link?.isOnCriticalPath) {
                if (!ganttSettingView?.isShowCriticalRelationships)
                    linkClass = `${linkClass} ${CLASS_HIDE_RELATIONSHIP_LINE}`;
                linkClass = `${linkClass} ${CLASS_LINK_ON_CRITICAL_PATH}`;
            } else if (link?.isDrivingRelationship) {
                linkClass = `${linkClass} ${CLASS_DRIVING_RELATIONSHIP}`;
            } else if (!link?.isDrivingRelationship) {
                if (!ganttSettingView?.isShowNonDrivingRelationships)
                    linkClass = `${linkClass} ${CLASS_HIDE_RELATIONSHIP_LINE}`;
                linkClass = `${linkClass} ${CLASS_NON_DRIVING_RELATIONSHIP}`;
            }

            return linkClass;
        };
    };

    const configLinkDirection = () => {
        gantt.config[LinkType.FINISH_TO_START] = 0;
        gantt.config[LinkType.START_TO_START] = 1;
        gantt.config[LinkType.FINISH_TO_FINISH] = 2;
        gantt.config[LinkType.START_TO_FINISH] = 3;
    };

    const configZooming = () => {
        const zoomConfig = {
            levels: [
                {
                    name: 'year',
                    scale_height: 50,
                    min_column_width: 30,
                    scales: [{ unit: 'year', step: 1, format: '%Y' }],
                },

                {
                    name: 'quarter',
                    height: 50,
                    min_column_width: 90,
                    scales: [
                        { unit: 'month', step: 1, format: '%M' },
                        {
                            unit: 'quarter',
                            step: 1,
                            format: function (date: Date) {
                                const monthString = gantt.date.date_to_str('%M');
                                const yearString = gantt.date.date_to_str('%Y');
                                const endDate = gantt.date.add(
                                    gantt.date.add(date, 3, 'month'),
                                    -1,
                                    'day',
                                );
                                return (
                                    monthString(date) +
                                    ' - ' +
                                    monthString(endDate) +
                                    ' - ' +
                                    yearString(date)
                                );
                            },
                        },
                    ],
                },
                {
                    name: 'month',
                    scale_height: 50,
                    min_column_width: 120,
                    scales: [
                        { unit: 'month', format: '%F, %Y' },
                        { unit: 'week', format: 'Week #%W' },
                    ],
                },

                {
                    name: 'week',
                    scale_height: 50,
                    min_column_width: 50,
                    scales: [
                        {
                            unit: 'week',
                            step: 1,
                            format: function (date: Date) {
                                const dateToStr = gantt.date.date_to_str('%d %M');
                                const endDate = gantt.date.add(date, 6, 'day');
                                const weekNum = gantt.date.date_to_str('%W')(date);
                                return (
                                    '#' +
                                    weekNum +
                                    ', ' +
                                    dateToStr(date) +
                                    ' - ' +
                                    dateToStr(endDate)
                                );
                            },
                        },
                        { unit: 'day', step: 1, format: '%j %D' },
                    ],
                },

                {
                    name: 'day',
                    scale_height: 27,
                    min_column_width: 80,
                    scales: [{ unit: 'day', step: 1, format: '%d %M' }],
                },
                {
                    name: TaskDurationFormat.HOUR,
                    scale_height: 27,
                    min_column_width: 80,
                    scales: [{ unit: TaskDurationFormat.HOUR, step: 1, format: '%h' }],
                },
            ],
            // TODO config date later
            startDate: new Date(2010, 2, 27),
            endDate: new Date(2030, 3, 20),
            useKey: 'ctrlKey',
            trigger: 'wheel',
            element: function () {
                return document.querySelector('#gantt-chart-4d-planning .gantt_task');
            },
        };

        gantt.ext.zoom.init(zoomConfig);
        gantt.ext.zoom.setLevel('day');
    };

    const configAutoScheduling = (value: boolean) => {
        gantt.plugins({
            auto_scheduling: value,
        });
        gantt.config.auto_scheduling = value;
        gantt.config.auto_scheduling_initial = value;
        gantt.config.auto_scheduling_compatibility = value;
        gantt.config.auto_scheduling_strict = value;
    };

    const configGanttCommon = () => {
        gantt.config.bar_height = 16;
        gantt.config.row_height = 50;
        gantt.config.fit_tasks = true;
        gantt.config.drag_progress = false;
        // if use this config, gantt will throw an error when delete a task which has some children
        // gantt.config.cascade_delete = false;
        Object.values(TaskType).forEach((type) => {
            gantt.config.types[type] = type;
            gantt.locale.labels[`type_${type}`] = t(`planning.task.types.${type}`);
        });
        gantt.config.drag_timeline = {
            ignore: '.gantt_task_line, .gantt_task_link',
            useKey: 'ctrlKey',
        };
        gantt.config.show_markers = true;
        gantt.config.scales = [
            { unit: 'month', step: 1, format: '%M %Y' },
            { unit: 'day', step: 1, format: '%d, %l' },
            // { unit: TaskDurationFormat.HOUR, step: 1, format: '%H' },
        ];
        gantt.config.scale_height = 20 * 3;
        gantt.config.min_column_width = 18;
        gantt.config.min_grid_column_width = 1;

        gantt.config.horizontal_scroll_key = 'altKey';
        gantt.config.date_format = '%Y-%m-%d%T%H:%i:%s';
        // duration in decimal format
        gantt.config.work_time = true;
        // gantt.config.skip_off_time = true;
        gantt.config.duration_unit = TaskDurationFormat.MINUTE;
        gantt.config.duration_step = 1;

        // display column in grid
        gantt.config.reorder_grid_columns = true;
        gantt.config.grid_resize = true;

        gantt.config.columns = getVisibleColumnConfiguration(
            gantt,
            projectPlanningModule.planning?.view?.columns ?? [],
            convertDurationStringFormat,
            durationStringFormat,
            calculateDuration,
            t,
        );

        // show horizontal scrollbar in grid area
        gantt.config.layout = {
            css: 'gantt_container',
            cols: [
                {
                    width: gridWidthWithoutScrollbar || 500,
                    min_width: 300,
                    rows: [
                        {
                            view: 'grid',
                            scrollX: 'gridScroll',
                            scrollable: true,
                            scrollY: 'scrollVer',
                        },

                        // horizontal scrollbar for the grid
                        { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' },
                    ],
                },
                { resizer: true, width: 1 },
                {
                    rows: [
                        { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },

                        // horizontal scrollbar for the timeline
                        { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
                    ],
                },
                { view: 'scrollbar', id: 'scrollVer' },
            ],
        };

        gantt.config.type_renderers[gantt.config.types.project] = function (task: any) {
            const main_el = document.createElement('div');
            main_el.setAttribute(gantt.config.task_attribute, task.id);
            const size = (gantt as any).getTaskPosition(task);
            main_el.innerHTML = [
                "<div class='project-left' style='border-left-color: " +
                    task.color +
                    "'></div>",
                "<div class='project-right' style='border-right-color: " +
                    task.color +
                    "'></div>",
            ].join('');
            main_el.className = 'custom-project';

            main_el.style.left = size.left + 'px';
            main_el.style.top = size.top + 16 + 'px';
            main_el.style.width = size.width + 'px';

            return main_el;
        };
    };

    const parseData = async (data: IPlanning): Promise<void> => {
        // get baseline selected
        let baselineSelected: IBaselinePlanning;
        if (projectPlanningModule.baselineIdSelected) {
            const response = await projectPlanningService.getBaselineDetail(
                projectPlanningModule.baselineIdSelected,
            );
            if (response.success) {
                baselineSelected = response.data;
            } else {
                showErrorNotificationFunction(response.message);
            }
        }
        addCalendars();

        const tasks = (data.tasks || []).map((task) => {
            const baselineTask = baselineSelected?.baselineTasks?.find((baselineTask) => {
                return task._id.toString() === baselineTask.taskId?.toString();
            });

            return convertToGanttTask(
                gantt,
                task,
                projectPlanningModule.taskFieldList || [],
                baselineTask,
            );
        });

        configLinkDirection();
        const defaultCalendar = gantt.getCalendar(
            projectPlanningModule.planning?.defaultCalendar as string,
        );

        const taskLinks = (data.taskLinks || []).map((item) => {
            return {
                ...item,
                id: item._id,
                source: tasks.find((task) => task._id === item.source)?.ganttId,
                target: tasks.find((task) => task._id === item.target)?.ganttId,
                type: gantt.config[item.type],
                lag: item.lag,
                // lag: calculateLinkLag(item, defaultCalendar),
                isDrivingRelationship: item?.isDrivingRelationship ?? true,
                freeFLoat: item?.freeFloat ?? 0,
                isOnCriticalPath: item?.isOnCriticalPath ?? false,
            };
        });
        // set a delay because in some case gantt don't have enough time to parse large calendars

        setTimeout(() => {
            const filteredTasks = filterTasks(tasks);
            groupTasks(filteredTasks);

            projectPlanningModule.setFilteredTaskIds(
                filteredTasks.map((task) => task._id),
            );

            filteredTasks.sort((taskA, taskB) => {
                if (taskA?.isMilestoneFolder) {
                    return taskA?.milestoneType === MilestoneType.TOP_DOWN ? -1 : 1;
                }
                if (taskB?.isMilestoneFolder) {
                    return taskB?.milestoneType === MilestoneType.TOP_DOWN ? 1 : -1;
                }
                return 0;
            });
            gantt.parse({
                data: filteredTasks,
                links: taskLinks,
            });
        }, PARSE_DATA_TO_GANTT_DELAY);
    };

    const getValueAtColumn = (
        task: ReturnType<typeof convertToGanttTask>,
        columnType: ColumnType,
        columnName: string,
    ) => {
        if (columnType === ColumnType.ACTIVITY_CODE) {
            const activityCode = projectPlanningModule.activityCodeList.find(
                (activityCode) => activityCode.name === columnName,
            );
            const activityCodeValue = activityCode?.activityCodeValues.find(
                (activityCodeValue) =>
                    task.assignedActivityCodeValueIds.includes(activityCodeValue._id!),
            );
            return activityCodeValue?.name;
        }

        if (columnType === ColumnType.ADDITIONAL_TASK_FIELD) {
            return task.additionalFields?.[columnName];
        }

        switch (columnName) {
            case GanttColumn.PARENT_NAME:
                return gantt.getTask(task.parent).name;
            case GanttColumn.STATUS:
                return task.status ? t(`planning.gantt.status.${task.status}`) : '';
            case GanttColumn.TYPE:
                return task.type ? t(`planning.task.types.${task.taskType}`) : '';
            case GanttColumn.PR_CONSTRAINT:
                return task.primaryConstraints
                    ? t(`planning.gantt.primaryConstraints.${task.primaryConstraints}`)
                    : '';
            case GanttColumn.DURATION_TYPE:
                return task.durationType
                    ? t(`planning.gantt.durationTypes.${task.durationType}`)
                    : '';
            case GanttColumn.PERCENT_COMPLETE_TYPE:
                return task.percentageComplete
                    ? t(`planning.gantt.percentCompleteTypes.${task.percentageComplete}`)
                    : '';
            case GanttColumn.PHYSICAL_UNIT:
                return task.physicalQuantity
                    ? t(
                          `planning.gantt.physicalQuantityUnit.${task.physicalQuantityUnit}`,
                      )
                    : '';
            case GanttColumn.RESOURCE_3D:
                return task.resourceIds?.length ? task.resourceIds?.length : '';
            case GanttColumn.RESOURCE_GROUP:
                return task.resourceGroupIds?.length ? task.resourceGroupIds?.length : '';
            case GanttColumn.LONGEST_PATH:
                return task.isOnCriticalPath;

            case GanttColumn.ACTUAL_DURATION:
            case GanttColumn.AT_COMPLETE_DURATION:
            case GanttColumn.BL_ACTUAL_DURATION:
            case GanttColumn.BL_DURATION:
            case GanttColumn.ORIGINAL_DURATION:
            case GanttColumn.PLANNED_DURATION:
            case GanttColumn.REMAINING_DURATION:
            case GanttColumn.VBL_DURATION:
                return convertDurationStringFormat(task[columnName], task.calendarId!);

            default:
                return task[columnName];
        }
    };

    const filterTasks = (tasks: ReturnType<typeof convertToGanttTask>[]) => {
        const processFilterLogic = (
            valueInTask: any,
            filter: IGanttFilter,
            columnName: string,
            columnType: ColumnType,
        ) => {
            let realValueInTask: any = null;
            let filterValue: any = null;

            let dataType: TaskFieldDataType;
            if (columnType === ColumnType.ACTIVITY_CODE)
                dataType = TaskFieldDataType.STRING;
            else if (columnType === ColumnType.ADDITIONAL_TASK_FIELD) {
                const field = projectPlanningModule.taskFieldList.find(
                    (field) => field.name === columnName,
                );
                dataType = field?.dataType ?? TaskFieldDataType.STRING;
            } else {
                dataType = MAP_COLUMN_NAME_TO_VALUE_TYPE[columnName as GanttColumn];
            }

            if (dataType === TaskFieldDataType.STRING) {
                realValueInTask = valueInTask + ''; // convert to string
                filterValue = filter.value + ''; // convert to string
            } else if (dataType === TaskFieldDataType.NUMBER) {
                realValueInTask = +valueInTask; // convert to string
                filterValue = +filter.value; // convert to string
            } else if (dataType === TaskFieldDataType.DATE) {
                realValueInTask = moment(
                    moment(valueInTask).format(
                        DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                    ),
                ).unix();
                filterValue = moment(
                    moment(filter.value).format(
                        DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_COLON,
                    ),
                ).unix();
            } else if (dataType === TaskFieldDataType.BOOLEAN) {
                realValueInTask = valueInTask === true || valueInTask === true.toString();
                filterValue = filter.value === true.toString();
            }

            if (filter.operation === GanttFilterOperation.EQUALS) {
                return realValueInTask === filterValue;
            }
            if (filter.operation === GanttFilterOperation.DOES_NOT_EQUAL) {
                return realValueInTask !== filterValue;
            }
            if (filter.operation === GanttFilterOperation.CONTAINS) {
                return realValueInTask?.includes(filterValue);
            }
            if (filter.operation === GanttFilterOperation.DOES_NOT_CONTAIN) {
                return !realValueInTask?.includes(filterValue);
            }
            if (filter.operation === GanttFilterOperation.MATCHES) {
                const patter = new RegExp(filterValue);
                return patter.test(realValueInTask);
            }
            if (filter.operation === GanttFilterOperation.DOES_NOT_MATCH) {
                const patter = new RegExp(filterValue);
                return !patter.test(realValueInTask);
            }
            if (filter.operation === GanttFilterOperation.IS_GREATER_THAN) {
                return realValueInTask > filterValue;
            }
            if (filter.operation === GanttFilterOperation.IS_GREATER_THAN_OR_EQUAL_TO) {
                return realValueInTask >= filterValue;
            }
            if (filter.operation === GanttFilterOperation.IS_LESS_THAN) {
                return realValueInTask < filterValue;
            }
            if (filter.operation === GanttFilterOperation.IS_LESS_THAN_OR_EQUAL_TO) {
                return realValueInTask <= filterValue;
            }

            return false;
        };

        const planningFilters = cloneDeep(projectPlanningModule.planningFilters);
        const savedFilters = projectPlanningModule.planning?.view?.filters || [];
        savedFilters.forEach((filter) => {
            if (!filter.selected) return;

            const columnWithType =
                filter.columnType + VIEW_COLUMN_TYPE_SEPARATOR + filter.columnName;
            if (planningFilters?.[columnWithType]?.length) {
                planningFilters?.[columnWithType]?.push(filter);
            } else {
                planningFilters[columnWithType] = [filter];
            }
        });

        const logicBetweenFilters =
            projectPlanningModule.planning?.view?.logicBetweenFilters ||
            GanttFilterLogic.AND;

        const filteredColumns = Object.keys(planningFilters);

        const filteredTasks = tasks.filter((task) => {
            let matchAllFilter = true;

            // loop through each column
            filteredColumns.forEach((columnWithType, columnIndex) => {
                const columnFilters = planningFilters?.[columnWithType];
                if (columnFilters?.length) {
                    const { columnType, columnName } =
                        extractColumnWithType(columnWithType);
                    const value = getValueAtColumn(task, columnType, columnName);

                    let match = true;
                    columnFilters.forEach((filters, filterIndex) => {
                        // loop through each filter of the column
                        let filterMatch = true;
                        filters.configs.forEach((filter, filterColumnIndex) => {
                            const satisfieldLogic = processFilterLogic(
                                value,
                                filter,
                                filters.columnName,
                                filters.columnType,
                            );
                            if (filterColumnIndex === 0) {
                                filterMatch = satisfieldLogic;
                            } else if (filter.logic === GanttFilterLogic.AND) {
                                filterMatch = filterMatch && satisfieldLogic;
                            } else if (filter.logic === GanttFilterLogic.OR) {
                                filterMatch = filterMatch || satisfieldLogic;
                            }
                        });

                        if (filterIndex === 0) {
                            match = filterMatch;
                        } else {
                            if (logicBetweenFilters === GanttFilterLogic.AND) {
                                match = match && filterMatch;
                            } else {
                                match = match || filterMatch;
                            }
                        }
                    });

                    if (columnIndex === 0) {
                        matchAllFilter = match;
                    } else {
                        if (
                            projectPlanningModule.logicBetweenFilters ===
                            GanttFilterLogic.AND
                        ) {
                            matchAllFilter = matchAllFilter && match;
                        } else {
                            matchAllFilter = matchAllFilter || match;
                        }
                    }
                }
            });
            return matchAllFilter;
        });

        const checkTaskExistWithID = new Map<string, boolean>();
        filteredTasks.forEach((task) => {
            checkTaskExistWithID.set(task?._id, true);
        });
        const mapTaskIdToParentId = new Map<string, string>();
        const mapIdToTask = new Map<string, typeof tasks[0]>();
        tasks.forEach((task) => {
            mapIdToTask.set(task._id, task);
            if (task?.parentId) {
                mapTaskIdToParentId.set(task._id, task.parentId);
            }
        });
        for (let i = 0; i < filteredTasks.length; ++i) {
            let parentId = filteredTasks[i]?.parentId;
            while (parentId) {
                const parentTask = mapIdToTask.get(parentId);
                if (!parentTask) {
                    break;
                }

                if (!checkTaskExistWithID.has(parentId)) {
                    checkTaskExistWithID.set(parentId, true);
                    filteredTasks.push(parentTask);
                }
                parentId = parentTask?.parentId;
            }
        }

        return filteredTasks;
    };

    const groupTasks = (tasks: ReturnType<typeof convertToGanttTask>[]) => {
        const groupBy = projectPlanningModule.planning?.view?.groupBy;
        if (!groupBy?.columnType) {
            gantt.groupBy(false);
            return;
        }

        const values = new Set<unknown>();
        tasks.forEach((task) => {
            const value =
                getValueAtColumn(task, groupBy.columnType!, groupBy.columnName!) ?? '';
            task._groupField = value;
            values.add(value);
        });

        // Groups can be make nested, by adding relation property (_groupField
        // in my code) to the group itself.
        // Currently, grouping does NOT preserve parent-child task relationship
        // (WBS). In v8, with save_tree_structure, WBS relationship can be
        // preserved, BUT only at the last level i.e. child tasks can NOT be
        // further grouped.
        const groups = Array.from(values).map((value) => ({
            key: value,
            label: value,
        }));

        // Sort the groups here
        // groups.sort()

        gantt.groupBy({
            relation_property: '_groupField',
            groups: groups,
            group_id: 'key',
            group_text: 'label',
        });
    };

    const initGanttEvent = () => {
        if (eventInitialized) return;
        eventInitialized = true;

        gantt.attachEvent(
            'onBeforeGanttRender',
            function () {
                projectPlanningModule.taskBarIds.forEach((taskBarId) => {
                    gantt.removeTaskLayer(taskBarId);
                });
                addBars();
            },
            null,
        );

        gantt.attachEvent(
            'onDataRender',
            () => {
                if (currentMarkerFocusTimeId.value) {
                    document
                        .querySelector(
                            `[data-marker-id="${currentMarkerFocusTimeId.value}"]`,
                        )
                        ?.addEventListener('click', async (e) => {
                            e.stopPropagation();
                            await onFilter();
                        });
                }
                // addBars();
                return true;
            },
            null,
        );

        gantt.attachEvent(
            'onGanttReady',
            async () => {
                const grid = (gantt as any).$ui.getView('grid');

                await grid.attachEvent(
                    'onAfterColumnReorder',
                    async ({
                        draggedColumn,
                        targetColumn,
                    }: {
                        draggedColumn: IGanttChartColumn;
                        targetColumn: IGanttChartColumn;
                        draggedIndex: number;
                        targetIndex: number;
                    }) => {
                        if (!projectPlanningModule.planning?.view) return;
                        const columns = cloneDeep(
                            projectPlanningModule.planning.view.columns,
                        );

                        // TODO: handle edge case: column of different types with the same name
                        const targetColumnIndex = columns.findIndex(
                            (item) => item.name === targetColumn.name,
                        );
                        const draggedColumnIndex = columns.findIndex(
                            (item) => item.name === draggedColumn.name,
                        );
                        if (targetColumnIndex === -1 || draggedColumnIndex === -1)
                            return false;

                        const tmp = columns[targetColumnIndex];
                        columns[targetColumnIndex] = columns[draggedColumnIndex];
                        columns[draggedColumnIndex] = tmp;

                        const response = await projectPlanningService.updatePlanningView(
                            projectPlanningModule.planningId,
                            projectPlanningModule.planning.view._id,
                            { columns },
                        );
                        if (!response.success) return;

                        const planning = cloneDeep(projectPlanningModule.planning);
                        if (planning.view) {
                            planning.view.columns = response.data.columns;
                        }
                        projectPlanningModule.setPlanning(planning);
                    },
                    null,
                );
            },
            null,
        );

        gantt.attachEvent(
            'onColumnResizeEnd',
            async (index: number, column: IGanttChartColumn, newWidth: number) => {
                if (!projectPlanningModule.planning?.view) return;
                const columns = cloneDeep(projectPlanningModule.planning.view.columns);

                // TODO: handle edge case: column of different types with the same name
                const draggedColumnInStore = columns.find(
                    (item) => item.name === column.name,
                );
                if (!draggedColumnInStore) return false;
                draggedColumnInStore.width = newWidth;

                const response = await projectPlanningService.updatePlanningView(
                    projectPlanningModule.planningId,
                    projectPlanningModule.planning.view._id,
                    { columns },
                );

                if (response.success) {
                    const planning = cloneDeep(projectPlanningModule.planning);
                    if (planning?.view) {
                        planning.view.columns = response.data.columns;
                    }
                    projectPlanningModule.setPlanning(planning);
                }

                return true;
            },
            null,
        );

        // event fire when use click add icon in grid area
        gantt.attachEvent(
            'onTaskCreated',
            (task: IGanttChartTask) => {
                if (
                    !localStorageAuthService
                        .getPlanningPermissions()
                        ?.permissions?.includes(
                            ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING'],
                        )
                ) {
                    showErrorNotificationFunction(
                        t('planning.task.errors.insufficientPermission'),
                    );
                    return false;
                }

                // get parent task
                let tasksStore = projectPlanningModule.planning?.tasks.find(
                    (taskStore) => {
                        return (
                            taskStore._id ===
                            projectPlanningModule.taskPopupParams.selectedTaskId
                        );
                    },
                );

                // if parent task isn't selected then set parent task is Content task
                if (!tasksStore) {
                    if (projectPlanningModule.planning?.isTemplate) {
                        return false;
                    }
                    tasksStore = projectPlanningModule.planning?.tasks.find(
                        (taskStore) => {
                            return (
                                taskStore.parentId === null &&
                                taskStore.name === DefaultNameTask.CONTENT
                            );
                        },
                    );
                }

                if (
                    !tasksStore ||
                    !checkPermissionToCreateChidTask(gantt.getTask(tasksStore.ganttId))
                ) {
                    return false;
                }

                if (!projectPlanningModule.planning) {
                    return false;
                } else {
                    const calendar = gantt.getCalendar(
                        projectPlanningModule.planning?.defaultCalendar,
                    );

                    const startDate = calculateStartDateToStartOfDay(
                        projectPlanningModule.planning?.dataDate,
                        calendar,
                    );

                    const taskDuration = convertDurationFormat(
                        projectPlanningModule.planning?.defaultDuration ||
                            DefaultDurationTaskInHour.regularTask,
                        TaskDurationFormat.HOUR,
                        TaskDurationFormat.MINUTE,
                    );

                    const calculatedFinishDate = calendar?.calculateEndDate({
                        start_date: new Date(startDate),
                        duration: taskDuration,
                        unit: TaskDurationFormat.MINUTE,
                    });

                    const finishDate = calculateEndDateToEndOfDay(
                        calculatedFinishDate,
                        calendar,
                    );

                    const startDateFormatted = moment(startDate)
                        .utc()
                        .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);
                    const finishDateFormatted = moment(finishDate)
                        .utc()
                        .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);

                    // calculate lateStart and lateFinish
                    const lateFinish = calculateMaxFinishOfPlanningSingle() as Date;
                    const lateStart = calendar?.calculateEndDate({
                        start_date: new Date(lateFinish),
                        duration: -taskDuration,
                        unit: TaskDurationFormat.MINUTE,
                    });

                    const lateStartFormatted = moment(lateStart ? lateStart : startDate)
                        .utc()
                        .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);

                    const lateFinishFormatted = moment(
                        lateFinish ? lateFinish : finishDate,
                    )
                        .utc()
                        .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);

                    const newTask: ICreateProjectTaskDto = {
                        ganttId: null,
                        name: DefaultNameTask.NEW_TASK,
                        taskType:
                            projectPlanningModule.planning?.activityType ||
                            TaskType.STANDARD,
                        status: TaskStatus.TODO,
                        start: startDateFormatted,
                        actualStart: null,
                        plannedStart: startDateFormatted,
                        baselineStart: null,
                        finish: finishDateFormatted,
                        actualFinish: null,
                        plannedFinish: finishDateFormatted,
                        baselineFinish: null,
                        primaryConstraints: null,
                        primaryConstraintDate: null,
                        expectedFinish: null,
                        durationType:
                            projectPlanningModule.planning?.durationType ||
                            TaskDuration.STANDARD,
                        originalDuration: taskDuration,
                        actualDuration: null,
                        remainingDuration: taskDuration,
                        plannedDuration: taskDuration,
                        percentageComplete:
                            projectPlanningModule.planning?.percentageComplete ||
                            TaskPercentageComplete.MANUAL_COMPLETE,
                        manualComplete: null,
                        physicalQuantityUnit: null,
                        physicalQuantity: null,
                        actualPhysicalQuantity: null,
                        remainingPhysicalQuantity: null,
                        atCompletePhysicalQuantity: null,
                        rules: null,
                        parentId: tasksStore._id,
                        parentGanttId: tasksStore.ganttId,
                        additionalFields: {},
                        calendarId: projectPlanningModule.planning?.defaultCalendar,
                        lateStart: lateStartFormatted,
                        earlyStart: startDateFormatted,
                        lateFinish: lateFinishFormatted,
                        earlyFinish: finishDateFormatted,
                        durationFormat: TaskDurationFormat.MINUTE,
                    };

                    if (tasksStore.isMilestoneFolder) {
                        newTask.finish = null;
                        newTask.actualFinish = null;
                        newTask.plannedFinish = null;
                        newTask.originalDuration = 0;
                        newTask.plannedDuration = 0;
                        newTask.remainingDuration = 0;
                        newTask.taskType = TaskType.START_MILESTONE;
                    }
                    handleCreateTask(newTask);
                    return false;
                }
            },
            null,
        );

        gantt.attachEvent(
            'onAfterTaskAdd',
            async (id: string, item: IGanttChartTask) => {
                if (addingMilestoneAfterDelegate) {
                    return true;
                }
                const parentId = gantt.getParent(id);
                if (parentId) {
                    // update task type to wbs_summary
                    const parent = gantt.getTask(parentId);
                    const newParent = cloneDeep(parent);
                    newParent.type = TaskType.PROJECT;
                    newParent.taskType = TaskType.WBS_SUMMARY;
                    gantt.updateTask(parentId.toString(), newParent);
                }

                await bulkUpdateTaskAutoScheduling();
            },
            null,
        );

        // block show light box
        gantt.attachEvent(
            'onBeforeLightbox',
            () => {
                return false;
            },
            null,
        );

        gantt.attachEvent(
            'onTaskDblClick',
            (id: string, e: any) => {
                const inlineEditors = gantt.ext.inlineEditors;
                const cell = inlineEditors.locateCell(e.target);
                if (cell && inlineEditors.isVisible()) {
                    return false;
                }

                gantt.ext.inlineEditors?.hide();
                const currentTask = gantt.getTask(id);

                if (!currentTask.readonly) {
                    projectPlanningModule.setTaskPopupParams({
                        show: true,
                        selectedTask: currentTask,
                        parentOfSelectedTask: currentTask.parent
                            ? gantt.getTask(currentTask.parent)
                            : null,
                    });
                }
                return false;
            },
            null,
        );

        gantt.attachEvent(
            'onLinkDblClick',
            (id: string) => {
                const currentLink = projectPlanningModule.planning?.taskLinks.find(
                    (link) => {
                        return link._id === id;
                    },
                );
                if (!currentLink) {
                    return false;
                }
                projectPlanningModule.setLinkDetailPopupFormParam({
                    linkSelected: currentLink as ITaskLink,
                    show: true,
                });
                return false;
            },
            null,
        );

        gantt.attachEvent(
            'onBeforeTaskDrag',
            (id: string) => {
                gantt.config.auto_scheduling = false;
                const task: IGanttChartTask = gantt.getTask(id);
                if (
                    !task.parentGanttId ||
                    task.canEdit === false ||
                    task.taskType === TaskType.LEVEL_EFFORT ||
                    task.status !== TaskStatus.TODO
                ) {
                    return false;
                }
                return true;
            },
            null,
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        gantt.attachEvent(
            'onAfterTaskDrag',
            async (id: string, mode: 'resize' | 'progress' | 'move' | 'ignore') => {
                const task = gantt.getTask(id);
                if (!task.parentGanttId) {
                    return false;
                }
                if (!task.canEdit) {
                    gantt.updateTask(
                        id,
                        convertToGanttTask(
                            gantt,
                            task,
                            projectPlanningModule.taskFieldList || [],
                        ),
                    );

                    return false;
                }
                await bulkUpdateTaskAutoScheduling();
                shouldAutoScheduling();
                return true;
            },
            null,
        );

        gantt.attachEvent(
            'onBeforeTaskAutoSchedule',
            (task: IGanttChartTask, _start, _link, _predecessor) => {
                if (
                    gantt.config.is_forward &&
                    task?.constraint_type === TaskConstraint.SO &&
                    (moment(_start).isSameOrBefore(moment(task?.constraint_date)) ||
                        (!_link &&
                            !_predecessor &&
                            moment(task?.constraint_date).isSameOrAfter(
                                moment(projectPlanningModule?.planning?.dataDate),
                            )))
                ) {
                    gantt.getTask(task.id).constraint_type = TaskConstraint.MSO;

                    const calendar = gantt.getCalendar(task.calendarId as string);
                    gantt.getTask(task.ganttId).constraint_date =
                        calculateStartDateToStartOfDay(
                            new Date(task?.constraint_date as Date),
                            calendar,
                        );

                    needMoreRescheduling = true;
                    return true;
                }

                if (
                    gantt.config.is_forward &&
                    task?.constraint_type === TaskConstraint.FO
                ) {
                    const taskInStore = projectPlanningModule?.planning?.tasks?.find(
                        (_task) => _task.ganttId === task.id,
                    );
                    const currentPrimaryConstraintDate = moment(
                        taskInStore?.primaryConstraintDate,
                    );

                    const _startDate = calculateStart(
                        task.start,
                        task.finish,
                        currentPrimaryConstraintDate.fmFullTimeString(),
                        task.calendarId as string,
                    );

                    if (
                        moment(_start).isSameOrBefore(moment(_startDate)) ||
                        (!_link &&
                            !_predecessor &&
                            moment(_startDate).isSameOrAfter(
                                moment(projectPlanningModule?.planning?.dataDate),
                            ))
                    ) {
                        gantt.getTask(task.id).constraint_type = TaskConstraint.MSO;

                        gantt.getTask(task.ganttId).constraint_date = _startDate;

                        needMoreRescheduling = true;
                        return true;
                    }
                }

                if (task.taskType === TaskType.FINISH_MILESTONE) {
                    const calendar = gantt.getCalendar(task.calendarId as string);
                    const start =
                        _link?.type === +START_TO_FINISH ||
                        _link?.type === +START_TO_START
                            ? calculateStartDateToStartOfDay(_start, calendar)
                            : calculateEndDateToEndOfDay(_start, calendar);
                    (_start as Date).setTime(start.getTime());
                    (task.end_date as Date).setTime(start.getTime());
                } else if (task.taskType === TaskType.START_MILESTONE) {
                    const calendar = gantt.getCalendar(task.calendarId as string);
                    (_start as Date).setTime(
                        calculateStartDateToStartOfDay(_start, calendar).getTime(),
                    );
                }

                return true;
            },
            null,
        );

        // after auto scheduling finishes
        gantt.attachEvent(
            'onAfterAutoSchedule',
            (taskId: string | undefined, updatedTasks: string[] | []) => {
                // taskIdHasChangeList = uniq([...taskIdHasChangeList, ...updatedTasks]);
                if (needMoreRescheduling) {
                    needMoreRescheduling = false;
                    gantt.autoSchedule();
                }
            },
            null,
        );

        gantt.attachEvent(
            'onTaskClick',
            (id: string, e: any) => {
                projectPlanningModule.setSelectedTaskIdList(
                    // gantt.getSelectedTasks() || [],
                    gantt
                        .getSelectedTasks()
                        .map((ganttId) => gantt.getTask(ganttId)._id) || [],
                );

                const task = gantt.getTask(id);
                projectPlanningModule.setTaskPopupParams({
                    selectedTaskId: task._id,
                });
                projectPlanningModule.setIsDisableButtonAdd(
                    !checkPermissionToCreateChidTask(task),
                );
                const inlineEditors = gantt.ext.inlineEditors;
                const cell = inlineEditors.locateCell(e.target);

                if (!cell || !inlineEditors.isVisible()) {
                    gantt.ext.inlineEditors?.hide();
                }
                return true;
            },
            null,
        );

        gantt.attachEvent(
            'onEmptyClick',
            () => {
                if (projectPlanningModule.isMovingFocusTime) {
                    gantt.detachEvent(onMouseMoveEventId);

                    projectPlanningModule.setNeedReload3DViewer(true);
                    document
                        .querySelector(
                            `[data-marker-id="${currentMarkerFocusTimeId.value}"]`,
                        )
                        ?.addEventListener('click', async (e) => {
                            e.stopPropagation();
                            await onFilter();
                        });
                    projectPlanningModule.setIsMovingFocusTime(false);
                }
                return false;
            },
            null,
        );

        gantt.attachEvent(
            'onGridResizeEnd',
            (oldWidth: number, newWidth: number) => {
                gridWidthWithoutScrollbar = newWidth;
                ganttChartStorage.setGridWitdh(
                    newWidth < 0 ? gridWidthWithoutScrollbar : newWidth,
                );
                return true;
            },
            null,
        );

        gantt.attachEvent(
            'onTaskLoading',
            (task: IGanttChartTask) => {
                task.bl_start = gantt.date.parseDate(
                    task.baselineCurrentStart,
                    'xml_date',
                );
                task.bl_finish = gantt.date.parseDate(
                    task.baselineCurrentFinish,
                    'xml_date',
                );
                return true;
            },
            null,
        );

        gantt.attachEvent(
            'onBeforeLinkAdd',
            (id: string, link: ITaskLink) => {
                gantt.config.auto_scheduling = false;
                if ((link as any).isBackupLink) return true;
                if (
                    !localStorageAuthService
                        .getPlanningPermissions()
                        ?.permissions?.includes(
                            ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING'],
                        )
                ) {
                    showErrorNotificationFunction(
                        t('planning.task.errors.insufficientPermission'),
                    );
                    return false;
                }

                // do not allow creating links with WBS
                const sourceTask: IGanttChartTask = gantt.getTask(link.source);
                const targetTask: IGanttChartTask = gantt.getTask(link.target);
                if (
                    !sourceTask ||
                    !targetTask ||
                    sourceTask.type === TaskType.PROJECT ||
                    targetTask.type === TaskType.PROJECT ||
                    (sourceTask.taskType === TaskType.LEVEL_EFFORT &&
                        targetTask.taskType === TaskType.LEVEL_EFFORT)
                ) {
                    return false;
                }

                const linkExists =
                    gantt
                        .getLinks()
                        .findIndex(
                            (item) =>
                                item.source === link.source &&
                                link.target === item.target &&
                                link.type === item.type,
                        ) !== -1;
                // prevent gantt create duplicate link
                if (linkExists) {
                    return false;
                }
                return true;
            },
            null,
        );

        gantt.attachEvent(
            'onAfterLinkAdd',
            async (id: string, link: ITaskLink) => {
                if ((link as any).isBackupLink) return true;
                if (addingLinksForRender) {
                    return true;
                }
                const response = await projectPlanningService.createLink(
                    planningId.value as string,
                    {
                        source: gantt.getTask(link.source)._id,
                        target: gantt.getTask(link.target)._id,
                        type: getLinkType(Number(link.type)),
                        lag: 0,
                        projectId: projectModule.selectedProjectId || '',
                        path:
                            localStorageAuthService.getPlanningPermissions()?.path || '',
                        isDrivingRelationship: true,
                        freeFloat: 0,
                        isOnCriticalPath: false,
                    },
                );

                if (response.success) {
                    // calculate start and finish date to 0h
                    const sourceTask = gantt.getTask(link.source);
                    const sourceCalendar = gantt.getCalendar(sourceTask.calendarId);
                    sourceTask.start_date = calculateStartDateToStartOfDay(
                        sourceTask.start_date as Date,
                        sourceCalendar,
                    );
                    sourceTask.end_date = calculateEndDateToEndOfDay(
                        sourceTask.end_date as Date,
                        sourceCalendar,
                    );
                    const targetTask = gantt.getTask(link.target);
                    const targetCalendar = gantt.getCalendar(targetTask.calendarId);
                    targetTask.start_date = calculateStartDateToStartOfDay(
                        targetTask.start_date as Date,
                        targetCalendar,
                    );
                    targetTask.end_date = calculateEndDateToEndOfDay(
                        targetTask.end_date as Date,
                        targetCalendar,
                    );

                    await bulkUpdateTaskAutoScheduling();
                    projectPlanningModule.setCreatedLink(true);
                    gantt.changeLinkId(id, response.data.link._id);
                    projectPlanningModule.planning?.taskLinks.push(response.data.link);
                    showSuccessNotificationFunction(
                        t('planning.task.messages.createdLink'),
                    );

                    const mapTaskIdToType = new Map<string, TaskType>();
                    projectPlanningModule.planning?.tasks.forEach((task) => {
                        mapTaskIdToType.set(task._id, task.taskType);
                    });
                    const newIds = [];
                    if (
                        mapTaskIdToType.get(response.data.link.source) ===
                        TaskType.STANDARD
                    ) {
                        newIds.push(response.data.link.source);
                    }
                    if (
                        mapTaskIdToType.get(response.data.link.target) ===
                        TaskType.STANDARD
                    ) {
                        newIds.push(response.data.link.target);
                    }
                    projectPlanningModule.setEditedTaskIds(
                        uniq([...projectPlanningModule.editedTaskIds, ...newIds]),
                    );
                    if (newIds.length) {
                        projectPlanningModule.setEditedLinkIds(
                            uniq([
                                ...projectPlanningModule.editedLinkIds,
                                response.data.link._id,
                            ]),
                        );
                    }
                    shouldAutoScheduling();
                    return true;
                } else {
                    const createdLinks = gantt.getLinks();
                    if (createdLinks.length) {
                        gantt.deleteLink(id);
                    }
                    showErrorNotificationFunction(response.message);
                }
                return true;
            },
            null,
        );

        const isTaskDisplaying = (taskId: string): boolean => {
            const task = gantt.getTask(taskId);
            let taskValue = task?.[searchColumn.value];
            if (!searchValue.value?.length) {
                return true;
            }
            if (searchColumn.value === GanttColumn.PARENT_ID) {
                taskValue = task.parent ? task.parent : '';
            } else if (searchColumn.value === GanttColumn.PARENT_NAME) {
                if (!task.parent) {
                    taskValue = '';
                } else {
                    taskValue = gantt.getTask(task.parent).name;
                }
            } else if (searchColumn.value === GanttColumn.STATUS) {
                taskValue = task.status ? t(`planning.gantt.status.${task.status}`) : '';
            } else if (searchColumn.value === GanttColumn.TYPE) {
                if (!task.type) {
                    taskValue = '';
                } else {
                    taskValue = t(`planning.task.types.${task.taskType}`);
                }
            } else if (searchColumn.value === GanttColumn.START) {
                if (!task.start || task.taskType === TaskType.FINISH_MILESTONE) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.start).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.PLANNED_START) {
                if (task.status !== TaskStatus.TODO) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.plannedStart).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.ACTUAL_START) {
                if (!task.actualStart) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.actualStart).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.EARLY_START) {
                if (!task.plannedStart) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.plannedStart).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.LATE_START) {
                taskValue =
                    task.plannedStart && gantt.getTotalSlack(task)
                        ? calcDateIgnoreNonWorkingTime(
                              task.plannedStart,
                              gantt.getTotalSlack(task),
                              [9, 13, 14, 18],
                          )
                        : undefined;
            } else if (searchColumn.value === GanttColumn.BL_START) {
                if (!task.baselineStart) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.baselineStart).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.FINISH) {
                if (!task.finish || task.taskType === TaskType.START_MILESTONE) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.finish).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.ACTUAL_FINISH) {
                if (!task.actualFinish) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.actualFinish).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.EARLY_FINISH) {
                if (!task.plannedFinish) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.plannedFinish).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.LATE_FINISH) {
                taskValue =
                    task.plannedFinish && gantt.getTotalSlack(task)
                        ? calcDateIgnoreNonWorkingTime(
                              task.plannedFinish,
                              gantt.getTotalSlack(task),
                              [9, 13, 14, 18],
                          )
                        : null;
            } else if (searchColumn.value === GanttColumn.PLANNED_FINISH) {
                if (!task.plannedFinish) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.plannedFinish).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.BL_FINISH) {
                taskValue = task.baselineFinish
                    ? moment(task.baselineFinish).fmDayString()
                    : undefined;
            } else if (searchColumn.value === GanttColumn.PR_CONSTRAINT) {
                taskValue = task.primaryConstraints
                    ? t(`planning.gantt.primaryConstraints.${task.primaryConstraints}`)
                    : '';
            } else if (searchColumn.value === GanttColumn.PR_CONSTRAINT_DATE) {
                if (!task.primaryConstraintDate) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.primaryConstraintDate).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.EXPECTED_FINISH) {
                if (!task.expectedFinish) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.expectedFinish).fmDayString();
                }
            } else if (searchColumn.value === GanttColumn.DURATION_TYPE) {
                taskValue = task.durationType
                    ? t(`planning.gantt.durationTypes.${task.durationType}`)
                    : '';
            } else if (searchColumn.value === GanttColumn.AT_COMPLETE_DURATION) {
                taskValue = (task.actualDuration || 0) + (task.remainingDuration || 0);
            } else if (searchColumn.value === GanttColumn.BL_DURATION) {
                taskValue = calculateDuration(
                    task.bl_start as Date,
                    task.bl_finish as Date,
                    task.calendarId,
                );
            } else if (searchColumn.value === GanttColumn.VBL_DURATION) {
                taskValue = calculateDuration(
                    task.bl_start as Date,
                    task.bl_finish as Date,
                    task.calendarId,
                );
            } else if (searchColumn.value === GanttColumn.VBL_FINISH_DATE) {
                if (!task.finish || !task.baselineFinish) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.finish).diff(task.baselineFinish, 'days');
                }
            } else if (searchColumn.value === GanttColumn.VBL_START_DATE) {
                if (!task.start || !task.baselineStart) {
                    taskValue = undefined;
                } else {
                    taskValue = moment(task.start).diff(task.baselineStart, 'days');
                }
            } else if (searchColumn.value === GanttColumn.FREE_FLOAT) {
                taskValue = gantt.getFreeSlack(task);
            } else if (searchColumn.value === GanttColumn.TOTAL_FLOAT) {
                taskValue = gantt.getTotalSlack(task);
            } else if (searchColumn.value === GanttColumn.PERCENT_COMPLETE_TYPE) {
                taskValue = task.percentageComplete
                    ? t(`planning.gantt.percentCompleteTypes.${task.percentageComplete}`)
                    : '';
            } else if (searchColumn.value === GanttColumn.PHYSICAL_PERCENTAGE) {
                if (task.actualPhysicalQuantity && task.physicalQuantity) {
                    taskValue = task.actualPhysicalQuantity / task.physicalQuantity;
                } else {
                    taskValue = undefined;
                }
            } else if (searchColumn.value === GanttColumn.DURATION_PERCENTAGE) {
                if (task.actualDuration && task.remainingDuration) {
                    taskValue =
                        task.actualDuration /
                        (task.actualDuration + task.remainingDuration);
                } else {
                    taskValue = undefined;
                }
            } else if (searchColumn.value === GanttColumn.MANUAL_PERCENTAGE) {
                taskValue = task.manualComplete;
            } else if (searchColumn.value === GanttColumn.PHYSICAL_UNIT) {
                taskValue = task.physicalQuantity
                    ? t(
                          `planning.gantt.physicalQuantityUnit.${task.physicalQuantityUnit}`,
                      )
                    : '';
            } else if (searchColumn.value === GanttColumn.REMAIN_PHYSICAL_QUANTITY) {
                taskValue = task.remainingPhysicalQuantity
                    ? task.remainingPhysicalQuantity
                    : undefined;
            } else if (searchColumn.value === GanttColumn.RESOURCE_3D) {
                taskValue = task.resourceIds?.length ? task.resourceIds?.length : '';
            } else if (searchColumn.value === GanttColumn.RESOURCE_GROUP) {
                taskValue = task.resourceGroupIds?.length
                    ? task.resourceGroupIds?.length
                    : '';
            } else if (searchColumn.value === GanttColumn.ACTIVITY_CODE) {
                taskValue = task.activityCode;
            } else if (searchColumn.value === GanttColumn.ACTIVITY_CODE_VALUE) {
                taskValue = task.activityCodeValue;
            }

            if (
                searchType.value === SearchTaskOption.APPROPRIATE &&
                taskValue
                    ?.toLocaleLowerCase()
                    ?.includes(searchValue.value.toLocaleLowerCase())
            ) {
                return true;
            }
            if (
                searchType.value === SearchTaskOption.EXACT &&
                taskValue?.toString() === searchValue.value
            ) {
                return true;
            }

            const children = gantt.getChildren(taskId);
            for (let i = 0; i < children.length; i++) {
                if (isTaskDisplaying(children[i])) return true;
            }
            return false;
        };

        gantt.attachEvent(
            'onBeforeTaskDisplay',
            (id, task: IGanttChartTask) => {
                // calculate start and finish date to 0h
                // task.start_date = calculateStartDateToStartOfDay(task.start_date as Date);
                // task.end_date = calculateEndDateToEndOfDay(task.end_date as Date);
                task.start_date = task.start_date as Date;
                task.end_date = task.end_date as Date;

                if (isTaskDisplaying(id)) {
                    return true;
                }

                return false;
            },
            {},
        );

        gantt.attachEvent(
            'onContextMenu',
            (taskId: string, linkId: string, event) => {
                if (taskId || linkId) {
                    event.preventDefault();
                    if (taskId) {
                        const task: IGanttChartTask = gantt.getTask(taskId) || null;
                        gantt.eachSelectedTask((id) => {
                            gantt.unselectTask(id);
                        });
                        gantt.selectTask(taskId);

                        const parentTask = task.parent
                            ? gantt.getTask(task.parent)
                            : null;

                        projectPlanningModule.setGanttContextMenuParam({
                            task,
                            link: null,
                            show: true,
                            top:
                                event.clientY +
                                document.body.scrollTop +
                                document.documentElement.scrollTop,
                            left:
                                event.clientX +
                                document.body.scrollLeft +
                                document.documentElement.scrollLeft,
                            permissionAddLink: task
                                ? checkPermissionToAddLink(task.id)
                                : false,
                            permissionCurrentLink: task
                                ? checkPermissionToCurrentLink(task.id)
                                : false,
                            parentTask,
                        });
                    }
                }

                return true;
            },
            null,
        );

        gantt.addShortcut(
            ACTION.DELETE,
            async function (e) {
                const taskIds = projectPlanningModule.selectedTaskIdList;
                if (!taskIds.length) return;
                const confirm = await showConfirmPopUpFunction(
                    t('planning.task.messages.confirmBulkDelete', {
                        count: taskIds.length,
                    }),
                    t('planning.task.messages.titleConfirmBulkDelete'),
                );
                if (confirm === ACTION.CONFIRM) {
                    const loading = ElLoading.service({});
                    const response = await projectPlanningService.bulkDeleteTask({
                        taskIds,
                        projectId: projectModule.selectedProjectId || '',
                        path: `${projectPlanningModule.planning?.planningFilePath}/${projectPlanningModule.planning?.name}${ABSUploadedFileExtensions.PLANNING}`.replace(
                            '//',
                            '/',
                        ),
                    });
                    if (response.success) {
                        // delete coresponding task in task store
                        const ganttIdTaskDeleted: string[] = [];
                        const parentTaskIds: string[] = [];
                        const currentTasks = (
                            projectPlanningModule.planning?.tasks || []
                        ).filter((task) => {
                            if (response.data.deletedTaskIds.includes(task._id)) {
                                ganttIdTaskDeleted.push(task.ganttId);
                                if (task.parentGanttId) {
                                    parentTaskIds.push(task.parentGanttId);
                                }
                                return false;
                            }
                            return true;
                        });

                        uniq(parentTaskIds).forEach((taskId) => {
                            const parentTask = currentTasks.find((_task) => {
                                return _task.ganttId === taskId;
                            });

                            if (
                                parentTask &&
                                !currentTasks.some(
                                    (_task) => _task.parentGanttId === taskId,
                                )
                            ) {
                                const parentTaskInGantt = gantt.getTask(
                                    parentTask.ganttId,
                                );
                                parentTask.taskType = TaskType.STANDARD;

                                if (parentTask.isRootWbs) {
                                    parentTaskInGantt.originalDuration = 0;
                                    (parentTaskInGantt.end_date as Date).setTime(
                                        parentTaskInGantt.start_date.getTime(),
                                    );
                                    parentTask.originalDuration = 0;
                                    parentTask.finish = parentTask.start;
                                    projectPlanningService.updateTask(parentTask._id, {
                                        ...convertGanttTaskToTaskDto(
                                            durationStringFormat,
                                            calculateDuration,
                                            gantt,
                                            {
                                                ...parentTaskInGantt,
                                            },
                                        ),
                                        projectId: projectModule.selectedProjectId || '',
                                        path:
                                            localStorageAuthService.getPlanningPermissions()
                                                ?.path || '',
                                    });
                                }
                                gantt.getTask(parentTask.ganttId).type =
                                    TaskType.STANDARD;
                                updateOneTask(parentTask);
                            }
                        });

                        projectPlanningModule.setPlanning({
                            ...(projectPlanningModule.planning as IPlanning),
                            tasks: currentTasks,
                        });
                        // delete task in UI
                        ganttIdTaskDeleted.forEach((id) => {
                            if (id) {
                                gantt?.deleteTask(id);
                            }
                        });
                        showSuccessNotificationFunction(
                            t('planning.task.messages.bulkDeleted'),
                        );
                        projectPlanningModule.setTaskPopupParams({
                            show: false,
                            selectedTask: null,
                            parentOfSelectedTask: null,
                        });
                        projectPlanningModule.setSelectedTaskIdList([]);
                        projectPlanningModule.setNeedReload3DViewer(true);
                    } else if (!response.isRequestError) {
                        showErrorNotificationFunction(response.message);
                    }
                    shouldAutoScheduling();
                    loading.close();
                }
            },
            'taskRow',
        );

        gantt.attachEvent(
            'onAfterTaskDelete',
            async function (id, item) {
                const oldTask = (projectPlanningModule.planning?.tasks || []).find(
                    (task) => task.ganttId === id,
                );

                if (oldTask) {
                    const response = await projectPlanningService.deleteTask(
                        oldTask?._id as string,
                    );
                    if (response.success) {
                        // delete coresponding task in task store
                        const ganttIdTaskDeleted: string[] = [];
                        const currentTasks = (
                            projectPlanningModule.planning?.tasks || []
                        ).filter((task) => {
                            if (response.data._ids.includes(task._id)) {
                                ganttIdTaskDeleted.push(task.ganttId);
                                return false;
                            }
                            return true;
                        });
                        const parentTask = currentTasks.find((_task) => {
                            return _task.ganttId === oldTask?.parentGanttId;
                        });

                        if (
                            parentTask &&
                            !currentTasks.some(
                                (_task) =>
                                    _task.parentGanttId === oldTask?.parentGanttId &&
                                    _task._id !== oldTask._id,
                            )
                        ) {
                            parentTask.taskType = TaskType.STANDARD;
                            gantt.getTask(parentTask.ganttId).type = TaskType.STANDARD;
                            updateOneTask(parentTask);
                        }

                        projectPlanningModule.setPlanning({
                            ...(projectPlanningModule.planning as IPlanning),
                            tasks: currentTasks,
                        });
                        // delete task in UI
                        showSuccessNotificationFunction(
                            t('planning.task.messages.deleted'),
                        );
                        projectPlanningModule.setNeedReload3DViewer(true);

                        shouldAutoScheduling();
                    } else if (!response.isRequestError) {
                        showErrorNotificationFunction(response.message);
                    }
                }
            },
            null,
        );
    };

    const initEditInlineEvent = () => {
        const inlineEditors = gantt.ext.inlineEditors;

        inlineEditors.attachEvent('onSave', async (state: any) => {
            if (projectPlanningModule.isDisabledInlineEdit) {
                return;
            }

            projectPlanningModule.setIsDisableInlineEdit(true);
            const currentTask = gantt.getTask(state.id);
            const oldTask = cloneDeep(currentTask);
            const calendar = gantt.getCalendar(currentTask.calendarId);
            if (
                (state.columnName === GanttColumn.ACTUAL_START &&
                    currentTask.status !== TaskStatus.TODO) ||
                (state.columnName === GanttColumn.PLANNED_START &&
                    currentTask.status === TaskStatus.TODO) ||
                state.columnName === GanttColumn.START
            ) {
                currentTask.start_date.setTime(
                    new Date(
                        calculateStartDateToStartOfDay(state.newValue, calendar),
                    ).getTime(),
                );
            } else if (
                (state.columnName === GanttColumn.ACTUAL_FINISH &&
                    currentTask.status === TaskStatus.FINISHED) ||
                (state.columnName === GanttColumn.PLANNED_FINISH &&
                    currentTask.status === TaskStatus.TODO) ||
                state.columnName === GanttColumn.FINISH
            ) {
                currentTask.end_date.setTime(
                    new Date(
                        calculateEndDateToEndOfDay(state.newValue, calendar),
                    ).getTime(),
                );
            } else if (state.columnName === GanttColumn.ORIGINAL_DURATION) {
                if (currentTask.status === TaskStatus.TODO) {
                    const newFinish = calculateFinish(
                        state.newValue,
                        currentTask.start_date,
                        currentTask.calendarId,
                        false,
                    );

                    currentTask.end_date.setTime(new Date(newFinish).getTime());
                } else {
                    currentTask.plannedFinish = calculateFinish(
                        state.newValue,
                        currentTask.plannedStart,
                        currentTask.calendarId,
                        false,
                    );
                }
            } else if (
                state.columnName === GanttColumn.PLANNED_DURATION &&
                currentTask.status === TaskStatus.TODO
            ) {
                const newFinish = calculateFinish(
                    state.newValue,
                    currentTask.start_date,
                    currentTask.calendarId,
                    false,
                );

                currentTask.end_date.setTime(new Date(newFinish).getTime());
            } else if (
                state.columnName === GanttColumn.ACTUAL_DURATION &&
                currentTask.status === TaskStatus.FINISHED
            ) {
                const newFinish = calculateFinish(
                    state.newValue,
                    currentTask.start_date,
                    currentTask.calendarId,
                    false,
                );

                currentTask.end_date.setTime(new Date(newFinish).getTime());
            } else if (
                state.columnName === GanttColumn.REMAINING_DURATION &&
                currentTask.status === TaskStatus.IN_PROGRESS
            ) {
                const newFinish = calculateFinish(
                    state.newValue,
                    projectPlanningModule.planning?.dataDate?.toString() || '',
                    currentTask.calendarId,
                    false,
                );

                currentTask.end_date.setTime(new Date(newFinish).getTime());
            } else if (
                state.columnName === GanttColumn.DURATION_PERCENTAGE &&
                currentTask.status === TaskStatus.IN_PROGRESS
            ) {
                const remainingDuration =
                    (currentTask.plannedDuration *
                        (DefaultMaxDurationComplete - state.newValue)) /
                    DefaultMaxDurationComplete;

                const newFinish = calculateFinish(
                    remainingDuration,
                    projectPlanningModule.planning?.dataDate?.toString() || '',
                    currentTask.calendarId,
                    false,
                );
                currentTask.end_date.setTime(new Date(newFinish).getTime());
            } else if (
                state.columnName === GanttColumn.ID &&
                state.id !== state.newValue
            ) {
                const duplicateTask = gantt.getTask(state.newValue);
                if (duplicateTask) {
                    showErrorNotificationFunction(
                        t('planning.task.errors.ganttIdDuplicate'),
                    );
                    projectPlanningModule.setIsDisableInlineEdit(false);
                    return;
                }
                gantt?.changeTaskId(state.id, state.newValue);
            } else if (
                state.columnName === GanttColumn.RULES &&
                !state.newValue?.length
            ) {
                currentTask.rules = null;
            }

            const responseTask = await projectPlanningService.updateTask(
                currentTask?._id,
                {
                    ...convertGanttTaskToTaskDto(
                        durationStringFormat,
                        calculateDuration,
                        gantt,
                        {
                            ...currentTask,
                            calendarDuration: gantt.calculateDuration(currentTask),
                        },
                    ),
                    projectId: projectModule.selectedProjectId || '',
                    path: localStorageAuthService.getPlanningPermissions()?.path || '',
                },
            );

            if (responseTask?.success) {
                const currentTaskIndex = projectPlanningModule.planning?.tasks.findIndex(
                    (_task) => _task.ganttId === responseTask?.data?.ganttId,
                );
                if (currentTaskIndex !== undefined && currentTaskIndex !== -1) {
                    const clonedPlanning = cloneDeep(projectPlanningModule.planning);
                    if (clonedPlanning?.tasks) {
                        clonedPlanning.tasks[currentTaskIndex] = cloneDeep(
                            responseTask?.data,
                        );
                        projectPlanningModule.setPlanning(clonedPlanning);
                    }
                }
                const ganttTask = convertToGanttTask(
                    gantt,
                    responseTask?.data,
                    projectPlanningModule.taskFieldList || [],
                );
                gantt.updateTask(responseTask?.data.ganttId, ganttTask);
                showSuccessNotificationFunction(t('planning.task.messages.updatedTask'));
                shouldAutoScheduling();
            } else {
                showErrorNotificationFunction(responseTask.message as string);

                if (state.columnName === GanttColumn.ID && state.id !== state.newValue) {
                    gantt?.changeTaskId(state.newValue, state.id);
                } else {
                    const currentTaskInStore = projectPlanningModule.planning?.tasks.find(
                        (_task) => _task._id === currentTask?._id,
                    );

                    if (currentTaskInStore) {
                        const ganttTask = convertToGanttTask(
                            gantt,
                            currentTaskInStore,
                            projectPlanningModule.taskFieldList || [],
                        );
                        gantt.updateTask(currentTaskInStore.ganttId, ganttTask);
                    }
                }
            }
            projectPlanningModule.setIsDisableInlineEdit(false);
        });
    };

    const addGanttTaskLayer = () => {
        const dataSettingBaseline = projectPlanningModule.planning?.setting?.baselines;
        const layer_id = gantt.addTaskLayer({
            renderer: {
                render: function draw_planned(task: IGanttChartTask) {
                    if (task.bl_start && task.bl_finish) {
                        const sizes = gantt.getTaskPosition(
                            task,
                            task.bl_start,
                            task.bl_finish,
                        );
                        const el = document.createElement('div');
                        el.className = 'gantt-task-baseline';
                        el.style.backgroundColor =
                            dataSettingBaseline?.color || '#FFD180';
                        el.style.left = sizes.left + 'px';
                        el.style.width = sizes.width + 'px';

                        if (task.type === TaskType.MILESTONE) {
                            el.className =
                                'gantt-task-baseline gantt-task-baseline-milestone';
                            el.style.left = sizes.left - 12 + 'px';
                            el.style.width = '22px';
                            el.style.top =
                                sizes.top + gantt.config.bar_height + 31 + 'px';
                            el.style.height = '22px';
                            el.style.transform = 'rotate(45deg)';
                            return el;
                        }

                        switch (dataSettingBaseline?.position) {
                            case 'top':
                                el.style.top =
                                    sizes.top + gantt.config.bar_height + 27 + 'px';
                                if (task.taskType === TaskType.WBS_SUMMARY) {
                                    el.style.height = '8px';
                                } else el.style.height = '10px';
                                break;
                            case 'bottom':
                                el.style.top =
                                    sizes.top + gantt.config.bar_height + 43 + 'px';
                                if (task.taskType === TaskType.WBS_SUMMARY) {
                                    el.style.height = '8px';
                                } else el.style.height = '10px';
                                break;
                            default:
                                el.style.top =
                                    sizes.top + gantt.config.bar_height + 26 + 'px';
                                if (task.taskType === TaskType.WBS_SUMMARY) {
                                    el.style.height = '24px';
                                } else el.style.height = '36px';
                                break;
                        }

                        return el;
                    }
                    return false;
                },
                // define getRectangle in order to hook layer with the smart rendering
                getRectangle: function (task: IGanttChartTask, view: any) {
                    if (task.bl_start && task.bl_finish) {
                        return gantt.getTaskPosition(task, task.bl_start, task.bl_finish);
                    }
                    return null;
                },
            },
        });

        projectPlanningModule.setTaskLayerId(layer_id);
    };

    const addBars = () => {
        const dataDate = new Date(projectPlanningModule.planning?.dataDate as Date);
        const taskBarCounts = new Map<string, string[]>();
        const taskBarIds: string[] = [];
        projectPlanningModule.planning?.view?.bars?.forEach((bar) => {
            if (bar) {
                const barConfig = BarsConfig[
                    bar.barType as keyof typeof BarsConfig
                ] as any;

                const barId = gantt.addTaskLayer({
                    id: bar.barType,
                    renderer: {
                        render: (task: any) => {
                            const el = document.createElement('div');
                            let sizes;
                            if (
                                bar.barType === Bars.LOE_ACTUAL ||
                                bar.barType === Bars.STANDARD_ACTUAL
                            ) {
                                if (task.status === TaskStatus.IN_PROGRESS) {
                                    sizes = gantt.getTaskPosition(
                                        task,
                                        new Date(task?.start),
                                        dataDate,
                                    );
                                } else {
                                    sizes = gantt.getTaskPosition(
                                        task,
                                        new Date(task?.start),
                                        new Date(task?.finish),
                                    );
                                }
                            } else {
                                sizes = gantt.getTaskPosition(
                                    task,
                                    barConfig?.startField === 'dataDate'
                                        ? dataDate
                                        : new Date(task?.[barConfig?.startField]),
                                    barConfig?.endField === 'dataDate'
                                        ? dataDate
                                        : new Date(task?.[barConfig?.endField]),
                                );
                            }
                            const currentTaskBarCount =
                                taskBarCounts.get(task.ganttId) || [];
                            const currentBarIndex = currentTaskBarCount.findIndex(
                                (_bar) => _bar === bar.barType,
                            );
                            if (+sizes.width) {
                                el.style.left = sizes.left + 'px';
                                el.style.top =
                                    sizes.top +
                                    35 +
                                    (currentBarIndex !== -1
                                        ? currentBarIndex
                                        : currentTaskBarCount.length) *
                                        4 +
                                    'px';
                                el.style.width = sizes.width + 'px';
                                el.style.height = 6 + 'px';
                                el.style.zIndex = MAX_BARS_COUNT - bar.order + '';
                                el.style.position = 'absolute';
                                el.style.backgroundColor = barConfig?.color || '#FFD180';
                                if (!currentTaskBarCount.includes(bar.barType)) {
                                    taskBarCounts.set(task.ganttId, [
                                        ...currentTaskBarCount,
                                        bar.barType,
                                    ]);
                                }
                            }

                            return el;
                        },
                        // define getRectangle in order to hook layer with the smart rendering
                        getRectangle: function (task: any, view: any) {
                            if (
                                bar.barType === Bars.LOE_ACTUAL &&
                                task.taskType === TaskType.LEVEL_EFFORT
                            ) {
                                if (task.status === TaskStatus.IN_PROGRESS) {
                                    return gantt.getTaskPosition(
                                        task,
                                        new Date(task?.start),
                                        dataDate,
                                    );
                                } else {
                                    return gantt.getTaskPosition(
                                        task,
                                        new Date(task?.start),
                                        new Date(task?.finish),
                                    );
                                }
                            }

                            if (
                                bar.barType === Bars.STANDARD_ACTUAL &&
                                task.taskType === TaskType.STANDARD
                            ) {
                                if (task.status === TaskStatus.IN_PROGRESS) {
                                    return gantt.getTaskPosition(
                                        task,
                                        new Date(task?.start),
                                        dataDate,
                                    );
                                } else if (task.status === TaskStatus.FINISHED) {
                                    return gantt.getTaskPosition(
                                        task,
                                        new Date(task?.start),
                                        new Date(task?.finish),
                                    );
                                } else {
                                    return null;
                                }
                            }

                            if (
                                (bar.barType === Bars.STANDARD_REMAINING_CRITICAL &&
                                    !task.critical) ||
                                (bar.barType === Bars.STANDARD_REMAINING_NO_CRITICAL &&
                                    task.critical)
                            ) {
                                return null;
                            }

                            if (
                                barConfig?.startField &&
                                barConfig?.endField &&
                                (!barConfig?.taskTypes ||
                                    barConfig?.taskTypes.includes(task.taskType)) &&
                                (barConfig?.startField === 'dataDate' ||
                                    task?.[barConfig?.startField]) &&
                                (barConfig?.startField === 'dataDate' ||
                                    task?.[barConfig?.endField])
                            ) {
                                return gantt.getTaskPosition(
                                    task,
                                    barConfig?.startField === 'dataDate'
                                        ? dataDate
                                        : new Date(task?.[barConfig?.startField]),
                                    barConfig?.endField === 'dataDate'
                                        ? dataDate
                                        : new Date(task?.[barConfig?.endField]),
                                );
                            }
                            return null;
                        },
                    },
                });

                taskBarIds.push(barId);
            }
        });
        taskBarCounts.clear();
        projectPlanningModule.setTaskBarIds(taskBarIds);
    };

    const changeTaskLayer = () => {
        gantt.removeTaskLayer(projectPlanningModule.taskLayerId);
        addGanttTaskLayer();
    };

    const configChart = () => {
        gantt.plugins({
            multiselect: true,
            marker: true,
            collision: false,
            keyboard_navigation: true,
            critical_path: true,
            grouping: true,
        });
        configAutoScheduling(false);
        configGanttCommon();
        configZooming();
        configLinkDirection();
        configGanttLabels();
        initGanttTemplates();
        initGanttEvent();
        initEditInlineEvent();
        addGanttTaskLayer();
        projectPlanningModule.taskBarIds.forEach((taskBarId) => {
            gantt.removeTaskLayer(taskBarId);
        });
        addBars();

        projectPlanningModule.setIsDisableInlineEdit(false);
    };

    const fetchData = async () => {
        const response = await getPlanning();
        if (response?.success) {
            await parseData(response?.data);
        }
    };

    const updateFilter = async (forceFetchData = true) => {
        await initChart(forceFetchData);
        await configChart();
        const planning = cloneDeep(projectPlanningModule.planning);
        if (!forceFetchData) {
            if (planning) {
                parseData(planning);
            }
        }

        gantt.config.columns = getVisibleColumnConfiguration(
            gantt,
            projectPlanningModule.planning?.view?.columns ?? [],
            convertDurationStringFormat,
            durationStringFormat,
            calculateDuration,
            t,
        );

        gantt.render();
    };

    const addCalendars = () => {
        const calendars = projectPlanningModule.planning?.calendars || [];
        const calendarsInStorage = ganttChartStorage.getCalendar() || [];

        calendars.forEach((calendar) => {
            const defaultWorkHours =
                calendar.dayType?.timeBlocks.map(
                    (timeBlock) => `${timeBlock.startTime}-${timeBlock.endTime}`,
                ) || defaultWorkTimeBlocks;
            const calendarId = gantt.addCalendar({
                id: calendar._id,
                worktime: {
                    hours: defaultWorkHours,
                    days: calendar.weekDays
                        ? defaultWorkingDay.map((weekDay) =>
                              calendar.weekDays?.includes(+weekDay) ? 1 : 0,
                          )
                        : defaultWeekDays,
                },
            });

            const ganttCalendar = gantt.getCalendar(calendarId);

            ganttChartStorage.setCalendar(calendarsInStorage);

            calendar.configs.forEach((config, i) => {
                let workTimeData = {};
                const mapDateToWorkHours = new Map<string, string[]>();
                const workHours = config?.dayType?.timeBlocks?.map(
                    (timeBlock) => `${timeBlock.startTime}-${timeBlock.endTime}`,
                );
                if (config.date) {
                    const momentDate = moment(config.date).clone();
                    workTimeData = {
                        date: momentDate.toDate(),
                        hours: workHours ? workHours : false,
                    };
                    ganttCalendar.setWorkTime(workTimeData);
                    if (calendar.isDefaultCalendar) {
                        gantt.setWorkTime(workTimeData);
                    }
                    mapDateToWorkHours.set(
                        moment(config.date).format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN),
                        workHours,
                    );
                } else {
                    getDaysBetweenDates(
                        config.startDateAt || calendar.startDateAt || '',
                        config.endDateAt || calendar.endDateAt || '',
                        DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN,
                        undefined,
                        config.weekDay,
                    ).forEach((date) => {
                        if (!mapDateToWorkHours.has(date)) {
                            const momentDate = moment(date).clone();
                            workTimeData = {
                                date: momentDate.toDate(),
                                hours: workHours ? workHours : false,
                            };
                            ganttCalendar.setWorkTime(workTimeData);
                            if (calendar.isDefaultCalendar) {
                                gantt.setWorkTime(workTimeData);
                            }
                            mapDateToWorkHours.set(
                                moment(config.date).format(
                                    DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN,
                                ),
                                workHours,
                            );
                        }
                    });
                }
            });
        });
    };

    const initChart = async (forceFetchData: boolean) => {
        gantt.clearAll();
        configAutoScheduling(false);
        if (forceFetchData) {
            await fetchData();
        }
        if (!gantt.config.initFlag) {
            configChart();
            gantt.config.initFlag = true;
        }
        // ensure that template must be re-config
        initGanttTemplates();
        gantt.init(
            'gantt-chart-4d-planning',
            // new Date('03-04-2020 00:00'),
            // new Date('03-04-2025 00:00'),
        );
        gantt.config.show_errors = false;
        initMakers();
        fillMissingColumnSettings();
        ganttOptionView();
        initEditInlineEvent();
    };

    const ganttOptionView = () => {
        const ganttSettingView = projectPlanningModule.planning?.view?.gantt;
        if (ganttSettingView) {
            gantt.config.skip_off_time = !ganttSettingView?.isShowNonWorkTimeShading;
        }
    };

    const handleAfterDelegateTask = async () => {
        addingLinksForRender = true;
        addingMilestoneAfterDelegate = true;
        const response = await getPlanning();
        if (response?.success) {
            const tasks = (response?.data?.tasks || []).map((task) => {
                return convertToGanttTask(
                    gantt,
                    task,
                    projectPlanningModule.taskFieldList || [],
                );
            });

            const taskLinks = (response?.data?.taskLinks || []).map((item) => {
                return {
                    ...item,
                    id: item._id,
                    source: tasks.find((task) => task._id === item.source)?.ganttId,
                    target: tasks.find((task) => task._id === item.target)?.ganttId,
                    type: gantt.config[item.type],
                    lag: item.lag,
                    // lag: calculateLinkLag(item, defaultCalendar),
                    isDrivingRelationship: item?.isDrivingRelationship ?? true,
                    freeFLoat: item?.freeFloat ?? 0,
                    isOnCriticalPath: item?.isOnCriticalPath ?? false,
                };
            });

            const filteredTasks = filterTasks(tasks);
            groupTasks(filteredTasks);

            filteredTasks.sort((taskA, taskB) => {
                if (taskA?.isMilestoneFolder) {
                    return taskA?.milestoneType === MilestoneType.TOP_DOWN ? -1 : 1;
                }
                if (taskB?.isMilestoneFolder) {
                    return taskB?.milestoneType === MilestoneType.TOP_DOWN ? 1 : -1;
                }
                return 0;
            });
            gantt.parse({
                data: filteredTasks,
                links: taskLinks,
            });
        }

        addingLinksForRender = false;
        addingMilestoneAfterDelegate = false;
    };

    const onClickTopDown = async (planningIds: string[]) => {
        const editedTaskIds = computed(() => projectPlanningModule.editedTaskIds);
        const editedLinkIds = computed(() => projectPlanningModule.editedLinkIds);
        const loading = ElLoading.service();
        const response = await projectPlanningService.topDown(
            projectPlanningModule.planningId,
            editedTaskIds.value,
            planningIds,
            projectModule.selectedProjectId || '',
            localStorageAuthService.getPlanningPermissions()?.path || '',
            editedLinkIds.value,
        );
        loading.close();
        if (!response.success) {
            showErrorNotificationFunction(response.message);
        } else {
            projectPlanningModule.setIsShowTopDownFormPopup(false);
            showSuccessNotificationFunction(t('planning.topDown.message.success'));
        }
    };

    const onClickBottomUp = async (planningIds: string[]) => {
        const editedTaskIds = computed(() => projectPlanningModule.editedTaskIds);
        const editedLinkIds = computed(() => projectPlanningModule.editedLinkIds);
        const loading = ElLoading.service();
        const response = await projectPlanningService.bottomUp(
            projectPlanningModule.planningId,
            editedTaskIds.value,
            planningIds,
            projectModule.selectedProjectId || '',
            localStorageAuthService.getPlanningPermissions()?.path || '',
            editedLinkIds.value,
        );
        loading.close();
        if (!response.success) {
            showErrorNotificationFunction(response.message);
        } else {
            projectPlanningModule.setIsShowBottomUpFormPopup(false);
            showSuccessNotificationFunction(t('planning.bottomUp.message.success'));
        }
    };

    /**
     * update milestone IM (create by delegate) and FL (create by synthesis).
     * @param planningId Id of original planning.
     */
    const onClickUpdateMilestone = async (planningId: string) => {
        // for update mile IM
        const taskIdsDelegatedFromOtherPlanning =
            projectPlanningModule.rootTaskHasPermissionCreateChild.map((task) => {
                return task._id;
            });
        const tasks: ITaskUpdateOriginalPlanning[] | undefined =
            projectPlanningModule.planning?.tasks
                .filter((task) => {
                    return taskIdsDelegatedFromOtherPlanning.includes(task._id);
                })
                .map((task) => {
                    return {
                        _id: task.clonedFromTaskId || '',
                        planningId: planningId,
                        startModified: moment(task.start)
                            .utc()
                            .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
                        finishModified: moment(task.finish)
                            .utc()
                            .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
                        name: task.name,
                        ganttId: task.ganttId,
                    };
                });

        // for update milestone FL
        const milestones: IMilestoneUpdateOriginalPlanning[] =
            projectPlanningModule.planning?.tasks
                .filter((task) => {
                    return (
                        task.milestoneType === MilestoneType.TOP_DOWN &&
                        task.isStaticMilestone === false &&
                        task.linkedTaskId &&
                        task.linkedLinkId
                    );
                })
                .map((task) => {
                    return {
                        _id: task._id,
                        linkedTaskId: task.linkedTaskId || '',
                        linkedLinkId: task.linkedLinkId || '',
                        milestoneLinkTo: task.milestoneLinkTo || null,
                        startModified: task.start
                            ? moment(task.start)
                                  .utc()
                                  .format(
                                      DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON,
                                  )
                            : null,
                        finishModified: task.finish
                            ? moment(task.finish)
                                  .utc()
                                  .format(
                                      DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON,
                                  )
                            : null,
                    };
                }) || [];

        if (!tasks) {
            return;
        }

        const loading = ElLoading.service();
        const response = await projectPlanningService.updateOriginalPlanning(
            tasks,
            milestones,
            projectPlanningModule.planningId || '',
            projectModule.selectedProjectId || '',
            localStorageAuthService.getPlanningPermissions()?.path || '',
        );
        loading.close();
        if (!response.success) {
            showErrorNotificationFunction(response.message);
        } else {
            projectPlanningModule.setIsShowBottomUpFormPopup(false);
            showSuccessNotificationFunction(
                t('planning.bottomUp.message.updateMilestonesSuccess'),
            );
        }
    };

    const addTaskListToChart = (tasks: IProjectTask[]) => {
        if (tasks.length) {
            tasks.forEach((task) => {
                if (
                    task.milestoneType === MilestoneType.TOP_DOWN &&
                    task.isMilestoneFolder
                ) {
                    gantt.addTask(
                        convertToGanttTask(
                            gantt,
                            task,
                            projectPlanningModule.taskFieldList || [],
                        ),
                        undefined,
                        0,
                    );
                } else {
                    gantt.addTask(
                        convertToGanttTask(
                            gantt,
                            task,
                            projectPlanningModule.taskFieldList || [],
                        ),
                    );
                }
                if (task.isMilestoneFolder) {
                    gantt.open(task._id);
                }
            });
        }
    };

    const getTaskListToUpdateBaseline = (baselineTasks: IBaselineTask[]) => {
        const taskList: IBulkUpdateTask[] = [];
        baselineTasks.forEach((baselineTask) => {
            const taskGanttId = projectPlanningModule.planning?.tasks?.find((task) => {
                return task._id === baselineTask.taskId;
            })?.ganttId;
            if (taskGanttId) {
                const taskInGantt = gantt.getTask(taskGanttId);
                taskList.push({
                    ...convertGanttTaskToTaskDto(
                        durationStringFormat,
                        calculateDuration,
                        gantt,
                        taskInGantt,
                    ),
                    baselineStart: baselineTask.baselineStart
                        ? moment(baselineTask.baselineStart).utc().fmFullTimeString()
                        : null,
                    baselineFinish: baselineTask.baselineFinish
                        ? moment(baselineTask.baselineFinish).utc().fmFullTimeString()
                        : null,
                    taskId: baselineTask.taskId,
                    blDuration: baselineTask.baselineDuration,
                    blActualDuration: baselineTask.baselineActualDuration,
                    blRemainDuration: baselineTask.baselineRemainingDuration,
                    blFreeFloat: baselineTask.baselineFreeFloat,
                    blTotalFloat: baselineTask.baselineTotalFloat,
                    VBLDuration: baselineTask.varianceBLDuration,
                    VBLStartDate: baselineTask.varianceBLStart,
                    VBLFinishDate: baselineTask.varianceBLFinish,
                    blDurationPercentage: baselineTask.baselineDurationPercentage,
                    blManualPercentage: baselineTask.baselineManualPercentage,
                    blPhysicalPercentage: baselineTask.baselinePhysicalPercentage,
                    blHRCost: baselineTask.baselineHumanResourceCost,
                    blMaterialCost: baselineTask.baselineMaterialCost,
                    blEquipmentCost: baselineTask.baselineEquipmentCost,
                    blLocationCost: baselineTask.baselineLocationCost,
                    blTotalCost: baselineTask.baselineTotalCost,
                    blFixedCost: baselineTask.baselineFixedCost,
                    blMaterialUnit: baselineTask.baselineMaterialUnit,
                    blEquipmentUnit: baselineTask.baselineEquipmentUnit,
                    blLocationUnit: baselineTask.baselineLocationUnit,
                });
            }
        });
        return taskList;
    };

    const calculateFinishWithNewStart = (
        currentStart: string,
        startDate: string,
        currentFinish: string,
        calendarId: string,
    ) => {
        const calendar = gantt.getCalendar(calendarId);
        const currentDurationUnit = gantt.config.duration_unit;
        gantt.config.duration_unit = TaskDurationFormat.MINUTE;
        const currentDuration = calendar?.calculateDuration({
            start_date: new Date(currentStart),
            end_date: new Date(currentFinish),
        });
        gantt.config.duration_unit = currentDurationUnit;

        return calendar?.calculateEndDate({
            start_date: new Date(startDate),
            duration: currentDuration,
            unit: TaskDurationFormat.MINUTE,
        });
    };

    const calculateFinishWithNewCalendar = (
        startDate: string,
        currentFinish: string,
        currentCalendarId: string,
        calendarId: string,
    ) => {
        const currentCalendar = gantt.getCalendar(currentCalendarId);
        const currentDurationUnit = gantt.config.duration_unit;
        gantt.config.duration_unit = TaskDurationFormat.MINUTE;
        const currentDuration = currentCalendar?.calculateDuration({
            start_date: new Date(startDate),
            end_date: new Date(currentFinish),
        });

        gantt.config.duration_unit = currentDurationUnit;

        const calendar = gantt.getCalendar(calendarId);
        const newStartDate = calculateStartDateToStartOfDay(
            new Date(startDate),
            calendar,
        );
        return calendar?.calculateEndDate({
            start_date: new Date(newStartDate),
            duration: currentDuration,
            unit: TaskDurationFormat.MINUTE,
        });
    };

    const calculateStart = (
        currentStart: string | Date,
        currentFinish: string | Date,
        finish: string | Date,
        calendarId: string,
    ) => {
        const calendar = gantt.getCalendar(calendarId);

        const currentDurationUnit = gantt.config.duration_unit;
        gantt.config.duration_unit = TaskDurationFormat.MINUTE;
        const currentDuration = calendar?.calculateDuration({
            start_date: new Date(currentStart),
            end_date: new Date(currentFinish),
        });
        gantt.config.duration_unit = currentDurationUnit;

        return calendar?.calculateEndDate({
            start_date: new Date(finish),
            duration: -currentDuration,
            unit: TaskDurationFormat.MINUTE,
        });
    };

    const calculateFinish = (
        duration: number,
        startDate: string,
        calendarId: string,
        needConvertDuration = true,
    ) => {
        const calendar = gantt.getCalendar(calendarId);
        let currentDuration = duration;

        if (needConvertDuration) {
            const planningCalendar = projectPlanningModule.planning?.calendars?.find(
                (calendar) => calendar._id === calendarId,
            );
            currentDuration = convertDurationFormat(
                duration,
                projectPlanningModule.planning?.durationFormat || TaskDurationFormat.HOUR,
                TaskDurationFormat.MINUTE,
                planningCalendar,
            );
        }

        return calendar?.calculateEndDate({
            start_date: moment(startDate).toDate(),
            duration: Math.round(currentDuration),
            unit: TaskDurationFormat.MINUTE,
        });
    };

    const calculateDuration = (
        startDate: string | Date,
        endDate: string | Date,
        calendarId: string,
        taskDurationFormat?: TaskDurationFormat,
    ) => {
        if (!startDate || !endDate) {
            return null;
        }

        const calendar = gantt.getCalendar(calendarId);
        const planningCalendar = projectPlanningModule.planning?.calendars?.find(
            (calendar) => calendar._id === calendarId,
        );
        if (!calendar) {
            return null;
        }

        const currentDurationUnit = gantt.config.duration_unit;

        gantt.config.duration_unit = TaskDurationFormat.MINUTE;
        const duration = calendar?.calculateDuration({
            start_date: moment(startDate).toDate(),
            end_date: moment(endDate).toDate(),
        });

        gantt.config.duration_unit = currentDurationUnit;
        return convertDurationFormat(
            duration,
            TaskDurationFormat.MINUTE,
            taskDurationFormat ||
                projectPlanningModule.planning?.durationFormat ||
                TaskDurationFormat.MINUTE,
            planningCalendar,
        );
    };

    const calculateTotalFloat = (task: IProjectTask): number | undefined => {
        if (!task?.calendarId) return undefined;

        if (
            task.taskType === TaskType.START_MILESTONE &&
            task?.earlyStart &&
            task?.lateStart
        ) {
            return (
                calculateDuration(
                    new Date(task?.earlyStart),
                    new Date(task?.lateStart),
                    task?.calendarId,
                    TaskDurationFormat.MINUTE,
                ) ?? undefined
            );
        }

        if (!task?.lateFinish || !task?.earlyFinish) {
            return undefined;
        }

        return (
            calculateDuration(
                new Date(task?.earlyFinish),
                new Date(task?.lateFinish),
                task?.calendarId,
                TaskDurationFormat.MINUTE,
            ) ?? undefined
        );
    };

    const calculateFreeFloat = (
        preEarlyFinish: string,
        succEarlyStart: string,
        preCalenderId: string,
        delay: number,
    ) => {
        const freeFLoat =
            (calculateDuration(
                new Date(preEarlyFinish),
                new Date(succEarlyStart),
                preCalenderId,
                TaskDurationFormat.MINUTE,
            ) as number) - delay;

        return freeFLoat < 0 ? 0 : freeFLoat;
    };

    const calculateFreeFloatMinOfTaskLinks = (
        taskLinks: Array<ITaskLink & { id: string }>,
        task: IGanttChartTask,
        // EarlyFinishOfCurrentTask: string,
        // calenderIdOfCurrentTask: string,
    ): number | undefined => {
        const listFreeFloat: Array<number> = [];

        taskLinks?.forEach((link) => {
            let preDate;
            let succDate;
            const succTaskInGantt = gantt.getTask(link.target);
            if (!succTaskInGantt) return;

            if (`${link.type}` === FINISH_TO_START) {
                preDate = task?.earlyFinish;
                succDate = succTaskInGantt?.earlyStart;
            } else if (`${link.type}` === START_TO_START) {
                preDate = task?.earlyStart;
                succDate = succTaskInGantt?.earlyStart;
            } else if (`${link.type}` === FINISH_TO_FINISH) {
                preDate = task?.earlyFinish;
                succDate = succTaskInGantt?.earlyFinish;
            } else {
                // START_TO_FINISH
                preDate = task?.earlyStart;
                succDate = succTaskInGantt?.earlyFinish;
            }

            const freeFLoatOfLink = calculateFreeFloat(
                preDate as string,
                succDate as string,
                task?.calendarId as string,
                link.lag,
            );

            gantt.getLink(link.id).freeFloat = freeFLoatOfLink;
            listFreeFloat.push(freeFLoatOfLink);
        });

        return lodash.min(listFreeFloat);
    };

    const calculateMaxEarlyFinishOfPlanning = (): string => {
        let maxEarlyFinish = projectPlanningModule.planning?.tasks[0]
            ?.earlyFinish as string;
        // get early finish of project
        const taskWBSContent = projectPlanningModule.planning?.tasks.forEach((task) => {
            const taskInGantt: IGanttChartTask = gantt.getTask(task.ganttId);
            if (moment(maxEarlyFinish).isSameOrBefore(moment(taskInGantt?.earlyFinish))) {
                maxEarlyFinish = taskInGantt?.earlyFinish as string;
            }
        });

        return maxEarlyFinish;
    };

    const handleFreeFloat = (
        task: IGanttChartTask,
        earlyFinishOfPlanning: string,
    ): number => {
        const linksInGantt: Array<ITaskLink & { id: string }> = gantt.getLinks();

        const linksOfSource = linksInGantt.filter(
            (linkInGantt) => linkInGantt.source === task.ganttId,
        );
        if (!linksOfSource || !linksOfSource.length) {
            return calculateFreeFloat(
                task?.earlyFinish as string,
                earlyFinishOfPlanning,
                task?.calendarId as string,
                0,
            );
        }

        const freeFLoat = calculateFreeFloatMinOfTaskLinks(linksOfSource, task);
        if (!freeFLoat) return 0;
        return freeFLoat;
    };

    const updateDomWithLinkClass = (linkId: string, props: Record<string, boolean>) => {
        const elementWithLink = document.querySelector(`[link_id="${linkId}"]`);
        if (!elementWithLink) return;

        if (props.isOnCriticalPath) {
            elementWithLink.setAttribute('class', CLASS_LINK_ON_CRITICAL_PATH);
            return;
        }

        if (props.isDrivingRelationship) {
            elementWithLink.setAttribute('class', CLASS_DRIVING_RELATIONSHIP);
            return;
        } else {
            elementWithLink.setAttribute('class', CLASS_NON_DRIVING_RELATIONSHIP);
            return;
        }
    };

    const handleDrivingRelationship = () => {
        const mapTaskLinks = new Map<string, boolean>();
        const linksInGantt: Array<ITaskLink & { id: string }> = gantt.getLinks();
        const dataLinks: Array<ITaskLink> = [];

        linksInGantt.forEach((link) => {
            const taskLinkDriving = mapTaskLinks.get(link.id);

            if (taskLinkDriving == null) {
                const targetTask: IGanttChartTask = gantt.getTask(link?.target);
                if (!targetTask) return;
                const linksOfTaskTarget = linksInGantt.filter(
                    (linkGantt) => linkGantt.target === targetTask?.ganttId,
                );
                linksOfTaskTarget.forEach((linkOfTarget) => {
                    const linkInStore = projectPlanningModule.planning?.taskLinks.find(
                        (linkStore) => {
                            return linkStore._id === linkOfTarget.id;
                        },
                    );
                    const sourceTask: IGanttChartTask = gantt.getTask(
                        linkOfTarget?.source,
                    );
                    if (!sourceTask) return;
                    const isDrivingRelationship =
                        linkOfTarget?.freeFloat === IS_NUMBER_DRIVING_RELATIONSHIP;

                    updateDomWithLinkClass(linkOfTarget.id, { isDrivingRelationship });
                    mapTaskLinks.set(linkOfTarget.id, isDrivingRelationship);
                    gantt.getLink(linkOfTarget.id).isDrivingRelationship =
                        isDrivingRelationship;
                    dataLinks.push({
                        _id: linkOfTarget?.id,
                        source: sourceTask._id,
                        target: targetTask?._id as string,
                        createdAt: linkInStore?.createdAt || linkOfTarget?.createdAt,
                        lag: linkInStore?.lag || 0,
                        type: linkInStore?.type || LinkType.FINISH_TO_START,
                        isDrivingRelationship: isDrivingRelationship,
                        freeFloat: linkOfTarget?.freeFloat,
                        // isOnCriticalPath will be handled later
                    });
                });
            }
        });
        return dataLinks;
    };

    const reschedulingGantt = async () => {
        if (
            !localStorageAuthService
                .getPlanningPermissions()
                ?.permissions?.includes(
                    ProjectSecurityPermissions['4DPLANNING_RESCHEDULING'],
                )
        ) {
            return;
        }

        configAutoScheduling(true);

        applyRescheduleOption();
        const dataDate = projectPlanningModule.planning?.dataDate;
        const {
            projectEnd,
            inProgressTasks,
            remainingDurationOfInProgressTasks,
            backupLink,
            predeTaskOfInprogressAndFinished,
            taskConstraintALAP,
            taskExceededProjectEnd,
        } = await beforeAutoSchedulingBackward(dataDate as Date);
        gantt.autoSchedule();

        const { taskWBSSummary, refreshTaskAutoScheduling } =
            await afterAutoSchedulingBackward(
                dataDate as Date,
                projectEnd as Date,
                inProgressTasks,
                remainingDurationOfInProgressTasks,
                predeTaskOfInprogressAndFinished,
                taskExceededProjectEnd,
            );
        gantt.autoSchedule();

        afterAutoSchedulingForward(
            dataDate as Date,
            projectEnd as Date,
            taskWBSSummary,
            backupLink,
            refreshTaskAutoScheduling,
            taskConstraintALAP,
            inProgressTasks,
            remainingDurationOfInProgressTasks,
        );

        afterForwardComplete();

        // Update to db
        await bulkUpdateTaskAutoScheduling();
        configAutoScheduling(autoScheduling.value);

        // notification success
        showSuccessNotificationFunction(
            t('planning.scheduling.messages.reschedulingSuccess'),
        );
    };

    const handleCircularLinks = (): Array<ICircularLink> => {
        configAutoScheduling(true);

        const cycles: Array<ICircularLink> = gantt?.findCycles();

        configAutoScheduling(autoScheduling.value);

        return cycles;
    };

    const bulkAddLinks = (taskLinks: ITaskLink[]) => {
        const tasks = projectPlanningModule.planning?.tasks;
        const newTaskLinks = (taskLinks || []).map((item) => {
            projectPlanningModule.planning?.taskLinks.push(item);
            return {
                ...item,
                id: item._id,
                source: tasks?.find((task) => task._id === item.source)?.ganttId,
                target: tasks?.find((task) => task._id === item.target)?.ganttId,
                type: gantt.config[item.type],
                lag: item.lag,
                isDrivingRelationship: item.isDrivingRelationship,
                freeFloat: item.freeFloat,
                isOnCriticalPath: item.isOnCriticalPath, // lmao, look at these last 4 lines!
            };
        });

        addingLinksForRender = true;
        newTaskLinks.forEach((link) => {
            gantt.addLink(link);
        });
        addingLinksForRender = false;
    };

    const deleteLink = async (linkId: string) => {
        const link = gantt.getLink(linkId);
        // prevent delete one link multi times
        if (!link) return;
        const response = await projectPlanningService.deleteLink(
            planningId.value as string,
            linkId,
        );
        if (response.success) {
            gantt.deleteLink(linkId);
            bulkUpdateTaskAutoScheduling();
            response.data.deletedMilestoneIds.forEach((milestoneId) => {
                gantt.deleteTask(milestoneId);
            });
            projectPlanningModule.setPlanning(
                cloneDeep({
                    ...projectPlanningModule.planning,
                    taskLinks: projectPlanningModule.planning?.taskLinks.filter(
                        (link) => link._id !== linkId,
                    ),
                }) as IPlanning,
            );
            showSuccessNotificationFunction(t('planning.task.messages.deletedLink'));
        }
    };

    const updatedLink = (link: ITaskLink) => {
        const linkInGantt = gantt.getLink(link._id);
        const linkInStore = projectPlanningModule.planning?.taskLinks.find((taskLink) => {
            return link._id === taskLink._id;
        });
        if (!linkInStore) {
            return;
        } else {
            linkInStore.lag = link.lag;
            linkInStore.type = link.type;
        }

        const defaultCalendar = gantt.getCalendar(
            projectPlanningModule.planning?.defaultCalendar as string,
        );

        linkInGantt.lag = calculateLinkLag(link, defaultCalendar);
        linkInGantt.type = getLinkKeyFromNumericType(link.type);
        gantt.updateLink(link._id);
    };

    const durationStringFormat = (
        startDate: Date,
        endDate: Date,
        calendarId: string,
        needConvert = true,
    ) => {
        const format =
            projectPlanningModule.planning?.durationFormat || TaskDurationFormat.HOUR;
        const duration = calculateDuration(
            startDate,
            endDate,
            calendarId,
            needConvert ? format : TaskDurationFormat.MINUTE,
        );
        return duration;
    };

    const convertDurationStringFormat = (
        currentDuration: number,
        calendarId: string,
        durationFormat = TaskDurationFormat.MINUTE,
    ) => {
        const format =
            projectPlanningModule.planning?.durationFormat || TaskDurationFormat.HOUR;

        const planningCalendar = projectPlanningModule.planning?.calendars?.find(
            (calendar) => calendar._id === calendarId,
        );

        const duration = convertDurationFormat(
            currentDuration,
            durationFormat || TaskDurationFormat.MINUTE,
            format,
            planningCalendar,
        );
        return duration || 0;
    };

    const updateOneTask = (taskData: IProjectTask) => {
        const task = gantt.getTask(taskData.ganttId);
        if (!task) {
            return;
        }
        gantt.updateTask(
            taskData.ganttId,
            convertToGanttTask(
                gantt,
                taskData,
                projectPlanningModule.taskFieldList || [],
            ),
        );
    };

    const fillMissingColumnSettings = async () => {
        const columns = gantt.getGridColumns();
        const missingColumnsData: IColumnSetting[] = [];
        const planningColumnSettings =
            computed(() => projectPlanningModule.planning?.setting?.columns).value || [];
        const existColumn = new Map<string, boolean>();
        planningColumnSettings.forEach((column) => existColumn.set(column.name, true));
        columns.forEach((col) => {
            if (!existColumn.has(col.name)) {
                missingColumnsData.push({
                    name: col.name,
                    display: true,
                    width: col.width,
                    order: col.order,
                });
            }
        });

        // TODO: convert this to view
        if (missingColumnsData.length) {
            const settingColumns =
                cloneDeep(projectPlanningModule.planning?.setting?.columns) || [];
            const newSettings = [...settingColumns, ...missingColumnsData];
            let order = 0;
            newSettings.forEach((column: IColumnSetting, index: number) => {
                newSettings[index].order = order++;
            });
            settingColumns.sort((colA: any, colB: any) => colA.order - colB.order);
            const clonedPlanning = cloneDeep(projectPlanningModule.planning) as IPlanning;
            const newSetting: IPlanningSetting = {
                planningId: projectPlanningModule.planningId,
                columns: [...settingColumns, ...missingColumnsData],
            };
            projectPlanningModule.setPlanning({
                ...clonedPlanning,
                setting: newSetting,
            });
            const response = await projectPlanningService.updatePlanningSetting(
                projectPlanningModule.planningId,
                newSetting,
            );
            if (!response.success) {
                showErrorNotificationFunction(response.message);
            }
        }
    };

    const updateLevelEffort = (task: IProjectTask) => {
        const taskInGantt = gantt.getTask(task.ganttId);

        const predecessorLinkWithStarts = projectPlanningModule.planning?.taskLinks
            .filter(
                (link) =>
                    link.source === task._id && link.type === LinkType.START_TO_START,
            )
            .map((link) => ({
                taskId: link.target,
                delay: link.lag,
                type: LinkFieldName.SOURCE,
            }))
            .concat(
                projectPlanningModule.planning?.taskLinks
                    .filter(
                        (link) =>
                            link.target === task._id &&
                            link.type === LinkType.START_TO_START,
                    )
                    .map((link) => ({
                        taskId: link.source,
                        delay: link.lag,
                        type: LinkFieldName.TARGET,
                    })),
            );

        const predecessorLinkWithFinishes = projectPlanningModule.planning?.taskLinks
            .filter(
                (link) =>
                    link.source === task._id && link.type === LinkType.START_TO_FINISH,
            )
            .map((link) => ({
                taskId: link.target,
                delay: link.lag,
                type: LinkFieldName.SOURCE,
            }))
            .concat(
                projectPlanningModule.planning?.taskLinks
                    .filter(
                        (link) =>
                            link.target === task._id &&
                            link.type === LinkType.FINISH_TO_START,
                    )
                    .map((link) => ({
                        taskId: link.source,
                        delay: link.lag,
                        type: LinkFieldName.TARGET,
                    })),
            );

        const calendar = gantt.getCalendar(taskInGantt.calendarId);
        const starts: Date[] = [];
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const currentTaskInGantt = gantt.getTask(task.ganttId);
            const predecessorLinkWithStart = predecessorLinkWithStarts?.find(
                (link) => link.taskId === task._id,
            );

            if (predecessorLinkWithStart) {
                starts.push(
                    calculateStartDateToStartOfDay(
                        calendar?.calculateEndDate({
                            start_date: new Date(
                                currentTaskInGantt.start_date ||
                                    currentTaskInGantt.end_date,
                            ),
                            duration:
                                predecessorLinkWithStart.type === LinkFieldName.TARGET
                                    ? predecessorLinkWithStart.delay
                                    : -predecessorLinkWithStart.delay,
                            task: taskInGantt,
                            unit: TaskDurationFormat.MINUTE,
                        }),
                        calendar,
                    ),
                );
            }

            const predecessorLinkWithFinish = predecessorLinkWithFinishes?.find(
                (link) => link.taskId === task._id,
            );
            if (predecessorLinkWithFinish) {
                starts.push(
                    calculateStartDateToStartOfDay(
                        calendar?.calculateEndDate({
                            start_date: new Date(
                                currentTaskInGantt.end_date ||
                                    currentTaskInGantt.start_date,
                            ),
                            duration:
                                predecessorLinkWithFinish.type === LinkFieldName.TARGET
                                    ? predecessorLinkWithFinish.delay
                                    : -predecessorLinkWithFinish.delay,
                            unit: TaskDurationFormat.MINUTE,
                            task: taskInGantt,
                        }),
                        calendar,
                    ),
                );
            }
        });

        const successorLinkWithFinishes = projectPlanningModule.planning?.taskLinks
            .filter(
                (link) =>
                    link.source === task._id && link.type === LinkType.FINISH_TO_FINISH,
            )
            .map((link) => ({
                taskId: link.target,
                delay: link.lag,
                type: LinkFieldName.SOURCE,
            }))
            .concat(
                projectPlanningModule.planning?.taskLinks
                    .filter(
                        (link) =>
                            link.target === task._id &&
                            link.type === LinkType.FINISH_TO_FINISH,
                    )
                    .map((link) => ({
                        taskId: link.source,
                        delay: link.lag,
                        type: LinkFieldName.TARGET,
                    })),
            );

        const successorLinkWithStarts = projectPlanningModule.planning?.taskLinks
            .filter(
                (link) =>
                    link.source === task._id && link.type === LinkType.FINISH_TO_START,
            )
            .map((link) => ({
                taskId: link.target,
                delay: link.lag,
                type: LinkFieldName.SOURCE,
            }))
            .concat(
                projectPlanningModule.planning?.taskLinks
                    .filter(
                        (link) =>
                            link.target === task._id &&
                            link.type === LinkType.START_TO_FINISH,
                    )
                    .map((link) => ({
                        taskId: link.source,
                        delay: link.lag,
                        type: LinkFieldName.TARGET,
                    })),
            );

        const ends: Date[] = [];
        projectPlanningModule.planning?.tasks.forEach((task) => {
            const currentTaskInGantt = gantt.getTask(task.ganttId);
            const successorLinkWithStart = successorLinkWithStarts?.find(
                (link) => link.taskId === task._id,
            );

            if (successorLinkWithStart) {
                ends.push(
                    calculateEndDateToEndOfDay(
                        calendar?.calculateEndDate({
                            start_date: new Date(
                                currentTaskInGantt.start_date ||
                                    currentTaskInGantt.end_date,
                            ),
                            duration:
                                successorLinkWithStart.type === LinkFieldName.TARGET
                                    ? successorLinkWithStart.delay
                                    : -successorLinkWithStart.delay,
                            unit: TaskDurationFormat.MINUTE,
                            task: taskInGantt,
                        }),
                        calendar,
                    ),
                );
            }

            const successorLinkWithFinish = successorLinkWithFinishes?.find(
                (link) => link.taskId === task._id,
            );
            if (successorLinkWithFinish) {
                ends.push(
                    calculateEndDateToEndOfDay(
                        calendar?.calculateEndDate({
                            start_date: new Date(
                                currentTaskInGantt.end_date ||
                                    currentTaskInGantt.start_date,
                            ),
                            duration:
                                successorLinkWithFinish.type === LinkFieldName.TARGET
                                    ? successorLinkWithFinish.delay
                                    : -successorLinkWithFinish.delay,
                            unit: TaskDurationFormat.MINUTE,
                            task: taskInGantt,
                        }),
                        calendar,
                    ),
                );
            }
        });
        const startDate = starts?.length
            ? calculateStartDateToStartOfDay(sortBy(starts)[0], calendar)
            : calculateStartDateToStartOfDay(
                  new Date(projectPlanningModule.planning?.dataDate as Date),
                  calendar,
              );

        const endDate = ends?.length
            ? calculateEndDateToEndOfDay(
                  sortBy(ends)?.[(ends?.length || 0) - 1],
                  calendar,
              )
            : null;

        if (startDate) {
            task.start = startDate;
            task.plannedStart = startDate;
            taskInGantt.start_date = new Date(startDate);

            if (endDate && moment(startDate).isSameOrBefore(moment(endDate))) {
                task.finish = endDate;
                task.plannedFinish = endDate;
                taskInGantt.end_date = new Date(endDate);
            } else {
                task.finish = startDate;
                task.plannedFinish = startDate;
                taskInGantt.end_date = new Date(startDate);
            }
        } else {
            const projectStart = new Date(
                projectPlanningModule.planning?.projectStart || '',
            );
            if (endDate && moment(projectStart).isSameOrBefore(moment(endDate))) {
                task.start = endDate;
                task.plannedStart = endDate;
                taskInGantt.start_date = new Date(endDate);
                task.finish = endDate;
                task.plannedFinish = endDate;
                taskInGantt.end_date = new Date(endDate);
            } else {
                task.start = projectStart;
                task.plannedStart = projectStart;
                taskInGantt.start_date = new Date(projectStart);
                task.finish = projectStart;
                task.plannedFinish = projectStart;
                taskInGantt.end_date = new Date(projectStart);
            }
        }
    };

    const calculateLinkLag = (link: ITaskLink, defaultCalendar: any) => {
        const calendarForDelayLinkOption =
            projectPlanningModule.planning?.calendarForDelayLink;

        const predecessorTask = projectPlanningModule.planning?.tasks.find((task) => {
            return link.source === task._id;
        });

        const successorTask = projectPlanningModule.planning?.tasks.find((task) => {
            return link.target === task._id;
        });

        const predecessorDate =
            link?.type === LinkType.FINISH_TO_FINISH ||
            link?.type === LinkType.FINISH_TO_START
                ? predecessorTask?.finish
                : predecessorTask?.start;

        if (!predecessorDate) {
            return link.lag;
        }

        const successorCalendar = gantt.getCalendar(successorTask?.calendarId as string);
        const predecessorCalendar = gantt.getCalendar(
            predecessorTask?.calendarId as string,
        );
        let targetDate;
        if (calendarForDelayLinkOption === CalendarForDelayLinkOption.PREDECESSOR) {
            targetDate = predecessorCalendar?.calculateEndDate({
                start_date: new Date(predecessorDate as Date),
                duration: link.lag,
                unit: TaskDurationFormat.MINUTE,
            });
        } else if (calendarForDelayLinkOption === CalendarForDelayLinkOption.DEFAULT) {
            targetDate = defaultCalendar?.calculateEndDate({
                start_date: new Date(predecessorDate as Date),
                duration: link.lag,
                unit: TaskDurationFormat.MINUTE,
            });
        } else {
            targetDate = successorCalendar?.calculateEndDate({
                start_date: new Date(predecessorDate as Date),
                duration: link.lag,
                unit: TaskDurationFormat.MINUTE,
            });
        }

        gantt.config.duration_unit = TaskDurationFormat.MINUTE;
        const currentDurationUnit = gantt.config.duration_unit;

        const ganttLinkLag = successorCalendar?.calculateDuration({
            start_date: calculateStartDateToStartOfDay(
                moment(predecessorDate).toDate(),
                predecessorCalendar,
            ),
            end_date: moment(targetDate).toDate(),
        });

        gantt.config.duration_unit = currentDurationUnit;

        if (!ganttLinkLag) {
            return DefaultZeroLinkLag;
        }
        return +ganttLinkLag;
    };

    const applyRescheduleOption = () => {
        // Define calendar for scheduling link delay
        const defaultCalendar = gantt.getCalendar(
            projectPlanningModule.planning?.defaultCalendar as string,
        );

        gantt.config.auto_scheduling = false;
        projectPlanningModule.planning?.taskLinks?.forEach((link) => {
            const linkInGantt = gantt.getLink(link?._id);
            if (linkInGantt) {
                linkInGantt.lag = calculateLinkLag(link, defaultCalendar);
                gantt.updateLink(link?._id);
            }
        });
        gantt.config.auto_scheduling = true;
    };

    const convertCircularLinks = (
        cycles: Array<ICircularLink>,
    ): { tasks: IGanttChartTask[] }[] => {
        return cycles.map((cycle) => {
            const tasks: IGanttChartTask[] = cycle.tasks.map((taskId) =>
                gantt.getTask(taskId),
            );
            return {
                tasks,
            };
        });
    };

    return {
        currentMarkerFocusTimeId,
        searchValue,
        searchType,
        searchColumn,
        updateOneTask,
        durationStringFormat,
        initChart,
        getPlanning,
        handleFileUpload,
        onFilter,
        handleMarkerMove,
        hanldeRunAutoSimulation,
        stopMarkerMover,
        onClickTopDown,
        onClickBottomUp,
        onClickUpdateMilestone,
        initGanttTemplates,
        fetchData,
        addTaskListToChart,
        changeTaskLayer,
        reschedulingGantt,
        getTaskListToUpdateBaseline,
        calculateFinish,
        calculateDuration,
        checkPermissionToAddLink,
        bulkAddLinks,
        deleteLink,
        updatedLink,
        handleAfterDelegateTask,
        fillMissingColumnSettings,
        updateLevelEffort,
        calculateFinishWithNewStart,
        calculateFinishWithNewCalendar,
        calculateStart,
        updateFilter,
        configGanttCommon,
        configChart,
        shouldAutoScheduling,
        convertDurationStringFormat,
        calculateLinkLag,
        handleCircularLinks,
        convertCircularLinks,
    };
};
