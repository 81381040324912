export default {
    breadcrumb: {
        project: 'Project',
    },
    sizeUnit: {
        mb: 'MB',
        kb: 'KB',
        b: 'B',
    },
    header: {
        name: 'Name',
        type: 'Type',
        createdBy: 'Created by',
        '4d': '4D',
        pbs: 'PBS',
        modifiedDate: 'Modified date',
        size: 'Size',
        action: 'Action',
        '4DBox': '4DBox',
        planning: 'Planning',
    },
    action: {
        open: 'Open',
        import: 'Import',
        rename: 'Rename',
        download: 'Download',
        delete: 'Delete',
        placeholder: 'Select action',
        assign: 'Assign',
        view: 'View',
        delegate: 'Delegate',
        topDown: 'Top down',
        bottomUp: 'Bottom up',
    },
    tooltip: {
        grid: 'View file in table',
        tree: 'View tree of folder',
        model: "View file's preview",
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        fitScreen: 'Fit Screen',
    },
};
