import { ApiService } from '@/common/services/api';
import {
    IBodyResponse,
    ICommonGetListQuery,
    IFolderStructureTree,
    IGetListResponse,
    IResourceViewColumnRes,
    IResourceViewResponse,
} from '@/common/interfaces';
import service from '@/plugins/axios';
import {
    IAssignActivityCodeValue,
    IAdditionalTaskField,
    IAppearanceProfile,
    IAppearanceProfileListQuery,
    IAssignResource,
    IBaselinePlanning,
    IBaselinePlanningListQuery,
    IBaselineTaskBody,
    ICreateLinkDto,
    ICreateLinkResponse,
    ICreateProjectTaskDto,
    ICreateTaskFieldBody,
    IDelegateTaskBody,
    IFilePathsByFocusTimeQuery,
    IGetTaskByIdsQueryString,
    IPlanning,
    IPlanningListQuery,
    IPlanningQuery,
    IProjectTask,
    IResource,
    IResourceGroup,
    ISynthesisPlanningBody,
    ITaskLink,
    IUpdateAppearanceProfile,
    IUpdateLinkDto,
    IUpdateProjectTaskDto,
    ITopDownResponse,
    IPlanningGetListQuery,
    IExportPlanningQuery,
    IPlanningByPathNameQuery,
    IResourceIdQueryString,
    IUpdatePlanning,
    IUpdateTaskFieldBody,
    ITaskUpdateDelegation,
    ITaskUpdateOriginalPlanning,
    IBulkUpdateTaskBody,
    IResourceListQuery,
    IExportPlanningToPrimaveraP6,
    IMilestoneUpdateOriginalPlanning,
    IBulkCreateLinkBody,
    IBulkCreateLinkResponse,
    IAssignResourceGroup,
    IActivityCode,
    IActivityCodeListItem,
    IActivityCodeValueItem,
    IDelegateTaskToExistDelegationBody,
    IDelegateResponse,
    IDelegationHasModifyResponse,
    ILinkListGetByTaskIdResponse,
    IUpdateTaskName,
    IPlanningSetting,
    IGetTaskFieldListQueryString,
    IDeleteTaskBody,
    IDeleteTaskResponse,
    IUnAssignResource,
    IImportPlanningFromABS,
    IImportProjectXml,
    IImportSummary,
    IExportSummary,
    IPasteOption,
    IUpdatePlanningView,
    IPlanningView,
    ICreatePlanningView,
    IColumnFilter,
    IUpdateRescheduleOptions,
    IBulkUpdateLinkBody,
    IUpdateResourceAssignment,
    IAssignCalendar,
} from '../interfaces';
import localStorageAuthService from '@/common/authStorage';
import { projectPlanningModule } from '../store';
import { UserFieldTypeEnum } from '../constants';

class ProjectPlanningService extends ApiService {
    async getPlanning(projectId: string, query: IPlanningQuery) {
        return await this.client.get<IPlanningQuery, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/${projectId}`,
            {
                params: {
                    ...query,
                },
            },
        );
    }

    async getPlanningInformation(planningId: string, query: IPlanningGetListQuery) {
        return await this.client.get<void, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/${planningId}/planning-info`,
            {
                params: {
                    ...query,
                },
            },
        );
    }

    async getRescheduleOption(planningId: string) {
        return await this.client.get<void, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/${planningId}/reschedule-option`,
        );
    }

    async getPlanningList(projectId: string, query: IPlanningListQuery) {
        return await this.client.get<
            IPlanningListQuery,
            IBodyResponse<IGetListResponse<IPlanning>>
        >(`${this.baseUrl}/${projectId}/list`, {
            params: {
                ...query,
            },
        });
    }

    async getTopDownFileList(planningId: string, query: IPlanningListQuery) {
        return await this.client.get<
            IPlanningListQuery,
            IBodyResponse<IGetListResponse<IPlanning>>
        >(`${this.baseUrl}/${planningId}/top-down-file-list`, {
            params: {
                ...query,
            },
        });
    }

    async getBottomUpFileList(planningId: string, query: IPlanningListQuery) {
        return await this.client.get<
            IPlanningListQuery,
            IBodyResponse<IGetListResponse<IPlanning>>
        >(`${this.baseUrl}/${planningId}/bottom-up-file-list`, {
            params: {
                ...query,
            },
        });
    }

    async getRootTaskHasPermissionCreateChild(planningId: string) {
        return await this.client.get<void, IBodyResponse<IProjectTask[]>>(
            `${this.baseUrl}/${planningId}/task-by-permission-create-child`,
        );
    }

    async createTask(planningId: string, data: ICreateProjectTaskDto) {
        this.beforeCreate<ICreateProjectTaskDto>(data);
        return await this.client.post<IProjectTask, IBodyResponse<IProjectTask>>(
            `${this.baseUrl}/${planningId}/task`,
            { ...data },
        );
    }

    async updateTask(id: string, data: IUpdateProjectTaskDto) {
        this.beforeUpdate<IUpdateProjectTaskDto>(data);
        return await this.client.patch<IProjectTask, IBodyResponse<IProjectTask>>(
            `${this.baseUrl}/task/${id}`,
            { ...data },
        );
    }

    async updateTaskBasicData(id: string, data: IUpdateTaskName) {
        this.beforeUpdate<IUpdateTaskName>(data);
        return await this.client.patch<IProjectTask, IBodyResponse<IProjectTask>>(
            `${this.baseUrl}/task/${id}/basic`,
            { ...data },
        );
    }

    async deleteTask(id: string) {
        return await this.client.delete<
            { _ids: string[] },
            IBodyResponse<{ _ids: string[] }>
        >(`${`${this.baseUrl}/task`}/${id}`);
    }

    async bulkDeleteTask(data: IDeleteTaskBody) {
        this.beforeUpdate<IDeleteTaskBody>(data);
        return await this.client.post<IProjectTask, IBodyResponse<IDeleteTaskResponse>>(
            `${this.baseUrl}/task/bulk-delete`,
            { ...data },
        );
    }

    async getTask(id: string) {
        return await this.client.get<{ _ids: string }, IBodyResponse<IProjectTask>>(
            `${`${this.baseUrl}/task`}/${id}`,
        );
    }

    async pasteTasks(targetTaskId: string, taskIds: string[], options: IPasteOption) {
        return await this.client.post<IProjectTask, IBodyResponse<IProjectTask>>(
            `${this.baseUrl}/task/${targetTaskId}/paste`,
            { taskIds, options },
        );
    }

    async createLink(planningId: string, data: ICreateLinkDto) {
        this.beforeCreate<ICreateLinkDto>(data);
        return await this.client.post<
            ICreateLinkResponse,
            IBodyResponse<ICreateLinkResponse>
        >(`${this.baseUrl}/${planningId}/link`, { ...data });
    }

    async getLinkListByTaskId(planningId: string, taskId: string) {
        return await this.client.get<void, IBodyResponse<ILinkListGetByTaskIdResponse>>(
            `${this.baseUrl}/${planningId}/${taskId}/link`,
        );
    }

    async bulkCreateLink(planningId: string, data: IBulkCreateLinkBody) {
        this.beforeCreate<IBulkCreateLinkBody>(data);
        return await this.client.post<
            IBulkCreateLinkResponse,
            IBodyResponse<IBulkCreateLinkResponse>
        >(`${this.baseUrl}/${planningId}/link/bulk-create`, { ...data });
    }

    async bulkUpdateLink(planningId: string, data: IBulkUpdateLinkBody) {
        this.beforeCreate<IBulkUpdateLinkBody>(data);
        return await this.client.patch<ITaskLink[], IBodyResponse<ITaskLink[]>>(
            `${this.baseUrl}/${planningId}/links/bulk-update`,
            { ...data },
        );
    }

    async updateLink(id: string, data: IUpdateLinkDto) {
        const planningId = projectPlanningModule.planningId;
        this.beforeUpdate<IUpdateLinkDto>(data);
        return await this.client.patch<ITaskLink, IBodyResponse<ITaskLink>>(
            `${this.baseUrl}/${planningId}/link/${id}`,
            { ...data },
        );
    }

    async deleteLink(planningId: string, id: string) {
        return await this.client.delete<
            { _id: string },
            IBodyResponse<{ _ids: string; deletedMilestoneIds: string[] }>
        >(`${this.baseUrl}/${planningId}/link/${id}`);
    }

    // create a new delegation and delegate tasks to this delegation
    async delegateTask(projectId: string, body: IDelegateTaskBody) {
        return await this.client.post<
            IDelegateTaskBody,
            IBodyResponse<IDelegateResponse>
        >(`${this.baseUrl}/${projectId}/delegate`, {
            ...body,
        });
    }

    // delegate tasks to exist delegation
    async delegateTaskToExistDelegation(
        delegationId: string,
        body: IDelegateTaskToExistDelegationBody,
    ) {
        return await this.client.post<
            IDelegateTaskToExistDelegationBody,
            IBodyResponse<IDelegateResponse>
        >(`${this.baseUrl}/${delegationId}/task/delegate/bulk-create`, {
            ...body,
        });
    }

    async resetBaseline(planningId: string) {
        return await this.client.post<string, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/reset-baseline`,
            { planningId },
        );
    }

    async applyBaseline(planningId: string, baselineId: string) {
        return await this.client.post<string, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/${planningId}/apply-baseline`,
            { baselineId },
        );
    }

    async createTaskBaseline(data: IBaselineTaskBody) {
        return await this.client.post<IBaselineTaskBody, IBodyResponse<boolean>>(
            `/baseline-planning`,
            { ...data },
        );
    }

    async getBaselineList(query: IBaselinePlanningListQuery) {
        return await this.client.get<
            void,
            IBodyResponse<IGetListResponse<IBaselinePlanning>>
        >(`/baseline-planning`, {
            params: { ...query },
        });
    }

    async getBaselineDetail(id: string) {
        return await this.client.get<void, IBodyResponse<IBaselinePlanning>>(
            `/baseline-planning/${id}`,
        );
    }

    async updateBaseline(id: string, data: IBaselineTaskBody) {
        return await this.client.patch<void, IBodyResponse<IBaselinePlanning>>(
            `/baseline-planning/${id}`,
            { ...data },
        );
    }

    async deleteBaseline(id: string) {
        return await this.client.delete<void, IBodyResponse<boolean>>(
            `/baseline-planning/${id}`,
        );
    }

    async getPlanningViewList(planningId: string) {
        return await this.client.get<
            void,
            IBodyResponse<IGetListResponse<IPlanningView>>
        >(`${this.baseUrl}/${planningId}/view`);
    }

    async updatePlanningSetting(id: string, body: IPlanningSetting) {
        return await this.client.patch<void, IBodyResponse<IPlanningSetting>>(
            `${this.baseUrl}/${id}/setting`,
            {
                ...body,
            },
        );
    }

    async createPlanningView(planningId: string, body: ICreatePlanningView) {
        const url = `${this.baseUrl}/${planningId}/view`;
        return await this.client.post<void, IBodyResponse<IPlanningView>>(url, body);
    }

    async updatePlanningView(
        planningId: string,
        planningViewId: string,
        body: IUpdatePlanningView,
    ) {
        const url = `${this.baseUrl}/${planningId}/view/${planningViewId}`;
        return await this.client.patch<void, IBodyResponse<IPlanningView>>(url, body);
    }

    async deletePlanningView(planningId: string, planningViewId: string) {
        const url = `${this.baseUrl}/${planningId}/view/${planningViewId}`;
        return await this.client.delete<void, IBodyResponse<void>>(url);
    }

    async synthesis(projectId: string, body: ISynthesisPlanningBody) {
        return await this.client.post<ISynthesisPlanningBody, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/${projectId}/synthesis`,
            {
                ...body,
            },
        );
    }

    async getResourceDetail(id: string) {
        return await this.client.get<IBodyResponse<IResource>, IBodyResponse<IResource>>(
            `${this.baseUrl}/resource/${id}`,
        );
    }

    async getResourceTree(query: IResourceListQuery) {
        return await this.client.get<
            ICommonGetListQuery,
            IBodyResponse<IFolderStructureTree[]>
        >(`${this.baseUrl}/resource`, {
            params: {
                ...query,
            },
        });
    }

    async getResourceAssignmentTree(taskId: string, query: IResourceListQuery) {
        return await this.client.get<
            ICommonGetListQuery,
            IBodyResponse<IFolderStructureTree[]>
        >(`${this.baseUrl}/resource-assignment/${taskId}`, {
            params: {
                ...query,
            },
        });
    }

    async getResourceView(planningId?: string) {
        let resourceView = await this.client.get<
            IResourceViewResponse,
            IBodyResponse<IResourceViewResponse>
        >(`${this.baseUrl}/resource/${planningId}/resource-view`);

        if (+resourceView.code === 200 && !resourceView.data) {
            resourceView = await this.client.post<
                ICommonGetListQuery,
                IBodyResponse<IResourceViewResponse>
            >(`${this.baseUrl}/resource/${planningId}/resource-view`);
        }
        return resourceView;
    }

    async updateResourceView(planningId?: string, payload?: IResourceViewColumnRes[]) {
        const resourceView = await this.client.patch<
            IResourceViewResponse,
            IBodyResponse<IResourceViewResponse>
        >(`${this.baseUrl}/resource/${planningId}/resource-view`, { columns: payload });
        return resourceView;
    }

    async getResourceList(query: IResourceListQuery) {
        return await this.client.get<
            ICommonGetListQuery,
            IBodyResponse<IGetListResponse<IResource>>
        >(`${this.baseUrl}/resource/list`, {
            params: {
                ...query,
            },
        });
    }

    async updateResourceAssignment(body: IUpdateResourceAssignment, taskId: string) {
        return await this.client.patch<any, IBodyResponse<any>>(
            `${this.baseUrl}/resource-assignment/${taskId}`,
            body,
        );
    }

    async createResource(data: IResource) {
        this.beforeCreate<IResource>(data);
        return await this.client.post<IResource, IBodyResponse<IResource>>(
            `${this.baseUrl}/resource`,
            { ...data },
        );
    }

    async updateResource(id: string, data: IResource) {
        this.beforeUpdate<IResource>(data);
        return await this.client.patch<IResource, IBodyResponse<IResource>>(
            `${this.baseUrl}/resource/${id}`,
            { ...data },
        );
    }

    async bulkUpdateResource(data: any[]) {
        return await this.client.patch<any, IBodyResponse<any>>(
            `${this.baseUrl}/bulk-update-resource`,
            { resource: data },
        );
    }

    async deleteResource(id: string) {
        return await this.client.delete<void, IBodyResponse<boolean>>(
            `${this.baseUrl}/resource/${id}`,
        );
    }

    async setGlobalResource(id: string) {
        return await this.client.post<void, IBodyResponse<boolean>>(
            `${this.baseUrl}/resource/${id}/set-global`,
        );
    }

    async getResourceGroupDetail(id: string) {
        return await this.client.get<
            IBodyResponse<IResourceGroup>,
            IBodyResponse<IResourceGroup>
        >(`${this.baseUrl}/resource-group/${id}`);
    }

    async getResourceGroupList(query: IResourceListQuery) {
        return await this.client.get<
            ICommonGetListQuery,
            IBodyResponse<IGetListResponse<IResourceGroup>>
        >(`${this.baseUrl}/resource-group`, {
            params: {
                ...query,
            },
        });
    }

    async createResourceGroup(data: IResourceGroup) {
        this.beforeCreate<IResourceGroup>(data);
        return await this.client.post<IResourceGroup, IBodyResponse<IResourceGroup>>(
            `${this.baseUrl}/resource-group`,
            { ...data },
        );
    }

    async updateResourceGroup(id: string, data: IResourceGroup) {
        this.beforeUpdate<IResourceGroup>(data);
        return await this.client.patch<IResourceGroup, IBodyResponse<IResourceGroup>>(
            `${this.baseUrl}/resource-group/${id}`,
            { ...data },
        );
    }

    async deleteResourceGroup(id: string) {
        return await this.client.delete<void, IBodyResponse<boolean>>(
            `${this.baseUrl}/resource-group/${id}`,
        );
    }

    async assignResource(data: IAssignResource) {
        this.beforeCreate<IAssignResource>(data);
        return await this.client.post<IAssignResource, IBodyResponse<IProjectTask[]>>(
            `${this.baseUrl}/assign-resource`,
            { ...data },
        );
    }

    async unassignResource(data: IUnAssignResource) {
        this.beforeCreate<IUnAssignResource>(data);
        return await this.client.post<IUnAssignResource, IBodyResponse<IProjectTask[]>>(
            `${this.baseUrl}/unassign-resource`,
            { ...data },
        );
    }

    async assignResourceGroup(data: IAssignResourceGroup) {
        this.beforeCreate<IAssignResourceGroup>(data);
        return await this.client.post<
            IAssignResourceGroup,
            IBodyResponse<IProjectTask[]>
        >(`${this.baseUrl}/assign-resource-group`, { ...data });
    }

    async unassignResourceGroup(data: IAssignResourceGroup) {
        this.beforeCreate<IAssignResourceGroup>(data);
        return await this.client.post<
            IAssignResourceGroup,
            IBodyResponse<IProjectTask[]>
        >(`${this.baseUrl}/unassign-resource-group`, { ...data });
    }

    async topDown(
        planningId: string,
        taskIds: string[],
        planningIds: string[],
        projectId: string,
        path: string,
        linkIds: string[],
    ) {
        return await this.client.post<
            ISynthesisPlanningBody,
            IBodyResponse<ITopDownResponse>
        >(`${this.baseUrl}/${planningId}/top-down`, {
            taskIds,
            planningIds,
            projectId,
            path,
            linkIds,
        });
    }

    async bottomUp(
        planningId: string,
        taskIds: string[],
        planningIds: string[],
        projectId: string,
        path: string,
        linkIds: string[],
    ) {
        return await this.client.post<
            ISynthesisPlanningBody,
            IBodyResponse<ITopDownResponse>
        >(`${this.baseUrl}/${planningId}/bottom-up`, {
            taskIds,
            planningIds,
            projectId,
            path,
            linkIds,
        });
    }

    async deletePlanning(_id: string) {
        return await this.client.delete<void, IBodyResponse<{ _id: string }>>(
            `${this.baseUrl}/${_id}`,
        );
    }

    async updatePlanning(_id: string, body: IUpdatePlanning) {
        return await this.client.patch<IUpdatePlanning, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/${_id}`,
            body,
        );
    }

    async updateRescheduleOption(id: string, body: IUpdateRescheduleOptions) {
        return await this.client.patch<void, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/${id}/reschedule-option`,
            {
                ...body,
            },
        );
    }

    async getAdditionalTaskFieldList(query: IGetTaskFieldListQueryString) {
        return await this.client.get<
            void,
            IBodyResponse<IGetListResponse<IAdditionalTaskField>>
        >(`${this.baseUrl}/task-field`, {
            params: query,
        });
    }

    async getAdditionalTaskField(fieldId: string, query?: { type?: UserFieldTypeEnum }) {
        return await this.client.get<void, IBodyResponse<IAdditionalTaskField>>(
            `${this.baseUrl}/task-field/${fieldId}`,
            { params: query },
        );
    }

    async createAdditionalTaskField(body: ICreateTaskFieldBody) {
        this.beforeCreate<ICreateTaskFieldBody>(body);
        return await this.client.post<
            ICreateTaskFieldBody,
            IBodyResponse<IAdditionalTaskField>,
            ICreateTaskFieldBody
        >(`${this.baseUrl}/task-field`, body);
    }

    async updateAdditionalTaskField(fieldId: string, body: IUpdateTaskFieldBody) {
        this.beforeUpdate<IUpdateTaskFieldBody>(body);
        return await this.client.patch<
            IAdditionalTaskField,
            IBodyResponse<IAdditionalTaskField>,
            IUpdateTaskFieldBody
        >(`${this.baseUrl}/task-field/${fieldId}`, body);
    }

    async deleteAdditionalTaskField(
        fieldId: string,
        query: { type?: UserFieldTypeEnum },
    ) {
        return await this.client.delete<
            void,
            IBodyResponse<{ _id: string }>,
            { _id: string }
        >(`${this.baseUrl}/task-field/${fieldId}`, {
            params: query,
        });
    }

    async setGlobalAdditionalTaskField(
        fieldId: string,
        body?: { type: UserFieldTypeEnum },
    ) {
        return await this.client.post<void, IBodyResponse<{ _id: string }>, any>(
            `${this.baseUrl}/task-field/${fieldId}/set-global`,
            body,
        );
    }

    async getFilePathsByTaskIds(query: IGetTaskByIdsQueryString) {
        return await this.client.get<
            IGetTaskByIdsQueryString,
            IBodyResponse<IGetListResponse<string>>
        >(`${this.baseUrl}/files-by-taskIds`, {
            params: {
                ...query,
            },
        });
    }

    async getFilePathByFocusTime(query: IFilePathsByFocusTimeQuery) {
        return await this.client.get<
            IFilePathsByFocusTimeQuery,
            IBodyResponse<IGetListResponse<string>>
        >(`${this.baseUrl}/files-by-focus-time`, {
            params: {
                ...query,
            },
        });
    }

    async getAppearanceProfileList(query: IAppearanceProfileListQuery) {
        return await this.client.get<
            IAppearanceProfileListQuery,
            IBodyResponse<IGetListResponse<IAppearanceProfile>>
        >(`${this.baseUrl}/appearance-profile`, {
            params: {
                ...query,
            },
        });
    }

    async getAppearanceProfileDetail(id: string) {
        return await this.client.get<
            IBodyResponse<IAppearanceProfile>,
            IBodyResponse<IAppearanceProfile>
        >(`${this.baseUrl}/appearance-profile/${id}`);
    }

    async createAppearanceProfile(data: IAppearanceProfile) {
        this.beforeCreate<IAppearanceProfile>(data);
        return await this.client.post<
            IAppearanceProfile,
            IBodyResponse<IAppearanceProfile>
        >(`${this.baseUrl}/appearance-profile`, { ...data });
    }

    async updateAppearanceProfile(id: string, data: IUpdateAppearanceProfile) {
        this.beforeUpdate<IAppearanceProfile>(data);
        return await this.client.patch<
            IAppearanceProfile,
            IBodyResponse<IAppearanceProfile>
        >(`${this.baseUrl}/appearance-profile/${id}`, { ...data });
    }

    async deleteAppearanceProfile(id: string) {
        return await this.client.delete<void, IBodyResponse<boolean>>(
            `${this.baseUrl}/appearance-profile/${id}`,
        );
    }

    async setGlobalAppearanceProfile(id: string) {
        return await this.client.post<void, IBodyResponse<boolean>>(
            `${this.baseUrl}/appearance-profile/${id}/set-global`,
        );
    }

    async getActivityCodeList() {
        const projectId = localStorageAuthService.getSelectedProjectId();
        const planningId = projectPlanningModule.planning?._id;
        return await this.client.get<void, IBodyResponse<IActivityCodeListItem[]>>(
            `/activity-code`,
            {
                params: { projectId, planningId },
            },
        );
    }

    async getActivityCodeTree() {
        const projectId = localStorageAuthService.getSelectedProjectId();
        const planningId = projectPlanningModule.planning?._id;
        return await this.client.get<void, IBodyResponse<IFolderStructureTree[]>>(
            `/activity-code/tree`,
            {
                params: { projectId, planningId },
            },
        );
    }

    async getActivityCodeValue(id: string) {
        const projectId = localStorageAuthService.getSelectedProjectId();
        return await this.client.get<void, IBodyResponse<IActivityCodeValueItem>>(
            `/activity-code/value/${id}`,
            {
                params: { projectId },
            },
        );
    }

    async createActivityCodeValue(data: IActivityCodeValueItem) {
        this.beforeCreate<IActivityCodeValueItem>(data);
        return await this.client.post<
            IActivityCodeValueItem,
            IBodyResponse<IActivityCodeValueItem>
        >(`/activity-code/value`, data);
    }

    async updateActivityCodeValue(id: string, data: IActivityCodeValueItem) {
        this.beforeUpdate<IActivityCodeValueItem>(data);
        return await this.client.patch<
            IActivityCode,
            IBodyResponse<IActivityCodeValueItem>
        >(`/activity-code/value/${id}`, data);
    }

    async deleteActivityCodeValue(id: string) {
        return await this.client.delete<void, IBodyResponse<boolean>>(
            `/activity-code/value/${id}`,
        );
    }

    /**
     * Currently the assign logic has an overwrite behavior, therefore
     * it can be used for both assigning and unassigning.
     */
    async assignActivityCodeValue(data: IAssignActivityCodeValue) {
        this.beforeCreate<IAssignActivityCodeValue>(data);
        return await this.client.post<
            IAssignActivityCodeValue,
            IBodyResponse<IProjectTask[]>
        >(`/activity-code/assign`, data);
    }

    async getActivityCode(id: string) {
        const projectId = localStorageAuthService.getSelectedProjectId();
        return await this.client.get<void, IBodyResponse<IActivityCode>>(
            `/activity-code/${id}`,
            {
                params: { projectId },
            },
        );
    }

    async createActivityCode(data: IActivityCode) {
        this.beforeCreate<IActivityCode>(data);
        return await this.client.post<IActivityCode, IBodyResponse<IActivityCode>>(
            `/activity-code/`,
            data,
        );
    }

    async updateActivityCode(id: string, data: IActivityCode) {
        this.beforeUpdate<IActivityCode>(data);
        return await this.client.patch<IActivityCode, IBodyResponse<IActivityCode>>(
            `/activity-code/${id}`,
            data,
        );
    }

    async deleteActivityCode(id: string) {
        return await this.client.delete<void, IBodyResponse<{ _id: string }>>(
            `/activity-code/${id}`,
        );
    }

    async setGlobalActivityCode(id: string) {
        return await this.client.post<void, IBodyResponse<{ _id: string }>>(
            `/activity-code/${id}/set-global`,
        );
    }

    async exportPlanning(query: IExportPlanningQuery) {
        return await this.client.get<IExportPlanningQuery, IBodyResponse>(
            `${this.baseUrl}/export-planning`,
            {
                params: { ...query },
            },
        );
    }

    async getPlanningByPathAndName(query: IPlanningByPathNameQuery) {
        return await this.client.get<IPlanningByPathNameQuery, IBodyResponse<IPlanning>>(
            `${this.baseUrl}/planning-by-path-name`,
            {
                params: { ...query },
            },
        );
    }

    async getTaskNameByResourceId(query: IResourceIdQueryString) {
        return await this.client.get<IResourceIdQueryString, IBodyResponse<string[]>>(
            `${this.baseUrl}/task-name-by-resourceId`,
            {
                params: { ...query },
            },
        );
    }

    async getDelegatePlanningListHasModifyFromOriginal(planningId: string) {
        return await this.client.get<void, IBodyResponse<IDelegationHasModifyResponse[]>>(
            `${this.baseUrl}/${planningId}/delegation`,
        );
    }

    async updateDelegation(
        tasks: ITaskUpdateDelegation[],
        planningId: string,
        projectId: string,
        path: string,
    ) {
        return await this.client.patch<void, IBodyResponse>(
            `${this.baseUrl}/${planningId}/delegation`,
            {
                tasks,
                path,
                projectId,
            },
        );
    }

    async updateOriginalPlanning(
        tasks: ITaskUpdateOriginalPlanning[],
        milestones: IMilestoneUpdateOriginalPlanning[],
        planningId: string,
        projectId: string,
        path: string,
    ) {
        return await this.client.patch<void, IBodyResponse>(
            `${this.baseUrl}/${planningId}/original-planning`,
            {
                tasks,
                milestones,
                path,
                projectId,
            },
        );
    }
    async cancelDelegation(planningId: string, taskIds: string[]) {
        return await this.client.post<
            void,
            IBodyResponse<{ updatedTaskIds: string[]; deletedMilestoneIds: string[] }>
        >(`${this.baseUrl}/${planningId}/cancel-delegation`, { taskIds });
    }

    async bulkUpdateTasks(body: IBulkUpdateTaskBody) {
        return await this.client.patch<
            IBulkUpdateTaskBody,
            IBodyResponse<IProjectTask[]>
        >(`${this.baseUrl}/task/bulk-update`, {
            ...body,
        });
    }

    async exportPlanningToPrimaveraP6(id: string, body: IExportPlanningToPrimaveraP6) {
        return await this.client.post<
            IExportPlanningToPrimaveraP6,
            IBodyResponse<IExportSummary>
        >(`${this.baseUrl}/${id}/export/primavera`, body);
    }

    async exportPlanningToExcel(id: string, body: any) {
        return await this.client.post<any, IBodyResponse<any>>(
            `${this.baseUrl}/${id}/export-excel`,
            body,
        );
    }

    async importPlanningFromABS(id: string, body: IImportPlanningFromABS) {
        return await this.client.post<
            IImportPlanningFromABS,
            IBodyResponse<IImportSummary>
        >(`${this.baseUrl}/${id}/import/primavera/abs`, body);
    }

    async importPlanningFromLocalFile(id: string, formData: FormData) {
        return await this.client.post<FormData, IBodyResponse<IImportSummary>>(
            `${this.baseUrl}/${id}/import/primavera/local-file`,
            formData,
        );
    }

    async importExcelPlanningFromABS(id: string, body: IImportPlanningFromABS) {
        return await this.client.post<
            IImportPlanningFromABS,
            IBodyResponse<IImportSummary>
        >(`${this.baseUrl}/${id}/import/excel/abs`, body);
    }

    async importExcelPlanningFromLocalFile(id: string, formData: FormData) {
        return await this.client.post<FormData, IBodyResponse<IImportSummary>>(
            `${this.baseUrl}/${id}/import/excel/local-file`,
            formData,
        );
    }

    async getPlanningXmlFileProject(fileId: string, query: { projectId: string }) {
        return await this.client.get<
            { projectId: string },
            IBodyResponse<IImportProjectXml>
        >(`${this.baseUrl}/file/${fileId}`, {
            params: query,
        });
    }

    async setFileByFocusTime(data: IFilePathsByFocusTimeQuery) {
        return await this.client.post<void, IBodyResponse<IGetListResponse<string>>>(
            `${this.baseUrl}/set-task-with-focus-time`,
            {
                ...data,
            },
        );
    }

    async getProjectFileDetail(fileId: string) {
        return await this.client.get<void, IBodyResponse>(`/project-file/${fileId}`);
    }
    async getPlanningsFromFileIds(fileIds: string[], projectId: string) {
        return await this.client.get<void, IBodyResponse<IPlanning[]>>(
            `/planning/plannings-from-file-ids`,
            {
                params: {
                    fileIds,
                    projectId,
                },
            },
        );
    }

    async createColumnFilter(planningId: string, viewId: string, data: IColumnFilter) {
        return await this.client.post<IColumnFilter, IBodyResponse<IColumnFilter>>(
            `${this.baseUrl}/${planningId}/view/${viewId}/filter`,
            data,
        );
    }

    async updateColumnFilter(
        planningId: string,
        viewId: string,
        columnFilterId: string,
        data: IColumnFilter,
    ) {
        return await this.client.patch<IColumnFilter, IBodyResponse<IColumnFilter>>(
            `${this.baseUrl}/${planningId}/view/${viewId}/filter/${columnFilterId}`,
            data,
        );
    }

    async getPlanningViewDetail(planningId: string, viewId: string) {
        return await this.client.get<void, IBodyResponse<IPlanningView>>(
            `${this.baseUrl}/${planningId}/view/${viewId}`,
        );
    }
    async getColumnFilterDetail(planningId: string, viewId: string, filterId: string) {
        return await this.client.get<void, IBodyResponse<IColumnFilter>>(
            `${this.baseUrl}/${planningId}/view/${viewId}/filter/${filterId}`,
        );
    }
    async deleteColumnFilter(planningId: string, viewId: string, filterId: string) {
        return await this.client.delete<void, IBodyResponse<void>>(
            `${this.baseUrl}/${planningId}/view/${viewId}/filter/${filterId}`,
        );
    }

    async assignCalendar(data: IAssignCalendar) {
        this.beforeCreate(data);

        // https://www.totaltypescript.com/the-empty-object-type-in-typescript
        type TResponse = IBodyResponse<Record<string, never>>;

        return await this.client.post<IAssignActivityCodeValue, TResponse>(
            `/calendar/assign`,
            data,
        );
    }

    async createTaskRule(data: any) {
        this.beforeCreate(data);

        return await this.client.post<any, IBodyResponse<any>>(
            `${this.baseUrl}/task-rule`,
            data,
        );
    }

    async getListTaskRule(data: any) {
        return await this.client.get<any, IBodyResponse<any>>(
            `${this.baseUrl}/task-rule`,
            { params: data },
        );
    }

    async getTaskRuleById(id: string) {
        return await this.client.get<any, IBodyResponse<any>>(
            `${this.baseUrl}/task-rule/${id}`,
        );
    }

    async updateTaskRule(data: any, id: string) {
        this.beforeUpdate(data);

        return await this.client.patch<any, IBodyResponse<any>>(
            `${this.baseUrl}/task-rule/${id}`,
            data,
        );
    }

    async deleteTaskRule(id: string) {
        return await this.client.delete<any, IBodyResponse<any>>(
            `${this.baseUrl}/task-rule/${id}`,
        );
    }

    async setGlobalTaskRule(id: string) {
        return await this.client.post<any, IBodyResponse<any>>(
            `${this.baseUrl}/task-rule/${id}/set-global`,
        );
    }

    async assignTaskRule(data: any) {
        return await this.client.post<any, IBodyResponse<any>>(
            `${this.baseUrl}/assign-task-rule/`,
            data,
        );
    }

    async getListTaskRuleAssignment(data: any) {
        return await this.client.post<any, IBodyResponse<any>>(
            `${this.baseUrl}/task-rules-assigned-in-task}`,
            data,
        );
    }

    async getResourceSetting(planningId: string) {
        return await this.client.get<any, IBodyResponse<any>>(
            `${this.baseUrl}/${planningId}/resource-view`,
        );
    }

    async updateResourceSetting(planningId: string, payload: any) {
        return await this.client.patch<any, IBodyResponse<any>>(
            `${this.baseUrl}/${planningId}/resource-view`,
            payload,
        );
    }
}

export const projectPlanningService = new ProjectPlanningService(
    { baseUrl: '/planning' },
    service,
);
