export const project = {
    breadcrumb: {
        projectList: 'Project List',
        projectDetail: 'Project Detail',
    },
    projectForm: {
        createProject: 'Create project',
        export: 'Export',
        delete: 'Delete',
        title: {
            create: 'Create project',
            update: 'Update project',
        },
        name: {
            label: 'Name',
            placeholder: 'Input name',
        },
        category: {
            label: 'Category',
            placeholder: 'Select category',
        },
        description: {
            label: 'Description',
            placeholder: 'Input description',
        },
        postalCode: {
            label: 'Postal Code',
            placeholder: 'Input postal code',
        },
        latitude: {
            label: 'Latitude',
            placeholder: 'Input latitude',
        },
        longitude: {
            label: 'Longitude',
            placeholder: 'Input longitude',
        },
        selectFromExistingProject: {
            label: 'Select from existing project',
            placeholder: 'Select project',
        },
        dataDate: {
            label: 'Data date',
            placeholder: 'Input data date',
        },
        adminId: {
            label: 'Admin',
            placeholder: 'Select admin',
        },
        taskIdPrefix: {
            label: 'Task ID prefix',
            placeholder: 'Enter task ID prefix',
        },
        taskIdSuffix: {
            label: 'Task ID suffix',
            placeholder: 'Enter task ID suffix',
        },
        taskIdIncrement: {
            label: 'Task ID increment',
            placeholder: 'Enter task ID increment',
        },
        resourceIdPrefix: {
            label: 'Resource ID prefix',
            placeholder: 'Enter Resource ID prefix',
        },
        resourceIdSuffix: {
            label: 'Resource ID suffix',
            placeholder: 'Enter resource ID suffix',
        },
        resourceIdIncrement: {
            label: 'Resource ID increment',
            placeholder: 'Enter resource ID increment',
        },
        fileId: {
            label: 'File ID suffix',
            placeholder: 'Enter File ID suffix',
        },
        cancel: 'Cancel',
        save: 'Save',
    },
    popup: {
        delete: {
            text: 'Are you sure that you want to delete this project?',
            title: 'Delete project',
        },
        view: 'Access this project',
        edit: 'Edit this project',
        selectAProject: 'Select a project',
    },
    categories: {
        infrastructure: 'Infrastructure',
        buildingsAndFacilities: 'Buildings and Facilities',
        communications: 'Communications',
        mappingAndSurveying: 'Mapping and Surveying',
        mining: 'Mining',
        oilAndGas: 'Oil and Gas',
        powerGeneration: 'Power Generation',
        waterAndWastewater: 'Water and Wastewater',
        manufacturing: 'Manufacturing',
    },
    message: {
        createSuccess: 'Create project successfully',
        updateSuccess: 'Update project successfully',
        deleteSuccess: 'Delete project successfully',
    },
    dashboard: {
        statistic: 'Statistic',
        category: 'Category',
        description: 'Description',
        postalCode: 'Postal code',
        address: 'Address',
        coordinates: 'Coordinates',
        resource: {
            title: 'Resource',
            humanResource: 'Human resource',
            material: 'Material resource',
            equipment: 'Equipment resource',
            location: 'Location resource',
            humanResourceAssigned: 'Human resource assigned',
            materialAssigned: 'Material resource assigned',
            equipmentAssigned: 'Equipment resource assigned',
            locationAssigned: 'Location resource assigned',
            resourceAssignedWith3DObject: 'Assigned resource with 3D Object',
        },
        task: {
            title: 'Task',
            taskCount: 'Task count',
            taskLink: 'Task link',
            finishedTask: 'Finished task',
            inProgressTask: 'In progress task',
            todoTask: 'Todo task',
        },
        main: {
            title: 'Main',
            planning: 'Total planning',
            assignedUser: 'Assigned user',
            numberOf3DModel: 'Number of 3D model',
            numberOfCompany: 'Number of company participated',
        },
        userAssigned: 'User assigned',
        numberOfPlanning: 'Number of planning',
        numberOf3DModel: 'Number of 3D model',
        numberOfCompany: 'Number of company participated',
        totalTask: 'Total task',
        taskLink: 'Task link',
        completedTaskCount: 'Completed task',
        inProgressTask: 'In progress task',
        plannedTask: 'Planned task',
        popup: {
            title: 'Project field setting',
            category: 'Category',
            description: 'Description',
            postalCode: 'Postal code',
            address: 'Address',
            coordinates: 'Coordinates',
            projectAdmin: 'Project admin',
            time: 'Time',
            success: 'Project field setting updated successful',
        },
    },
};
