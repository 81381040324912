import { cloneDeep } from 'lodash';
import { ITreeNode } from './interfaces';

/*
function to build a tree
input: subGroup: group information, level
output
tree {
    _id: string,
    level: number,
    children: [...]
}
*/

export function buildATree(node: ITreeNode, level: number, treeList: ITreeNode[]) {
    const currentTree = {
        ...node,
        level: level,
        children: [] as ITreeNode[],
    };
    // find children
    const listSubTree = treeList.filter(
        (tree) => tree.parentId?.toString() === currentTree._id?.toString(),
    );

    for (let i = 0; i < listSubTree.length; i++) {
        // find all children contains

        const subNode = cloneDeep(buildATree(listSubTree[i], level + 1, treeList));
        currentTree.children.push(subNode);
    }
    return currentTree;
}

/*
function to build group of tree
output
[{
    _id: string,
    level: number,
    children: [...]
}]
*/
export function buildTreeGroups(treeList: any[]) {
    // Find all root
    const firstLevelGroup = treeList.filter((tree) => !tree.parentId);

    const trees = [];
    for (let i = 0; i < firstLevelGroup.length; i++) {
        // build tree from level 1
        const tree = buildATree(firstLevelGroup[i], 1, treeList);
        trees.push(tree);
    }
    return trees;
}

export function searchTree(_id: string, tree: ITreeNode): ITreeNode | null {
    if (tree._id.toString() === _id.toString()) {
        return tree;
    } else if (tree.children?.length) {
        for (let i = 0; i < tree.children?.length; i++) {
            if (searchTree(_id, tree.children[i])?._id?.toString()?.length) {
                return searchTree(_id, tree.children[i]);
            }
        }
    }
    return null;
}

export function searchTrees(_id: string, treeList: ITreeNode[]) {
    for (let i = 0; i < treeList?.length; i++) {
        if (searchTree(_id, treeList[i])?._id?.toString()?.length) {
            return searchTree(_id, treeList[i]);
        }
    }
    return null;
}

export function getListItemFormTree(treeNode: ITreeNode, itemList: any[]) {
    if (treeNode?.children?.length) {
        treeNode?.children.forEach((childNode) => {
            getListItemFormTree(childNode, itemList);
        });
    }
    itemList.push(treeNode.data);
}

/*
Func to flat TreeArray to FlatArray
And Sort 1, 1.1, 1.1.1, 2, 2.1, etc
*/
export const flattenTree = (array: any[]) => {
    const result: any[] = [];

    const flatten = (items: any) => {
        items.forEach((item: any) => {
            result.push(item);
            if (item.children && item.children.length > 0) {
                flatten(item.children);
            }
        });
    };

    flatten(array);
    return result;
};
