import localStorageAuthService from '@/common/authStorage';
import { ProjectSecurityPermissions } from '@/features/3D-viewer-profile/constants';
import { ABSUploadedFileExtensions } from '@/features/abs/constants';
import { UtilMixins } from '@/mixins/utilMixins';
import { mixins } from 'vue-class-component';
import { useRoute } from 'vue-router';
import { ImportOption, ResourceType, RibbonGroup } from '../constants';

export class Planning4DMixin extends mixins(UtilMixins) {
    ribbonGroup = RibbonGroup;
    resourceType = ResourceType;
    importOption = ImportOption;
    route = useRoute();
    get planningPermissions() {
        return localStorageAuthService.getPlanningPermissions()?.permissions || [];
    }

    get canEditPlanning() {
        return this.planningPermissions.includes(
            ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING'],
        );
    }

    get canEditPlanningSettings() {
        return this.planningPermissions.includes(
            ProjectSecurityPermissions['4DPLANNING_EDIT_PLANNING_SETTINGS'],
        );
    }

    get canReSchedule() {
        return this.planningPermissions.includes(
            ProjectSecurityPermissions['4DPLANNING_RESCHEDULING'],
        );
    }

    get canEditResourceAndResourceGroup() {
        return this.planningPermissions.includes(
            ProjectSecurityPermissions['4DPLANNING_EDIT_RESOURCE_AND_RESOURCE_GROUP'],
        );
    }

    get canEditAppearanceProfile() {
        return this.planningPermissions.includes(
            ProjectSecurityPermissions['4DPLANNING_EDIT_APPEARANCE_PROFILE'],
        );
    }

    get canImportAndExportXml() {
        return this.planningPermissions.includes(
            ProjectSecurityPermissions['4DPLANNING_IMPORT_EXPORT_PLANNING'],
        );
    }

    get planningPath() {
        const { planningFilePath, name } = this.route.query;
        if (!planningFilePath || !name) return '';

        return `${planningFilePath}/${name}${ABSUploadedFileExtensions.PLANNING}`.replace(
            '//',
            '/',
        );
    }
}
