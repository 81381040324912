import { computed } from 'vue';
import { GanttStatic } from 'dhtmlx-gantt';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
    DATE_TIME_FORMAT,
    DEFAULT_TO_FIXED_LENGTH,
    DateFormatValue,
    DateFormatValueNonHour,
    SUPPORT_LANGUAGE,
} from '@/common/constants';
import ganttChartStorage from '@/common/ganttChartStorage';
import { appModule } from '@/plugins/vuex/appModule';
import { authModule } from '../auth/store';
import { ICalendar } from '../calendar/interfaces';
import { calendarModule } from '../calendar/store';
import {
    AddColumnName,
    AddColumnWidth,
    CRITICAL_TASK_COLOR,
    ColumnType,
    ConstraintTypeMapping,
    DEFAULT_TASK_GROUP_BY,
    DURATION_ZERO_WITH_MILESTONE,
    DefaultMaxDurationComplete,
    DefaultMinDurationComplete,
    FullTimeCalendarId,
    GanttColumn,
    InlineColumn,
    TaskConstraint,
    TaskDuration,
    TaskDurationFormat,
    TaskFieldDataType,
    TaskPercentageComplete,
    TaskPhysicalQuantityUnit,
    TaskStatus,
    TaskType,
    VIEW_COLUMN_TYPE_SEPARATOR,
    defaultCalendarHoursPerDay,
    defaultCalendarHoursPerMinute,
    defaultCalendarHoursPerMonth,
    defaultCalendarHoursPerWeek,
    defaultCalendarHoursPerYear,
} from './constants';
import {
    IActivityCodeColumns,
    IActivityCodeListItem,
    IAdditionalTaskField,
    IBaselineTask,
    IColumnSetting2,
    ICreateProjectTaskDto,
    IGanttChartTask,
    IGroupBy,
    IProjectTask,
    ITaskDragUpdate,
} from './interfaces';
import { projectPlanningModule } from './store';

export const convertToGanttTask = (
    gantt: GanttStatic,
    task: IProjectTask,
    _additionalTaskFields: IAdditionalTaskField[],
    baselineTask?: IBaselineTask,
): IGanttChartTask & Record<string, any> => {
    const calendar = gantt.getCalendar(task?.calendarId || '');

    const currentEndDate = (() => {
        if (
            !task.durationFormat ||
            task.taskType === TaskType.FINISH_MILESTONE ||
            task.taskType === TaskType.START_MILESTONE
        ) {
            return new Date(task.finish);
        }

        if (
            task.expectedFinish &&
            projectPlanningModule.planning?.useExpectedFinish &&
            (task.status === TaskStatus.IN_PROGRESS ||
                task.status === TaskStatus.FINISHED)
        ) {
            return new Date(task.expectedFinish);
        }

        if (task.status === TaskStatus.FINISHED) {
            return calendar?.calculateEndDate({
                start_date: new Date(task?.start),
                duration: task.actualDuration,
                unit: task.durationFormat,
            });
        }

        if (task.status === TaskStatus.IN_PROGRESS) {
            return calendar?.calculateEndDate({
                start_date: new Date(projectPlanningModule.planning?.dataDate as Date),
                duration: task.remainingDuration,
                unit: task.durationFormat,
            });
        }

        return calendar?.calculateEndDate({
            start_date: new Date(task?.start),
            duration: task.originalDuration,
            unit: task.durationFormat,
        });
    })();

    const currentStartDate = (() => {
        if (task.taskType !== TaskType.FINISH_MILESTONE) {
            if (
                task.status === TaskStatus.IN_PROGRESS &&
                moment(task?.start).isAfter(projectPlanningModule.planning?.dataDate)
            ) {
                return calendar?.calculateEndDate({
                    start_date: currentEndDate,
                    duration: -(task.remainingDuration as number),
                    unit: task.durationFormat,
                });
            }

            return new Date(task?.start);
        }

        return new Date(currentEndDate);
    })();

    const calcTaskProgress = () => {
        if (
            task.status === TaskStatus.TODO ||
            (task.status === TaskStatus.IN_PROGRESS &&
                moment(projectPlanningModule.planning?.dataDate).isSameOrBefore(
                    moment(task.start),
                ))
        ) {
            return 0;
        } else if (task.status === TaskStatus.FINISHED) {
            return 100;
        }
        return (
            (new Date(projectPlanningModule.planning?.dataDate as Date).getTime() -
                new Date(task.start).getTime()) /
            (new Date(currentEndDate).getTime() - new Date(task.start).getTime())
        );
    };
    const calcBLDuration = () => {
        if (!baselineTask) {
            return 0;
        }

        return Math.abs(
            (new Date(baselineTask.baselineFinish).getTime() -
                new Date(baselineTask.baselineStart).getTime()) /
                36e5,
        );
    };

    const calcVarianceBLDuration = () => {
        const baselineDuration = calcBLDuration();
        if (baselineDuration === 0) {
            return 0;
        }

        const originalDuration =
            task.plannedFinish && task.plannedStart
                ? (new Date(task.plannedFinish).getTime() -
                      new Date(task.plannedStart).getTime()) /
                  36e5
                : 0;

        return originalDuration - baselineDuration;
    };

    const calcVarianceBLFinishDate = () => {
        if (!task.finish || !task.baselineFinish) {
            return null;
        }
        return Math.abs(
            (new Date(task.finish).getTime() - new Date(task.baselineFinish).getTime()) /
                36e5,
        );
    };

    const calcVarianceBLStartDate = () => {
        if (!task.start || !task.baselineStart) {
            return null;
        }
        return Math.abs(
            (new Date(task.start).getTime() - new Date(task.baselineStart).getTime()) /
                36e5,
        );
    };

    const calcActivityCodeData = (
        selectedActivityCodeValueId: string,
    ): IActivityCodeColumns => {
        const activityCodeList = projectPlanningModule.activityCodeList;
        const displayActivityCode = ganttChartStorage.getDisplayActivityCode();
        const activityCodeValueList = activityCodeList.flatMap(
            (activityCode) => activityCode.activityCodeValues,
        );

        const activityCodeValue = activityCodeValueList.find((activityCodeValue) => {
            return activityCodeValue._id === selectedActivityCodeValueId;
        });
        const activityCode = activityCodeList.find((activityCode) => {
            return activityCode._id === activityCodeValue?.activityCodeId;
        });

        return {
            activityCode: activityCode?.name || null,
            activityCodeValue: activityCodeValue?.name || null,
            activityCodeValueColor: displayActivityCode
                ? activityCodeValue?.colorCode
                : undefined,
        };
    };

    let activityCodeData: IActivityCodeColumns;
    if (task?.selectedActivityCodeValueId) {
        activityCodeData = calcActivityCodeData(task?.selectedActivityCodeValueId);
    } else {
        activityCodeData = {
            activityCode: null,
            activityCodeValue: null,
            activityCodeValueColor: undefined,
        };
    }

    const convertToGanttType = (type: TaskType): TaskType => {
        if (
            task.isRootWbs &&
            !projectPlanningModule.planning?.tasks?.some(
                (_task) => _task.parentId === task._id,
            )
        ) {
            return TaskType.STANDARD;
        }
        if (type === TaskType.WBS_SUMMARY) {
            return TaskType.PROJECT;
        } else if (
            type === TaskType.MILESTONE ||
            type === TaskType.START_MILESTONE ||
            type === TaskType.FINISH_MILESTONE
        ) {
            return TaskType.MILESTONE;
        }

        return TaskType.STANDARD;
    };

    const convertConstraintType = () => {
        if (
            task.primaryConstraints === TaskConstraint.SNLT ||
            task.primaryConstraints === TaskConstraint.FNLT ||
            task.isRootWbs
        ) {
            return TaskConstraint.ASAP;
        }
        return task.primaryConstraints;
    };

    let color: string | undefined;
    if (activityCodeData.activityCodeValueColor) {
        color = activityCodeData.activityCodeValueColor;
    } else if (task.critical || task.isOnCriticalPath) {
        color = CRITICAL_TASK_COLOR;
    } else {
        color = task.color;
    }

    // Read comment on IProjectTask.calendarName
    const calendarName =
        task.calendarName ??
        projectPlanningModule.planning?.calendars?.find(
            (item) => item._id === task.calendarId,
        )?.name;

    const result = {
        ...task,
        auto_scheduling: task.status !== TaskStatus.FINISHED,
        //some milestones do not have start or end date
        start_date: currentStartDate,
        end_date: currentEndDate
            ? new Date(currentEndDate)
            : task?.finish
            ? new Date(task?.finish)
            : new Date(task?.start),
        type: convertToGanttType(task.taskType),
        id: task.ganttId,
        text: task.name,
        parent: task.parentGanttId ? task.parentGanttId : task?.parentId || 0,
        // duration:
        //     (new Date(task.finish).getTime() - new Date(task.start).getTime()) / 36e5,
        bl_start: task.baselineStart ? new Date(task.baselineStart) : undefined,
        bl_finish: task.baselineFinish ? new Date(task.baselineFinish) : undefined,
        progress: calcTaskProgress(),
        atCompleteDuration: (task.actualDuration || 0) + (task.remainingDuration || 0),
        baselineDuration: calcBLDuration(),
        varianceBLDuration: calcVarianceBLDuration(),
        varianceBLFinishDate: calcVarianceBLFinishDate(),
        varianceBLStartDate: calcVarianceBLStartDate(),
        // freeFloat, critical and totalFloat will calculate when gantt render column in grid
        open: true,
        baselineCurrentStart: baselineTask
            ? new Date(baselineTask.baselineStart)
            : undefined,
        baselineCurrentFinish: baselineTask
            ? new Date(baselineTask.baselineFinish)
            : undefined,
        color,
        additionalFields: task.additionalFields,
        calendar_id: task?.calendarId || FullTimeCalendarId,
        calendarName,
        duration: task.originalDuration || 0,
        activityCode: activityCodeData.activityCode,
        activityCodeValue: activityCodeData.activityCodeValue,
        constraint_type: task.primaryConstraints,
        constraint_date:
            task.primaryConstraintDate &&
            task.primaryConstraints !== TaskConstraint.ASAP &&
            task.primaryConstraints !== TaskConstraint.ALAP
                ? moment(task.primaryConstraintDate).toDate()
                : null,
    };
    return result as IGanttChartTask;
};

export const convertGanttTaskToTaskDto = (
    durationStringFormat: (
        startDate: Date,
        endDate: Date,
        calendarId: string,
    ) => number | null,
    calculateDuration: (
        startDate: Date,
        endDate: Date,
        calendarId: string,
        taskDurationFormat?: TaskDurationFormat,
    ) => any,
    gantt: GanttStatic,
    task: IGanttChartTask,
): ICreateProjectTaskDto => {
    const format =
        task.durationFormat ||
        projectPlanningModule.planning?.durationFormat ||
        TaskDurationFormat.HOUR;

    const useExpectedFinish = !!projectPlanningModule.planning?.useExpectedFinish;
    const calendar = gantt.getCalendar(task.calendarId as string);

    const calculateFinish = (() => {
        if (!task?.end_date) {
            return null;
        }
        if (
            task.taskType === TaskType.LEVEL_EFFORT ||
            task.taskType === TaskType.WBS_SUMMARY
        ) {
            return task.end_date;
        }
        if (task.taskType === TaskType.FINISH_MILESTONE) {
            return calculateDateWithoutConvert(task.end_date as Date, calendar);
        }
        if (task.taskType === TaskType.START_MILESTONE) {
            return calculateDateWithoutConvert(task.start_date as Date, calendar);
        }

        let finishDate;

        if ((task as any).calendar_id !== (task as any).calendarId) {
            finishDate = calculateEndDateToEndOfDay(
                calculateFinishWithNewCalendar(
                    gantt,
                    task.start_date as string,
                    task.end_date as string,
                    (task as any).calendar_id as string,
                    task.calendarId as string,
                ) as Date,
                calendar,
            );
            if (task.status === TaskStatus.IN_PROGRESS) {
                const newRemainingDuration = calendar.calculateDuration(
                    moment(projectPlanningModule.planning?.dataDate as Date).toDate(),
                    finishDate,
                    task.calendarId as string,
                    projectPlanningModule.planning?.durationFormat,
                );
                if (task.remainingDuration !== newRemainingDuration) {
                    finishDate = moment(finishDate).add(
                        (task.remainingDuration as number) - newRemainingDuration,
                        task.durationFormat,
                    );
                }
            }
            if (task.status === TaskStatus.FINISHED) {
                finishDate = task.finish;
            }
        } else {
            if (task.status === TaskStatus.FINISHED) {
                finishDate = task.finish;
            } else {
                finishDate = calculateEndDateToEndOfDay(task?.end_date as Date, calendar);
            }
        }

        return finishDate;
    })();

    const calculateStart = (() => {
        if (!task?.start_date) {
            return null;
        }
        if (
            task.taskType === TaskType.LEVEL_EFFORT ||
            task.taskType === TaskType.WBS_SUMMARY
        ) {
            return task.start_date;
        }
        if (
            task.status === TaskStatus.IN_PROGRESS ||
            task.status === TaskStatus.FINISHED
        ) {
            return task.start;
        }
        if (task.taskType === TaskType.FINISH_MILESTONE) {
            return calculateDateWithoutConvert(task.end_date as Date, calendar);
        } else if (task.taskType === TaskType.START_MILESTONE) {
            return calculateDateWithoutConvert(task.start_date as Date, calendar);
        }
        return calculateStartDateToStartOfDay(task?.start_date as Date, calendar);
    })();

    const calculatePlannedStart = (() => {
        if (task.status === TaskStatus.TODO) {
            return calculateStart ? calculateStart : task?.plannedStart;
        }
        return calculateStartDateToStartOfDay(task.plannedStart as Date, calendar);
    })();

    const calculatePlannedFinish = (() => {
        if (task.status === TaskStatus.TODO) {
            if (task.expectedFinish && useExpectedFinish) {
                return calculateEndDateToEndOfDay(task.expectedFinish as Date, calendar);
            } else {
                return calculateFinish ? calculateFinish : task?.plannedFinish;
            }
        }
        if (
            task.status === TaskStatus.IN_PROGRESS &&
            (task as any).calendar_id !== (task as any).calendarId
        ) {
            const newPlannedFinish = cloneDeep(calculateFinish);
            const newPlannedDuration = calendar.calculateDuration(
                moment(calculatePlannedStart).toDate(),
                moment(newPlannedFinish).toDate(),
                task.calendarId as string,
                projectPlanningModule.planning?.durationFormat,
            );

            return moment(newPlannedFinish).add(
                (task.plannedDuration as number) - newPlannedDuration,
                task.durationFormat,
            );
        }
        if (task.status === TaskStatus.FINISHED) {
            return calendar.calculateEndDate({
                start_date: moment(task.plannedStart as Date).toDate(),
                duration: task.originalDuration,
                unit: task.durationFormat,
            });
        }
        return calculateEndDateToEndOfDay(task.plannedFinish as Date, calendar);
    })();

    const calculateActualStart = (() => {
        if (
            task.status === TaskStatus.FINISHED ||
            task.status === TaskStatus.IN_PROGRESS
        ) {
            return calculateStart ? calculateStart : task.actualStart;
        }
        return calculateStartDateToStartOfDay(task.actualStart as Date, calendar);
    })();

    const calculateActualFinish = (() => {
        if (task.status === TaskStatus.FINISHED) {
            return calculateFinish ? calculateFinish : task.actualFinish;
        }
        return calculateEndDateToEndOfDay(task.actualFinish as Date, calendar);
    })();

    const calcDurationAndTimelineField = ((): ITaskDragUpdate => {
        const durationAndTimelineField: ITaskDragUpdate = {
            originalDuration: task.originalDuration!,
            actualDuration: task.actualDuration!,
            remainingDuration: task.remainingDuration!,
            plannedDuration: task.plannedDuration,
            finish: moment(calculateFinish)
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
            actualFinish: calculateActualFinish
                ? moment(calculateActualFinish)
                      .utc()
                      .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                : null,
            plannedFinish: calculatePlannedFinish
                ? moment(calculatePlannedFinish)
                      .utc()
                      .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                : null,
            start: moment(calculateStart)
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
            actualStart: calculateActualStart
                ? moment(calculateActualStart)
                      .utc()
                      .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                : null,
            plannedStart: calculatePlannedStart
                ? moment(calculatePlannedStart)
                      .utc()
                      .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                : null,
        };

        if (task.status === TaskStatus.TODO && task.expectedFinish && useExpectedFinish) {
            durationAndTimelineField.finish = moment(
                calculateEndDateToEndOfDay(task.expectedFinish as Date, calendar),
            )
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);
        }

        return durationAndTimelineField;
    })();

    const calculateLateStart = () => {
        if (task.taskType === TaskType.LEVEL_EFFORT) {
            return moment(task.start_date)
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);
        }
        return task?.lateStart
            ? moment(task?.lateStart)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null;
    };

    const calculateLateFinish = () => {
        if (task.taskType === TaskType.LEVEL_EFFORT) {
            return moment(task.end_date)
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);
        }
        return task?.lateFinish
            ? moment(task?.lateFinish)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null;
    };

    const calculateEarlyStart = () => {
        if (task.taskType === TaskType.LEVEL_EFFORT) {
            return moment(task.start_date)
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);
        }
        return task.earlyStart
            ? moment(task.earlyStart)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null;
    };

    const calculateEarlyFinish = () => {
        if (task.taskType === TaskType.LEVEL_EFFORT) {
            return moment(task.end_date)
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);
        }
        return task.earlyFinish
            ? moment(task.earlyFinish)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null;
    };

    const calculateVBLFinishDate = () => {
        if (!task.finish || !task.baselineFinish) {
            return null;
        }
        return moment(task.finish).diff(task.baselineFinish, 'days');
    };

    const calculatePhysicalPercentage = () => {
        if (!task.actualPhysicalQuantity || !task.remainingPhysicalQuantity) {
            return undefined;
        }

        const atCompletePhysicalQuantity =
            +task.actualPhysicalQuantity + +(task.remainingPhysicalQuantity as number);
        const physicalPercentage =
            (+task.actualPhysicalQuantity / (atCompletePhysicalQuantity || 1)) *
            DefaultMaxDurationComplete;

        return +physicalPercentage.toFixed(
            projectPlanningModule.planning?.durationDecimalFormat ??
                DEFAULT_TO_FIXED_LENGTH,
        );
    };

    const calculateOriginalDuration = () => {
        if (
            task.taskType === TaskType.START_MILESTONE ||
            task.taskType === TaskType.FINISH_MILESTONE
        ) {
            return DURATION_ZERO_WITH_MILESTONE;
        }

        if (task.expectedFinish && useExpectedFinish && task.status === TaskStatus.TODO) {
            return calculateDuration(
                calculateStart as Date,
                new Date(task.expectedFinish as Date),
                task.calendarId as string,
                format,
            );
        }
        return task.status === TaskStatus.TODO
            ? calculateDuration(
                  calculateStart as Date,
                  new Date(calculateFinish as Date),
                  task.calendarId as string,
                  format,
              )
            : calculateDuration(
                  new Date(calculatePlannedStart as Date),
                  new Date(calculatePlannedFinish as Date),
                  task.calendarId as string,
                  format,
              );
    };

    const calculateRemainingDuration = () => {
        if (
            task.taskType === TaskType.LEVEL_EFFORT ||
            task.taskType === TaskType.WBS_SUMMARY
        ) {
            return calculateDuration(
                projectPlanningModule.planning?.dataDate as Date,
                new Date(calculateFinish),
                task.calendarId as string,
                format,
            );
        }
        if (task.status === TaskStatus.TODO) {
            return calculateOriginalDuration();
        } else if (task.status === TaskStatus.IN_PROGRESS) {
            if (task.expectedFinish && useExpectedFinish) {
                return calculateDuration(
                    projectPlanningModule.planning?.dataDate as Date,
                    new Date(task.expectedFinish as Date),
                    task.calendarId as string,
                    format,
                );
            } else {
                return calculateDuration(
                    projectPlanningModule.planning?.dataDate as Date,
                    new Date(calculateFinish as Date),
                    task.calendarId as string,
                    format,
                );
            }
        }
        return 0;
    };

    const calculateActualDurationStringFormat = () => {
        if (
            task.taskType === TaskType.LEVEL_EFFORT ||
            task.taskType === TaskType.WBS_SUMMARY
        ) {
            return calculateDuration(
                new Date(calculateStart),
                projectPlanningModule.planning?.dataDate as Date,
                task.calendarId as string,
                format,
            );
        }

        return task.status === TaskStatus.IN_PROGRESS
            ? calculateDuration(
                  calculateActualStart as Date,
                  projectPlanningModule.planning?.dataDate as Date,
                  task.calendarId as string,
                  format,
              )
            : task.status === TaskStatus.FINISHED
            ? calculateDuration(
                  moment(calculateActualStart as Date).toDate(),
                  moment(calculateFinish as Date).toDate(),
                  task.calendarId as string,
                  format,
              )
            : 0;
    };

    const calculateAtCompleteDurationStringFormat = () => {
        const planningCalendar = projectPlanningModule.planning?.calendars?.find(
            (calendar) => calendar._id === task.calendarId,
        );

        const actualDuration = calculateActualDurationStringFormat();
        return (
            (actualDuration >= 0 ? actualDuration : 0) +
            convertDurationFormat(
                task.remainingDuration as number,
                TaskDurationFormat.MINUTE,
                format,
                planningCalendar,
            )
        );
    };

    const calculatePlannedDuration = () => {
        if (
            task.taskType === TaskType.START_MILESTONE ||
            task.taskType === TaskType.FINISH_MILESTONE
        ) {
            return DURATION_ZERO_WITH_MILESTONE;
        }

        return calculateDuration(
            new Date(calculatePlannedStart as Date),
            new Date(calculatePlannedFinish as Date),
            task.calendarId as string,
            format,
        );
    };

    const calculateVBLStartDate = () => {
        if (!task.start || !task.baselineStart) {
            return undefined;
        }
        return moment(task.start).diff(task.baselineStart, 'days');
    };

    const calculateDurationPercentage = () => {
        if (
            task.taskType === TaskType.LEVEL_EFFORT ||
            task.taskType === TaskType.WBS_SUMMARY
        ) {
            const initialDuration = calculateDuration(
                task.start_date as Date,
                task.end_date as Date,
                task.calendarId as string,
                format,
            );

            const remainingDuration = calculateDuration(
                projectPlanningModule.planning?.dataDate as Date,
                task.end_date as Date,
                task.calendarId as string,
                format,
            );

            return +remainingDuration < +initialDuration
                ? +(
                      ((+initialDuration - +remainingDuration) / +initialDuration) *
                      DefaultMaxDurationComplete
                  ).toFixed(
                      projectPlanningModule.planning?.durationDecimalFormat ??
                          DEFAULT_TO_FIXED_LENGTH,
                  )
                : 0;
        }

        if (task.plannedDuration && task.remainingDuration) {
            return +(
                (calculatePlannedDuration() - calculateRemainingDuration()) /
                calculatePlannedDuration()
            ).toFixed(
                projectPlanningModule.planning?.durationDecimalFormat ??
                    DEFAULT_TO_FIXED_LENGTH,
            ) > 0
                ? +(
                      +(
                          (calculatePlannedDuration() - calculateRemainingDuration()) /
                          calculatePlannedDuration()
                      ) * DefaultMaxDurationComplete
                  ).toFixed(
                      projectPlanningModule.planning?.durationDecimalFormat ??
                          DEFAULT_TO_FIXED_LENGTH,
                  )
                : 0;
        }
        return undefined;
    };

    const calculateStatus = () => {
        if (
            task.taskType === TaskType.LEVEL_EFFORT ||
            task.taskType === TaskType.WBS_SUMMARY
        ) {
            if (task.isRootWbs && task.isMilestoneFolder) {
                return task.status;
            }

            if (
                moment(calculateFinish).isBefore(projectPlanningModule.planning?.dataDate)
            ) {
                return TaskStatus.FINISHED;
            }

            if (
                moment(calculateStart).isBefore(projectPlanningModule.planning?.dataDate)
            ) {
                return TaskStatus.IN_PROGRESS;
            }

            return TaskStatus.TODO;
        }
        return task.status;
    };

    const calcConstraintDate = () => {
        if ((task as any)?.constraintDateWork)
            return moment((task as any).constraintDateWork)
                .utc()
                .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON);

        return task.constraint_date && moment(task.constraint_date).isValid()
            ? moment(task.constraint_date)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null;
    };

    return {
        ...calcDurationAndTimelineField,
        ganttId: task.id,
        parentId: task.parentId ? task.parentId.toString() : null,
        parentGanttId: task.parent ? task.parent.toString() : null,
        name: task.name,
        taskType: task.taskType,
        status: calculateStatus(),
        baselineFinish: task.baselineFinish
            ? moment(task.baselineFinish)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null,
        baselineStart: task.baselineStart
            ? moment(task.baselineStart)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null,
        expectedFinish: task.expectedFinish
            ? moment(task.expectedFinish)
                  .utc()
                  .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
            : null,
        primaryConstraintDate: calcConstraintDate(),
        primaryConstraints: task.constraint_type || null,
        durationType: task.durationType,
        percentageComplete: task.percentageComplete,
        manualComplete: task.manualComplete,
        physicalQuantityUnit: task.physicalQuantityUnit,
        physicalQuantity: task.physicalQuantity,
        actualPhysicalQuantity: task.actualPhysicalQuantity,
        rules: task.rules,
        additionalFields: task.additionalFields,
        calendarId: task.calendarId,
        lateStart: calculateLateStart(),
        earlyStart: calculateEarlyStart(),
        finish: moment(calculateFinish)
            .utc()
            .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
        earlyFinish: calculateEarlyFinish(),
        lateFinish: calculateLateFinish(),
        VBLFinishDate: calculateVBLFinishDate(),
        freeFloat: task.freeFloat,
        totalFloat: task.totalFloat,
        critical: task.critical,
        isOnCriticalPath: task.isOnCriticalPath,
        physicalPercentage: calculatePhysicalPercentage(),
        durationPercentage: calculateDurationPercentage(),
        originalDuration:
            calculateOriginalDuration() > 0 ? calculateOriginalDuration() : 0,
        actualDuration:
            calculateActualDurationStringFormat() > 0
                ? calculateActualDurationStringFormat()
                : 0,
        remainingDuration:
            calculateRemainingDuration() > 0 ? calculateRemainingDuration() : 0,
        plannedDuration: calculatePlannedDuration() > 0 ? calculatePlannedDuration() : 0,
        atCompleteDuration:
            calculateAtCompleteDurationStringFormat() > 0
                ? calculateAtCompleteDurationStringFormat()
                : 0,
        VBLDuration:
            calculateAtCompleteDurationStringFormat() > 0
                ? calculateAtCompleteDurationStringFormat()
                : 0,
        VBLStartDate: calculateVBLStartDate(),
    };
};

/**
 *
 * @param from date from
 * @param hours number of hours to add
 * @param workingTime array of number contains exactly 4 items example [8,12,14,18]
 */
export const calcDateIgnoreNonWorkingTime = (
    from: Date | string | null,
    hours: number,
    workingTime: number[],
): Date | null => {
    if (!from) {
        return null;
    }
    const firstShiftDuration = workingTime[1] - workingTime[0];
    const lastShiftDuration = workingTime[3] - workingTime[2];
    const totalWorkingHourPerDay = lastShiftDuration + firstShiftDuration;
    const numOfDays = Math.floor(hours / totalWorkingHourPerDay);
    const remainHours = hours % totalWorkingHourPerDay;
    return new Date(
        moment(
            new Date(from).getTime() + numOfDays * 24 * 36e5 + remainHours * 36e5,
        ).fmDayString(),
    );
};

export const getVisibleColumnConfiguration = (
    gantt: GanttStatic,
    displayedColumns: IColumnSetting2[],
    convertDurationStringFormat: (
        currentDuration: number,
        calendarId: string,
        durationFormat?: TaskDurationFormat,
    ) => number,
    durationStringFormat: (
        startDate: Date,
        endDate: Date,
        calendarId: string,
    ) => number | null,
    calculateDuration: (
        startDate: Date,
        endDate: Date,
        calendarId: string,
        taskDurationFormat?: TaskDurationFormat,
    ) => any,
    t: any,
) => {
    const planningFilters = cloneDeep(projectPlanningModule.planningFilters);

    const savedFilters =
        computed(() => projectPlanningModule.planning?.view?.filters) || [];
    savedFilters?.value?.forEach((filter) => {
        if (filter.selected) {
            if (planningFilters?.[filter.columnName]?.length) {
                planningFilters?.[filter.columnName]?.push(filter);
            } else {
                planningFilters[filter.columnName] = [filter];
            }
        }
    });

    const getLabel = (
        columnName: string,
        columnType: ColumnType = ColumnType.PREDEFINED,
    ) => {
        const displayColumnName =
            columnType === ColumnType.PREDEFINED
                ? t(`planning.gantt.columns.${columnName}`)
                : columnName;
        const columnWithType = columnType + VIEW_COLUMN_TYPE_SEPARATOR + columnName;
        return `
            <div
                class="filterable-column"
                oncontextmenu="event.preventDefault();window.handleRightClickGanttHeader(event)"
                column-type="${columnType}"
                column-name="${columnName}"
            >
                ${displayColumnName}
                <img
                    class="filter-button"
                    src="${require('@/assets/icons/funnel.svg')}"
                    style="${
                        planningFilters?.[columnWithType]?.length ? '' : 'display: none'
                    }"
                />
            </div>`;
    };

    const format =
        projectPlanningModule.planning?.durationFormat || TaskDurationFormat.HOUR;

    gantt.config.editor_types.inputTextEditor = {
        show: (id: any, column: any, config: any, placeholder: any) => {
            const html =
                "<div><input type='text' name='" +
                column.name +
                "' class='inline_editor'></div>";
            placeholder.innerHTML = html;
        },

        hide: () => {
            // called when input is hidden
            // destroy any complex editors or detach event listeners from here
        },

        set_value: async (value: string, id: any, column: any, node: any) => {
            if (column.name === GanttColumn.ID) {
                node.querySelector('input').value = id;
                return;
            }
            node.querySelector('input').value = value;
        },

        get_value: (id: any, column: any, node: any) => {
            return node.querySelector('input').value;
        },

        is_changed: (value: string, id: any, column: any, node: any) => {
            //called before save/close. Return true if new value differs from the original one
            //returning true will trigger saving changes, returning false will skip saving
            return true;
        },

        is_valid: (value: string, id: any, column: any, node: any) => {
            return true;
        },

        focus: (node: any) => {
            gantt.config.editor_types.text.focus(node);
        },
    };

    gantt.config.editor_types.selectEditor = {
        show: (id: any, column: any, config: any, placeholder: any) => {
            const currentTask = gantt.getTask(id);
            let html =
                "<div><select name='" + column.name + "' class='inline_editor'></div>";

            let options: Record<string, string>[] = [];
            switch (config.map_to) {
                case InlineColumn.TASK_TYPE: {
                    if (currentTask.taskType === TaskType.WBS_SUMMARY) {
                        return;
                    }
                    const allowTaskTypes = Object.values(TaskType).filter((taskType) =>
                        (ConstraintTypeMapping[taskType] as TaskConstraint[]).includes(
                            (currentTask.primaryConstraints ||
                                TaskConstraint.ASAP) as TaskConstraint,
                        ),
                    );

                    if (
                        currentTask.parentId &&
                        (projectPlanningModule.planning?.tasks || []).find(
                            (task) => task._id === currentTask.parentId,
                        )?.isMilestoneFolder
                    ) {
                        options = [TaskType.START_MILESTONE, TaskType.FINISH_MILESTONE]
                            .filter((taskType) => allowTaskTypes.includes(taskType))
                            .map((item) => ({
                                label: t(`planning.task.types.${item}`),
                                value: item,
                            }));
                    } else {
                        options =
                            currentTask.taskStatus === TaskStatus.IN_PROGRESS
                                ? allowTaskTypes
                                      .filter(
                                          (item) =>
                                              ![
                                                  TaskType.MILESTONE,
                                                  TaskType.PROJECT,
                                                  TaskType.START_MILESTONE,
                                                  TaskType.FINISH_MILESTONE,
                                              ].includes(item),
                                      )
                                      .map((item) => ({
                                          label: t(`planning.task.types.${item}`),
                                          value: item,
                                      }))
                                : allowTaskTypes
                                      .filter(
                                          (item) =>
                                              ![
                                                  TaskType.MILESTONE,
                                                  TaskType.PROJECT,
                                              ].includes(item),
                                      )
                                      .map((item) => ({
                                          label: t(`planning.task.types.${item}`),
                                          value: item,
                                      }));
                    }
                    break;
                }

                case InlineColumn.CALENDAR_ID: {
                    options = calendarModule.calendarList.map((calendar) => ({
                        label: calendar.name,
                        value: calendar._id,
                    }));
                    break;
                }

                case GanttColumn.DURATION_TYPE: {
                    options = Object.values(TaskDuration).map((item) => ({
                        label: t(`planning.gantt.durationTypes.${item}`),
                        value: item,
                    }));
                    break;
                }

                case InlineColumn.PHYSICAL_QUANTITY_UNIT: {
                    options = Object.values(TaskPhysicalQuantityUnit).map((item) => ({
                        label: t(`planning.gantt.physicalQuantityUnit.${item}`),
                        value: item,
                    }));
                    break;
                }

                case InlineColumn.PERCENTAGE_COMPLETE: {
                    options = Object.values(TaskPercentageComplete).map((item) => ({
                        label: t(`planning.gantt.percentCompleteTypes.${item}`),
                        value: item,
                    }));
                    break;
                }

                case InlineColumn.RULES: {
                    options = projectPlanningModule.taskRuleListAssignment.map((r) => ({
                        label: `${r?.name} (${r?.rate} ${r?.unit}/${r?.period})`,
                        value: r._id,
                    }));
                    break;
                }
            }

            for (let i = 0; i < options.length; i++) {
                html +=
                    "<option value='" +
                    options[i].value +
                    "'>" +
                    options[i].label +
                    '</option>';
            }

            html += '</select>';
            placeholder.innerHTML = html;
        },

        hide: () => {
            // called when input is hidden
            // destroy any complex editors or detach event listeners from here
        },

        set_value: async (value: string, id: any, column: any, node: any) => {
            if (node.querySelector('select')) {
                node.querySelector('select').value = value;
            }
        },

        get_value: (id: any, column: any, node: any) => {
            // TODO: Update to the database
            return node.querySelector('select')?.value;
        },

        is_changed: async (value: string, id: any, column: any, node: any) => {
            //called before save/close. Return true if new value differs from the original one
            //returning true will trigger saving changes, returning false will skip saving
            const currentTask = gantt.getTask(id);

            if (column.name === GanttColumn.TYPE) {
                if (!value || currentTask.taskType === TaskType.WBS_SUMMARY) {
                    return false;
                }
                return true;
            }

            return true;
        },

        is_valid: (value: string, id: any, column: any, node: any) => {
            return true;
        },

        focus: (node: any) => {
            gantt.config.editor_types.text.focus(node);
        },
    };

    gantt.config.editor_types.dateEditor = {
        show: (id: any, column: any, config: any, placeholder: any) => {
            const currentTask = gantt.getTask(id);
            if (
                (config.map_to === GanttColumn.ACTUAL_START &&
                    currentTask.status === TaskStatus.TODO) ||
                (config.map_to === GanttColumn.ACTUAL_FINISH &&
                    currentTask.status !== TaskStatus.FINISHED) ||
                (config.map_to === GanttColumn.EXPECTED_FINISH &&
                    currentTask.status === TaskStatus.FINISHED) ||
                ((config.map_to === GanttColumn.PLANNED_START ||
                    config.map_to === GanttColumn.PLANNED_FINISH) &&
                    currentTask.status !== TaskStatus.TODO) ||
                ((config.map_to === GanttColumn.FINISH ||
                    config.map_to === GanttColumn.PLANNED_FINISH) &&
                    currentTask.expectedFinish &&
                    projectPlanningModule.planning?.useExpectedFinish)
            ) {
                return;
            }

            const html =
                "<div><input type='datetime-local' name='" +
                column.name +
                "' class='inline_editor'></div>";
            placeholder.innerHTML = html;
        },

        hide: () => {
            // called when input is hidden
            // destroy any complex editors or detach event listeners from here
        },

        set_value: async (value: string, id: any, column: any, node: any) => {
            if (node.querySelector('input')) {
                node.querySelector('input').value = value
                    ? moment(value).format(DATE_TIME_FORMAT.YYYY_MM_DD_T_HH_MM_COLON)
                    : value;
            }
        },

        get_value: (id: any, column: any, node: any) => {
            return node.querySelector('input')?.value;
        },

        is_changed: (value: string, id: any, column: any, node: any) => {
            //called before save/close. Return true if new value differs from the original one
            //returning true will trigger saving changes, returning false will skip saving
            const currentTask = gantt.getTask(id);
            if (column.name === GanttColumn.ACTUAL_START) {
                if (currentTask.status === TaskStatus.TODO) {
                    return false;
                }

                return true;
            }

            if (column.name === GanttColumn.ACTUAL_FINISH) {
                if (currentTask.status !== TaskStatus.FINISHED) {
                    return false;
                }

                return true;
            }

            if (column.name === GanttColumn.EXPECTED_FINISH) {
                if (currentTask.status === TaskStatus.FINISHED) {
                    return false;
                }

                return true;
            }

            if (column.name === GanttColumn.PLANNED_START) {
                if (currentTask.status !== TaskStatus.TODO) {
                    return false;
                }

                return true;
            }

            if (column.name === GanttColumn.PLANNED_FINISH) {
                if (
                    currentTask.status !== TaskStatus.TODO ||
                    (currentTask.expectedFinish &&
                        projectPlanningModule.planning?.useExpectedFinish)
                ) {
                    return false;
                }

                return true;
            }

            if (column.name === GanttColumn.FINISH) {
                if (
                    currentTask.expectedFinish &&
                    projectPlanningModule.planning?.useExpectedFinish
                ) {
                    return false;
                }

                return true;
            }

            return true;
        },

        is_valid: (value: string, id: any, column: any, node: any) => {
            return true;
        },

        focus: (node: any) => {
            gantt.config.editor_types.text.focus(node);
        },
    };

    gantt.config.editor_types.inputNumberEditor = {
        show: (id: any, column: any, config: any, placeholder: any) => {
            const currentTask = gantt.getTask(id);

            if (
                (config.map_to === GanttColumn.ORIGINAL_DURATION &&
                    currentTask.status === TaskStatus.TODO &&
                    currentTask.expectedFinish &&
                    projectPlanningModule.planning?.useExpectedFinish) ||
                (config.map_to === GanttColumn.PLANNED_DURATION &&
                    (currentTask.status !== TaskStatus.TODO ||
                        (currentTask.status === TaskStatus.TODO &&
                            currentTask.expectedFinish &&
                            projectPlanningModule.planning?.useExpectedFinish))) ||
                (config.map_to === GanttColumn.ACTUAL_DURATION &&
                    currentTask.status !== TaskStatus.FINISHED) ||
                ((config.map_to === GanttColumn.REMAINING_DURATION ||
                    config.map_to === GanttColumn.DURATION_PERCENTAGE) &&
                    (currentTask.status !== TaskStatus.IN_PROGRESS ||
                        (currentTask.status === TaskStatus.IN_PROGRESS &&
                            currentTask.expectedFinish &&
                            projectPlanningModule.planning?.useExpectedFinish)))
            ) {
                return;
            }

            const html =
                "<div><input type='number' name='" +
                column.name +
                "' class='inline_editor inline_editor_number'></div>";
            placeholder.innerHTML = html;
        },

        hide: () => {
            // called when input is hidden
            // destroy any complex editors or detach event listeners from here
        },

        set_value: async (value: number, id: any, column: any, node: any) => {
            if (node.querySelector('input')) {
                const currentTask = gantt.getTask(id);

                const planningCalendar = projectPlanningModule.planning?.calendars?.find(
                    (calendar) => calendar._id === currentTask.calendarId,
                );

                if (
                    column.name === GanttColumn.ORIGINAL_DURATION ||
                    column.name === GanttColumn.PLANNED_DURATION ||
                    column.name === GanttColumn.ACTUAL_DURATION ||
                    column.name === GanttColumn.REMAINING_DURATION
                ) {
                    node.querySelector('input').value = convertDurationFormat(
                        value,
                        TaskDurationFormat.MINUTE,
                        projectPlanningModule.planning?.durationFormat ||
                            TaskDurationFormat.HOUR,
                        planningCalendar,
                    );

                    return;
                } else if (column.name === GanttColumn.DURATION_PERCENTAGE) {
                    node.querySelector('input').value = value || 0;
                    return;
                }

                node.querySelector('input').value = value;
            }
        },

        get_value: (id: any, column: any, node: any) => {
            const currentTask = gantt.getTask(id);

            const planningCalendar = projectPlanningModule.planning?.calendars?.find(
                (calendar) => calendar._id === currentTask.calendarId,
            );

            if (
                column.name === GanttColumn.ORIGINAL_DURATION ||
                column.name === GanttColumn.PLANNED_DURATION ||
                column.name === GanttColumn.ACTUAL_DURATION ||
                column.name === GanttColumn.REMAINING_DURATION
            ) {
                return convertDurationFormat(
                    node.querySelector('input')?.value || 0,
                    projectPlanningModule.planning?.durationFormat ||
                        TaskDurationFormat.HOUR,
                    TaskDurationFormat.MINUTE,
                    planningCalendar,
                );
            } else if (column.name === GanttColumn.DURATION_PERCENTAGE) {
                return node.querySelector('input')?.value || 0;
            }

            return node.querySelector('input')?.value || 0;
        },

        is_changed: (value: string, id: any, column: any, node: any) => {
            //called before save/close. Return true if new value differs from the original one
            //returning true will trigger saving changes, returning false will skip saving
            const currentTask = gantt.getTask(id);

            if (column.name === GanttColumn.ORIGINAL_DURATION) {
                if (
                    currentTask.status === TaskStatus.TODO &&
                    currentTask.expectedFinish &&
                    projectPlanningModule.planning?.useExpectedFinish
                ) {
                    return false;
                }
                return true;
            }

            if (column.name === GanttColumn.PLANNED_DURATION) {
                if (
                    currentTask.status !== TaskStatus.TODO ||
                    (currentTask.status === TaskStatus.TODO &&
                        currentTask.expectedFinish &&
                        projectPlanningModule.planning?.useExpectedFinish)
                ) {
                    return false;
                }
                return true;
            }

            if (column.name === GanttColumn.ACTUAL_DURATION) {
                if (currentTask.status !== TaskStatus.FINISHED) {
                    return false;
                }
                return true;
            }

            if (column.name === GanttColumn.REMAINING_DURATION) {
                if (
                    currentTask.status !== TaskStatus.IN_PROGRESS ||
                    (currentTask.status === TaskStatus.IN_PROGRESS &&
                        currentTask.expectedFinish &&
                        projectPlanningModule.planning?.useExpectedFinish)
                ) {
                    return false;
                }
                return true;
            }

            if (column.name === GanttColumn.DURATION_PERCENTAGE) {
                if (
                    currentTask.status !== TaskStatus.IN_PROGRESS ||
                    (currentTask.status === TaskStatus.IN_PROGRESS &&
                        currentTask.expectedFinish &&
                        projectPlanningModule.planning?.useExpectedFinish)
                ) {
                    return false;
                }
                return true;
            }

            return true;
        },

        is_valid: (value: string, id: any, column: any, node: any) => {
            return true;
        },

        focus: (node: any) => {
            gantt.config.editor_types.text.focus(node);
        },
    };

    const nameEditor = { type: 'inputTextEditor', map_to: GanttColumn.NAME };
    const idEditor = { type: 'inputTextEditor', map_to: InlineColumn.GANTT_ID };

    const typeEditor = {
        type: 'selectEditor',
        map_to: InlineColumn.TASK_TYPE,
    };

    const calendarEditor = {
        type: 'selectEditor',
        map_to: InlineColumn.CALENDAR_ID,
    };

    const durationTypeEditor = {
        type: 'selectEditor',
        map_to: GanttColumn.DURATION_TYPE,
    };

    const percentCompleteTypesEditor = {
        type: 'selectEditor',
        map_to: InlineColumn.PERCENTAGE_COMPLETE,
    };

    const physicalQuantityUnitEditor = {
        type: 'selectEditor',
        map_to: InlineColumn.PHYSICAL_QUANTITY_UNIT,
    };

    const ruleEditor = { type: 'selectEditor', map_to: GanttColumn.RULES };
    const startEditor = { type: 'dateEditor', map_to: GanttColumn.START };
    const finishEditor = { type: 'dateEditor', map_to: GanttColumn.FINISH };
    const actualStartEditor = { type: 'dateEditor', map_to: GanttColumn.ACTUAL_START };
    const actualFinishEditor = { type: 'dateEditor', map_to: GanttColumn.ACTUAL_FINISH };
    const plannedStartEditor = { type: 'dateEditor', map_to: GanttColumn.PLANNED_START };
    const plannedFinishEditor = {
        type: 'dateEditor',
        map_to: GanttColumn.PLANNED_FINISH,
    };
    const expectedFinishEditor = {
        type: 'dateEditor',
        map_to: GanttColumn.EXPECTED_FINISH,
    };

    const originalDurationEditor = {
        type: 'inputNumberEditor',
        map_to: GanttColumn.ORIGINAL_DURATION,
    };

    const plannedDurationEditor = {
        type: 'inputNumberEditor',
        map_to: GanttColumn.PLANNED_DURATION,
    };

    const actualDurationEditor = {
        type: 'inputNumberEditor',
        map_to: GanttColumn.ACTUAL_DURATION,
    };

    const remainingDurationEditor = {
        type: 'inputNumberEditor',
        map_to: GanttColumn.REMAINING_DURATION,
    };

    const durationPercentageEditor = {
        type: 'inputNumberEditor',
        map_to: GanttColumn.DURATION_PERCENTAGE,
    };

    const predefinedColumnConfigs = [
        {
            name: GanttColumn.NAME,
            label: getLabel(GanttColumn.NAME),
            tree: true,
            editor: nameEditor,
            template: (task: IGanttChartTask & Record<string, any>) => {
                if (!task.$virtual) return task.name;
                if (!projectPlanningModule.planning?.view) return;

                const { columnName, columnType } =
                    projectPlanningModule.planning.view.groupBy;
                const displayedColumnName =
                    columnType === ColumnType.PREDEFINED
                        ? t(`planning.gantt.columns.${columnName}`)
                        : columnName;

                if (task.label == null || task.label === '') {
                    return t('planning.gantt.group.groupLabelEmpty', {
                        column: displayedColumnName,
                    });
                } else {
                    return t('planning.gantt.group.groupLabel', {
                        column: displayedColumnName,
                        value: task.label,
                    });
                }
            },
            resize: true,
        },
        {
            name: GanttColumn.ID,
            editor: idEditor,
            label: getLabel(GanttColumn.ID),
            resize: true,
        },
        {
            name: GanttColumn.CALENDAR_NAME,
            label: getLabel(GanttColumn.CALENDAR_NAME),
            editor: calendarEditor,
            resize: true,
        },
        {
            name: GanttColumn.PARENT_ID,
            label: getLabel(GanttColumn.PARENT_ID),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.parent ? task.parent : '';
            },
        },
        {
            name: GanttColumn.PARENT_NAME,
            label: getLabel(GanttColumn.PARENT_NAME),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (!task.parent) {
                    return '';
                }
                return gantt.getTask(task.parent).name;
            },
        },
        {
            name: GanttColumn.STATUS,
            label: getLabel(GanttColumn.STATUS),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.status ? t(`planning.gantt.status.${task.status}`) : '';
            },
        },
        {
            name: GanttColumn.TYPE,
            label: getLabel(GanttColumn.TYPE),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (!task.type) {
                    return '';
                }
                return t(`planning.task.types.${task.taskType}`);
            },
        },
        // TODO correct the display value later
        {
            name: GanttColumn.START,
            label: getLabel(GanttColumn.START),
            resize: true,
            editor: startEditor,
            template: (task: IGanttChartTask) => {
                if (!task.start || task.taskType === TaskType.FINISH_MILESTONE) {
                    return undefined;
                }
                return formatDateWithoutHour(task.start);
            },
        },
        {
            name: GanttColumn.PLANNED_START,
            label: getLabel(GanttColumn.PLANNED_START),
            resize: true,
            editor: plannedStartEditor,
            template: (task: IGanttChartTask) => {
                if (!task?.plannedStart || task.taskType === TaskType.FINISH_MILESTONE) {
                    return undefined;
                }
                return formatDateWithoutHour(task.plannedStart);
            },
        },
        {
            name: GanttColumn.ACTUAL_START,
            label: getLabel(GanttColumn.ACTUAL_START),
            resize: true,
            editor: actualStartEditor,
            template: (task: IGanttChartTask) => {
                if (!task.actualStart) {
                    return undefined;
                }
                return formatDateWithoutHour(task.actualStart);
            },
        },
        {
            name: GanttColumn.EARLY_START,
            label: getLabel(GanttColumn.EARLY_START),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (task.taskType === TaskType.FINISH_MILESTONE) return undefined;
                return task.earlyStart
                    ? formatDateWithoutHour(task.earlyStart)
                    : undefined;
            },
        },
        {
            name: GanttColumn.LATE_START,
            label: getLabel(GanttColumn.LATE_START),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (task.taskType === TaskType.FINISH_MILESTONE) return undefined;
                return task.lateStart ? formatDateWithoutHour(task.lateStart) : undefined;
            },
        },
        {
            name: GanttColumn.BL_START,
            label: getLabel(GanttColumn.BL_START),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (!task.baselineStart) {
                    return undefined;
                }
                return formatDateWithoutHour(task?.baselineStart ?? '');
            },
        },
        {
            name: GanttColumn.FINISH,
            label: getLabel(GanttColumn.FINISH),
            resize: true,
            editor: finishEditor,
            template: (task: IGanttChartTask) => {
                if (!task.finish || task.taskType === TaskType.START_MILESTONE) {
                    return undefined;
                }
                return formatDateWithoutHour(task.finish);
            },
        },
        {
            name: GanttColumn.ACTUAL_FINISH,
            label: getLabel(GanttColumn.ACTUAL_FINISH),
            resize: true,
            editor: actualFinishEditor,
            template: (task: IGanttChartTask) => {
                return task.actualFinish
                    ? formatDateWithoutHour(task.actualFinish)
                    : undefined;
            },
        },
        {
            name: GanttColumn.EARLY_FINISH,
            label: getLabel(GanttColumn.EARLY_FINISH),
            resize: true,
            template: (task: IGanttChartTask) => {
                // if (task.taskType === TaskType.WBS_SUMMARY) {
                //     return task.finish ? formatDateWithoutHour(task.finish) : undefined;
                // }
                if (task.taskType === TaskType.START_MILESTONE) return undefined;
                return task.earlyFinish
                    ? formatDateWithoutHour(task.earlyFinish)
                    : undefined;
            },
        },
        {
            name: GanttColumn.LATE_FINISH,
            label: getLabel(GanttColumn.LATE_FINISH),
            resize: true,
            template: (task: IGanttChartTask) => {
                // if (task.taskType === TaskType.WBS_SUMMARY) {
                //     return task.finish ? formatDateWithoutHour(task.finish) : undefined;
                // }
                if (task.taskType === TaskType.START_MILESTONE) return undefined;
                return task.lateFinish
                    ? formatDateWithoutHour(task.lateFinish)
                    : undefined;
            },
        },
        {
            name: GanttColumn.PLANNED_FINISH,
            label: getLabel(GanttColumn.PLANNED_FINISH),
            resize: true,
            editor: plannedFinishEditor,
            template: (task: IGanttChartTask) => {
                if (task.taskType === TaskType.START_MILESTONE) return undefined;

                return task.plannedFinish
                    ? formatDateWithoutHour(task.plannedFinish)
                    : undefined;
            },
        },
        {
            name: GanttColumn.BL_FINISH,
            label: getLabel(GanttColumn.BL_FINISH),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.baselineFinish
                    ? formatDateWithoutHour(task.baselineFinish)
                    : undefined;
            },
        },
        {
            name: GanttColumn.PR_CONSTRAINT,
            label: getLabel(GanttColumn.PR_CONSTRAINT),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.primaryConstraints
                    ? t(`planning.gantt.primaryConstraints.${task.primaryConstraints}`)
                    : t(`planning.gantt.primaryConstraints.${TaskConstraint.ASAP}`);
            },
        },
        {
            name: GanttColumn.PR_CONSTRAINT_DATE,
            label: getLabel(GanttColumn.PR_CONSTRAINT_DATE),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (
                    task.primaryConstraints === TaskConstraint.ASAP ||
                    task.primaryConstraints === TaskConstraint.ALAP
                ) {
                    return undefined;
                }
                return formatDateWithoutHour(task.primaryConstraintDate);
            },
        },
        {
            name: GanttColumn.EXPECTED_FINISH,
            label: getLabel(GanttColumn.EXPECTED_FINISH),
            resize: true,
            editor: expectedFinishEditor,
            template: (task: IGanttChartTask) => {
                if (!task.expectedFinish) {
                    return undefined;
                }
                return formatDateWithoutHour(task.expectedFinish);
            },
        },
        {
            name: GanttColumn.DURATION_TYPE,
            label: getLabel(GanttColumn.DURATION_TYPE),
            resize: true,
            editor: durationTypeEditor,
            template: (task: IGanttChartTask) => {
                return task.durationType
                    ? t(`planning.gantt.durationTypes.${task.durationType}`)
                    : '';
            },
        },
        {
            name: GanttColumn.ORIGINAL_DURATION,
            label: getLabel(GanttColumn.ORIGINAL_DURATION),
            resize: true,
            editor: originalDurationEditor,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                return `${convertDurationStringFormat(
                    task?.originalDuration || 0,
                    task.calendarId as string,
                    task?.durationFormat,
                )} ${format}`;
            },
        },
        {
            name: GanttColumn.ACTUAL_DURATION,
            label: getLabel(GanttColumn.ACTUAL_DURATION),
            resize: true,
            editor: actualDurationEditor,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                return `${
                    (task?.actualDuration || 0) > 0
                        ? convertDurationStringFormat(
                              task?.actualDuration || 0,
                              task.calendarId as string,
                              task?.durationFormat,
                          ) || 0
                        : 0
                } ${format}`;
            },
        },
        {
            name: GanttColumn.REMAINING_DURATION,
            label: getLabel(GanttColumn.REMAINING_DURATION),
            resize: true,
            editor: remainingDurationEditor,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                return `${convertDurationStringFormat(
                    task?.remainingDuration || 0,
                    task.calendarId as string,
                    task?.durationFormat,
                )} ${format}`;
            },
        },
        {
            name: GanttColumn.PLANNED_DURATION,
            label: getLabel(GanttColumn.PLANNED_DURATION),
            resize: true,
            editor: plannedDurationEditor,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                return `${
                    convertDurationStringFormat(
                        task?.plannedDuration || 0,
                        task.calendarId as string,
                        task?.durationFormat,
                    ) || 0
                } ${format}`;
            },
        },
        {
            name: GanttColumn.AT_COMPLETE_DURATION,
            label: getLabel(GanttColumn.AT_COMPLETE_DURATION),
            resize: true,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                return `${
                    convertDurationStringFormat(
                        task?.atCompleteDuration || 0,
                        task.calendarId as string,
                        task?.durationFormat,
                    ) || 0
                } ${format}`;
            },
        },
        {
            name: GanttColumn.BL_DURATION,
            label: getLabel(GanttColumn.BL_DURATION),
            resize: true,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                return `${task?.baselineDuration || 0} ${format}`;
            },
        },
        {
            name: GanttColumn.VBL_DURATION,
            label: getLabel(GanttColumn.VBL_DURATION),
            resize: true,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                return `${task?.varianceBLDuration || 0} ${format}`;
            },
        },
        {
            name: GanttColumn.VBL_FINISH_DATE,
            label: getLabel(GanttColumn.VBL_FINISH_DATE),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task?.baselineFinish
                    ? formatDateWithoutHour(task.baselineFinish)
                    : undefined;
            },
        },
        {
            name: GanttColumn.VBL_START_DATE,
            label: getLabel(GanttColumn.VBL_START_DATE),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task?.varianceBLStartDate;
            },
        },
        {
            name: GanttColumn.FREE_FLOAT,
            label: getLabel(GanttColumn.FREE_FLOAT),
            resize: true,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                const planningCalendar = projectPlanningModule.planning?.calendars?.find(
                    (calendar) => calendar._id === task.calendarId,
                );
                const convertToDurationFormat = convertDurationFormat(
                    task?.freeFloat || 0,
                    TaskDurationFormat.MINUTE,
                    projectPlanningModule.planning?.durationFormat ||
                        TaskDurationFormat.MINUTE,
                    planningCalendar,
                );

                return `${convertToDurationFormat} ${format}`;
            },
        },
        {
            name: GanttColumn.TOTAL_FLOAT,
            label: getLabel(GanttColumn.TOTAL_FLOAT),
            resize: true,
            template: (task: IGanttChartTask) => {
                const format =
                    projectPlanningModule.planning?.durationFormat ||
                    TaskDurationFormat.HOUR;
                const planningCalendar = projectPlanningModule.planning?.calendars?.find(
                    (calendar) => calendar._id === task.calendarId,
                );
                const convertToDurationFormat = convertDurationFormat(
                    task?.totalFloat || 0,
                    TaskDurationFormat.MINUTE,
                    projectPlanningModule.planning?.durationFormat ||
                        TaskDurationFormat.MINUTE,
                    planningCalendar,
                );

                return `${convertToDurationFormat} ${format}`;
            },
        },
        {
            name: GanttColumn.CRITICAL,
            label: getLabel(GanttColumn.CRITICAL),
            resize: true,
            template: (task: IGanttChartTask) => {
                return `
                    <div style="text-align: center">
                        <input
                            type="checkbox"
                            ${task.critical ? 'checked' : ''}
                            id="critical-checkbox-${task.id}"
                            onClick="return false;"
                        />
                    </div>
                `;
            },
        },
        {
            name: GanttColumn.LONGEST_PATH,
            label: getLabel(GanttColumn.LONGEST_PATH),
            resize: true,
            template: (task: IGanttChartTask) => {
                return `
                    <div style="text-align: center">
                        <input
                            type="checkbox"
                            ${task.isOnCriticalPath ? 'checked' : ''}
                            id="critical-checkbox-${task.id}"
                            onClick="return false;"
                        />
                    </div>
                `;
            },
        },
        {
            name: GanttColumn.PERCENT_COMPLETE_TYPE,
            label: getLabel(GanttColumn.PERCENT_COMPLETE_TYPE),
            resize: true,
            editor: percentCompleteTypesEditor,
            template: (task: IGanttChartTask) => {
                return task.percentageComplete
                    ? t(`planning.gantt.percentCompleteTypes.${task.percentageComplete}`)
                    : '';
            },
        },
        {
            name: GanttColumn.PERCENT_COMPLETE,
            label: getLabel(GanttColumn.PERCENT_COMPLETE),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (
                    task.percentageComplete === TaskPercentageComplete.DURATION_COMPLETE
                ) {
                    return task.durationPercentage;
                } else if (
                    task.percentageComplete === TaskPercentageComplete.MANUAL_COMPLETE
                ) {
                    return task.manualComplete;
                } else return task.physicalPercentage;
            },
        },
        {
            name: GanttColumn.PHYSICAL_PERCENTAGE,
            label: getLabel(GanttColumn.PHYSICAL_PERCENTAGE),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (
                    task.percentageComplete === TaskPercentageComplete.PHYSICAL_COMPLETE
                ) {
                    return task.physicalPercentage;
                }
                return undefined;
            },
        },
        {
            name: GanttColumn.DURATION_PERCENTAGE,
            label: getLabel(GanttColumn.DURATION_PERCENTAGE),
            editor: durationPercentageEditor,
            resize: true,
            template: (task: IGanttChartTask) => {
                if (task.status === TaskStatus.IN_PROGRESS)
                    return task.durationPercentage;
                else if (task.status === TaskStatus.FINISHED) {
                    return DefaultMaxDurationComplete;
                }
                return DefaultMinDurationComplete;
            },
        },
        {
            name: GanttColumn.MANUAL_PERCENTAGE,
            label: getLabel(GanttColumn.MANUAL_PERCENTAGE),
            resize: true,
            template: (task: IGanttChartTask) => {
                if (task.percentageComplete === TaskPercentageComplete.MANUAL_COMPLETE) {
                    return task.manualComplete;
                }
                return undefined;
            },
        },
        {
            name: GanttColumn.PHYSICAL_UNIT,
            label: getLabel(GanttColumn.PHYSICAL_UNIT),
            resize: true,
            editor: physicalQuantityUnitEditor,
            template: (task: IGanttChartTask) => {
                return task.physicalQuantityUnit
                    ? t(
                          `planning.gantt.physicalQuantityUnit.${task.physicalQuantityUnit}`,
                      )
                    : '';
            },
        },
        {
            name: GanttColumn.PHYSICAL_QUANTITY,
            label: getLabel(GanttColumn.PHYSICAL_QUANTITY),
            resize: true,
        },
        {
            name: GanttColumn.ACTUAL_PHYSICAL_QUANTITY,
            label: getLabel(GanttColumn.ACTUAL_PHYSICAL_QUANTITY),
            resize: true,
        },
        {
            name: GanttColumn.REMAIN_PHYSICAL_QUANTITY,
            label: getLabel(GanttColumn.REMAIN_PHYSICAL_QUANTITY),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.remainingPhysicalQuantity
                    ? task.remainingPhysicalQuantity
                    : undefined;
            },
        },
        {
            name: GanttColumn.RULES,
            label: getLabel(GanttColumn.RULES),
            editor: ruleEditor,
            resize: true,
            template: (task: IGanttChartTask) => {
                const selectedRule = projectPlanningModule.taskRuleListAssignment?.find(
                    (r) => r._id === task.rules,
                );
                return selectedRule
                    ? `${selectedRule?.name} (${selectedRule?.rate} ${selectedRule?.unit}/${selectedRule?.period})`
                    : '';
            },
        },
        {
            name: GanttColumn.APPEARANCE_PROFILE,
            label: getLabel(GanttColumn.APPEARANCE_PROFILE),
            resize: true,
        },
        {
            name: GanttColumn.RESOURCE_3D,
            label: getLabel(GanttColumn.RESOURCE_3D),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.resourceIds?.length ? task.resourceIds?.length : '';
            },
        },
        {
            name: GanttColumn.RESOURCE_GROUP,
            label: getLabel(GanttColumn.RESOURCE_GROUP),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.resourceGroupIds?.length ? task.resourceGroupIds?.length : '';
            },
        },
        {
            name: GanttColumn.ACTIVITY_CODE,
            label: getLabel(GanttColumn.ACTIVITY_CODE),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.activityCode;
            },
        },
        {
            name: GanttColumn.ACTIVITY_CODE_VALUE,
            label: getLabel(GanttColumn.ACTIVITY_CODE_VALUE),
            resize: true,
            template: (task: IGanttChartTask) => {
                return task.activityCodeValue;
            },
        },
    ];

    const additionalTaskFieldColumnConfig = (
        field: IAdditionalTaskField,
        column: IColumnSetting2,
    ) => {
        let editor: Record<string, string> = {};
        gantt.config.editor_types[field.name] = {
            show: (id: any, column: any, config: any, placeholder: any) => {
                switch (field.dataType) {
                    case TaskFieldDataType.NUMBER:
                    case TaskFieldDataType.INTEGER: {
                        const html =
                            "<div><input type='number' name='" +
                            column.name +
                            "' class='inline_editor inline_editor_number'></div>";
                        placeholder.innerHTML = html;
                        break;
                    }
                    case TaskFieldDataType.STRING: {
                        const html =
                            "<div><input type='text' name='" +
                            column.name +
                            "' class='inline_editor'></div>";
                        placeholder.innerHTML = html;
                        break;
                    }
                    case TaskFieldDataType.BOOLEAN: {
                        const html =
                            "<div class='inline_editor_checkbox inline_editor'><input type='checkbox' name='" +
                            column.name +
                            "' class='inline_editor'></div>";
                        placeholder.innerHTML = html;
                        break;
                    }
                    case TaskFieldDataType.DATE: {
                        const html =
                            "<div><input type='datetime-local' name='" +
                            column.name +
                            "' class='inline_editor'></div>";
                        placeholder.innerHTML = html;
                        break;
                    }
                }
            },

            hide: () => {
                // called when input is hidden
                // destroy any complex editors or detach event listeners from here
            },

            set_value: async (value: number, id: any, column: any, node: any) => {
                if (node.querySelector('input')) {
                    const currentTask = gantt.getTask(id);
                    switch (field.dataType) {
                        case TaskFieldDataType.NUMBER:
                        case TaskFieldDataType.INTEGER: {
                            node.querySelector('input').value =
                                currentTask?.additionalFields?.[field.name];
                            break;
                        }
                        case TaskFieldDataType.STRING: {
                            node.querySelector('input').value =
                                currentTask?.additionalFields?.[field.name] || '';
                            break;
                        }
                        case TaskFieldDataType.BOOLEAN: {
                            node.querySelector('input').checked =
                                currentTask?.additionalFields?.[field.name] || false;
                            break;
                        }
                        case TaskFieldDataType.DATE: {
                            node.querySelector('input').value = currentTask
                                ?.additionalFields?.[field.name]
                                ? moment(
                                      currentTask?.additionalFields?.[field.name],
                                  ).format(DATE_TIME_FORMAT.YYYY_MM_DD_T_HH_MM_COLON)
                                : value;
                            break;
                        }
                    }
                }
            },

            get_value: (id: any, column: any, node: any) => {
                const currentTask = gantt.getTask(id);

                if (field.dataType === TaskFieldDataType.BOOLEAN) {
                    Object.assign(currentTask, {
                        additionalFields: {
                            ...currentTask.additionalFields,
                            [field.name]: node.querySelector('input')?.checked || false,
                        },
                    });
                } else {
                    Object.assign(currentTask, {
                        additionalFields: {
                            ...currentTask.additionalFields,
                            [field.name]: node.querySelector('input')?.value,
                        },
                    });
                }
                return node.querySelector('input')?.value;
            },

            is_changed: (value: string, id: any, column: any, node: any) => {
                return true;
            },

            is_valid: (value: string, id: any, column: any, node: any) => {
                return true;
            },

            focus: (node: any) => {
                gantt.config.editor_types.text.focus(node);
            },
        };
        editor = {
            type: field.name,
            map_to: `additionalFields.${field.name}`,
        };

        return {
            name: field.name,
            width: column.width,
            resize: true,
            editor,
            label: getLabel(field.name, ColumnType.ADDITIONAL_TASK_FIELD),
            template: (task: IGanttChartTask & Record<string, any>) => {
                if (
                    field.dataType === TaskFieldDataType.DATE &&
                    moment(task?.additionalFields?.[field.name] as string).isValid()
                ) {
                    return formatDateWithoutHour(
                        task?.additionalFields?.[field.name] as string,
                    );
                }

                if (field.dataType === TaskFieldDataType.BOOLEAN) {
                    return `
                    <div style="text-align: center">
                        <input
                            type="checkbox"
                            ${task?.additionalFields?.[field.name] ? 'checked' : ''}
                            onClick="return false;"
                        />
                    </div>
                `;
                }

                return task?.additionalFields?.[field.name]
                    ? task?.additionalFields?.[field.name]
                    : '';
            },
        };
    };

    const activityCodeColumnConfig = (
        code: IActivityCodeListItem,
        column: IColumnSetting2,
    ) => ({
        name: code.name,
        width: column.width,
        resize: true,
        label: getLabel(code.name, ColumnType.ACTIVITY_CODE),
        template: (task: IGanttChartTask & Record<string, any>) => {
            const activityCode = projectPlanningModule.activityCodeList.find(
                ({ _id }) => _id === code._id,
            );
            if (!activityCode) return '';

            const activityCodeValueIds = task.assignedActivityCodeValueIds;
            const activityCodeValue = activityCode.activityCodeValues.find((codeValue) =>
                activityCodeValueIds.includes(codeValue._id ?? ''),
            );
            return activityCodeValue?.name ?? '';
        },
    });

    const columnConfigs = displayedColumns
        .map((column) => {
            switch (column.type) {
                case ColumnType.PREDEFINED: {
                    const columnConfig = predefinedColumnConfigs.find(
                        (columnConfig) => columnConfig.name === column.name,
                    );

                    // Not yet implemented columns
                    if (!columnConfig)
                        return {
                            name: column.name,
                            label: getLabel(column.name),
                            width: column.width,
                            resize: true,
                            template: (task: IGanttChartTask) =>
                                task[column.name as keyof IGanttChartTask],
                        };

                    return { ...columnConfig, width: column.width };
                }

                case ColumnType.ADDITIONAL_TASK_FIELD: {
                    const field = projectPlanningModule.taskFieldList.find(
                        (field) => field.name === column.name,
                    );
                    if (!field) return;
                    return additionalTaskFieldColumnConfig(field, column);
                }

                case ColumnType.ACTIVITY_CODE: {
                    const code = projectPlanningModule.activityCodeList.find(
                        (code) => code.name === column.name,
                    );
                    if (!code) return;
                    return activityCodeColumnConfig(code, column);
                }
            }
        })
        .filter(Boolean);

    const AddButtonColumnConfig = {
        name: AddColumnName,
        width: AddColumnWidth,
    };

    return [...columnConfigs, AddButtonColumnConfig];
};

export const getBaselineTask = (taskId: string): IBaselineTask | undefined => {
    const baselineIdSelected = projectPlanningModule.baselineIdSelected;
    const baselineSelected = projectPlanningModule.baselineList.find((baseline) => {
        return baseline._id === baselineIdSelected;
    });
    const baselineTask = baselineSelected?.baselineTasks.find((baselineTask) => {
        return baselineTask.taskId === taskId;
    });

    return baselineTask;
};

export const convertDurationFormat = (
    duration: number,
    sourceFormat: TaskDurationFormat,
    targetFormat: TaskDurationFormat,
    calendar?: ICalendar,
) => {
    const getHoursPerTime = (unit: TaskDurationFormat) => {
        switch (unit) {
            case TaskDurationFormat.DAY:
                return calendar?.hoursPerDay || defaultCalendarHoursPerDay;
            case TaskDurationFormat.WEEK:
                return calendar?.hoursPerWeek || defaultCalendarHoursPerWeek;
            case TaskDurationFormat.MONTH:
                return calendar?.hoursPerMonth || defaultCalendarHoursPerMonth;
            case TaskDurationFormat.YEAR:
                return calendar?.hoursPerYear || defaultCalendarHoursPerYear;
            case TaskDurationFormat.MINUTE:
                return defaultCalendarHoursPerMinute;
            default:
                return 1;
        }
    };

    return +(
        (duration * getHoursPerTime(sourceFormat)) /
        getHoursPerTime(targetFormat)
    ).toFixed(
        projectPlanningModule.planning?.durationDecimalFormat ?? DEFAULT_TO_FIXED_LENGTH,
    );
};

export const calculateStartDateToStartOfDay = (
    date: Date,
    calendar?: Record<string, any>,
) => {
    if (!date) {
        return date;
    }

    if (calendar) {
        const tempEndDate = calendar.calculateEndDate({
            start_date: new Date(date),
            duration: 1,
            unit: TaskDurationFormat.MINUTE,
        });
        return calendar.calculateEndDate({
            start_date: tempEndDate,
            duration: -1,
            unit: TaskDurationFormat.MINUTE,
        });
    }

    return moment(date).set({ hour: 0, minute: 0 }).toDate();
};

export const calculateEndDateToEndOfDay = (
    date: Date,
    calendar?: Record<string, any>,
    needDelayTime = false,
) => {
    if (!date) {
        return date;
    }

    if (calendar) {
        const tempEndDate = calendar.calculateEndDate({
            start_date: new Date(date),
            duration: -1,
            unit: TaskDurationFormat.MINUTE,
        });

        const endOfDate = calendar.calculateEndDate({
            start_date: tempEndDate,
            duration: 1,
            unit: TaskDurationFormat.MINUTE,
        });

        if (needDelayTime && moment(date).isSame(moment(endOfDate), 'date')) {
            const delayTime = moment(date).diff(endOfDate, 'minutes');
            return calendar.calculateEndDate({
                start_date: endOfDate,
                duration: delayTime,
                unit: TaskDurationFormat.MINUTE,
            });
        } else {
            return endOfDate;
        }
    }

    return moment(date).hour() === 0
        ? date
        : moment(date).set({ hour: 0, minute: 0 }).add(1, 'day').toDate();
};

export const calculateDateWithoutConvert = (
    date: Date,
    calendar?: Record<string, any>,
) => {
    if (!date) {
        return date;
    }

    if (calendar) {
        const tempEndDate = calendar.calculateEndDate({
            start_date: new Date(date),
            duration: -1,
            unit: TaskDurationFormat.MINUTE,
        });

        const currentDate = calendar.calculateEndDate({
            start_date: new Date(tempEndDate),
            duration: +1,
            unit: TaskDurationFormat.MINUTE,
        });

        if (moment(date).isSame(moment(currentDate))) {
            return currentDate;
        }

        const tempStartDate = calendar.calculateEndDate({
            start_date: new Date(date),
            duration: 1,
            unit: TaskDurationFormat.MINUTE,
        });
        return calendar.calculateEndDate({
            start_date: tempStartDate,
            duration: -1,
            unit: TaskDurationFormat.MINUTE,
        });
    }

    return moment(date).set({ hour: 0, minute: 0 }).toDate();
};

export const formatDateWithoutHour = (date: string | Date | null) => {
    if (!date) {
        return undefined;
    }

    const currentLanguage = appModule.selectedLanguage as SUPPORT_LANGUAGE;
    const isDisplayHour = projectPlanningModule.planning?.isDisplayHourOfAllDateTime;
    const dateFormatProfile =
        authModule.profile?.dateFormat ?? DateFormatValue.HH_mm_DD_MM_YYYY_SLASH;
    let dateFormat;
    if (!isDisplayHour) {
        if (dateFormatProfile.includes(DateFormatValueNonHour.DD_MM_YYYY_DASH))
            dateFormat = DateFormatValueNonHour.DD_MM_YYYY_DASH;
        else if (dateFormatProfile.includes(DateFormatValueNonHour.DD_MM_YYYY_SLASH))
            dateFormat = DateFormatValueNonHour.DD_MM_YYYY_SLASH;
        else dateFormat = DateFormatValueNonHour.DD_MONTH_YYYY;
    } else dateFormat = dateFormatProfile;

    return moment(date).locale(currentLanguage).format(dateFormat);
};

export const extractColumnWithType = (columnWithType: string) => {
    const sepIdx = columnWithType.indexOf(VIEW_COLUMN_TYPE_SEPARATOR);
    const columnType = columnWithType.substring(0, sepIdx) as ColumnType;
    const columnName = columnWithType.substring(
        sepIdx + VIEW_COLUMN_TYPE_SEPARATOR.length,
    );
    return { columnType, columnName };
};

export const extractColumnWithTypeInGroupBy = (columnWithType: string): IGroupBy =>
    columnWithType === DEFAULT_TASK_GROUP_BY
        ? { columnName: null, columnType: null }
        : extractColumnWithType(columnWithType);

export const calculateFinishWithNewCalendar = (
    gantt: GanttStatic,
    startDate: string,
    currentFinish: string,
    currentCalendarId: string,
    calendarId: string,
) => {
    const currentCalendar = gantt.getCalendar(currentCalendarId);
    const currentDurationUnit = gantt.config.duration_unit;
    gantt.config.duration_unit = TaskDurationFormat.MINUTE;
    const currentDuration = currentCalendar?.calculateDuration({
        start_date: new Date(startDate),
        end_date: new Date(currentFinish),
    });
    gantt.config.duration_unit = currentDurationUnit;

    const calendar = gantt.getCalendar(calendarId);
    const newStartDate = calculateStartDateToStartOfDay(new Date(startDate), calendar);
    return calendar?.calculateEndDate({
        start_date: new Date(newStartDate),
        duration: currentDuration,
        unit: TaskDurationFormat.MINUTE,
    });
};
