import { ElLoading } from 'element-plus';
import { useField, useForm } from 'vee-validate';

import {
    showErrorNotificationFunction,
    showSuccessNotificationFunction,
} from '@/common/helpers';
import i18n from '@/plugins/vue-i18n';
import yup from '@/plugins/yup';

import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';

const baselineFormSchema = yup.object({
    calendarId: yup.string().required().label('calendar'),
});

export const setupAssignCalendarForm = () => {
    const initValues = {
        calendarId: '',
    };

    const { handleSubmit, errors, resetForm, setFieldValue } = useForm({
        initialValues: initValues,
        validationSchema: baselineFormSchema,
    });

    const onSubmit = handleSubmit(async (values) => {
        const loading = ElLoading.service({});
        const response = await projectPlanningService.assignCalendar({
            calendarId: values.calendarId ?? '',
            projectId: projectPlanningModule.planning?.projectId || '',
            taskIds: projectPlanningModule.selectedTaskIdList,
        });
        loading.close();
        if (response.success) {
            showSuccessNotificationFunction(
                i18n.global.t('planning.calendar.assign.message.success'),
            );
            projectPlanningModule.setShowAssignCalendarFormPopup(false);
        } else {
            showErrorNotificationFunction(response.message as string);
        }
        return response;
    });

    const { value: calendarId } = useField('calendarId');
    return { calendarId, errors, initValues, onSubmit, resetForm, setFieldValue };
};
