import {
    ARRAY_MAX_LENGTH,
    DATE_TIME_FORMAT,
    INPUT_TEXT_MAX_LENGTH,
    INTEGER_POSITIVE_MAX_VALUE,
} from '@/common/constants';
import { showErrorNotificationFunction } from '@/common/helpers';
import yup from '@/plugins/yup';
import { ElLoading } from 'element-plus';
import moment from 'moment';
import { useField, useForm } from 'vee-validate';
import { computed } from 'vue-demi';
import {
    TaskConstraint,
    TaskDuration,
    TaskDurationFormat,
    TaskPercentageComplete,
    TaskPhysicalQuantityUnit,
    TaskStatus,
    TaskType,
} from '../constants';
import { IUpdateProjectTaskDto } from '../interfaces';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';
import uniq from 'lodash/uniq';
import { projectModule } from '@/features/project/store';
import localStorageAuthService from '@/common/authStorage';

const taskFormSchema = yup
    .object({
        isUpdate: yup.boolean(),
        ganttId: yup
            .string()
            .trim()
            .max(INPUT_TEXT_MAX_LENGTH)
            .label('id')
            .when('isUpdate', {
                is: true,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired().nullable(),
            }),
        name: yup
            .string()
            .trim()
            .max(INPUT_TEXT_MAX_LENGTH)
            .label('activityName')
            .required(),
        parentId: yup
            .string()
            .nullable()
            .when('isRootFolder', {
                is: true,
                then: yup.string().optional().nullable(),
                otherwise: yup.string().nullable().max(INPUT_TEXT_MAX_LENGTH).required(),
            }),
        parentGanttId: yup.string().nullable().max(INPUT_TEXT_MAX_LENGTH).notRequired(),
        taskType: yup
            .string()
            .transform((val) => (val ? val : ''))
            .oneOf(Object.values(TaskType))
            .label('activityType')
            .required(),
        status: yup
            .string()
            .nullable()
            .oneOf(Object.values(TaskStatus))
            .label('activityStatus')
            .required(),
        // add calendarName later
        calendarId: yup.string().nullable().notRequired(),
        baselineStart: yup.date().nullable().notRequired(),
        baselineFinish: yup.date().nullable().notRequired(),

        primaryConstraints: yup
            .string()
            .transform((val) => (val ? val : null))
            .oneOf([null, ...Object.values(TaskConstraint)])
            .nullable()
            .notRequired(),
        primaryConstraintDate: yup.string().nullable().notRequired(),
        expectedFinish: yup.date().nullable().notRequired(),
        durationType: yup
            .string()
            .transform((val) => (val ? val : null))
            .nullable()
            .optional()
            .oneOf([...Object.values(TaskDuration), null])
            .notRequired(),
        originalDuration: yup
            .number()
            .min(0)
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        actualDuration: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        plannedDuration: yup
            .number()
            .min(0)
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        remainingDuration: yup
            .number()
            .min(0)
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        // unneed atCompletionDuration field

        // unneed planned  field
        // unneed baselineDuration field
        // unneed varianceBLDuration field
        // unneed varianceBLFinishDate field
        // unneed varianceBLStartDate field
        // unneed freeFloat field
        // unneed totalFloat field
        // unneed critical field

        percentageComplete: yup
            .string()
            .transform((val) => (val ? val : null))
            .oneOf([...Object.values(TaskPercentageComplete), null])
            .nullable()
            .optional()
            .notRequired(),
        durationPercentage: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (val ? val : null))
            .nullable()
            .notRequired()
            .max(100)
            .positive(),
        physicalPercentage: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (val ? val : null))
            .nullable()
            .notRequired()
            .max(100)
            .positive(),
        manualComplete: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (val ? val : null))
            .nullable()
            .notRequired()
            .max(100)
            .positive(),
        physicalQuantityUnit: yup
            .string()
            .transform((val) => (val ? val : null))
            .oneOf([...Object.values(TaskPhysicalQuantityUnit), null])
            .label('physicalUnit')
            .nullable()
            .optional()
            .notRequired(),
        physicalQuantity: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        actualPhysicalQuantity: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        remainingPhysicalQuantity: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        atCompletePhysicalQuantity: yup
            .number()
            .max(INTEGER_POSITIVE_MAX_VALUE)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        //unneed remainingPhysicalQuantity
        rules: yup.string().nullable().optional(),

        // add apprearanceProfile field later
        resourceIds: yup
            .array()
            .of(yup.string().optional().optional().nullable())
            .max(ARRAY_MAX_LENGTH)
            .optional(),
        resourceGroupIds: yup
            .array()
            .of(yup.string().optional().optional().nullable())
            .max(ARRAY_MAX_LENGTH)
            .optional(),
        appearanceProfileId: yup
            .string()
            .max(INPUT_TEXT_MAX_LENGTH)
            .transform((val) => (+val ? val : null))
            .nullable()
            .notRequired(),
        isRootFolder: yup.boolean().required(),
        canEdit: yup.boolean().required(),
        estimatedRate: yup.number().nullable().optional(),
    })
    .shape({
        start: yup
            .date()
            .nullable()
            .when('taskType', {
                is: (taskType: TaskType) =>
                    taskType === TaskType.FINISH_MILESTONE ||
                    taskType === TaskType.LEVEL_EFFORT,
                then: yup.date().nullable(),
                otherwise: yup
                    .date()
                    .nullable()
                    .test({
                        name: 'start',
                        message: 'planning.task.form.errors.maxStart',
                        exclusive: false,
                        params: {},
                        test: function (value) {
                            if (this.parent.taskType === TaskType.START_MILESTONE)
                                return true;
                            if (value && this.parent.finish)
                                return (
                                    moment(value).diff(
                                        moment(this.parent.finish),
                                        'millisecond',
                                    ) <= 0
                                );
                            return true;
                        },
                    })
                    .test({
                        name: 'dataDate',
                        message: 'planning.task.form.errors.minStartDataDate',
                        exclusive: false,
                        params: {},
                        test: function (value) {
                            if (
                                value &&
                                projectPlanningModule.planning?.dataDate &&
                                this.parent.status === TaskStatus.TODO
                            )
                                return (
                                    moment(value)
                                        .startOfDay()
                                        .diff(
                                            moment(
                                                projectPlanningModule.planning?.dataDate,
                                            ).startOfDay(),
                                            'millisecond',
                                        ) >= 0
                                );
                            return true;
                        },
                    })
                    .required(),
            }),
        finish: yup
            .date()
            .nullable()
            .when('taskType', {
                is: (taskType: TaskType) =>
                    taskType === TaskType.START_MILESTONE ||
                    taskType === TaskType.LEVEL_EFFORT,
                then: yup.date().nullable(),
                otherwise: yup
                    .date()
                    .nullable()
                    .test({
                        name: 'finish',
                        message: 'planning.task.form.errors.minFinish',
                        exclusive: false,
                        params: {},
                        test: function (value) {
                            if (value && this.parent.start)
                                return (
                                    moment(value).diff(
                                        moment(this.parent.start),
                                        'millisecond',
                                    ) >= 0
                                );
                            return true;
                        },
                    })

                    .required(),
            }),
    })
    .shape(
        {
            actualStart: yup
                .date()
                .nullable()
                .when(['status', 'taskType'], {
                    is: (value: TaskStatus | null, taskType: TaskType) =>
                        (value !== TaskStatus.FINISHED &&
                            value !== TaskStatus.IN_PROGRESS) ||
                        taskType === TaskType.FINISH_MILESTONE,
                    then: (schema) => schema.nullable().notRequired(),
                    otherwise: (schema) => schema.required(),
                })
                .test({
                    name: 'actualStart',
                    message: 'planning.task.form.errors.maxActualStart',
                    exclusive: false,
                    params: {},
                    test: function (value) {
                        if (value && this.parent.actualFinish)
                            return (
                                moment(value)
                                    .startOfDay()
                                    .diff(
                                        moment(this.parent.actualFinish).startOfDay(),
                                        'millisecond',
                                    ) <= 0
                            );
                        return true;
                    },
                }),
            actualFinish: yup
                .date()
                .nullable()
                .when(['status', 'taskType'], {
                    is: (value: TaskStatus | null, taskType: TaskType) =>
                        value !== TaskStatus.FINISHED ||
                        taskType === TaskType.START_MILESTONE,
                    then: (schema) => schema.nullable().notRequired(),
                    otherwise: (schema) => schema.required(),
                })
                .test({
                    name: 'actualFinish',
                    message: 'planning.task.form.errors.minActualFinish',
                    exclusive: false,
                    params: {},
                    test: function (value) {
                        if (value && this.parent.actualStart)
                            return (
                                moment(value)
                                    .startOfDay()
                                    .diff(
                                        moment(this.parent.actualStart).startOfDay(),
                                        'millisecond',
                                    ) >= 0
                            );
                        return true;
                    },
                }),
        },
        [['actualFinish', 'actualStart']],
    )
    .shape(
        {
            plannedStart: yup
                .date()
                .nullable()
                .when('taskType', {
                    is: (taskType: TaskType) =>
                        taskType === TaskType.FINISH_MILESTONE ||
                        taskType === TaskType.LEVEL_EFFORT,
                    then: yup.date().nullable(),
                    otherwise: yup
                        .date()
                        .nullable()
                        .when('status', {
                            is: (value: TaskStatus | null) => value !== TaskStatus.TODO,
                            then: (schema) => schema.notRequired(),
                            otherwise: (schema) => schema.required(),
                        })
                        .test({
                            name: 'plannedStart',
                            message: 'planning.task.form.errors.maxPlannedStart',
                            exclusive: false,
                            params: {},
                            test: function (value) {
                                if (this.parent.taskType === TaskType.START_MILESTONE)
                                    return true;
                                if (value && this.parent.plannedFinish)
                                    return (
                                        moment(value)
                                            .startOfDay()
                                            .diff(
                                                moment(
                                                    this.parent.plannedFinish,
                                                ).startOfDay(),
                                                'millisecond',
                                            ) <= 0
                                    );
                                return true;
                            },
                        }),
                }),
            plannedFinish: yup
                .date()
                .nullable()
                .when('taskType', {
                    is: (taskType: TaskType) =>
                        taskType === TaskType.START_MILESTONE ||
                        taskType === TaskType.LEVEL_EFFORT,
                    then: yup.date().nullable(),
                    otherwise: yup
                        .date()
                        .nullable()
                        .when('status', {
                            is: (value: TaskStatus | null) =>
                                value === TaskStatus.FINISHED || !value,
                            then: (schema) => schema.notRequired(),
                            otherwise: (schema) => schema.required(),
                        })
                        .test({
                            name: 'plannedFinish',
                            message: 'planning.task.form.errors.minPlannedFinish',
                            exclusive: false,
                            params: {},
                            test: function (value) {
                                if (value && this.parent.plannedStart)
                                    return (
                                        moment(value)
                                            .startOfDay()
                                            .diff(
                                                moment(
                                                    this.parent.plannedStart,
                                                ).startOfDay(),
                                                'millisecond',
                                            ) >= 0
                                    );
                                return true;
                            },
                        }),
                }),
        },
        [['plannedFinish', 'plannedStart']],
    );

export const useTaskForm = () => {
    const initValues = {
        isUpdate: false,
        ganttId: '',
        name: '',
        parentId: null,
        parentGanttId: null,
        taskType: TaskType.STANDARD,
        status: TaskStatus.TODO,
        start: null,
        actualStart: null,
        plannedStart: null,
        baselineStart: null,
        finish: null,
        actualFinish: null,
        plannedFinish: null,
        baselineFinish: null,
        primaryConstraints: null,
        primaryConstraintDate: null,
        expectedFinish: null,
        durationType: null,
        originalDuration: null,
        plannedDuration: null,
        actualDuration: null,
        remainingDuration: null,
        percentageComplete: TaskPercentageComplete.MANUAL_COMPLETE,
        manualComplete: null,
        physicalQuantityUnit: null,
        physicalQuantity: null,
        actualPhysicalQuantity: null,
        rules: null,
        remainingPhysicalQuantity: null,
        atCompletePhysicalQuantity: null,
        resourceIds: [],
        resourceGroupIds: [],
        appearanceProfileId: null,
        isRootFolder: false,
        calendarId: null,
        canEdit: true,
        durationPercentage: null,
        physicalPercentage: null,
        estimatedRate: null,
    };

    const {
        handleSubmit,
        errors,
        resetForm,
        validate,
        setValues,
        setFieldValue,
        values,
        setErrors,
    } = useForm({
        initialValues: initValues,
        validationSchema: taskFormSchema,
    });

    const selectedTaskId = computed(() => {
        return projectPlanningModule.taskPopupParams.selectedTask?._id;
    });

    const onUpdateTask = handleSubmit(async (values) => {
        if (values.isRootFolder) {
            delete values.parentId;
        }
        delete values.canEdit;
        delete values.isRootFolder;
        const loading = ElLoading.service({ target: '.el-dialog' });
        projectPlanningModule.setEditedTaskIds(
            uniq([
                ...projectPlanningModule.editedTaskIds,
                selectedTaskId.value as string,
            ]),
        );
        if (values.taskType === TaskType.START_MILESTONE) {
            values.finish = null;
            values.plannedFinish = null;
            values.actualFinish = null;
        } else if (values.taskType === TaskType.FINISH_MILESTONE) {
            values.start = null;
            values.plannedStart = null;
            values.plannedFinish = null;
        }
        const response = await projectPlanningService.updateTask(
            selectedTaskId.value as string,
            {
                ...values,
                start: values.start
                    ? moment(values.start)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                finish: values.finish
                    ? moment(values.finish)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                plannedStart: values.plannedStart
                    ? moment(values.plannedStart)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                plannedFinish: values.plannedFinish
                    ? moment(values.plannedFinish)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                actualStart: values.actualStart
                    ? moment(values.actualStart)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                actualFinish: values.actualFinish
                    ? moment(values.actualFinish)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                baselineFinish: values.baselineFinish
                    ? moment(values.baselineFinish)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                baselineStart: values.baselineStart
                    ? moment(values.baselineStart)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                expectedFinish: values.expectedFinish
                    ? moment(values.expectedFinish)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                primaryConstraintDate: values.primaryConstraintDate
                    ? moment(values.primaryConstraintDate)
                          .utc()
                          .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON)
                    : null,
                isUpdate: undefined,
                parentId: values.parentId || null,
                parentGanttId:
                    projectPlanningModule.planning?.tasks.find(
                        (task) => task._id.toString() === values.parentId?.toString(),
                    )?.ganttId || null,
                appearanceProfileId: values.appearanceProfileId || null,
                path: localStorageAuthService.getPlanningPermissions()?.path || '',
                projectId: projectModule.selectedProjectId || '',
                durationFormat: TaskDurationFormat.MINUTE,
                estimatedRate: values.estimatedRate ? +values.estimatedRate : null,
            } as IUpdateProjectTaskDto,
        );
        loading.close();
        if (response.success) {
            projectPlanningModule.setNeedReload3DViewer(true);
            return response.data;
        } else if (!response?.isRequestError) {
            showErrorNotificationFunction(response.message);
            return undefined;
        }
    });

    const { value: isUpdate } = useField('isUpdate');
    const { value: ganttId } = useField('ganttId');
    const { value: name } = useField('name');
    const { value: parentId } = useField('parentId');
    const { value: taskType } = useField('taskType');
    const { value: status } = useField('status');
    const { value: start } = useField('start');
    const { value: actualStart } = useField('actualStart');
    const { value: plannedStart } = useField('plannedStart');
    const { value: baselineStart } = useField('baselineStart');
    const { value: finish } = useField('finish');
    const { value: actualFinish } = useField('actualFinish');
    const { value: plannedFinish } = useField('plannedFinish');
    const { value: baselineFinish } = useField('baselineFinish');
    const { value: primaryConstraints } = useField('primaryConstraints');
    const { value: primaryConstraintDate } = useField('primaryConstraintDate');
    const { value: expectedFinish } = useField('expectedFinish');
    const { value: durationType } = useField('durationType');
    const { value: originalDuration } = useField('originalDuration');
    const { value: actualDuration } = useField('actualDuration');
    const { value: remainingDuration } = useField('remainingDuration');
    const { value: percentageComplete } = useField('percentageComplete');
    const { value: durationPercentage } = useField('durationPercentage');
    const { value: physicalPercentage } = useField('physicalPercentage');
    const { value: manualComplete } = useField('manualComplete');
    const { value: physicalQuantityUnit } = useField('physicalQuantityUnit');
    const { value: physicalQuantity } = useField('physicalQuantity');
    const { value: actualPhysicalQuantity } = useField('actualPhysicalQuantity');
    const { value: remainingPhysicalQuantity } = useField('remainingPhysicalQuantity');
    const { value: atCompletePhysicalQuantity } = useField('atCompletePhysicalQuantity');
    const { value: plannedDuration } = useField('plannedDuration');
    const { value: rules } = useField('rules');
    const { value: resourceIds } = useField('resourceIds');
    const { value: resourceGroupIds } = useField('resourceGroupIds');
    const { value: appearanceProfileId } = useField('appearanceProfileId');
    const { value: isRootFolder } = useField('isRootFolder');
    const { value: calendarId } = useField('calendarId');
    const { value: canEdit } = useField('canEdit');
    const { value: estimatedRate } = useField('estimatedRate');

    return {
        initValues,
        errors,
        isUpdate,
        ganttId,
        name,
        parentId,
        taskType,
        status,
        start,
        actualStart,
        plannedDuration,
        plannedStart,
        baselineStart,
        finish,
        rules,
        actualFinish,
        plannedFinish,
        baselineFinish,
        primaryConstraints,
        primaryConstraintDate,
        expectedFinish,
        durationType,
        originalDuration,
        actualDuration,
        remainingDuration,
        percentageComplete,
        durationPercentage,
        physicalPercentage,
        manualComplete,
        physicalQuantityUnit,
        physicalQuantity,
        actualPhysicalQuantity,
        remainingPhysicalQuantity,
        atCompletePhysicalQuantity,
        resourceIds,
        appearanceProfileId,
        resourceGroupIds,
        isRootFolder,
        calendarId,
        canEdit,
        estimatedRate,
        values,
        validate,
        setErrors,
        setValues,
        onUpdateTask,
        resetForm,
        setFieldValue,
    };
};
