import { PageName } from '@/common/constants';
import MainLayout from '@/layouts/MainLayout.vue';
import { RouteRecordRaw } from 'vue-router';
import Planning4DPage from './pages/Planning4DPage.vue';
import Planning3DPage from './pages/Planning3DPage.vue';
import Planning4DAnalyzerPage from './pages/Planning4DAnalyzerPage.vue';
import ActivityCodeListPage from './pages/ActivityCodeListPage.vue';
import AppearanceProfileListPage from './pages/AppearanceProfileListPage.vue';
import ResourceListPage from './pages/ResourceListPage.vue';
import UserDefinedFieldListPage from './pages/UserDefinedFieldListPage.vue';
import TaskRulePage from './pages/TaskRulePage.vue';
import {
    ProfilePermissionPrefix,
    ProjectSecurityPermissions,
} from '../3D-viewer-profile/constants';

const planningRouters: Array<RouteRecordRaw> = [
    {
        path: '/project/4d-planning',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName.PLANNING_4D_PAGE,
                component: Planning4DPage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.planning',
                        },
                    ],
                    requiredPermissions: [
                        ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                        ProjectSecurityPermissions['4DPLANNING_READ_ONLY'],
                    ],
                },
            },
        ],
    },
    {
        path: '/project/4D-analyzer',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName['4D_ANALYZER_PAGE'],
                component: Planning4DAnalyzerPage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.analyzer',
                        },
                    ],
                    requiredPermissions: [
                        ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                    ],
                },
            },
        ],
    },
    {
        path: '/project/3d-viewer',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName['3D_PAGE'],
                component: Planning3DPage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    require3DViewerRole: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.viewer3D',
                        },
                    ],
                    requiredPermissions: [
                        ...Object.values(ProjectSecurityPermissions).filter(
                            (permission) =>
                                permission.startsWith(
                                    ProfilePermissionPrefix.WEBVIEWER3D,
                                ),
                        ),
                        ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                    ],
                },
            },
        ],
    },
    {
        path: '/project/activity-code',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName.ACTIVITY_CODE_LIST_PAGE,
                component: ActivityCodeListPage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.activityCodeList',
                        },
                    ],
                    requiredPermissions: [
                        ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                    ],
                },
            },
        ],
    },
    {
        path: '/project/appearance-profile',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName.APPEARANCE_PROFILE_PAGE,
                component: AppearanceProfileListPage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.appearanceProfileListPage',
                        },
                    ],
                    requiredPermissions: [
                        ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                    ],
                },
            },
        ],
    },
    {
        path: '/project/resource',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName.RESOURCE_PAGE,
                component: ResourceListPage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.resourceListPage',
                        },
                    ],
                    requiredPermissions: [
                        ProjectSecurityPermissions.GENERAL_ACCESS_TO_PROJECT,
                    ],
                },
            },
        ],
    },
    {
        path: '/project/user-defined-field',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName.USER_DEFINED_FIELD_LIST_PAGE,
                component: UserDefinedFieldListPage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.userDefinedFieldListPage',
                        },
                    ],
                },
            },
        ],
    },
    {
        path: '/project/task-rule',
        component: MainLayout,
        children: [
            {
                path: '',
                name: PageName.TASK_RULE_PAGE,
                component: TaskRulePage,
                meta: {
                    requiresAuth: true,
                    isViewProject: true,
                    breadcrumb: [
                        {
                            index: 1,
                            text: 'planning.breadcrumb.userDefinedFieldListPage',
                        },
                    ],
                },
            },
        ],
    },
];
export default planningRouters;
