export default {
    title: {
        assign3D: 'Assign 3D file',
        assign4D: 'Assign 4D file',
        assignPlanning: 'Assign planning file',
        assign4DBox: 'Assign 4D Box file',
    },
    button: {
        close: 'Close',
    },
};
