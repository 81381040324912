import { computed, ref } from 'vue';

import { ElLoading } from 'element-plus';
import moment from 'moment';
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';

import {
    ARRAY_MAX_LENGTH,
    DATE_TIME_FORMAT,
    HttpStatus,
    INPUT_TEXT_MAX_LENGTH,
    INTEGER_POSITIVE_MAX_VALUE,
    TEXTAREA_MAX_LENGTH,
} from '@/common/constants';
import {
    showErrorNotificationFunction,
    showSuccessNotificationFunction,
} from '@/common/helpers';
import { projectModule } from '@/features/project/store';
import i18n from '@/plugins/vue-i18n';
import yup from '@/plugins/yup';

import {
    AssignToExistingResourceOption,
    ResourceMaterialUnitType,
    ResourceOtherUnitType,
    ResourceType,
} from '../constants';
import { IResourceWorkloadAndCapacity } from '../interfaces';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';
import { isNumber } from 'lodash';
import { webViewer3DModule } from '@/features/3D-viewer/store';
import { webViewer3DService } from '@/features/3D-viewer/services/api.service';

export const resourceSchema = yup.object({
    resourceId: yup.string().max(INPUT_TEXT_MAX_LENGTH).required().trim(),
    name: yup.string().max(INPUT_TEXT_MAX_LENGTH).required().trim(),
    parentId: yup.string().optional().nullable(),
    type: yup
        .string()
        .oneOf([...Object.values(ResourceType)])
        .required(),
    unit: yup
        .string()
        .oneOf([
            ...Object.values(ResourceMaterialUnitType),
            ...Object.values(ResourceOtherUnitType),
        ])
        .required(),
    defaultUnitPerPeriod: yup
        .number()
        .required()
        .nullable()
        .transform((val) => (isNumber(+val) ? val : null))
        .min(0)
        .max(INTEGER_POSITIVE_MAX_VALUE),
    fileIds: yup
        .array()
        .of(yup.string().optional().optional().nullable())
        .max(ARRAY_MAX_LENGTH)
        .optional(),
    description: yup.string().max(TEXTAREA_MAX_LENGTH).required().trim(),
    calendar: yup.string().nullable().required(),
    workloadAndCapacity: yup.array().of(
        yup
            .object({
                effectiveDate: yup
                    .date()
                    .nullable()
                    .required()
                    .test('customTestForInitData', function test(value) {
                        if (value === undefined) {
                            return this.createError({
                                message: '',
                                path: this.path,
                            });
                        }
                        return true;
                    }),
                unitPerPeriod: yup
                    .mixed()
                    .nullable()
                    .required()
                    .test('customTestForInitData', function test(value) {
                        if (+value < 0) {
                            return this.createError({
                                message: i18n.global.t(
                                    'planning.resource.message.numberLeastZero',
                                ),
                                path: this.path,
                            });
                        }
                        return true;
                    }),
                pricePerUnit: yup
                    .mixed()
                    .nullable()
                    .required()
                    .test('customTestForInitData', function test(value) {
                        if (+value < 0) {
                            return this.createError({
                                message: i18n.global.t(
                                    'planning.resource.message.numberLeastZero',
                                ),
                                path: this.path,
                            });
                        }
                        return true;
                    }),
            })
            .test('checkUniqueEffectiveDate', function test(value) {
                if (!value.effectiveDate) {
                    return true;
                }
                const countEffectiveDate = this.parent?.filter(
                    (item: IResourceWorkloadAndCapacity) => {
                        return (
                            item.effectiveDate?.toString() ===
                            value.effectiveDate?.toString()
                        );
                    },
                )?.length;

                if (countEffectiveDate > 1) {
                    return this.createError({
                        message: i18n.global.t(
                            'planning.resource.message.effectiveExist',
                        ),
                        path: `${this.path}.effectiveDate`,
                    });
                }

                return true;
            }),
    ),
    assignToExistingResourceOption: yup
        .string()
        .oneOf([...Object.values(AssignToExistingResourceOption), null])
        .optional()
        .nullable(),
    sessionToken: yup.string().optional(),
    consumableResource: yup.boolean().optional(),
});

export const innitResource: any = {
    resourceId: '',
    name: '',
    type: ResourceType.EQUIPMENT,
    unit: '',
    fileIds: [],
    description: '',
    parentId: null,
    calendar: null,
    workloadAndCapacity: [
        {
            effectiveDate: new Date(),
            unitPerPeriod: '',
            pricePerUnit: '',
        },
    ],
    assignToExistingResourceOption: null,
    sessionToken: '',
    consumableResource: false,
    defaultUnitPerPeriod: null,
};

export const setupResourceForm = () => {
    const { t } = useI18n();
    const isCreate = computed(() => !projectPlanningModule.selectedResource?._id);

    const {
        handleSubmit,
        errors,
        resetForm,
        validate,
        setErrors,
        setFieldValue,
        setFieldError,
        handleReset,
    } = useForm({
        initialValues: innitResource,
        validationSchema: resourceSchema,
    });

    const additionalFields = ref<Record<string, any>>({});

    const onSubmit = handleSubmit(async (values) => {
        let response;
        const loading = ElLoading.service({});
        if (values.type === ResourceType.HUMAN_RESOURCE) {
            values.fileIds = [];
        }
        if (values.assignToExistingResourceOption) {
            await webViewer3DService.getSelectedNodeIds(
                webViewer3DModule.sessionToken || '',
            );
        }

        if (!values.parentId?.length) {
            values.parentId = null;
        }
        const formatDateForEffectiveDate = values.workloadAndCapacity?.map(
            (item: any) => {
                return {
                    effectiveDate: moment(item.effectiveDate)
                        .utc()
                        .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
                    unitPerPeriod: +item.unitPerPeriod,
                    pricePerUnit: +item.pricePerUnit,
                };
            },
        );
        if (!isCreate.value) {
            response = await projectPlanningService.updateResource(
                projectPlanningModule.selectedResource?._id || '',
                {
                    name: values.name || '',
                    type: (values.type || '') as ResourceType,
                    unit: (values.unit || '') as
                        | ResourceOtherUnitType
                        | ResourceMaterialUnitType,
                    fileIds: values.fileIds || [],
                    planningId: projectPlanningModule.planning
                        ? projectPlanningModule.planning?._id
                        : undefined,
                    description: values.description || '',
                    projectId: projectModule.selectedProjectId || '',
                    parentId: values.parentId || null,
                    calendar: values.calendar || '',
                    resourceId: values.resourceId || '',
                    defaultUnitPerPeriod: values.defaultUnitPerPeriod || 0,
                    workloadAndCapacity:
                        formatDateForEffectiveDate as unknown as Array<IResourceWorkloadAndCapacity>,
                    assignToExistingResourceOption:
                        (values.assignToExistingResourceOption as AssignToExistingResourceOption) ||
                        undefined,
                    sessionToken: projectPlanningModule.planning
                        ? values.sessionToken
                        : undefined,
                    additionalFields: additionalFields.value,
                },
            );
        } else {
            response = await projectPlanningService.createResource({
                name: values.name || '',
                type: (values.type || '') as ResourceType,
                unit: (values.unit || '') as
                    | ResourceOtherUnitType
                    | ResourceMaterialUnitType,
                planningId: projectPlanningModule.planning
                    ? projectPlanningModule.planning?._id
                    : undefined,
                fileIds: values.fileIds || [],
                description: values.description || '',
                projectId: projectModule.selectedProjectId || '',
                parentId: values.parentId || undefined,
                calendar: values.calendar || '',
                resourceId: values.resourceId || '',
                defaultUnitPerPeriod: values.defaultUnitPerPeriod || 0,
                workloadAndCapacity:
                    formatDateForEffectiveDate as unknown as Array<IResourceWorkloadAndCapacity>,
                assignToExistingResourceOption:
                    (values.assignToExistingResourceOption as AssignToExistingResourceOption) ||
                    undefined,
                sessionToken: webViewer3DModule.sessionToken || '',
                additionalFields: additionalFields.value,
            });
        }
        loading.close();
        if (response.success) {
            showSuccessNotificationFunction(
                !isCreate.value
                    ? t('planning.resource.message.updateSuccess')
                    : t('planning.resource.message.createSuccess'),
            );
            projectPlanningModule.setIsShowResourceDetailForm(false);
            projectPlanningModule.setIsShowImportResourcePopup(false);
            projectPlanningModule.setIsSetFileIdsInResourceDetail(false);

            const loading = ElLoading.service({
                target: '.main-wrapper',
            });
            await projectPlanningModule.getResourceTree({
                planningId: projectPlanningModule.planning?._id || '',
                projectId: projectModule.selectedProjectId || '',
            });
            loading.close();
            projectPlanningModule.setSelectedResource(null);
            projectPlanningModule.setNeedReload3DViewer(true);
        } else {
            if (response.code === HttpStatus.ITEM_NOT_FOUND) {
                projectPlanningModule.setIsShowResourceDetailForm(false);
                const loading = ElLoading.service({
                    target: '.main-wrapper',
                });
                await projectPlanningModule.getResourceTree({
                    planningId: projectPlanningModule.planning?._id || '',
                    projectId: projectModule.selectedProjectId || '',
                });
                loading.close();
                projectPlanningModule.setSelectedResource(null);
            }
            showErrorNotificationFunction(response.message);
        }
    });

    const { value: name } = useField('name');
    const { value: type } = useField('type');
    const { value: fileIds } = useField('fileIds');
    const { value: description } = useField('description');
    const { value: parentId } = useField('parentId');
    const { value: calendar } = useField('calendar');
    const { value: consumableResource } = useField('consumableResource');
    const { value: resourceId } = useField('resourceId');
    const { value: defaultUnitPerPeriod } = useField('defaultUnitPerPeriod');

    // use useField instead of useFieldArray because vee-validate v4.5.11 provides attribute "fields" that a read-only version of your array items
    // so can't model it
    const { value: workloadAndCapacity } =
        useField<IResourceWorkloadAndCapacity[]>('workloadAndCapacity');
    const { value: unit } = useField('unit');
    const { value: assignToExistingResourceOption } = useField(
        'assignToExistingResourceOption',
    );

    return {
        errors,
        onSubmit,
        resetForm,
        validate,
        setErrors,
        setFieldValue,
        setFieldError,
        handleReset,
        resourceId,
        name,
        type,
        fileIds,
        description,
        parentId,
        calendar,
        workloadAndCapacity,
        unit,
        assignToExistingResourceOption,
        consumableResource,
        defaultUnitPerPeriod,
        additionalFields,
        innitResource,
    };
};
