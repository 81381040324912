import { useField, useForm } from 'vee-validate';

import { INPUT_TEXT_MAX_LENGTH } from '@/common/constants';
import yup from '@/plugins/yup';

import { ICreatePlanningView } from '../interfaces';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';

const createPlanningViewSchema = yup.object({
    name: yup.string().max(INPUT_TEXT_MAX_LENGTH).trim().required(),
    _id: yup.string().optional(),
});

export const setupPlanningViewNameForm = () => {
    const initialValues = {
        name: '',
    };
    const { handleSubmit, errors, resetForm, validate } = useForm<{
        name: string;
        _id?: string;
    }>({
        initialValues,
        validationSchema: createPlanningViewSchema,
    });

    const onCreate = handleSubmit(async (values) => {
        const planningId = projectPlanningModule.planning?._id;
        if (!planningId) return;

        return await projectPlanningService.createPlanningView(
            planningId,
            values as unknown as ICreatePlanningView,
        );
    });

    const onRename = handleSubmit(async (values) => {
        const planningId = projectPlanningModule.planning?._id;
        if (!planningId) return;

        const { name, _id } = values;
        return await projectPlanningService.updatePlanningView(planningId, _id ?? '', {
            name,
        });
    });

    const onSaveAs = handleSubmit(async (values) => {
        const planningId = projectPlanningModule.planning?._id;
        const columns = projectPlanningModule.tempPlanningView?.columns;
        const filters = projectPlanningModule.tempPlanningView?.filters;
        const gantt = projectPlanningModule.tempPlanningView?.gantt;
        if (!planningId || !columns) return;

        return await projectPlanningService.createPlanningView(planningId, {
            ...values,
            columns,
            gantt,
            filters:
                filters?.map((filter) => ({
                    name: filter.name,
                    columnName: filter.columnName,
                    columnType: filter.columnType,
                    configs: filter.configs.map((config) => ({
                        logic: config.logic,
                        operation: config.operation,
                        value: config.value,
                    })),
                    selected: filter.selected,
                })) || [],
        });
    });

    const { value: name } = useField<string>('name');

    return {
        errors,
        name,
        onCreate,
        onRename,
        onSaveAs,
        resetForm,
        validate,
    };
};
