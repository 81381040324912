import {
    IGenerateCustomId,
    ILoadFileSync,
    ILoadPlanning,
    ISetNodeProperties,
    ISyncFileBottomup,
    ISynchroniseFile,
    ITypeOpenFile,
    IClearCustomId,
} from './../interfaces';
import { ExportFormatExtension } from './../../../common/constants';
import {
    ICloseFile,
    IImportFile,
    ILoadFile,
    ILoadFileResponse,
    ISaveFile,
    IUploadFile,
    IGetFileResponse,
    IWebviewerSessionToken,
    IGetFileQuery,
    IOpenMultipleResponse,
    IOverrideSaveFile,
} from '../interfaces';
import { ApiService } from '@/common/services/api';
import service from '@/plugins/axios';
import { IBodyResponse } from '@/common/interfaces';
import { IResponseGetAttributeFile } from '@/features/4D-planning/interfaces';

class WebViewer3DService extends ApiService {
    async getSessionToken(): Promise<IBodyResponse<IWebviewerSessionToken>> {
        return await this.client.get(`${this.baseUrl}/token`);
    }

    async getTypeFileOpen(sessionToken: string): Promise<IBodyResponse<ITypeOpenFile>> {
        return await this.client.get(`${this.baseUrl}/type-open-file/${sessionToken}`);
    }

    async loadFile(data: ILoadFile) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/load-file`,
            {
                ...data,
            },
        );
    }

    async loadFileSynchronise(data: ILoadFileSync) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/load-file-synchronise`,
            {
                ...data,
            },
        );
    }

    async getFile(
        id: string,
        query?: IGetFileQuery,
    ): Promise<IBodyResponse<IGetFileResponse>> {
        return await this.client.get(`${this.baseUrl}/file/${id}`, {
            params: { ...query },
        });
    }

    async uploadFile(data: IUploadFile) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/upload`,
            {
                sessionToken: data.sessionToken,
                file: data.file,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
    }

    async openFileSynchronise(data: FormData) {
        return await this.client.patch<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/open-file-synchronise`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
    }

    async uploadOpenMultiple(data: FormData) {
        return await this.client.post<void, IBodyResponse<IOpenMultipleResponse>>(
            `${this.baseUrl}/upload-open-multiple`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
    }

    async uploadImportMultiple(data: FormData) {
        return await this.client.post<void, IBodyResponse<IOpenMultipleResponse>>(
            `${this.baseUrl}/upload-import`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
    }

    async saveFile(data: ISaveFile) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/save`,
            {
                folderPath: data.folderPath,
                name: `${data.name}.${ExportFormatExtension[data.exportFormat]}`,
                sessionToken: data.sessionToken,
                exportType: data.exportType,
                exportFormat: data.exportFormat,
                projectId: data.projectId,
                exportFileType: data.exportFileType,
            },
        );
    }
    async closeFile(data: ICloseFile) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/${data.sessionToken}/close`,
        );
    }
    async importFile(data: IImportFile) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/import`,
            data,
        );
    }
    async importFromRelatedFiles(data: IImportFile) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/import-from-related-files`,
            {
                ...data,
            },
        );
    }
    async overrideSaveFile(data: IOverrideSaveFile) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/override-save-file`,
            {
                ...data,
            },
        );
    }

    async getSelectedNodeIds(sessionToken: string) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/selected-node-ids`,
            { sessionToken },
        );
    }

    async mergeSelectedNodeIds(sessionToken: string) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/merge-selected-node`,
            { sessionToken },
        );
    }

    async loadFilePlanning(data: ILoadPlanning) {
        return await this.client.post<void, IBodyResponse<ILoadFileResponse>>(
            `${this.baseUrl}/load-file-planning`,
            { ...data },
        );
    }

    async generateCustomId(data: IGenerateCustomId) {
        return await this.client.patch<void, IBodyResponse>(
            `${this.baseUrl}/generate-customid`,
            { ...data },
        );
    }

    async clearCustomId(data: IClearCustomId) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/clear-custom-id`,
            { ...data },
        );
    }

    async deselectNode(sessionToken: string) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/de-select-nodes`,
            { sessionToken },
        );
    }

    async getAttributeFile(sessionToken: string) {
        return await this.client.get<void, IBodyResponse<any>>(
            `${this.baseUrl}/attribute-file/${sessionToken}`,
        );
    }

    async synchroniseFile(data: ISynchroniseFile) {
        return await this.client.patch<void, IBodyResponse>(
            `${this.baseUrl}/synchronise-file`,
            { ...data },
        );
    }

    async getSession(sessionToken: string) {
        return await this.client.get<void, IBodyResponse>(
            `${this.baseUrl}/session/${sessionToken}`,
        );
    }

    async setNodeProperties(data: ISetNodeProperties) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/set-node-properties`,
            { ...data },
        );
    }

    async getStructureNodeXML(sessionToken: string) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/get-structure-node-xml`,
            { sessionToken },
        );
    }

    async loadFileSynchroniseBottomup(data: ISyncFileBottomup) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/load-file-synchronise-bottom-up`,
            { ...data },
        );
    }

    async loadFileSynchroniseTopDown(data: ISyncFileBottomup) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/load-file-synchronise-top-down`,
            { ...data },
        );
    }

    async getPropertiesNode(sessionToken: string, selectionAllFile: boolean) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/get-node-properties`,
            { sessionToken, selectionAllFile },
        );
    }

    async getTaskInPlanning(sessionToken: string, planningId: string) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/get-selected-tasks`,
            { sessionToken, planningId },
        );
    }

    async selectObject3D(sessionToken: string, planningId: string, ganttId: string[]) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/get-selected-objects`,
            { sessionToken, planningId, ganttId },
        );
    }

    async loadFilePlanningSynthesis(data: ILoadPlanning) {
        return await this.client.post<void, IBodyResponse>(
            `${this.baseUrl}/load-file-synthesis`,
            { ...data },
        );
    }
}

export const webViewer3DService = new WebViewer3DService(
    { baseUrl: '/webviewer' },
    service,
);
