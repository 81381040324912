import MainLayout from '@/layouts/MainLayout.vue';
import { PageName } from '@/common/constants';
import { RouteRecordRaw } from 'vue-router';
import ProjectLogHistoryListPage from './pages/ProjectLogHistoryListPage.vue';
import LogReportPage from './pages/LogReportPage.vue';
import LogServerPage from './pages/LogServerPage.vue';

const projectLogRouters: Array<RouteRecordRaw> = [
    {
        path: '/log',
        component: MainLayout,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'project',
                name: PageName.PROJECT_LOG_HISTORY_LIST_PAGE,
                component: ProjectLogHistoryListPage,
                meta: {
                    breadcrumb: [
                        {
                            text: 'accessLog.breadcrumb.accessLog',
                        },
                        {
                            text: 'projectLog.breadcrumb.projectLogHistory',
                            link: '/log/project',
                        },
                    ],
                },
            },
            {
                path: 'report',
                name: PageName.PROJECT_LOG_REPORT_PAGE,
                component: LogReportPage,
                meta: {
                    breadcrumb: [
                        {
                            text: 'accessLog.breadcrumb.accessLog',
                        },
                        {
                            text: 'projectLog.breadcrumb.projectLogReport',
                            link: '/log/report',
                        },
                    ],
                },
            },
            {
                path: 'server',
                name: PageName.PROJECT_LOG_SERVER_PAGE,
                component: LogServerPage,
                meta: {
                    breadcrumb: [
                        {
                            text: 'accessLog.breadcrumb.accessLog',
                        },
                        {
                            text: 'projectLog.breadcrumb.logServer',
                            link: '/log/server',
                        },
                    ],
                },
            },
        ],
    },
];

export default projectLogRouters;
