import { computed } from 'vue';
import { ElLoading } from 'element-plus';
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import {
    INPUT_TEXT_MAX_LENGTH,
    INTEGER_POSITIVE_MAX_VALUE,
    TEXTAREA_MAX_LENGTH,
} from '@/common/constants';
import {
    showErrorNotificationFunction,
    showSuccessNotificationFunction,
} from '@/common/helpers';
import { projectModule } from '@/features/project/store';
import yup from '@/plugins/yup';
import { ResourceMaterialUnitType, ResourceOtherUnitType } from '../constants';
import { projectPlanningService } from '../services/planning.service';
import { projectPlanningModule } from '../store';
import { TaskActions } from '../interfaces';

export const ruleSchema = yup.object({
    ruleId: yup.string().max(INPUT_TEXT_MAX_LENGTH).required().trim(),
    name: yup.string().max(INPUT_TEXT_MAX_LENGTH).required().trim(),
    unit: yup
        .string()
        .oneOf([...Object.values(ResourceMaterialUnitType)])
        .required(),
    period: yup
        .string()
        .oneOf([...Object.values(ResourceOtherUnitType)])
        .required(),
    description: yup.string().max(TEXTAREA_MAX_LENGTH).optional().trim().nullable(),
    rate: yup
        .number()
        .min(0)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .required()
        .nullable()
        .transform((val) => (+val === 0 ? 0 : +val || null)),
});

export const initRule = {
    ruleId: '',
    name: '',
    unit: '',
    description: null,
    rate: null,
    period: '',
};

export const setupTaskRuleForm = () => {
    const { t } = useI18n();
    const isCreate = computed(
        () => projectPlanningModule.isShowTaskRulePopup.action === TaskActions.CREATE,
    );

    const { handleSubmit, errors, resetForm, validate, setErrors, setFieldValue } =
        useForm({
            initialValues: initRule,
            validationSchema: ruleSchema,
        });

    const onSubmit = handleSubmit(async (values) => {
        const loading = ElLoading.service({ target: '.task-rule-form' });
        if (isCreate.value) {
            const body = {
                projectId: projectModule.selectedProjectId || '',
                planningId: projectPlanningModule.planning?._id,
                ...values,
            };

            const response = await projectPlanningService.createTaskRule(body);

            if (response.success) {
                showSuccessNotificationFunction(
                    t('planning.taskRule.msg.create.success'),
                );

                const query = {
                    projectId: projectModule.selectedProjectId,
                    planningId: projectPlanningModule.planning?._id,
                };

                const res = await projectPlanningService.getListTaskRule(query);
                projectPlanningModule?.setTaskRuleList(res.data.items);
                projectPlanningModule?.setTaskRuleListAssignment(res.data.items);

                projectPlanningModule.setIsShowTaskRulePopup({
                    ...projectPlanningModule.isShowTaskRulePopup,
                    action: TaskActions.READ,
                });
            } else if (!response?.isRequestError) {
                showErrorNotificationFunction(t('planning.taskRule.msg.create.error'));
            }
        } else {
            const body = {
                projectId: projectModule.selectedProjectId || '',
                planningId: projectPlanningModule.planning?._id,
                ...values,
            };

            const response = await projectPlanningService.updateTaskRule(
                body,
                projectPlanningModule.isShowTaskRulePopup.selectedTaskRule ?? '',
            );

            if (response.success) {
                showSuccessNotificationFunction(
                    t('planning.taskRule.msg.update.success'),
                );

                const query = {
                    projectId: projectModule.selectedProjectId,
                    planningId: projectPlanningModule.planning?._id,
                };

                const res = await projectPlanningService.getListTaskRule(query);
                projectPlanningModule?.setTaskRuleList(res.data.items);

                projectPlanningModule.setIsShowTaskRulePopup({
                    ...projectPlanningModule.isShowTaskRulePopup,
                    action: TaskActions.READ,
                });
            } else if (!response?.isRequestError) {
                showErrorNotificationFunction(t('planning.taskRule.msg.update.error'));
            }
        }

        loading.close();
    });

    const { value: name } = useField('name');
    const { value: ruleId } = useField('ruleId');
    const { value: unit } = useField('unit');
    const { value: description } = useField('description');
    const { value: rate } = useField('rate');
    const { value: period } = useField('period');

    return {
        errors,
        onSubmit,
        resetForm,
        validate,
        setErrors,
        setFieldValue,
        name,
        description,
        unit,
        ruleId,
        rate,
        period,
        initRule,
    };
};
