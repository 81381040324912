import {
    CurrencyType,
    DelegateOptions,
    GanttColumn,
    GanttColumnGroups,
    LinkType,
    ManageViewTabs,
    ResourceMaterialUnitType,
    ResourceOtherUnitType,
    TaskConstraint,
    TaskDuration,
    TaskDurationFormat,
    TaskFieldDataType,
    TaskPercentageComplete,
    TaskPhysicalQuantityUnit,
    TaskStatus,
    TaskType,
    GanttFilterOperation,
    InProgressTaskOption,
    CalendarForDelayLinkOption,
    Bars,
} from '../../constants';
export default {
    scheduling: {
        messages: {
            reschedulingSuccess: 'Planning have been rescheduled successful',
        },
    },
    breadcrumb: {
        planning: '4D planning',
        analyzer: '4D Analyzer',
        viewer3D: '3D Viewer',
        activityCodeList: 'Activity code',
        appearanceProfileListPage: 'Appearance profile',
        resourceListPage: 'Resource',
        userDefinedFieldListPage: 'User defined field',
    },
    task: {
        copy: {
            message: {
                copied: 'Copied',
            },
            button: {
                copy: 'Copy',
            },
        },
        paste: {
            message: {
                success: 'Tasks have been pasted',
            },
            button: {
                paste: 'Paste',
            },
            option: {
                activityCodeValue: 'Activity code value',
                userField: 'User field',
                resource: 'Resource',
                allRelationShips: 'All relationships',
                relationshipsBetweenTasks: 'All relationships between tasks',
            },
        },
        renameForm: {
            title: 'Rename task',
            button: {
                cancel: 'Cancel',
                submit: 'Submit',
            },
        },
        tab: {
            updateTask: 'Update task',
            assignResource: 'Resource assignment',
            assignResourceGroup: 'Resource group assignment',
            assignActivityCode: 'Activity code assignment',
            assignTaskField: 'Task field assignment',
        },
        search: {
            option: {
                exact: 'Exact',
                appropriate: 'Appropriate',
                placeholder: 'Search mode',
            },
            value: {
                placeholder: 'Search value',
            },
            column: {
                placeholder: 'Search column',
            },
        },
        link: {
            popupTitle: {
                [LinkType.START_TO_START]:
                    'from start of <b>{from}</b> To start of <b>{to}</b>',
                [LinkType.START_TO_FINISH]:
                    'from start of <b>{from}</b> To end of <b>{to}</b>',
                [LinkType.FINISH_TO_START]:
                    'from end of <b>{from}</b> To start of <b>{to}</b>',
                [LinkType.FINISH_TO_FINISH]:
                    'from end of <b>{from}</b> To end of <b>{to}</b>',
            },
        },
        types: {
            [TaskType.STANDARD]: 'Standard',
            [TaskType.PROJECT]: 'Project',
            [TaskType.FINISH_MILESTONE]: 'Finish Milestone',
            [TaskType.START_MILESTONE]: 'Start Milestone',
            [TaskType.LEVEL_EFFORT]: 'Level Of Effort',
            [TaskType.RESOURCE_DEPENDENT]: 'Resource Dependent',
            [TaskType.WBS_SUMMARY]: 'WBS Summary',
            [TaskType.MILESTONE]: 'Milestone',
        },
        form: {
            title: 'Task detail',
            titleCreation: 'Create task',
            titleEdit: 'Update task',
            errors: {
                maxStart: 'Start date must be less than finish date',
                minStartDataDate: 'Start date must be greater than data date',
                maxActualStart: 'Actual start date must be less than actual finish date',
                maxPlannedStart:
                    'Planned start date must be less than planned finish date',
                minFinish: 'Finish date must be greater than start date',
                minActualFinish:
                    'Actual finish date must be greater than actual start date',
                minPlannedFinish:
                    'Planned finish date must be greater than planned start date',
                minBaselineFinish:
                    'Baseline finish date must be greater than baseline start date',
                constraint: {
                    start: {
                        snet: 'Start must not be earlier than {date}',
                        snlt: 'Start must not be later than {date}',
                        mso: 'Start must be equal to {date}',
                    },
                    finish: {
                        fnet: 'Finish must not be earlier than {date}',
                        fnlt: 'Finish must not be later than {date}',
                        mfo: 'Finish must be equal to {date}',
                    },
                },
            },
            labels: {
                actualDuration: 'Actual duration ({unit})',
                originalDuration: 'Original duration ({unit})',
                plannedDuration: 'Planned duration ({unit})',
                remainingDuration: 'Remaining duration ({unit})',
            },
            placeholders: {
                id: 'Enter activity ID',
                name: 'Enter activity name',
                parentName: 'WBS name',
                type: 'Choose activity type',
                status: 'Choose status',
                start: 'Choose start date',
                actualStart: 'Choose actual start date',
                plannedStart: 'Choose planned start date',
                finish: 'Choose finish date',
                actualFinish: 'Choose actual finish date',
                plannedFinish: 'Choose planned finish date',
                baselineStart: 'Choose baseline start date',
                baselineFinish: 'Choose baseline finish date',
                primaryConstraint: 'Choose primary constraint',
                primaryConstraintDate: 'Choose primary constraint date',
                expectedFinish: 'Choose expected finish date',
                durationType: 'Choose duration type',
                originalDuration: 'Enter original duration',
                actualDuration: 'Enter actual duration',
                plannedDuration: 'Enter planned duration',
                remainingDuration: 'Enter remaining duration',
                completeType: 'Choose complete type',
                manualComplete: 'Enter % complete',
                physicalQuntityUnit: 'Choose unit',
                physicalQuantity: 'Enter physical quantity',
                actualPhysicalQuantity: 'Enter actual physical quantity',
                rules: 'Enter rules',
                atCompletePhysicalQuantity: 'Enter at complete physical quantity',
                remainingPhysicalQuantity: 'Enter remaining physical quantity',
                appearanceProfile: 'Choose appearance profile',
                calendar: 'Choose calendar',
                estimatedRate: 'Enter estimated rate',
            },
        },
        errors: {
            insufficientPermission:
                "You don't have sufficient permission to perform this action",
            required: {
                actualFinish: 'The Actual finish field is required',
                actualStart: 'The Actual start field is required',
                plannedFinish: 'The planned finish field is required',
                plannedStart: 'The planned start field is required',
            },
            ganttIdDuplicate: 'The activity ID is already exists',
            linkBetweenTwoLOETask:
                'This task is already linked to another level of effort task',
        },
        messages: {
            createdTask: 'Created a task',
            createdLink: 'Created a link',
            deletedLink: 'Deleted link',
            updatedTask: 'Updated task',
            renameTask: 'Rename task successful',
            confirmDelete: 'Are you sure to delete task {name}',
            confirmBulkDelete: 'Are you sure to delete {count} task(s)?',
            titleConfirmDelete: 'Delete task',
            titleConfirmBulkDelete: 'Bulk delete task(s)',
            deleted: 'Deleted task',
            bulkDeleted: 'Deleted task(s)',
            bulkUpdateSuccess: 'Task have been updated successful',
            updatedResource: 'Updated resource',
        },
        resource: {
            title: 'Resource assignment detail',
            form: {
                id: 'ID',
                resourceName: 'Resource Name',
                status: 'Status',
                actualUnitPerPeriod: 'Actual Unit/Period',
                actualUnit: 'Actual Unit',
                budgetedUnit: 'Budgeted Unit',
                remainingUnit: 'Remaining Unit',
                atCompletionUnit: 'At Completion Unit',
                resourceAccount: 'Resource Account',
                budgetedCost: 'Budgeted Cost',
                remainingCost: 'Remaining Cost',
                actualCost: 'Actual Cost',
                atCompletionCost: 'At Completion Cost',
                fixedCost: 'Fixed Cost',
                driving: 'Driving',
                fixedUnit: 'Fixed Unit',
            },
            placeholder: {},
        },
    },
    buttons: {
        userField: 'User field',
        addField: 'Add field',
        filter: 'Filter',
        importXML: 'Import xml',
        exportXML: 'Export xml',
        importExcel: 'Import excel',
        exportExcel: 'Export excel',
        delegate: 'Delegate',
        synthesis: 'Synthesis',
        resource: 'Resource',
        assignResource: 'Assign Resource',
        assignResourceGroup: 'Assign Resource Group',
        resourceGroup: 'Resource Group',
        appearanceProfile: 'Appearance Profile',
        create: 'Create',
        save: 'Save',
        cancel: 'Cancel',
        edit: 'Edit',
        manageView: 'Manage view',
        baseLine: 'Baseline',
        createBaseline: 'Create baseline',
        delete: 'Delete',
        topDown: 'Top down',
        bottomUp: 'Bottom up',
        createPlanningN: "Create planning N'",
        reset: 'Reset',
        updateName: 'Update',
        settingBaseline: 'Baseline Settings',
        displayBaseline: 'Display',
        colorBaseline: 'Color',
        positionBaseline: 'Position',
        thicknessBaseline: 'Thickness',
        activityCode: 'Activity code',
        assignActivityCode: 'Assign activity code',
        select: 'Select',
        rules: 'Rules',
        assignRules: 'Assign Rules',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        planningSettings: 'Planning settings',
        rescheduling: 'Rescheduling',
        updateDelegation: 'Update delegation',
        updateMilestones: 'Update milestones',
        cancelDelegation: 'Cancel delegation',
        default: 'Default',
        skipAll: 'Skip all',
        import: 'Import',
        export: 'Export',
        saveAs: 'Save as',
        add: 'Add',
        constellation: 'Constellation',
        planning: 'Planning',
        general: 'General',
        calendar: 'Calendar',
        assignCalendar: 'Assign calendar',
        activityCodeValue: 'Activity code value',
        assign: 'Assign',
        close: 'Close',
        next: 'Next',
        back: 'back',
        setAsDefault: 'Set as default',
        task: 'Task',
        rule: 'Add Rule',
        setting: 'Setting',
    },
    folderForm: {
        labels: {
            planName: 'Planning name',
        },
        buttons: {
            cancel: 'Cancel',
            back: 'Back',
            next: 'Next',
            submit: 'Submit',
            delete: 'Delete',
            save: 'Save',
            reschedule: 'Reschedule',
        },
        folders: 'Folders',
        delegate: {
            success: 'Delegated tasks successfully',
            confirmDelegateTitle: 'Delegate tasks',
            confirmDelegateMessage: `Are you sure you want to delegate tasks to planning {planningName} ?`,
            options: {
                [DelegateOptions.NEW_PLANNING]: 'To new planning',
                [DelegateOptions.EXIST_PLANNING]: 'To exist planning',
            },
            validateMessage: 'Please select a planning',
        },
        synthesis: {
            success: 'Synthesis success',
            emptyPlanning: 'Please select at least two planning',
        },
        cancelDelegation: {
            success: 'Cancel delegation successfully',
            confirmTitle: 'Cancel delegation',
            confirmMessage: 'Are you sure you want to cancel delegation?',
        },
    },
    gantt: {
        marker: {
            dataDate: {
                text: 'Data date',
            },
        },
        lightbox: {
            labels: {
                name: 'Task name',
                time: 'Time',
                type: 'Type',
            },
        },
        filter: {
            popup: {
                title: 'Filter by column {name}',
                table: {
                    header: {
                        logic: 'Logic',
                        operation: 'Operation',
                        value: 'Value',
                    },
                    form: {
                        logic: {
                            and: 'AND',
                            or: 'OR',
                            placeholder: 'Select logic',
                        },
                        operation: {
                            [GanttFilterOperation.EQUALS]: 'Equals',
                            [GanttFilterOperation.DOES_NOT_EQUAL]: 'Does not equal',
                            [GanttFilterOperation.CONTAINS]: 'Contains',
                            [GanttFilterOperation.DOES_NOT_CONTAIN]: 'Does not contain',
                            [GanttFilterOperation.MATCHES]: 'Matches',
                            [GanttFilterOperation.DOES_NOT_MATCH]: 'Does not match',
                            [GanttFilterOperation.IS_GREATER_THAN]: 'Is greater than',
                            [GanttFilterOperation.IS_GREATER_THAN_OR_EQUAL_TO]:
                                'Is greater than or equal to',
                            [GanttFilterOperation.IS_LESS_THAN]: 'Is less than',
                            [GanttFilterOperation.IS_LESS_THAN_OR_EQUAL_TO]:
                                'Is less than or equal to',
                            placeholder: 'Select operation',
                        },
                        value: {
                            placeholder: 'Enter value',
                            true: 'True',
                            false: 'False',
                        },
                    },
                },
                button: {
                    add: 'Add',
                    remove: 'Remove',
                    removeAll: 'Remove All',
                    up: 'Up',
                    down: 'Down',
                    ok: 'Ok',
                    cancel: 'Cancel',
                },
            },
            menu: {
                customFilter: 'Custom filter',
                clearFilter: 'Clear filter',
                clearAllFilters: 'Clear all filters',
            },
        },
        group: {
            groupLabel: 'Group for {column} = {value}',
            groupLabelEmpty: 'Group for {column} with empty value',
        },
        columns: {
            activityCode: 'Activity code',
            activityCodeValue: 'Activity code value',
            id: 'Activity ID',
            status: 'Activity status',
            type: 'Activity type',
            parentId: 'WBS code',
            parentName: 'WBS name',
            name: 'Activity name',
            calendarName: 'Calendar name',
            start: 'Start',
            actualStart: 'Actual start',
            earlyStart: 'Early start',
            lateStart: 'Late start',
            plannedStart: 'Planned start',
            blStart: 'BL start',
            finish: 'Finish',
            actualFinish: 'Actual finish',
            earlyFinish: 'Early finish',
            lateFinish: 'Late finish',
            plannedFinish: 'Planned finish',
            blFinish: 'BL finish',
            prConstraint: 'Primary constraint',
            prConstraintDate: 'Primary constraint date',
            expectedFinish: 'Expected finish',
            durationType: 'Duration type',
            plannedDuration: 'Planned duration',
            originalDuration: 'Original duration',
            actualDuration: 'Actual duration',
            remainingDuration: 'Remaining duration',
            atCompleteDuration: 'At complete duration',
            blDuration: 'BL duration',
            VBLDuration: 'Variance - BL duration',
            VBLFinishDate: 'Variance - BL Finish Date',
            VBLStartDate: 'Variance - BL Start Date',
            freeFloat: 'Free float',
            totalFloat: 'Total float',
            critical: 'Critical',
            percentCompleteType: 'Percent complete type',
            physicalPercentage: 'Physical % Complete',
            durationPercentage: 'Duration % Complete',
            manualPercentage: 'Manual % Complete',
            physicalUnit: 'Physical quantity unit',
            physicalQuantity: 'Physical quantity',
            actualPhysicalQuantity: 'Actual physical quantity',
            atCompletePhysicalQuantities: 'At complete physical quantity',
            remainPhysicalQuantity: 'Remaining physical quantity',
            rules: 'Rules',
            code: 'Activity code',
            appearanceProfile: 'Appreance profile',
            resouce3D: 'Resource 3D',
            resourceGroup: 'Resource group',
            all: 'All columns',
            calendar: 'Calendar',
            action: 'Action',
            [GanttColumn.ACTUAL_HR_COST]: 'Actual Human Resource Cost',
            [GanttColumn.ACTUAL_MATERIAL_COST]: 'Actual Material Cost',
            [GanttColumn.ACTUAL_EQUIPMENT_COST]: 'Actual Equipment Cost',
            [GanttColumn.ACTUAL_LOCATION_COST]: 'Actual Location Cost',
            [GanttColumn.ACTUAL_TOTAL_COST]: 'Actual Total Cost',
            [GanttColumn.AT_COMPLETE_HR_COST]: 'At completion Human Resource Cost',
            [GanttColumn.AT_COMPLETE_MATERIAL_COST]: 'At completion Material Cost',
            [GanttColumn.AT_COMPLETE_EQUIPMENT_COST]: 'At completion Equipment Cost',
            [GanttColumn.AT_COMPLETE_LOCATION_COST]: 'At completion Location Cost',
            [GanttColumn.AT_COMPLETE_TOTAL_COST]: 'At completion Total Cost',
            [GanttColumn.BUDGET_HR_COST]: 'Budgeted Human Resource Cost',
            [GanttColumn.BUDGET_MATERIAL_COST]: 'Budgeted Material Cost',
            [GanttColumn.BUDGET_EQUIPMENT_COST]: 'Budgeted Equipment Cost',
            [GanttColumn.BUDGET_LOCATION_COST]: 'Budgeted Location Cost',
            [GanttColumn.BUDGET_TOTAL_COST]: 'Budgeted Total Cost',
            [GanttColumn.HR_FIXED_COST]: 'Human Resource Fixed Cost',
            [GanttColumn.MATERIAL_FIXED_COST]: 'Material Fixed Cost',
            [GanttColumn.EQUIPMENT_FIXED_COST]: 'Equipment Fixed Cost',
            [GanttColumn.LOCATION_FIXED_COST]: 'Location Fixed Cost',
            [GanttColumn.TOTAL_FIXED_COST]: 'Total Fixed Cost',
            [GanttColumn.BL_HR_COST]: 'BL Human Resource Cost',
            [GanttColumn.BL_MATERIAL_COST]: 'BL Material Cost',
            [GanttColumn.BL_EQUIPMENT_COST]: 'BL Equipment Cost',
            [GanttColumn.BL_LOCATION_COST]: 'BL Location Cost',
            [GanttColumn.BL_TOTAL_COST]: 'BL Total Cost',
            [GanttColumn.BL_FIXED_COST]: 'BL Fixed Cost',
            [GanttColumn.REMAIN_HR_COST]: 'Remaining Human Resource Cost',
            [GanttColumn.REMAIN_MATERIAL_COST]: 'Remaining Material Cost',
            [GanttColumn.REMAIN_EQUIPMENT_COST]: 'Remaining Equipment Cost',
            [GanttColumn.REMAIN_LOCATION_COST]: 'Remaining Location Cost',
            [GanttColumn.REMAIN_TOTAL_COST]: 'Remaining Total Cost',
            [GanttColumn.BL_ACTUAL_DURATION]: 'BL Actual Duration',
            [GanttColumn.BL_REMAIN_DURATION]: 'BL Remaining Duration',
            [GanttColumn.BL_FREE_FLOAT]: 'BL Free Float',
            [GanttColumn.BL_TOTAL_FLOAT]: 'BL Total Float',
            [GanttColumn.LONGEST_PATH]: 'Longest path',
            [GanttColumn.PERCENT_COMPLETE]: '% Complete',
            [GanttColumn.BL_DURATION_PERCENTAGE]: 'BL Duration % Complete',
            [GanttColumn.BL_MANUAL_PERCENTAGE]: 'BL Manual % Complete',
            [GanttColumn.BL_PHYSICAL_PERCENTAGE]: 'BL Physical % Complete',
            [GanttColumn.COMPLETE_PHYSICAL_QUANTITY]: 'At completion Physical Quantity',
            [GanttColumn.ESTIMATED_RATE]: 'Estimated Rate',
            [GanttColumn.PREDECESSORS]: 'Predecessors',
            [GanttColumn.PREDECESSOR_DETAILS]: 'Predecessor details',
            [GanttColumn.SUCCESSORS]: 'Successors',
            [GanttColumn.SUCCESSOR_DETAILS]: 'Successor details',
            [GanttColumn.ACTUAL_HR_UNIT]: 'Actual Human Resource unit',
            [GanttColumn.ACTUAL_MATERIAL_UNIT]: 'Actual Material unit',
            [GanttColumn.ACTUAL_EQUIPMENT_UNIT]: 'Actual Equipment unit',
            [GanttColumn.ACTUAL_LOCATION_UNIT]: 'Actual Location unit',
            [GanttColumn.AT_COMPLETE_HR_UNIT]: 'At completion Human Resource unit',
            [GanttColumn.AT_COMPLETE_MATERIAL_UNIT]: 'At completion Material unit',
            [GanttColumn.AT_COMPLETE_EQUIPMENT_UNIT]: 'At completion Equipment unit',
            [GanttColumn.AT_COMPLETE_LOCATION_UNIT]: 'At completion Location unit',
            [GanttColumn.BUDGET_HR_UNIT]: 'Budgeted Human Resource unit',
            [GanttColumn.BUDGET_MATERIAL_UNIT]: 'Budgeted Material unit',
            [GanttColumn.BUDGET_EQUIPMENT_UNIT]: 'Budgeted Equipment unit',
            [GanttColumn.BUDGET_LOCATION_UNIT]: 'Budgeted Location unit',
            [GanttColumn.BL_HR_UNIT]: 'BL Human Resource unit',
            [GanttColumn.BL_MATERIAL_UNIT]: 'BL Material unit',
            [GanttColumn.BL_EQUIPMENT_UNIT]: 'BL Equipment unit',
            [GanttColumn.BL_LOCATION_UNIT]: 'BL Location unit',
            [GanttColumn.REMAIN_HR_UNIT]: 'Remaining Human Resource unit',
            [GanttColumn.REMAIN_MATERIAL_UNIT]: 'Remaining Material unit',
            [GanttColumn.REMAIN_EQUIPMENT_UNIT]: 'Remaining Equipment unit',
            [GanttColumn.REMAIN_LOCATION_UNIT]: 'Remaining Location unit',
        },
        columnFilterPlaceholder: 'Filter column',
        columnGroups: {
            [GanttColumnGroups.ASSIGNMENTS]: 'Assignments',
            [GanttColumnGroups.COSTS]: 'Costs',
            [GanttColumnGroups.DATES]: 'Dates',
            [GanttColumnGroups.DURATION]: 'Duration',
            [GanttColumnGroups.GENERAL]: 'General',
            [GanttColumnGroups.PERCENT_COMPLETE]: 'Percent Completes',
            [GanttColumnGroups.PHYSICAL_QUANTITY]: 'Physical Quantity',
            [GanttColumnGroups.RELATIONSHIPS]: 'Relationships',
            [GanttColumnGroups.UNIT]: 'Unit',
        },
        task: {
            prefixStart: '<b>Start at: </b> {date}',
            prefixEnd: '<b>Finish at: </b> {date}',
        },
        status: {
            [TaskStatus.TODO]: 'Not Started',
            [TaskStatus.IN_PROGRESS]: 'In Progress',
            [TaskStatus.FINISHED]: 'Finished',
        },
        primaryConstraints: {
            [TaskConstraint.ASAP]: 'As soon as possible',
            [TaskConstraint.ALAP]: 'As late as possible',
            [TaskConstraint.SNET]: 'Start on or after',
            [TaskConstraint.SNLT]: 'Start on or before',
            [TaskConstraint.FNET]: 'Finish on or after',
            [TaskConstraint.FNLT]: 'Finish on or before',
            [TaskConstraint.MSO]: 'Must start on',
            [TaskConstraint.MFO]: 'Must finish on',
            [TaskConstraint.SO]: 'Start on',
            [TaskConstraint.FO]: 'Finish on',
        },
        durationTypes: {
            [TaskDuration.STANDARD]: 'Standard',
            [TaskDuration.RESOURCE_UNITS_DEPENDENT]: 'Resource unit dependent',
            [TaskDuration.PHYSICAL_QUANTITY_DEPENDENT]: 'Physical quantity dependent',
        },
        percentCompleteTypes: {
            [TaskPercentageComplete.PHYSICAL_COMPLETE]: 'Physical % complete',
            [TaskPercentageComplete.DURATION_COMPLETE]: 'Duration % complete',
            [TaskPercentageComplete.MANUAL_COMPLETE]: 'Manual % complete',
        },
        physicalQuantityUnit: {
            [TaskPhysicalQuantityUnit.BAG]: 'Bag',
            [TaskPhysicalQuantityUnit.BOX]: 'Box',
            [TaskPhysicalQuantityUnit.BUCKET]: 'Bucket',
            [TaskPhysicalQuantityUnit.BUNDLE]: 'Bundle',
            [TaskPhysicalQuantityUnit.CRANE]: 'Crane',
            [TaskPhysicalQuantityUnit.CARTON]: 'Carton',
            [TaskPhysicalQuantityUnit.CUBIC_FEET]: 'Cubic feet',
            [TaskPhysicalQuantityUnit.CUBIC_METRE]: 'Cubic metre',
            [TaskPhysicalQuantityUnit.CUBIC_YARD]: 'Cubic yard',
            [TaskPhysicalQuantityUnit.DOZEN]: 'Dozen',
            [TaskPhysicalQuantityUnit.FEET]: 'Feet',
            [TaskPhysicalQuantityUnit.GRAMME]: 'Gramme',
            [TaskPhysicalQuantityUnit.HOUR]: 'Hour',
            [TaskPhysicalQuantityUnit.ITEM]: 'Item',
            [TaskPhysicalQuantityUnit.KILO]: 'Kilo',
            [TaskPhysicalQuantityUnit.LENGTH]: 'Length',
            [TaskPhysicalQuantityUnit.LITRE]: 'Litre',
            [TaskPhysicalQuantityUnit.METRE]: 'Metre',
            [TaskPhysicalQuantityUnit.MILLIMETRE]: 'Millimetre',
            [TaskPhysicalQuantityUnit.PACK]: 'Pack',
            [TaskPhysicalQuantityUnit.PAIR]: 'Pair',
            [TaskPhysicalQuantityUnit.PERSON]: 'Person',
            [TaskPhysicalQuantityUnit.POUND]: 'Pound',
            [TaskPhysicalQuantityUnit.SACHET]: 'Sachet',
            [TaskPhysicalQuantityUnit.SET]: 'Set',
            [TaskPhysicalQuantityUnit.SHEET]: 'Sheet',
            [TaskPhysicalQuantityUnit.SQ_FOOT]: 'SQ foot',
            [TaskPhysicalQuantityUnit.SQ_YARD]: 'SQ yard',
            [TaskPhysicalQuantityUnit.SQ_METRE]: 'SQ metre',
            [TaskPhysicalQuantityUnit.TON]: 'Ton',
        },
    },
    baselineForm: {
        baseline: {
            label: 'Baseline list',
            placeholder: 'Select baseline',
        },
        baselineName: {
            label: 'Baseline name',
            placeholder: 'Enter baseline name',
        },
        message: {
            saveSuccess: 'Save baseline successfully',
            taskListEmpty: 'Task list empty',
            deleteSuccess: 'Delete baseline successfully',
            delete: {
                cannotDelete: 'Currently selected baseline cannot be deleted',
                title: 'Notification',
                confirmAsk: 'Are you sure you want to delete this baseline?',
            },
            update: {
                updateSuccess: 'Update baseline name successfully',
            },
            resetSuccess: 'Reset baseline successful',
        },
    },
    table: {
        columns: {
            name: 'Name',
            updatedAt: 'Updated at',
        },
        navigation: {
            synthesis: 'Synthesis',
        },
    },
    resource: {
        tab: {
            resourceDefaultTab: 'Resource Fields',
            resourceUserFieldTab: 'Resource User Fields',
            resourceGroupDefaultTab: 'Resource Group Fields',
            resourceGroupUserFieldTab: 'Resource Group User Fields',
        },
        tooltip: 'Right click to see options',
        title: {
            resourceList: 'Resource List',
            resourceDetail: 'Resource Detail',
            createResource: 'Create Resource',
            deleteResource: 'Delete Resource',
            assignedTaskNames: 'Assigned Tasks',
            setToGlobal: 'Set Resource To Global Data',
        },
        resourceList: {
            name: 'Name',
            resourceId: 'Resource Id',
            defaultUnitPerPeriod: 'Default Unit/Period',
            type: 'Type',
            planning: 'Planning',
            files: 'Files',
            resourceUnit: 'Unit',
            taskCount: 'Task Count',
            description: 'Description',
            action: 'Action',
            parentId: 'Parent',
            effectiveDate: 'Effective date',
            unit: 'Unit/Period',
            price: 'Price/Unit',
            createdBy: 'Created by',
            setGlobal: 'Set to global',
            create: 'Create',
            edit: 'Edit',
            delete: 'Delete',
            consumableResource: 'Consumable resource',
            actualUnitPerPeriod: 'Actual Unit/Period',
            budgetedUnit: 'Budgeted Unit',
            remainingUnit: 'Remaining Unit',
            actualUnit: 'Actual Unit',
            calendar: 'Calendar',
            ['3dObjectName']: '3d object name',
        },
        placeholder: {
            name: 'Enter name',
            resourceId: 'Enter Id',
            defaultUnitPerPeriod: 'Enter default Unit/Period',
            type: 'Select type',
            resourceUnit: 'Enter unit',
            files: 'Select files',
            description: 'Enter description',
            parentId: 'Select parent',
            effectiveDate: 'Enter effective date',
            unit: '...',
            price: '...',
            actualUnitPerPeriod: 'Enter Actual Unit/Period',
            budgetedUnit: 'Enter Budgeted Unit',
        },
        fileList: {
            name: 'File Name',
            createdBy: 'Created By',
        },
        resourceType: {
            equipment: 'Equipment',
            human_resource: 'Human Resource',
            material: 'Material',
            location: 'Location',
        },
        resourceUit: {
            material: {
                [ResourceMaterialUnitType.BAG]: 'Bag',
                [ResourceMaterialUnitType.BOX]: 'Box',
                [ResourceMaterialUnitType.BUCKET]: 'Bucket',
                [ResourceMaterialUnitType.BUNDLE]: 'Bundle',
                [ResourceMaterialUnitType.CRANE]: 'Crane',
                [ResourceMaterialUnitType.CARTON]: 'Carton',
                [ResourceMaterialUnitType.CUBIC_FEET]: 'Cubic feet',
                [ResourceMaterialUnitType.CUBIC_METRE]: 'Cubic metre',
                [ResourceMaterialUnitType.CUBIC_YARD]: 'Cubic yard',
                [ResourceMaterialUnitType.DOZEN]: 'Dozen',
                [ResourceMaterialUnitType.FEET]: 'Feet',
                [ResourceMaterialUnitType.GRAMME]: 'Gramme',
                [ResourceMaterialUnitType.HOUR]: 'Hour',
                [ResourceMaterialUnitType.ITEM]: 'Item',
                [ResourceMaterialUnitType.KILO]: 'Kilo',
                [ResourceMaterialUnitType.LENGTH]: 'Length',
                [ResourceMaterialUnitType.LITRE]: 'Litre',
                [ResourceMaterialUnitType.METRE]: 'Metre',
                [ResourceMaterialUnitType.MILLIMETRE]: 'Millimetre',
                [ResourceMaterialUnitType.PACK]: 'Pack',
                [ResourceMaterialUnitType.PAIR]: 'Pair',
                [ResourceMaterialUnitType.PERSON]: 'Person',
                [ResourceMaterialUnitType.POUND]: 'Pound',
                [ResourceMaterialUnitType.SACHET]: 'Sachet',
                [ResourceMaterialUnitType.SET]: 'Set',
                [ResourceMaterialUnitType.SHEET]: 'Sheet',
                [ResourceMaterialUnitType.SQ_FOOT]: 'SQ foot',
                [ResourceMaterialUnitType.SQ_YARD]: 'SQ yard',
                [ResourceMaterialUnitType.SQ_METRE]: 'SQ metre',
                [ResourceMaterialUnitType.TON]: 'Ton',
            },
            other: {
                [ResourceOtherUnitType.HOUR]: 'Hour',
                [ResourceOtherUnitType.DAY]: 'Day',
                [ResourceOtherUnitType.WEEK]: 'Week',
                [ResourceOtherUnitType.MONTH]: 'Month',
                [ResourceOtherUnitType.YEAR]: 'Year',
            },
        },
        message: {
            createSuccess: 'Create resource successfully',
            updateSuccess: 'Update resource successfully',
            deleteSuccess: 'Delete resource successfully',
            setGlobalSuccess: 'Set resource to global data successfully',
            confirmAsk: 'Are you sure you want to delete this resource?',
            confirmAskSetGlobal:
                'Are you sure you want setting this resource to global data?',
            taskCount: 'Click to see all assign tasks',
            effectiveExist: 'Effective date already exists',
            unitIsRequired: 'Unit/Period is required',
            numberLeastZero: 'The field must be at least 0',
        },
        import: {
            option: {
                skip: 'No assignment',
                create: 'Assign to a new resource',
                update: 'Assign to existing resource',
                label: 'Action',
                importToExistingResouce: {
                    assignToResourceWithoutBuildingTree:
                        'Assign to this resource, don’t build tree',
                    assignToResourceUnderneathWithoutBuildingTree:
                        'Assign resource underneath, don’t build tree',
                    assignToResourceUnderneathBuildTree:
                        'Assign resource underneath, build tree',
                },
            },
            resource: {
                label: 'Select resource to assign',
                placeholder: 'Select resource to assign',
            },
            button: {
                next: 'Next',
                prev: 'Prev',
                cancel: 'Cancel',
                submit: 'Submit',
            },
        },
    },
    resourceGroup: {
        title: {
            resourceGroupList: 'Resource Group List',
            resourceGroupDetail: 'Resource Group Detail',
            createResourceGroup: 'Create Resource Group',
            deleteResourceGroup: 'Delete Resource Group',
        },
        resourceGroupList: {
            name: 'Name',
            resources: 'Resources',
            description: 'Description',
            action: 'Action',
            resourceGroupId: 'Id',
        },
        placeholder: {
            name: 'Enter name',
            resources: 'Select resources',
            description: 'Enter description',
            resourceGroupId: 'Enter Id',
        },
        message: {
            createSuccess: 'Create resource group successfully',
            updateSuccess: 'Update resource group successfully',
            deleteSuccess: 'Delete resource group successfully',
            confirmAsk: 'Are you sure you want to delete this resource group?',
        },
    },
    assignResource: {
        title: {
            assignResource: 'Assign Resource',
        },
        label: {
            resource: 'Resources',
            appearanceProfileIds: 'Appearance profile',
        },
        placeholder: {
            resource: 'Select resource',
            appearanceProfileIds: 'Select appearance profile',
        },
        message: {
            errorSelectTaskIdsEmpty: 'Please select tasks',
            resourceRequired: 'The resources fields is required',
            assignResourceSuccess: 'Assign resource successfully',
            unassignResourceSuccess: 'Unassign resource successfully',
        },
        table: {
            header: {
                taskId: 'Task ID',
                taskName: 'Task name',
                status: 'Status',
                resourceName: 'Resource name',
                driving: 'Driving',
                fixedUnit: 'Fixed Unit',
                actualUnit: 'Actual Unit',
                budgetedUnit: 'Budgeted Unit',
                remainingUnit: 'Remaining Unit',
                actualPeriod: 'Actual Period',
                budgetedCost: 'Budgeted Cost',
                remainingCost: 'Remaining Cost',
                actualCost: 'Actual Cost',
                fixedCost: 'Fixed Cost',
                id: 'ID',
            },
            assigned: 'Assigned',
            unassigned: 'Unassigned',
            button: {
                assign: 'Assign selected',
                unassign: 'Unassign selected',
            },
        },
        popup: {
            title: 'Resource assignment setting',
            tab: {
                column: 'Column',
                filter: 'Filter',
            },
        },
    },
    assignResourceGroup: {
        title: {
            assignResourceGroup: 'Assign Resource Group',
        },
        label: {
            resourceGroup: 'Resource groups',
            appearanceProfileIds: 'Appearance profile',
        },
        placeholder: {
            resourceGroup: 'Select resource groups',
            appearanceProfileIds: 'Select appearance profile',
        },
        message: {
            errorSelectTaskIdsEmpty: 'Please select resource',
            resourceGroupRequired: 'The resource groups fields is required',
            assignResourceGroupSuccess: 'Assign resource groups successfully',
            unassignResourceGroupSuccess: 'Unassign resource groups successfully',
        },
        table: {
            header: {
                taskId: 'Task ID',
                taskName: 'Task name',
                status: 'Status',
                resourceGroupName: 'Resource group name',
            },
            assigned: 'Assigned',
            unassigned: 'Unassigned',
            button: {
                assign: 'Assign selected',
                unassign: 'Unassign selected',
            },
        },
    },
    assignActivityCode: {
        table: {
            header: {
                name: 'Activity Code Value Name',
                acName: 'Activity Code name',
                description: 'Description',
            },
        },
        message: {
            unassignSuccess: 'Unassign activity code successfully',
        },
    },
    assignTaskField: {
        table: {
            header: {
                name: 'Name',
                value: 'Value',
                type: 'Type',
            },
        },
    },
    topDown: {
        popup: {
            title: 'Top down',
        },
        message: {
            success: 'Top down successful',
            confirmUpdateDelegation: 'Are you sure to update delegation',
            titleConfirmUpdateDelegation: 'Update delegation',
            updateDelegationSuccess: 'Update delegation successfully',
        },
        confirm: {
            title: 'Confirm Topdown',
            message:
                'The Topdown process will delete milestones that relate to deleted links, continue?',
        },
    },
    taskFields: {
        title: {
            list: 'Additional user task fields',
            listResource: 'Additional user resource fields',
            listResourceGroup: 'Additional user resource group fields',
            create: 'Create additional field',
            createTask: 'Create additional task field',
            createResource: 'Create additional resource field',
            createResourceGroup: 'Create additional resource group field',
            update: 'Update additional field name',
            updateTask: 'Update task field name',
            updateResource: 'Update resource group field name',
            updateResourceGroup: 'Update resource group field name',
        },
        successMessage: {
            update: {
                name: 'Updated task field name',
            },
            create: {
                field: 'Created task field',
            },
            delete: {
                field: 'Deleted task field',
            },
            setToGlobal: {
                field: 'Set user field to global successfully',
            },
        },
        labels: {
            name: 'Field name',
            dataType: 'Data type',
            value: 'Value',
            true: 'True',
            false: 'False',
            action: 'Action',
            select: 'Select',
            type: 'Type',
        },
        dataType: {
            [TaskFieldDataType.BOOLEAN]: 'Boolean',
            [TaskFieldDataType.STRING]: 'String',
            [TaskFieldDataType.START_DATE]: 'Date time',
            [TaskFieldDataType.FINISH_DATE]: 'Date time',
            [TaskFieldDataType.NUMBER]: 'Number',
            [TaskFieldDataType.DATE]: 'Date time',
        },
        confirm: {
            delete: 'Are you sure to delete this field?',
            deleteTitle: 'Delete field',
            setGlobal: 'Are you sure setting this field to global data?',
            setGlobalTitle: 'Set user field to global',
        },
        placeholder: {
            fieldName: 'Enter field name',
            dataType: 'Choose data type',
        },
        tooltip: {
            edit: 'Edit',
            delete: 'Delete',
        },
        list: {
            header: {
                name: 'Name',
                planning: 'Planning',
                createdBy: 'Created by',
                action: 'Action',
            },
            button: {
                edit: 'Edit',
                delete: 'Delete',
                cancel: 'Cancel',
                submit: 'Submit',
                setGlobal: 'Set to global',
            },
        },
    },
    manageView: {
        tabs: {
            [ManageViewTabs.COLUMN_TAB]: 'Column',
            [ManageViewTabs.FILTER_TAB]: 'Filter',
            [ManageViewTabs.GANTT_TAB]: 'Gantt',
            [ManageViewTabs.BARS_TAB]: 'Bars',
            [ManageViewTabs.GROUP_TAB]: 'Group',
        },
        filterView: 'Filter view',
        buttons: {
            create: 'Create',
            save: 'Save',
            apply: 'Apply',
            saveAs: 'Save as',
        },
        namePopup: {
            titleCreate: 'Create planning view',
            titleRename: 'Rename planning view',
            titleSaveAs: 'Save planning view as',
            cancel: 'Cancel',
            create: 'Create',
            rename: 'Rename',
            saveAs: 'Save as',
            nameLabel: 'View name',
        },
        deletePopup: {
            message: 'Are you sure you want to delete the view {viewName}?',
            title: 'Delete planning view',
            cancel: 'Cancel',
            delete: 'Delete',
        },
        message: {
            createPlanningViewSuccess: 'Create planning view successfully',
            updatePlanningViewSuccess: 'Update planning view successfully',
            deletePlanningViewSuccess: 'Delete planning view successfully',
            cannotDeleteSelectedView:
                'Cannot delete currently selected planning view. Apply another view first.',
        },
        filterColumnForm: {
            table: {
                header: {
                    name: 'Name',
                    columnName: 'Column name',
                    action: 'Action',
                },
                form: {
                    logic: {
                        label: 'Logic between filters',
                    },
                },
            },
            input: {
                name: {
                    label: 'Name',
                    placeholder: 'Enter name',
                },
                columnName: {
                    label: 'Column name',
                    placeholder: 'Enter column name',
                },
            },
            button: {
                create: 'Create',
                update: 'Update',
                delete: 'Delete',
                cancel: 'Cancel',
                apply: 'Apply',
            },
            message: {
                delete: {
                    title: 'Confirm delete',
                    message: 'Are you sure you want to delete this column filter?',
                    success: 'Delete column filter successful',
                },
            },
        },
        gantt: {
            groupShow: {
                label: 'Show',
                dataDateLine: 'Data date line',
                relationshipLines: 'Relationship lines',
                criticalRelationships: 'Relationship on critical path',
                nonDrivingRelationships: 'Non-driving relationships',
                simulationLine: 'Simulation line',
                nonWorkTimeShading: 'Show weekend days',
            },
        },
        bars: {
            barList: 'Bar list',
            selectedBars: 'Selected bars',
            table: {
                display: 'Display',
                name: 'Name',
                description: 'Description',
                preview: 'Preview',
            },
            bars: {
                [Bars.LOE_REMAINING]: 'LOE remaining',
                [Bars.LOE_ACTUAL]: 'LOE actual',
                [Bars.STANDARD_ACTUAL]: 'Standard actual',
                [Bars.STANDARD_REMAINING_NO_CRITICAL]: 'Standard remaining no critical',
                [Bars.STANDARD_REMAINING_CRITICAL]: 'Standard remaining critical',
                [Bars.MILESTONE]: 'Milestone',
                [Bars.PLAN_BAR]: 'Plan bar',
                [Bars.CURRENT_BAR]: 'Current bar',
                [Bars.POSITIVE_FLOAT_BAR]: 'Positive float bar',
                [Bars.NEGATIVE_FLOAT_BAR]: 'Negative float bar',
            },
            descriptionBars: {
                [Bars.LOE_REMAINING]:
                    'The bar between data date and the early finish of LOE task',
                [Bars.LOE_ACTUAL]:
                    "The bar between actual start and actual finish of LOE task. If task in progress, it's between actual start and data date",
                [Bars.STANDARD_ACTUAL]:
                    'The bar between actual start and actual finish of finished standard task / between actual start and data date of in progress standard task',
                [Bars.STANDARD_REMAINING_NO_CRITICAL]:
                    'The bar between early start and early finish of no critical standard task',
                [Bars.STANDARD_REMAINING_CRITICAL]:
                    'The bar between early start and early finish of critical standard task',
                [Bars.MILESTONE]: 'The bar of milestone task',
                [Bars.PLAN_BAR]:
                    "Represents the activity's planned start to planned finish",
                [Bars.CURRENT_BAR]: 'Represents the start date to finish date',
                [Bars.POSITIVE_FLOAT_BAR]:
                    'Represents the early finish date to late finish date of the task',
                [Bars.NEGATIVE_FLOAT_BAR]:
                    'Represents the late finish date to early finish date of the task',
            },
        },
        groupTask: {
            groupBy: 'Group by',
        },
    },
    appearanceProfile: {
        title: {
            appearanceProfileList: 'Appearance Profile List',
            appearanceProfileDetail: 'Appearance Profile Detail',
            createAppearanceProfile: 'Create Appearance Profile',
            deleteAppearanceProfile: 'Delete  Appearance Profile',
            setGlobalAppearanceProfile: 'Set Global Appearance Profile',
        },
        appearanceProfileList: {
            name: 'Name',
            planning: 'Planning',
            createdBy: 'Created by',
            action: 'Action',
            type: 'Type',
            growthSimulation: 'Growth Simulation',
            edit: 'Edit',
            delete: 'Delete',
            setGlobal: 'Set to global',
        },
        placeholder: {
            name: 'Enter name',
            type: 'Select type',
            growthSimulation: 'Select growth simulation',
        },
        message: {
            createSuccess: 'Create appearance profile successfully',
            updateSuccess: 'Update appearance profile successfully',
            deleteSuccess: 'Delete appearance profile successfully',
            confirmAsk: 'Are you sure you want to delete this appearance profile?',
            confirmAskSetGlobal:
                'Are you sure you want setting this appearance profile to global data?',
            setGlobalSuccess: 'Set appearance profile to global successfully',
        },
        appearanceType: {
            install: 'Install',
            maintain: 'Maintain',
            remove: 'Remove',
            temporary: 'Temporary',
        },
        growthSimulation: {
            bottom_top: 'Bottom to top',
            top_bottom: 'Top to bottom',
            left_right: 'Left to right',
            right_left: 'Right to left',
            front_back: 'Front to back',
            back_front: 'Back to front',
            none: 'None',
        },
    },
    searchBox: {
        placeholder: {
            name: 'Search by task name',
        },
        button: {
            search: 'Search',
        },
    },
    bottomUp: {
        popup: {
            title: 'Bottom up',
        },
        message: {
            success: 'Bottom up successful',
            confirmUpdateMilestone: 'Are you sure to update milestones',
            titleConfirmUpdateUpdateMilestone: 'Update milestones',
            updateMilestonesSuccess: 'Update milestone successfully',
        },
        confirm: {
            title: 'Confirm Bottomup',
            message:
                'The Bottomup process will delete milestones that relate to deleted links, continue?',
        },
    },
    activityCode: {
        buttons: {
            createFolder: 'Create folder',
            createSubFolder: 'Create sub folder',
            createActivityCode: 'Create activity code',
        },
        title: {
            activityCodeList: 'Activity Code List',
            assignActivityCode: 'Assign Activity Code',
            deleteActivityCode: 'Delete Activity Code',
            deleteActivityCodeValue: 'Delete Activity Code Value',
            createActivityCode: 'Create Activity Code',
            updateActivityCode: 'Update Activity Code',
            createActivityCodeValue: 'Create Activity Code Value',
            updateActivityCodeValue: 'Update Activity Code Value',
            setGlobalActivityCode: 'Set Global Activity Code',
        },
        label: {
            name: 'Name',
            parent: 'Parent',
            description: 'Description',
            color: 'Color',
            actions: 'Actions',
            maxLength: 'Max length',
        },
        placeholder: {
            name: 'Enter name',
            parent: 'Enter parent name',
            description: 'Enter description',
            color: 'Select color',
            maxLength: 'Enter max length',
        },
        text: {
            display: 'Display',
        },
        message: {
            createActivityCodeSuccess: 'Create activity code successfully',
            updateActivityCodeSuccess: 'Update activity code successfully',
            deleteActivityCodeSuccess: 'Delete activity code successfully',
            setGlobalActivityCodeSuccess: 'Set activity code to global data successfully',
            createActivityCodeValueSuccess: 'Create activity code value successfully',
            updateActivityCodeValueSuccess: 'Update activity code value successfully',
            deleteActivityCodeValueSuccess: 'Delete activity code value successfully',
            assignSuccess: 'Assign activity code value successfully',
            resetSuccess: 'Reset activity code value successfully',
            updateDisplaySuccess: 'Update activity code display successfully',
            confirmDeleteActivityCode:
                'Are you sure you want to delete the selected code?',
            confirmDeleteActivityCodeValue:
                'Are you sure you want to remove the selected Activity Code Value?',
            errorSelectTaskIdsEmpty: 'Please select tasks',
            confirmSetGlobalActivityCode:
                'Are you sure setting the selected code to global data?',
        },
    },
    baseline: {
        messages: {
            updatedBaselineConfiguration: 'Updated baseline configuration',
        },
        position: {
            top: 'Top',
            bottom: 'Bottom',
            around: 'Around',
        },
    },
    viewer3D: {
        tooltip: 'Drag to resize',
    },
    planningForm: {
        title: {
            update: 'Update planning',
        },
        labels: {
            planningId: 'Planning ID',
            name: 'Planning name',
            currency: 'Currency',
            durationType: 'Default duration type',
            durationFormat: 'Duration format',
            durationDecimalFormat: 'Duration decimal format',
            defaultDuration: 'Default duration',
            activityType: 'Default activity type',
            percentCompleteType: 'Default percent complete type',
            projectStart: 'Project start',
            dataDate: 'Data date',
            autoScheduling: 'Auto scheduling',
            criticalThreshold: 'Define critical activities',
            activityCodeId: 'Assign activity code',
            isDisplayHourOfAllDateTime: 'Display hours of all date-time',
        },
        placeholders: {
            planningId: 'Enter planning ID',
            name: 'Enter planning name',
            currency: 'Select currency',
            durationType: 'Select duration type',
            durationFormat: 'Select duration format',
            durationDecimalFormat: 'Select duration decimal format',
            defaultDuration: 'Enter duration',
            activityType: 'Select activity type',
            percentCompleteType: 'Select percent complete type',
            projectStart: 'Choose project start date',
            dataDate: 'Choose data date',
            criticalThreshold: 'Enter critical activity',
            activityCodeId: 'Choose assign activity code',
        },
        messages: {
            createSuccess: 'Create planning successfully',
            updateSuccess: 'Update planning successfully',
            errors: {
                maxProjectStart: 'Project start date must be less then data date',
                minDataDate: 'Data date must be greater then project start date',
            },
        },
        currency: {
            [CurrencyType.EURO]: 'Euro',
            [CurrencyType.DOLLAR]: 'Dollar',
        },
        durationFormat: {
            [TaskDurationFormat.HOUR]: 'Hour',
            [TaskDurationFormat.DAY]: 'Day',
            [TaskDurationFormat.WEEK]: 'Week',
            [TaskDurationFormat.MONTH]: 'Month',
            [TaskDurationFormat.YEAR]: 'Year',
        },
    },
    scheduleOption: {
        title: {
            update: 'Reschedule options',
        },
        labels: {
            inProgressTaskOption: 'Scheduling in progress task option',
            calendarForDelayLink: 'Define calendar for scheduling link delay',
            useExpectedFinish: 'Use expected finish',
            disableScheduleTaskWithoutLinkAndConstraints:
                ' Do not reschedule tasks without links and constraints',
            criticalTaskWithoutSuccessor: ' The task without successor is critical',
        },
        placeholders: {
            inProgressTaskOption: 'Choose scheduling in progress task option',
            calendarForDelayLink: 'Choose define calendar for scheduling link delay',
        },
        inProgressTaskOption: {
            [InProgressTaskOption.RETAINED_LOGIC]: 'Retained Logic',
            [InProgressTaskOption.PROGRESS_OVERRIDE]: 'Progress Override',
            [InProgressTaskOption.ACTUAL_DATES]: 'Actual Dates',
        },
        calendarForDelayLink: {
            [CalendarForDelayLinkOption.PREDECESSOR]: 'Predecessor Activity Calendar',
            [CalendarForDelayLinkOption.SUCCESSOR]: 'Successor Activity Calendar',
            [CalendarForDelayLinkOption.DEFAULT]: 'Project Default Calendar',
        },
        circularLinks: {
            title: 'SCHEDULING REPORT',
            content: "The planning couldn't be rescheduled due to circular links",
            cycle: 'Cycle',
            isFormedTask: 'is formed by the tasks:',
        },
    },
    exportForm: {
        title: {
            primavera: 'Export planning to Primavera P6',
            excel: 'Export planning to Excel',
            exportOptions: 'Choose how you would like to process each object',
            summary: 'Export Result',
        },
        labels: {
            planningId: 'Planning ID',
            planningName: 'Planning name',
            fileName: 'File name',
            visibleOnly: 'Only export visible tasks',
            visibleOnlyExcel: 'Only export visible tasks, resources, Assignments',
            includeUnitLabels: 'Include unit labels',
        },
        placeholders: {
            planningId: 'Enter new planning ID',
            planningName: 'Enter new planning name',
            fileName: 'Enter file name',
        },
        selectionColumns: {
            object: 'Object',
            command: 'Command',
        },
        exportObjects: {
            tasks: 'Tasks',
            links: 'Links',
            calendars: 'Calendars',
            resources: 'Resources',
            resource_assignments: 'Resource assignments',
            resource_groups: 'Resource groups',
            resource_group_assignments: 'Resource group assignments',
            activity_codes: 'Activity codes',
            activity_code_assignments: 'Activity code assignments',
            user_fields: 'User fields',
            user_field_values: 'User field values',
            duration_units: 'Resource units',
            resource_units: 'Duration units',
        },
        message: {
            exportSuccess: 'Export planning successfully',
            resourceUnitNotFound: 'Resource Unit is Required',
            durationUnitNotFound: 'Duration Unit is Required',
            unitNotFound: 'Resource Unit and Duration Unit is Required',
        },
    },
    importXML: {
        importXML: 'Import XML',
        importXMLFile: 'Import XML file',
        importXMLFromAbs: 'Import XML file from ABS',
        importXMLFromLocal: 'Or upload file from your computer',
        importSummary: 'Import Result',
        importOptionTitle: 'How would you like to import the file?',
        underSelectedTask:
            'Use the Selected Task to merge the data into the current Project',
        endOfProject: 'Append the data to the end of the current Project',
        replacePlanning: 'Replace the data with the current Project',
        importObjectOption: 'Choose how you would like to process each object',
        noSatisfyingOption: 'Please select a task to import this file',
        message: {
            success: 'Import successfully',
        },
        summary: {
            activityCode: 'Activity code',
            activityCodeValue: 'Activity code value',
            calendar: 'Calendar',
            link: 'Link',
            resource: 'Resource',
            task: 'Task',
            userField: 'User field',
            objects: 'objects',
            resourceCode: 'Resource code',
            resourceCodeType: 'Resource code type',
            resourceAssignment: 'Resource assignment',
            resourceGroup: 'Resource group',
            resourceGroupAssignment: 'Resource group assignment',
            action: {
                created: 'created',
                updated: 'updated',
                processed: 'processed',
                exported: 'exported',
                deleted: 'deleted',
            },
        },
    },
    importExcel: {
        importExcel: 'Import excel',
        importExcelFile: 'Import excel file',
        importExcelFromAbs: 'Import excel file from ABS',
        importExcelFromLocal: 'Or upload file from your computer',
        importSummary: 'Import Result',
        importOptionTitle: 'How would you like to import the file?',
        underSelectedTask:
            'Use the Selected Task to merge the data into the current Project',
        endOfProject: 'Append the data to the end of the current Project',
        replacePlanning: 'Replace the data with the current Project',
        importObjectOption: 'Choose how you would like to process each object',
        noSatisfyingOption: 'Please select a task to import this file',
        message: {
            success: 'Import successfully',
        },
        summary: {
            activityCode: 'Activity code',
            activityCodeValue: 'Activity code value',
            calendar: 'Calendar',
            link: 'Link',
            resource: 'Resource',
            task: 'Task',
            userField: 'User field',
            objects: 'objects',
            resourceCode: 'Resource code',
            resourceCodeType: 'Resource code type',
            resourceAssignment: 'Resource assignment',
            resourceGroup: 'Resource group',
            resourceGroupAssignment: 'Resource group assignment',
            action: {
                created: 'created',
                updated: 'updated',
                processed: 'processed',
                exported: 'exported',
            },
        },
    },
    linkForm: {
        title: {
            createLink: 'Create link',
            linkDetail: 'Link detail',
            deleteLink: 'Delete link',
            editPredecessor: 'Edit predecessor',
            editSuccessor: 'Edit successor',
            addPredecessor: 'Add predecessor(s)',
            addSuccessor: 'Add successor(s)',
            currentLinks: 'Current link(s)',
            candidateLinks: 'Candidate for new link(s)',
            drivingRelationship: 'Driving relationship',
        },
        dependency: {
            predecessor: 'Predecessor',
            successor: 'Successor',
        },
        tree: {
            columnName: 'Activity name',
            columnID: 'Activity ID',
            columnType: 'Activity type',
        },
        properties: {
            source: 'Source name',
            target: 'Target name',
            type: 'Link type',
            delay: 'Delay ({unit})',
        },
        placeholder: {
            type: 'Enter link type',
            delay: 'Add lead time to a task',
        },
        buttons: {
            addLink: 'Add link',
        },
        linkType: {
            [LinkType.START_TO_START]: 'Start to start',
            [LinkType.START_TO_FINISH]: 'Start to finish',
            [LinkType.FINISH_TO_START]: 'Finish to start',
            [LinkType.FINISH_TO_FINISH]: 'Finish to finish',
        },
        linkListColumn: {
            dependency: 'Dependency',
            id: 'Activity ID',
            name: 'Activity name',
            start: 'Start',
            finish: 'Finish',
            linkType: 'Link type',
            delay: 'Delay',
            action: 'Action',
        },
        messages: {
            success: 'Create links successfully',
            updateSuccess: 'Update link successfully',
            linkExistInGantt: 'This link already exists',
            linkExistInList: 'This link is already in the list below',
            confirmDeleteLink: 'Are you sure to delete this link?',
            errorSelectTasksLinkToEmpty: 'The selected task list is empty or invalid',
            errorLinkListToCreateEmpty:
                'Please add the link you want to create to the list',
        },
    },
    contextMenu: {
        options: {
            addLink: 'Add link',
            currentLinks: 'Current link(s)',
            taskDetail: 'Task detail',
        },
    },
    saveAsForm: {
        titles: {
            saveResource: 'Save resource',
            saveXML: 'Save XML',
        },
        errors: {
            notFoundResource: 'Not found resource',
        },
    },
    startAppearance: {
        title: 'Start Appearance',
        contentDetail: {
            labelColor: 'Color',
            labelTransparency: 'Transparency',
            transparencyInput: 'Enter transparency',
        },
    },
    activeAppearance: {
        title: 'Active Appearance',
        contentDetail: {
            labelColor: 'Color',
            labelTransparency: 'Transparency',
            labelStartTransparency: 'Start Transparency',
            labelEndTransparency: 'End Transparency',
            labelTransparencyInterpolation: 'Transparency Interpolation',
            transparencyInput: 'Enter transparency',
        },
    },
    endAppearance: {
        title: 'End Appearance',
        contentDetail: {
            labelColor: 'Color',
            labelTransparency: 'Transparency',
            transparencyInput: 'Enter transparency',
        },
    },
    calendar: {
        title: {
            calendarList: 'Calendar List',
        },
        assign: {
            title: 'Assign calendar',
            button: {
                assign: 'Assign',
                cancel: 'Cancel',
            },
            message: {
                success: 'Assign calendar successful',
            },
        },
    },
    generateCustomId: {
        title: 'Please select user field for identification:',
        attribute: {
            label: 'Attribute Name',
        },
        titleOptionSameValue: {
            label: 'The selected attribute are the same for several objects',
        },
    },
    filterForm: {
        name: {
            label: 'Name',
            placeholder: 'Enter name',
        },
        createdBy: {
            label: 'Created by',
            placeholder: 'Enter created by name',
        },
        planning: {
            label: 'Planning name',
            placeholder: 'Enter planning name',
        },
        type: {
            label: 'Type',
        },
    },
    delegateForm: {
        selectType: {
            label: 'Select delegate type',
            option: {
                delegate: 'Delegate',
                selectiveDelegate: 'Selective Delegate',
            },
        },
        selectDelegateTask: {
            label: 'Select delegate task',
        },
        selectStartTaskForId: {
            label: 'Select start task to generate milestone id',
        },
        selectFinishTaskForId: {
            label: 'Select finish task to generate milestone id',
        },
    },
    taskRule: {
        title: {
            popup: 'Task rule List',
            createRule: 'Create rule',
            updateRule: 'Update rule',
            assign: 'Assign Task Rule',
        },
        btn: {
            assign: 'Assign',
        },
        form: {
            label: {
                ruleId: 'Rule Id',
                name: 'Name',
                description: 'Description',
                rate: 'Rate',
                unit: 'Unit',
                period: 'Period',
                select: 'Task Rules',
            },
            placehoder: {
                ruleId: 'Enter Rule Id',
                name: 'Enter Name',
                description: 'Enter Description',
                rate: 'Enter Rate',
                unit: 'Select Unit',
                period: 'Select Period',
                taskRule: 'Select Task rule',
            },
        },
        table: {
            id: 'Id',
            name: 'Name',
            description: 'Description',
            rate: 'Rate',
            unitPerPeriod: 'Unit/Period',
        },
        msg: {
            update: {
                success: 'Updated task rule successfully',
                error: 'Updated task rule failed',
            },
            create: {
                success: 'Create task rule successfully',
                error: 'Create task rule failed',
            },
            delete: {
                success: 'Delete task rule successfully',
                error: 'Delete task rule failed',
            },
            setToGlobal: {
                success: 'Set task rule to global successfully',
                error: 'Set task rule to global failed',
            },
        },
    },
    optimize: {
        success: 'Optimize for synchronisation successfully',
        error: 'Optimize for synchronisation failed',
    },
    synthesis: {
        success: 'Synthesis planning file successfully',
        error: 'Synthesis planning file failed',
    },
    simulation: {
        label: {
            startDate: 'Start date',
            endDate: 'End date',
            speed: 'Speed',
            delay: 'Delay',
        },
        placeholders: {
            startDate: 'Select start date',
            endDate: 'Select end date',
            delay: 'Enter delay',
            speed: 'Select speed',
        },
    },
};
