import { useField, useForm } from 'vee-validate';
import { ElLoading } from 'element-plus';
import {
    DATE_TIME_FORMAT,
    FILE_NAME_MAX_LENGTH,
    INPUT_TEXT_MAX_LENGTH,
    INTEGER_POSITIVE_MAX_VALUE,
    INTEGER_POSITIVE_MIN_VALUE,
    Regex,
    TEXTAREA_MAX_LENGTH,
} from '@/common/constants';
import yup from '@/plugins/yup';
import {
    showConfirmPopUpFunction,
    showErrorNotificationFunction,
    showSuccessNotificationFunction,
} from '@/common/helpers';
import { projectService } from '../services/project.service';
import { computed } from 'vue';
import { projectModule } from '../store';
import { IBodyResponse } from '@/common/interfaces';
import { IProject } from '../interfaces';
import {
    LATITUDE_MIN_VALUE,
    LATITUDE_MAX_VALUE,
    LONGITUDE_MIN_VALUE,
    LONGITUDE_MAX_VALUE,
    ProjectCategories,
} from '../constants';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

const validateProjectFormSchema = yup.object({
    name: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).nullable().required(),
    adminId: yup.string().max(INPUT_TEXT_MAX_LENGTH).nullable().required(),
    dataDate: yup.date().required(),
    category: yup
        .string()
        .max(INPUT_TEXT_MAX_LENGTH)
        .oneOf(Object.values(ProjectCategories))
        .nullable()
        .required(),
    description: yup.string().max(TEXTAREA_MAX_LENGTH).nullable(),
    postalCode: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).nullable().required(),
    latitude: yup
        .number()
        .min(LATITUDE_MIN_VALUE)
        .max(LATITUDE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? null : +val))
        .nullable()
        .required(),
    longitude: yup
        .number()
        .min(LONGITUDE_MIN_VALUE)
        .max(LONGITUDE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? null : +val))
        .nullable()
        .required(),
    taskIdPrefix: yup.string().max(INPUT_TEXT_MAX_LENGTH).trim().required(),
    taskIdSuffix: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    taskIdIncrement: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    resourceIdPrefix: yup.string().max(INPUT_TEXT_MAX_LENGTH).trim().required(),
    resourceIdSuffix: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    resourceIdIncrement: yup
        .number()
        .min(INTEGER_POSITIVE_MIN_VALUE)
        .max(INTEGER_POSITIVE_MAX_VALUE)
        .transform((val) => (isNaN(val) ? 0 : +val))
        .required(),
    fileId: yup.string().max(INPUT_TEXT_MAX_LENGTH).trim().required(),
    planningId: yup.string().when('isCreate', {
        is: true,
        then: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).required(),
        otherwise: yup.string().optional().nullable(),
    }),
    planningName: yup.string().when('isCreate', {
        is: true,
        then: yup
            .string()
            .matches(Regex.FILE_NAME)
            .trim()
            .max(FILE_NAME_MAX_LENGTH)
            .required(),
        otherwise: yup.string().optional().nullable(),
    }),
    isCreate: yup.boolean().required(),
});

export function setupProjectForm() {
    const { t } = useI18n();
    const isCreateProject = computed(() => !projectModule.selectedProjectIdToEdit);
    const initValues = {
        name: '',
        category: '',
        adminId: '',
        dataDate: undefined,
        description: '',
        postalCode: '',
        taskIdPrefix: '',
        taskIdSuffix: undefined,
        taskIdIncrement: undefined,
        resourceIdPrefix: '',
        resourceIdSuffix: undefined,
        resourceIdIncrement: undefined,
        fileId: undefined,
        latitude: undefined,
        longitude: undefined,
        planningId: '',
        planningName: '',
        isCreate: true,
    };
    const { handleSubmit, errors, resetForm, validate, setFieldValue } = useForm({
        initialValues: initValues,
        validationSchema: validateProjectFormSchema,
    });

    const onOpen = async () => {
        const selectedProjectId = computed(() => projectModule.selectedProjectIdToEdit);
        if (!isCreateProject.value) {
            const loading = ElLoading.service({
                target: '.project-form',
            });
            const response = (await projectService.getDetail(
                selectedProjectId.value || '',
            )) as IBodyResponse<IProject>;
            loading.close();
            if (response.success) {
                resetForm({
                    values: {
                        name: response.data.name,
                        adminId: response.data.adminId,
                        dataDate: new Date(response.data.dataDate),
                        description: response.data.description,
                        category: response.data.category,
                        postalCode: response.data.postalCode,
                        latitude: response.data.latitude,
                        longitude: response.data.longitude,
                        taskIdPrefix: response.data.taskIdPrefix,
                        taskIdSuffix: response.data.taskIdSuffix,
                        taskIdIncrement: response.data.taskIdIncrement,
                        resourceIdPrefix: response.data.resourceIdPrefix,
                        resourceIdSuffix: response.data.resourceIdSuffix,
                        resourceIdIncrement: response.data.resourceIdIncrement,
                        fileId: response.data.fileId,
                        planningName: '',
                        planningId: '',
                        isCreate: false,
                    },
                });
            } else {
                showErrorNotificationFunction(response.message as string);
            }
        } else {
            resetForm({
                values: initValues,
            });
        }
    };

    const onSubmit = handleSubmit(async (values) => {
        delete values.isCreate;
        const loading = ElLoading.service({
            target: '.project-form',
        });
        let response: IBodyResponse<IProject>;
        if (isCreateProject.value) {
            response = (await projectService.create({
                ...values,
                dataDate: moment(values.dataDate)
                    .utc()
                    .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
                timezone: moment.tz.guess(),
            })) as unknown as IBodyResponse<IProject>;
        } else {
            delete values.planningId;
            delete values.planningName;
            const selectedProjectId = computed(
                () => projectModule.selectedProjectIdToEdit,
            );
            response = (await projectService.update(selectedProjectId.value || NaN, {
                ...values,
                dataDate: moment(values.dataDate)
                    .utc()
                    .format(DATE_TIME_FORMAT.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON),
            })) as unknown as IBodyResponse<IProject>;
        }
        loading.close();
        if (response.success) {
            showSuccessNotificationFunction(
                !isCreateProject.value
                    ? t('project.message.updateSuccess')
                    : t('project.message.createSuccess'),
            );
            projectModule.setSelectedProjectIdToEdit(null);
            projectModule.setIsShowProjectForm(false);
            const loading = ElLoading.service({
                target: '.page-wrapper',
            });
            await projectModule.getProjectList();
            loading.close();
        } else {
            showErrorNotificationFunction(response.message as string);
        }
    });

    const onDelete = async () => {
        const isConfirm = await showConfirmPopUpFunction(
            t('project.popup.delete.text') as string,
            t('project.popup.delete.title') as string,
            {
                type: 'error',
            },
        );
        if (isConfirm) {
            const loading = ElLoading.service({
                target: '.project-form',
            });
            const selectedProjectId = computed(
                () => projectModule.selectedProjectIdToEdit,
            );
            const response = (await projectService.delete(
                selectedProjectId.value || '',
            )) as unknown as IBodyResponse;
            loading.close();
            if (response.success) {
                showSuccessNotificationFunction(t('project.message.deleteSuccess'));
                projectModule.setSelectedProjectIdToEdit(null);
                projectModule.setIsShowProjectForm(false);
                const loading = ElLoading.service({
                    target: '.page-wrapper',
                });
                await projectModule.getProjectList();
                loading.close();
            } else {
                showErrorNotificationFunction(response.message as string);
            }
        }
        return false;
    };
    const { value: name } = useField('name');
    const { value: adminId } = useField('adminId');
    const { value: dataDate } = useField('dataDate');
    const { value: category } = useField('category');
    const { value: description } = useField('description');
    const { value: postalCode } = useField('postalCode');
    const { value: taskIdPrefix } = useField('taskIdPrefix');
    const { value: taskIdSuffix } = useField('taskIdSuffix');
    const { value: taskIdIncrement } = useField('taskIdIncrement');
    const { value: resourceIdPrefix } = useField('resourceIdPrefix');
    const { value: resourceIdSuffix } = useField('resourceIdSuffix');
    const { value: resourceIdIncrement } = useField('resourceIdIncrement');
    const { value: latitude } = useField('latitude');
    const { value: fileId } = useField('fileId');
    const { value: longitude } = useField('longitude');
    const { value: planningName } = useField('planningName');
    const { value: planningId } = useField('planningId');
    const { value: isCreate } = useField('isCreate');

    return {
        errors,
        name,
        adminId,
        dataDate,
        category,
        description,
        postalCode,
        taskIdPrefix,
        taskIdSuffix,
        taskIdIncrement,
        resourceIdPrefix,
        resourceIdSuffix,
        resourceIdIncrement,
        latitude,
        longitude,
        planningName,
        planningId,
        isCreate,
        fileId,
        validate,
        onOpen,
        onSubmit,
        resetForm,
        onDelete,
        setFieldValue,
    };
}
