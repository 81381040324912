import { IProjectCalendar } from '@/features/4D-planning/interfaces';
import { storage } from './localStorage';

export const enum GANT_CHART_STORAGE_KEY {
    GANTT_GRID_DISPLAYING = 'GANTT_GRID_DISPLAYING',
    GANTT_GRID_ORDER_COLUMN = 'GANTT_GRID_ORDER_COLUMN',
    GANTT_GRID_WIDTH = 'GANTT_GRID_WIDTH',
    GANTT_ADDITIONAL_TASK_FIELD = 'GANTT_ADDITIONAL_TASK_FIELD',
    GANTT_ACTIVITY_CODE_DISPLAYING = 'GANTT_ACTIVITY_CODE_DISPLAYING',
    GANTT_ACTIVITY_CODE_NAME_DISPLAYING = 'GANTT_ACTIVITY_CODE_NAME_DISPLAYING',
    GANTT_CALENDAR = 'GANTT_CALENDAR',
}
class GantChartStorageService {
    setGridWitdh(value: number): void {
        storage.setLocalStorage(
            GANT_CHART_STORAGE_KEY.GANTT_GRID_WIDTH,
            JSON.stringify(value),
        );
    }
    getGridWidth(): number | undefined {
        const width = +storage.getLocalStorage(GANT_CHART_STORAGE_KEY.GANTT_GRID_WIDTH);
        return width || undefined;
    }
    resetGridWidth(): void {
        storage.setLocalStorage(GANT_CHART_STORAGE_KEY.GANTT_GRID_WIDTH, '');
    }

    setDisplayActivityCode(value: boolean): void {
        storage.setLocalStorage(
            GANT_CHART_STORAGE_KEY.GANTT_ACTIVITY_CODE_DISPLAYING,
            JSON.stringify(value),
        );
    }
    getDisplayActivityCode(): boolean {
        const display = storage.getLocalStorage(
            GANT_CHART_STORAGE_KEY.GANTT_ACTIVITY_CODE_DISPLAYING,
        );
        return display === 'true';
    }
    resetDisplayActivityCode(): void {
        storage.setLocalStorage(
            GANT_CHART_STORAGE_KEY.GANTT_ACTIVITY_CODE_DISPLAYING,
            '',
        );
    }

    setCalendar(value: any[]): void {
        storage.setLocalStorage(
            GANT_CHART_STORAGE_KEY.GANTT_CALENDAR,
            JSON.stringify(value),
        );
    }
    getCalendar(): any[] {
        return storage.getLocalStorage(GANT_CHART_STORAGE_KEY.GANTT_CALENDAR).length
            ? JSON.parse(storage.getLocalStorage(GANT_CHART_STORAGE_KEY.GANTT_CALENDAR))
            : [];
    }
    resetCalendar(): void {
        storage.setLocalStorage(GANT_CHART_STORAGE_KEY.GANTT_CALENDAR, '');
    }
}

const ganttChartStorage = new GantChartStorageService();
export default ganttChartStorage;
