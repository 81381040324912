export enum KEY_CODE {
    ENTER = 'Enter',
    v = 'v',
    c = 'c',
    DELETE = 'Delete',
    BACKSPACE = 'Backspace',
    TAB = 'Tab',
    ARROWRIGHT = 'ArrowRight',
    ARROWLEFT = 'ArrowLeft',
    MINUS = 'Minus',
    NUMPADSUBTRACT = 'NumpadSubtract',
}

export const OTP_LENGTH = 6;
